<template>
  <default-layout>
    <div class="events" :class="{empty: !showEvents}">
      <h1 class="events__title">{{$t('events')}}</h1>
      <div class="events__cards" v-if="showEvents">
        <div class="events__cards__event" v-for="(event, i) in events" :key="i">
          <h2 class="events__cards__event__title" v-if="event.firstEvent">{{event.date | month}}</h2>
          <span class="events__cards__event__borde"><span class="line" :class="{'no-line': event.firstEvent}"></span></span>
          <div class="events__cards__event__content">
            <expandable-image class="events__cards__event__content__img" :src="eventImages[event.id]" :class="{loading: !eventImages[event.id]}"/>
            <div class="events__cards__event__content__info">
              <span class="events__cards__event__content__info__date" v-if="event.date">{{`${getDate(event.date)}${getHour(event.hour)}`}}</span>
              <span class="events__cards__event__content__info__name">{{event.name}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="events__empty" v-if="!showEvents">
        <img src="@/assets/confetti.svg" alt="">
        <span>{{$t('emptyEvents')}}</span>
      </div>
    </div>
  </default-layout>
</template>
<script>
  import DefaultLayout from '@/layouts/DefaultLayout';
  import moment from 'moment';

  export default {
    components: {DefaultLayout},
    metaInfo() {
      return {title: this.$t("tabs.events")}
    },
      watch: {
      '$i18n.locale'() {
        moment.locale(this.$i18n.locale);
      },
      'eventIdList'() {
        this.eventIdList.forEach(eventId => {
          this.getImage(eventId);
        });
      }
    },
    filters: {
      month(date) {
        return moment(date).format("MMMM");
      }
    },
    data() {
      return {
        events: [],
        eventImages: {}
      }
    },
    computed:{
      showEvents() {
        return this.events.length > 0;
      },
      eventIdList() {
        return this.events && this.events.map(event => event.id);
      }
    },
    mounted() {
      moment.locale(this.$i18n.locale);
      this.getEvents();
    },
    methods: {
      async getImage(eventId) {
        let src = await this.$store.dispatch('getEventPicture', eventId);
        this.$set(this.eventImages, eventId, src ? 'data:image/png;base64,'.concat(src) : null);
      },
      async getEvents() {
        let events = await this.$store.dispatch('getNextEvents');
        this.orderEvents(events);
      },
      orderEvents(events) {
        let eventMonths = [];
        events.forEach(event => {
          let mouth = moment(event.date).format("YYYY-MM");
          if(eventMonths.indexOf(mouth) > -1) {
            event.firstEvent = false;
          } else {
            event.firstEvent = true;
            eventMonths.push(mouth);
          }
        });
        this.$set(this, 'events', events);
      },
      getDate(date) {
        if (date) {
          let d = moment(date);
          if (this.$i18n.locale === 'es') {
            return `${d.format("dddd DD")} de ${d.format("MMMM, YYYY")}`;
          } else {
            return `${d.format("dddd MMMM Do, YYYY")}`;
          }
        }
        return '';
      },
      getHour(hour) {
        if (hour) {
            return ` ${moment(hour).format("hh:mma")}`;
        }
        return '';
      }
    }
  }
</script>

<style lang="scss">
  .events {
    position: relative;
    letter-spacing: 0.05em;
    display: grid;
    grid-template-rows: auto 1fr;
    &__empty {
      display: grid;
      grid-row-gap: 1.5rem;
      grid-auto-rows: min-content;
      justify-items: center;
      align-items: center;
      span {
        font-family: $font-secondary;
        font-weight: normal;
        font-size: 1rem;
        text-transform: uppercase;
        color: $brown-opacity-color;
      }
    }
    &__title {
      font-size: 1.5rem;
    }
    &__cards {
      overflow: hidden;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(343px, 1fr));
      grid-gap: 1.5rem;
      &__event {
        padding-bottom: 1.5rem;
        display: grid;
        grid-template-areas: "month" "borde" "content";
        align-items: end;
        &__title {
          grid-area: month;
          color: $brown-opacity-color;
          font-size: 1.5rem;
        }
        &__content {
          grid-area: content;
          background: #FFFFFF;
          border-radius: 1rem;
          width: 100%;
          &__img {
            &:not(.expanded) {
              img {
                height: 100vh;
                border-radius: 1rem 1rem 0 0;
              }
            }
            &.loading {
              pointer-events: none;
              img {
                background-color: #D5D3D2;
                -webkit-animation: fadeIn .35s ease-in alternate infinite;
                -moz-animation: fadeIn .35s ease-in alternate infinite;
                animation: fadeIn .35s ease-in alternate infinite;
              }
            }
            img {
              max-height: 240px;
              object-fit: cover;
            }
          }
          &__info {
            display: grid;
            grid-template-rows: auto 1fr;
            grid-row-gap: .8rem;
            text-transform: uppercase;
              padding: 1.5rem;
              min-height: rem(144px);
            &__date, &__name {
              font-family: $font-secondary;
            }
            &__date {
              color: $orange-color;
            }
            &__name {
              font-size: 1.5rem;
              line-height: rem(33px);
            }
          }
        }
      }
    }
    @include respond-to("medium and up") {
      min-height: rem(596px);
      grid-row-gap: 2.4rem;
      padding: 2.5rem 1.5rem;
      &__empty {
        margin-top: -5.5rem;
      }
      &__title {
        font-size: 2rem;
      }
      &__cards {
        &__event {
          grid-template-rows: rem(36px) 2px 1fr;
          grid-row-gap: 21px;
          &__borde {
            position: relative;
            grid-area: borde;
            width: 100%;
            .line {
              position: absolute;
              left: -1.5rem;
              height: 1px;
              width: calc(100% + 1.5rem);
              background-color: $brown-opacity-color;
            }
            .no-line {
              left: 0;
              width: 100%;
            }
          }
        }
        &__content {
          box-shadow: 0px 0px 2rem rgba(0, 0, 0, 0.24);
        }
      }
    }
    @include respond-to("small and down") {
      padding: 1rem 0 rem(120px) 0;
      grid-row-gap: 1.5rem;
      min-height: rem(565px);
      &__empty {
        grid-row-gap: 1rem;
      }
      &__title {
        font-size: 1.5rem;
      }
      &__cards {
        grid-template-columns: 1fr;
        grid-row-gap: 1rem;
        &__event {
          padding-bottom: 0;
          &__title {
            font-size: 1rem;
            line-height: 1rem;
            border-bottom: 1px solid $brown-opacity-color;
            padding-bottom: 1rem;
            margin-bottom: 1rem;
          }
          &__borde {
            display: none;
          }
          &__content {
            &__img {
              img {
                max-height: 190px;
                object-fit: cover;
              }
            }
            &__info {
              padding: 1rem;
              min-height: rem(102px);
              grid-row-gap: rem(4px);
              &__name, &__date {
                font-size: 1rem;
              }
            }
          }
        }
      }
    }
    &.empty {
      grid-row-gap: 0;
      align-items: center;
    }
  }
</style>