<template>
    <div class="file-wrapper">
        <div class="file-wrapper__header">
            <h1>Documentación Requerida</h1>
            <p>Los documentos que necesitas para poder aplicar a cualquiera de nuestras vacantes</p>
        </div>
        <div class="file-wrapper__body">
            <file-uploader v-model="form" @onInput="updateForm" :attr="files['imss']"/>
            <file-uploader v-model="form" @onInput="updateForm" :attr="files['birthCertificate']"/>
            <file-uploader v-model="form" @onInput="updateForm" :attr="files['poa']"/>
            <file-uploader v-model="form" @onInput="updateForm" :attr="files['curp']"/>
            <file-uploader v-model="form" @onInput="updateForm" :attr="files['ine']"/>
            <file-uploader v-model="form" @onInput="updateForm" :attr="files['rfc']"/>
            <file-uploader v-model="form" @onInput="updateForm" :attr="files['pos']"/>
            <file-uploader v-model="form" @onInput="updateForm" :attr="files['driverLicense']" v-if="isDriver"/>
        </div>
        <div class="file-wrapper__footer">

            <p class="message__title">Carta de No Antecedentes Penales</p>
            <p class="message">En caso de ser contratado, deberás entregar una Carta de No Antecedentes Penales cuya antigüedad no sea mayor a 1 año a partir de la fecha de su expedición.</p>
            <p class="message">Puedes escanear o tomarle una fotografía a estos documentos y subir cada archivo aquí mismo en este portal. (Únicamente archivos JPG o PDF y no mayores a 2MB).</p>
            <p class="message">Si no tienes alguno de estos documentos al momento de llenar esta solicitud, puedes enviarlos posteriormente al correo: <span class="email">recursoshumanos@lascanadas.com</span>.</p>
            <p class="message">O si lo prefieres, puedes traer una copia de cada documento (no recibimos originales) a nuestra oficina de recursos humanos en Las Cañadas Campamento, de Domingo a Viernes de 8am a 6pm, Sábados de 8am a 1pm.</p>
            <div class="alert">
                <img src="@/assets/info.svg" alt="">
                <p>Para poder considerar tu solicitud, es necesario que toda la documentación requerida haya sido enviada o entregada por cualquier medio antes mencionado. Si falta alguno de los documentos requeridos, tu solicitud no será considerada.</p>
            </div>
        </div>
    </div>
</template>
<script>
import FileUploader from '@/components/files/FileUploader';

export default {
    name: 'JobApplicationFileWrapper',
    components: { FileUploader },
    props: ['hasStudies', 'hasCertificate', 'isDriver'],
    data() {
        return {
            form: new FormData(),
            files: {
              imss: {
                name: 'imss',
                title: 'Documento Oficial que contenga tu número de afiliación al IMSS'
              },
              birthCertificate: {
                name: 'acta',
                title: 'Acta de Nacimiento'
              },
              poa: {
                name: 'comprobante_de_domicilio',
                title: 'Comprobante de Domicilio Actual (No mayor a 3 meses de antigüedad)'
              },
              curp: {
                name: 'curp',
                title: 'CURP'
              },
              ine: {
                name: 'ine',
                title: 'Identificación Oficial expedida por el INE',
                bothSides: true,
                instructions: 'Indica la forma en la que proporcionarás tu INE'
              },
              rfc: {
                name: 'rfc',
                title: 'RFC - Registro Federal de Contribuyentes (Documento Oficial expedido por el SAT)'
              },
              pos: {
                name: 'comprobante_de_estudios',
                title: 'Último comprobante de estudios con Promedio de Aprovechamiento'
              },
              driverLicense: {
                name: 'licencia_de_conducir',
                title: 'Licencia de conducir por ambos lados',
                bothSides: true,
                instructions: 'Indica la forma en la que proporcionarás tu Licencia de Conducir'
              }
            }
        }
    },
    methods: {
        updateForm(value) {
            this.form = value;
        },
        getFiles() {
            const files = new FormData();
            const fileNames = [];
            for(let key of this.form.values()) {

                let index = fileNames.findIndex(name => name === key.name);
                if (index > -1) {
                    key = new File([key], key.name.concat("_2"), {
                        type: key.type,
                    });
                }
                fileNames.push(key.name);
                files.append('files', key);
            }

            return files;
        },
    },
}

</script>
<style lang="scss" scoped>
.file-wrapper {
    &__header {
        h1 {
            margin-bottom: 24px;
        }
        p {
            font-family: $font-primary;
            font-weight: 500;
            font-size: 16px;
            line-height: 16px;
            letter-spacing: 0.05em;
            color: $light-gray;
        }
    }

    &__footer {
        margin: 24px 0px;
        .message {
            font-family: $font-primary;
            font-weight: 500;
            font-size: 16px;
            line-height: 16px;
            letter-spacing: 0.05em;
            color: $light-gray;
            margin-bottom: 16px;
            
            .email {
                color: $blue-great-color;
                text-decoration: underline;
            }

            .title {
              margin-bottom: 0;
              color: $dark-brown-color;
            }
        }

        .alert {
            display: grid;
            grid-template-columns: auto 1fr;
            background: #FFDEDE;
            border-radius: 8px;
            padding: 8px;
            gap: 8px;

            img {
                align-self: center;
            }

            p {
                font-family: $font-primary;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.05em;
                color: $red-color2;
            }

            @include respond-to("small and down") {
                img {
                    align-self: start;
                }
            }
        }
    }
}
</style>