<template>
  <div class="activity-indicator" v-show="inRemoteActivity">
    <div class="activity-indicator__bar"></div>
  </div>
</template>

<script>
  export default {
    name: 'RemoteActivity',
    computed: {
      inRemoteActivity: {
        get() {
          return this.$store.getters.inRemoteActivity;
        },
      },
    },

  };
</script>

<style lang="scss" scoped>

  @keyframes bar-animation {
    0% {
      transform: scaleX(0);
      transform-origin: left;
    }
    25% {
      transform: scaleX(1);
      transform-origin: left;
    }
    26% {
      transform-origin: right;
    }
    50% {
      transform: scaleX(0);
      transform-origin: right;
    }
    75% {
      transform: scaleX(1);
      transform-origin: right;
    }
    76% {
      transform-origin: left;
    }
    100% {
      transform: scaleX(0);
      transform-origin: left;
    }
  }

  .activity-indicator {
    position: fixed;
    width: 100%;

    &__bar {
      width: 100%;
      height: 4px;
      left: 0;
      background-color: $activity-indicator__color;
      animation-name: bar-animation;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-direction: normal;
    }
  }
</style>
