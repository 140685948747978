<template>
  <article>
    <h1 class="content__title">{{ $t('faqPages.cabins.title') }}</h1>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.cabins.question1.title') }}</h3>
      <p class="question__answer">
        {{ $t('faqPages.cabins.question1.answer.text1') }}
        <router-link class="question__answer--link" :to="'/prices'" target="_blank">{{ $t('priceCalendar') }}</router-link>.
        {{ $t('faqPages.cabins.question1.answer.text2') }}
        <router-link class="question__answer--link" :to="'/prices'" target="_blank">{{ $t('priceCalendar') }}</router-link>
        {{ $t('faqPages.cabins.question1.answer.text3') }}
      </p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.cabins.question2.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.cabins.question2.answer') }}</p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.cabins.question3.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.cabins.question3.answer.text1') }}</p>
      <ul>
        <li>{{ $t('faqPages.cabins.question3.answer.text2') }}</li>
        <li>
          {{ $t('faqPages.cabins.question3.answer.text3') }}
          <a class="question__answer--link" href="tel:'6461531055'" target="_blank">+52 (646) 153.10155. Ext. 0</a>
        </li>
        <li>{{ $t('faqPages.cabins.question3.answer.text4') }}</li>
        <li>{{ $t('faqPages.cabins.question3.answer.text5') }}</li>
      </ul>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.cabins.question4.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.cabins.question4.answer') }}</p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.cabins.question5.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.cabins.question5.answer') }}</p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.cabins.question6.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.cabins.question6.answer') }}</p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.cabins.question7.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.cabins.question7.answer.title') }}</p>
      <br>
      <ul class="question__answer-list--bullets">
        <li>{{ $t('faqPages.cabins.question7.answer.text1') }}</li>
        <li>{{ $t('faqPages.cabins.question7.answer.text2') }}</li>
        <li>{{ $t('faqPages.cabins.question7.answer.text3') }}</li>
        <li>{{ $t('faqPages.cabins.question7.answer.text4') }}</li>
        <li>{{ $t('faqPages.cabins.question7.answer.text5') }}</li>
        <li>{{ $t('faqPages.cabins.question7.answer.text6') }}</li>
        <li>{{ $t('faqPages.cabins.question7.answer.text7') }}</li>
        <li>{{ $t('faqPages.cabins.question7.answer.text8') }}</li>
      </ul>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.cabins.question8.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.cabins.question8.answer') }}</p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.cabins.question9.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.cabins.question9.answer') }}</p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.cabins.question10.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.cabins.question10.answer.text1') }}</p>
      <br>
      <p class="question__answer">{{ $t('faqPages.cabins.question10.answer.text2') }}</p>
      <br>
      <p class="question__answer">{{ $t('faqPages.cabins.question10.answer.text3') }}</p>
      <ul>
        <li>{{ $t('faqPages.cabins.question10.answer.options.option1') }}</li>
        <li>{{ $t('faqPages.cabins.question10.answer.options.option2') }}</li>
      </ul>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.cabins.question11.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.cabins.question11.answer') }}</p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.cabins.question12.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.cabins.question12.answer') }}</p>
    </div>
  </article>
</template>

<script>
  export default {
  }
</script>