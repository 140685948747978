<template>
  <div class="selector">
    <button @click="show = !show">
      <span v-if="!selected">{{ $t("selector.label") }}</span>
      <span v-else>{{label}}</span>
      <img class="icon" src="@/assets/dropdown-arrow-2.svg" alt=""/>
    </button>

    <ul v-show="showOptions && show" :class="{'date-selector': selector === 'date', 'schedule-selector': selector === 'schedule'}">
      <li class="option" :class="{'selected': selected == option.id, 'disabled': !option.available }" @click="select(option)"
          v-for="(option, index) in options" :key="option + index">{{ option.value }}</li>
    </ul>
</div>
</template>

<script>
export default {
    name: 'Selector',
    props: [
        'selector',
        'options',
        'showOptions',
        'activity',
        'changeDate'
    ],
    data() {
        return {
            show: false,
            selected: null,
            label: ''
        }
    },
    watch: {
      'options'() {
        let options = this.options ? this.options.filter(o => o.available) : [];

        if(options.length == 1 && this.selector === 'date') {
          this.select(options[0]);
        } else if (options.length == 0 && this.selected) {
          this.selected = null;
        }

        if(this.selected) {
          let isValid = options.find(o => o.id == this.selected);
          if (!isValid) {
            this.selected = null;
          }
        }

      },
      'changeDate'() {
        if(this.changeDate) {
          this.selected = null;
          this.$emit("dateChanged", false)
        }
      }
    },
    methods: {
      select(option) {
        if(!option.available)
          return;
        this.selected = option.id;
        this.label = option.value;
        this.show = false;
        this.$emit('onSelect', {option: option.id, property: this.selector});
      },
      setLabel(option) {
        this.selected = option.id;
        this.label = option.label;
      },
    }
}
</script>

<style lang="scss" scoped>
.selector {
    display: grid;
    justify-content: right;
    height: 22px;
    position: relative;

    span {
      color: $light-gray;
    }

    button {
        align-self: baseline;
        background-color: transparent;
        border: none;
        display: flex;
        font-family: Chau Philomene One, serif;
        font-size: 1rem;
        font-weight: normal;
        padding: 0;
        text-transform: uppercase;
        cursor: pointer;

    }
    .icon {
        align-self: baseline;
        color: black;
        height: 0.5rem;
        margin: 0.5rem;
        width: 0.5rem;
    }
    ul {
        border-radius: 0.5rem;
        box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);
        display: grid;
        position: absolute;
        grid-row-gap: 8px;
        padding: 0.5rem;
        right: 7px;
        top: 25px;
        width: 100%;
        z-index: 1;
        min-width: 272px;
        background-color: white;

        li {
            border: 1px solid $lighter-gray;
            border-radius: 0.5rem;
            font-size: 12px;
            min-width: 10px;
            padding: 12px 0;
            text-align: center;
            text-transform: uppercase;
            &.selected {
                background-color: $light-orange;
                border: 1px solid $orange-color;
            }
        }
    }
}
.date-selector {
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 14px;
}
.schedule-selector {
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 10px;

}
//        grid-template-columns: repeat(4, minmax(10px, 1fr));

.option {
  cursor: pointer;

  &.disabled{
    cursor: not-allowed;
    border-color: $selector__border--disabled;
    color: $selector__text--disabled;
 }
}
</style>