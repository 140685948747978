<template>
  <default-layout>
    <div class="terms">
        <div class="terms__header">
            <h2 class="terms__title">{{$t('termsPage.title')}}</h2>
            <span class="terms__description">{{$t('termsPage.description')}}</span>
        </div>
        <ol class="terms__list">
            <li class="terms__list__section" v-for="(section, index) in sectionList" :key="index">
                <h3 class="terms__list__section__content__title">{{sectionTitle(section)}}</h3>
                <ol>
                    <li v-for="(term, index) in getTermsBySectionName(section.name)" :key="index">
                        <span>{{term.name}}</span>
                        <ol v-if="term.subList">
                            <li v-for="(element, index) in term.subList" :key="index">{{element}}</li>
                        </ol>
                        <span v-if="term.subtitle"> {{ term.subtitle }} </span>
                    </li>
                </ol>
            </li>
        </ol>
    </div>
  </default-layout>
</template>

<script>
  import DefaultLayout from '@/layouts/DefaultLayout';

  export default {
    components: {DefaultLayout},
    metaInfo() {
        return {title: this.$t("tabs.termsAndConditions")}
    },
    data() {
        return {
            sectionList: [
                {number: "I",   name: "policiesForOnlinePurchases" },
                {number: "II",  name: "childrenAdmission" },
                {number: "III", name: "changesOfDateAndValidityOfTheTicket" },
                {number: "IV",  name: "billing" },
                {number: "V",   name:"applicableLegislationAndJurisdiction" }
            ]
        }
    },
    methods: {
        sectionTitle(section) {
            let name = this.$t(`termsPage.${section.name}.title`);
            return `${section.number}.- ${name}:`;
        },
        getTermsBySectionName(sectionName) {
            let terms = this.$t(`termsPage.${sectionName}.listOfTerms`);
            let termList = [];
            if(terms) {
            let keys = Object.keys(terms);
                if(keys && keys.length > 0) {
                    keys.forEach(key => {
                        if(sectionName == 'policiesForOnlinePurchases' && key == 'term5') {
                            let subListKeys = this.$t(`termsPage.${sectionName}.listOfTerms.${key}`);
                            termList.push({name: subListKeys.title, subList: subListKeys.subList, subtitle: subListKeys.subtitle});
                        } else {
                            termList.push({ name: terms[key] });
                        }
                    });
                }
            }
            return termList;
        }
    }
  }
</script>

<style lang="scss" scoped>
.terms {
    counter-reset: item;
    letter-spacing: 0.05em;
    max-width: 1037px;
    justify-self: center;
    color: $dark-brown-color;
    &__header {
        display: grid;
    }
    &__title {
        font-weight: normal;
    }
    &__description {
        line-height: 1.5rem;
        font-weight: 500;
    }
    &__list {
        &__section {

            display: grid;

            ol {
                li {
                    display: block;
                    line-height: 1.5rem;
                    font-weight: 500;

                    &::before {
                        counter-increment: item;
                        content: counter(item) ". ";
                    }
                    >ol {
                        counter-reset: sublist;
                        li {
                            display: block;
                            line-height: 1.5rem;
                            font-weight: 500;
                            margin-left: .5rem;
                            @include respond-to("medium and up") {
                                margin-left: 1rem;
                            }
                            &::before{
                                counter-increment: sublist;
                                content: counter(sublist, lower-alpha) ") ";
                                white-space: pre;
                            }
                        }
                    }
                }
            }
        }
    }
    @include respond-to("medium and up") {
        padding: 3rem 0;
        &__title {
            font-weight: normal;
            font-size: 2rem;
            line-height: 2rem;
        }
        &__header {
            grid-row-gap: 1.5rem;
        }
        &__list {
            &__section {
                grid-row-gap: 1.5rem;
                margin-top: 3rem;
                h3 { font-size: 1.5rem }
            }
        }
    }
    @include respond-to("small and down") {
        padding: 1.5rem 0;
        &__title {
            font-weight: normal;
            font-size: 1.5rem;
            line-height: 2rem;
        }
        &__header {
            grid-row-gap: 1rem;
        }
        &__list {
            &__section {
                grid-row-gap: .5rem;
                margin-top: 1rem;
                h3 { font-size: 1rem }
            }
        }
    }
}
</style>