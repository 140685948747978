<template>
    <products-lodging selected-product="camping">
      <div class="product-summary__details" v-if="currentProduct && information">
        <gallery>
          <template slot="small-image">
            <img :src="require('@/assets/products/camping/camping_' + gallery[0] + '.jpg')" alt="">
          </template>
          <template slot="gallery-images">
            <img class="slide" v-for="(picture, i) in gallery" :key="i" :src="require('@/assets/products/camping/camping_' + picture + '.jpg')" alt="">
          </template>
        </gallery>
        <div class="product-summary__details__title">
          <h1>{{ $t(currentProduct.title) }}</h1>
        </div>
        <div class="product-summary__details__content">
          <div class="product-summary__details__content__information">
            <div class="product-summary__details__content__information__description" :class="{ hidden: informationHidden }">
              <ul>
                <li v-for="(text, index) in information[currentProduct.imageName].description" :key="index">{{ $t(text) }}</li>
              </ul>
            </div>
            <span class="hide-description" @click="informationHidden = !informationHidden">{{ informationHidden ? $t('readMore') : $t('hide') }}</span>
          </div>
          <div class="product-summary__details__content__services">
            <h1 class="product-summary__details__content__services__title">{{$t('services')}}</h1>
            <div class="product-summary__details__content__services__item" v-for="service in information[currentProduct.imageName].services" :key="service.id">
              <img class="icon" :src="require('@/assets/products/services/'+service.iconName+'.svg')" alt="">
              <span>{{ $t(service.name) }}</span>
            </div>
          </div>
        </div>
      </div>
    </products-lodging>
  </template>
  
  <script>
    import Gallery from '@/components/Gallery';
    import ProductsLodging from './ProductsLodging.vue';
  
    const information = {
      camping: {
        priceDescription: 'pricePerPerson',
        priceDescriptionMobile: 'perPerson',
        labels: ['priceFor'],
        services: [
          { id:'1', name: 'productServices.electricalOutlets', iconName:'electricity-outlets' },
          { id:'2', name: 'productServices.hotRunningWater', iconName:'water' },
          { id:'3', name: 'productServices.restrooms', iconName:'bathroom' },
          { id:'4', name: 'productServices.showers', iconName:'shower' },
          { id:'5', name: 'productServices.dressingRooms', iconName: 'dressing-room' },
          { id:'5', name: 'productServices.parasols', iconName:'palapas' },
          { id:'6', name: 'productServices.campingTables', iconName:'table' },
          { id:'7', name: 'productServices.grills', iconName:'steakhouse' },
          { id:'8', name: 'productServices.airCompressors', iconName:'air-compressors' }
        ],
        description: {
          text1: 'productPages.camping.text1',
          text2: 'productPages.camping.text2',
          text3: 'productPages.camping.text3',
          text4: 'productPages.camping.text4'
        }
      }
    }
  
    export default {
      name: 'Camping',
      components: {ProductsLodging, Gallery},
      metaInfo() {
        return {title: this.$t("tabs.camping")}
      },
      data() {
        return {
          currentProduct: null,
          informationHidden: true,
          gallery: [1,2,3,4]
        }
      },
      computed: {
          information() {
              return information;
          }
      },
      mounted() {
        this.currentProduct = { title:'camping', imageName: 'camping' };
      }
    };
  </script>

<style lang="scss" scoped>
.product-summary {
  &__details {
    &__content {
      &__information__description {
        li {
          margin-bottom: 1.5rem;
        }
      }
    }
  }
}
</style>