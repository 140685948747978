<template>
  <article>
    <h1 class="content__title">{{ $t('faqPages.pricesAndDiscounts.title') }}</h1>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.pricesAndDiscounts.question1.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.pricesAndDiscounts.question1.answer') }}</p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.pricesAndDiscounts.question2.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.pricesAndDiscounts.question2.answer') }}</p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.pricesAndDiscounts.question3.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.pricesAndDiscounts.question3.answer') }}</p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.pricesAndDiscounts.question4.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.pricesAndDiscounts.question4.answer.text1') }}</p>
      <ul class="question__answer-list">
        <li class="question__answer">{{ $t('faqPages.pricesAndDiscounts.question4.answer.text2') }}</li>
        <li class="question__answer">{{ $t('faqPages.pricesAndDiscounts.question4.answer.text3') }}</li>
        <li class="question__answer">{{ $t('faqPages.pricesAndDiscounts.question4.answer.text4') }}</li>
        <li class="question__answer">{{ $t('faqPages.pricesAndDiscounts.question4.answer.text5') }}</li>
        <li class="question__answer">{{ $t('faqPages.pricesAndDiscounts.question4.answer.text6') }}</li>
        <li class="question__answer">{{ $t('faqPages.pricesAndDiscounts.question4.answer.text7') }}</li>
        <li class="question__answer">{{ $t('faqPages.pricesAndDiscounts.question4.answer.text8') }}</li>
      </ul>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.pricesAndDiscounts.question5.title') }}</h3>
      <p class="question__answer">
        {{ $t('faqPages.pricesAndDiscounts.question5.answer.text1') }}
        <a class="question__answer--link" href="tel:'6461531055'" target="_blank">01 (646) 153.1055 Ext 0</a>
        {{ $t('faqPages.pricesAndDiscounts.question5.answer.text2') }}
        <a class="question__answer--link" href="mailto:info@lascanadas.com" target="_blank">info@lascanadas.com</a>
        {{ $t('faqPages.pricesAndDiscounts.question5.answer.text3') }}
      </p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.pricesAndDiscounts.question6.title') }}</h3>
      <p class="question__answer">
        {{ $t('faqPages.pricesAndDiscounts.question6.answer.text1') }}
        <router-link class="question__answer--link" :to="'/prices'" target="_blank">{{ $t('priceCalendar') }}</router-link>
        {{ $t('faqPages.pricesAndDiscounts.question6.answer.text2') }}
      </p>
      <br>
      <p class="question__answer">
        {{ $t('faqPages.pricesAndDiscounts.question6.answer.text3') }}
      </p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.pricesAndDiscounts.question7.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.pricesAndDiscounts.question7.answer.text1') }}</p>
      <ol class="question__answer-list--numbered">
        <li class="question__answer">{{ $t('faqPages.pricesAndDiscounts.question7.answer.options.option1') }}</li>
        <li class="question__answer">{{ $t('faqPages.pricesAndDiscounts.question7.answer.options.option2') }}</li>
        <li class="question__answer">{{ $t('faqPages.pricesAndDiscounts.question7.answer.options.option3') }}</li>
      </ol>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.pricesAndDiscounts.question8.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.pricesAndDiscounts.question8.answer') }}</p>
    </div>
  </article>
</template>

<script>
  export default {
    name: 'PricesAndDiscounts',
  }
</script>