<template>
  <product-summary :products="products" :selected-product="selectedProduct" sectionTitle="fun.earth" :show-section-links="true">
    <slot></slot>
  </product-summary>
</template>

<script>
  import ProductSummary from '@/views/products/ProductSummary';

  export default {
    components: {ProductSummary},
    name: 'ProductsEarth',
    props: ['selectedProduct'],
    data() {
      return {
        products: [
          { id:'camping', title:'camping', price: 200, priceDescription:'pricePerPerson', imageName:'camping', state: 'product.earth.camping', callback: this.analyticsSeeDetails, select: true, eventName: 'acampado' },
          { id:'atv-track', title:'atvTrack', price: 200, priceDescription:'pricePerPerson', imageName:'atv-track', state: 'product.earth.atv-track', callback: this.analyticsSeeDetails, select: false, eventName: 'pista de cuatrimotos'},
          { id:'horseback-riding', title:'horseBackRiding', imageName:'horseback-riding', state: 'product.earth.horseback-riding', callback: this.analyticsSeeDetails, select: false},
          { id:'sport-areas', title:'sportAreas', imageName:'sport-areas', state: 'product.earth.sport-areas', callback: this.analyticsSeeDetails, select: false},
          { id:'flag-viewpoint', title:'flagViewpoint', imageName:'flag-viewpoint', state: 'product.earth.flag-viewpoint', callback: this.analyticsSeeDetails, select: false},
          { id:'echo-viewpoint', title:'echoViewpoint', imageName:'echo-viewpoint', state: 'product.earth.echo-viewpoint', callback: this.analyticsSeeDetails, select: false},
          { id:'live-music', title:'liveMusic', imageName:'live-music', state: 'product.earth.live-music', callback: this.analyticsSeeDetails, select: false},
          { id:'farm', title:'farm', imageName:'farm', state: 'product.earth.farm', callback: this.analyticsSeeDetails, select: false},
        ],
        currentProduct: null
      }
    },
    methods: {
      analyticsSeeDetails(title) {
        this.onGenericEvent(`${title} menú sidebar`, { event_category: 'diversión en tierra', event_label: `menu` });
      },
      onGenericEvent(event, command) {
        this.$root.$emit('onGenericEvent', event, command);
      },
    }
  };
</script>