<template>
  <div class="mobile-summary" v-if="quote" v-show="mobileSumatyVisible">
      <a class="mobile-summary__activator" href="" @click.prevent="showSummary = !showSummary">
        <a v-if="!showSummary" href="">Mostrar resumen de compra</a>
        <a v-else href="">Ocultar resumen de compra</a>
      </a>
    <div class="mobile-summary__content" v-if="showSummary">
      <div class="mobile-summary__content__list" v-if="summaryItems">
        <div class="mobile-summary__content__list__item double" :class="{hidden: summaryItems.day == 0}">
          <span class="mobile-summary__content__list__item__pointer"></span>
          <span class="mobile-summary__content__list__item__text camping" v-if="summaryItems.camping && summaryItems.camping.value > 0">{{summaryItems.camping.value}} {{$t(summaryItems.camping.name)}},</span>
          <span class="mobile-summary__content__list__item__text" v-if="summaryItems.day">{{summaryItems.day.value}} {{$t(summaryItems.day.name)}}</span>
        </div>
        <div class="mobile-summary__content__list__item" v-for="(item, index) in summaryItems.items" :key="index" :class="{hidden: item.value == 0}">
          <span class="mobile-summary__content__list__item__pointer"></span>
          <span class="mobile-summary__content__list__item__text">{{item.value}} {{$t(item.name)}}</span>
        </div>
      </div>
      <div class="mobile-summary__content__items">
        <div class="mobile-summary__content__item" v-for="(item, index) in items" :key="index">
          <div class="mobile-summary__content__item__concept">
            <span class="mobile-summary__content__item__name">{{$t(item.name)}}</span>
            <span class="mobile-summary__content__item__amount">{{item.quantity}} x {{ item.price | exchangeRate(currency.exchangeRate) | currency }}</span>
          </div>
          <div class="mobile-summary__content__item__total-amount">{{(item.quantity * item.price) | exchangeRate(currency.exchangeRate) | currency}}</div>
        </div>
      </div>
    </div>
    <div class="mobile-summary__total-amount" :class="{open: showSummary}">
      <div class="mobile-summary__total-amount__discount" v-if="quote.discountAmount && quote.discountAmount > 0">
        <span class="mobile-summary__total-amount__discount__title">{{$t('promotionTitle')}}</span>
        <span class="mobile-summary__total-amount__discount__amount">- {{quote.discountAmount | exchangeRate(currency.exchangeRate) | currency }}</span>
      </div>
      <div class="mobile-summary__total-amount__total">
        <span class="mobile-summary__total-amount__total__title">Total</span>
        <span class="mobile-summary__total-amount__total__amount">{{quote.totalAmount | exchangeRate(currency.exchangeRate) | currency }} {{ currency.currency }}</span>
      </div>
    </div>
    <unavailable-reservation></unavailable-reservation>
    <div class="mobile-summary__action">
      <a v-if="$route.name === 'shopping-cart' && !isUnavailableCabins && !isUnavailableTipis && !isUnavailableLakeVillage" :class="{ 'disabled': disabledBtnToPay }" href="" class="button" @click.prevent="$router.push({name: 'contact-payment'})">{{ $t('continueToPayment') }}</a>
      <a v-if="$route.name === 'contact-payment' && !isUnavailableCabins && !isUnavailableTipis && !isUnavailableLakeVillage" :class="{ 'disabled': unavailableReservations || capacityFull || inRemoteActivity || reservationsWithOutPersons }" href="" class="button" @click.prevent="continueToPayment">{{ $t('completePurchase') }}</a>
    </div>
  </div>
</template>

<script>
  import {mapState} from 'vuex';
  import UnavailableReservation from '@/components/UnavailableReservation';
  import { fbq } from '@/main.js';

  const debounce = function (func, timeout = 2000) {
    let timer;
    return function(...args) {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  }

  export default {
    name: 'mobile-drawer',
    props: ['items', 'summaryItems'],
    components: {UnavailableReservation},
    computed: {
      currency() {
        return this.$store.getters.currency;
      },
      subTotal() {
        return this.quote.totalAmount + this.quote.discountAmount;
      },
      reservationsWithOutPersons() {
        return this.quote && this.quote.products
          && (this.quote.products.cabins + this.quote.products.tipis + this.quote.products.lakeVillages) > 0
          && this.quote.visit.numberOfPersons == 0;
      },
      ... mapState(['quote', 'isUnavailableCabins', 'isUnavailableTipis', 'isUnavailableLakeVillage', 'inRemoteActivity']),
      unavailableCabins() {
        return this.$store.getters.unavailableCabins;
      },
      unavailableTipis() {
        return this.$store.getters.unavailableTipis;
      },
      unavailableLakeVillages() {
        return this.$store.getters.unavailableLakeVillages;
      },
      unavailableReservations() {
        return (this.unavailableCabins && this.unavailableCabins.length > 0)
          || (this.unavailableTipis && this.unavailableTipis.length > 0)
          || (this.unavailableLakeVillages && this.unavailableLakeVillages.length > 0);
      },
      mobileSumatyVisible() {
        return this.$store.getters.mobileSumatyVisible;
      },
      capacityFull() {
        if (!this.quote) {
          return false;
        }

        if((this.quote.cabinNumbers && this.quote.cabinNumbers.length > 0)
          || (this.quote.tipiNumbers && this.quote.tipiNumbers.length > 0)
          || (this.quote.lakeVillageNumbers && this.quote.lakeVillageNumbers.length > 0)) {
          return false;
        }

        const haveDates = this.quote && this.quote.visit && this.quote.visit.arrivalDate && this.quote.visit.departureDate && this.quote.visit.arrivalTime;
        return haveDates ? (this.quote.visit.arrivalTime === 'DAY' ? this.$store.getters.capacityFullDay : this.$store.getters.capacityFullNight) : false;
      },
      disabledBtnToPay() {
        return this.unavailableReservations 
          || this.capacityFull 
          || this.remoteActivity
          || this.quote.totalAmount === 0
          || this.isUnavailableCabins
          || this.isUnavailableTipis
          || this.isUnavailableLakeVillage
          || !this.quote.visit.departureDate
          || this.reservationsWithOutPersons;
      },
      remoteActivity: debounce(function() {
        return this.inRemoteActivity;
      })
    },
    filters: {
      exchangeRate(val, exchangeRate) {
        return Math.round(val * 100.0 / exchangeRate) / 100;
      }
    },
    data() {
        return {
          showSummary: false
        }
    },
    methods: {
      continueToPayment() {
        fbq('track', 'AddPaymentInfo');
        this.$store.dispatch('clickToFinishPayment');
      }
    },
  };
</script>

<style lang="scss" scoped>
  .mobile-summary {
    z-index: 2100000000 !important;
    letter-spacing: 0.05em;
    @include respond-to('medium and up') {
      display: none;
    }
    @include respond-to('small and down'){
      z-index: 10;
    }
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    transition: height 0.2s;
    padding: 1rem;
    padding-top: 0;
    border-radius: 1rem 1rem 0px 0px;
    display: grid;
    grid-auto-rows: min-content;
    grid-row-gap: 1.5rem;
    align-items: center;
    justify-items: center;
    background-color: white;
    > * {
      width: 100%;
    }
    &__activator {
      display: grid;
      justify-items: center;
      padding-top: .5rem;
      text-decoration: none;
      a {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-decoration: none;
        color: #0075FF;
      }
    }
    &__content {
      &__list {
        display: grid;
        color: $dark-brown-color;
        font-family: 'Chau Philomene One', sans-serif;
        text-transform: uppercase;
        grid-row-gap: .5rem;
        padding-bottom: 1.5rem;
        &__item {
          display: grid;
          grid-template-columns: .5rem 1fr;
          align-items: center;
          justify-items: start;
          grid-column-gap: .5rem;
          line-height: 22px;
          &.double {
            grid-template-columns: 1rem auto 1fr;
            grid-column-gap: 0;
          }
          &__pointer {
            width: .5rem;
            height: .5rem;
            background-color: $orange-color;
            border-radius: 50%;
          }
          &.hidden {
            display: none;
          }
          .camping {
            padding-right: .5rem;
          }
        }
      }
      &__item {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 1rem;
        color: $dark-brown-color;
        span {
          line-height: 16px;
        }
        &__concept {
          display: grid;
          grid-auto-rows: 1fr;
          grid-row-gap: .5rem;
          align-items: center;
          justify-self: start;
        }
        &__total-amount {
          font-family: 'Chau Philomene One', sans-serif;
          justify-self: end;
          font-weight: normal;
          font-size: 1.5rem;
          line-height: rem(33px);
        }
      }
      &__items {
        padding-top: rem(23px);
        border-top: 1px dashed #1E0F00;
        display: grid;
        grid-row-gap: 1.5rem;
        font-weight: 500;
      }
    }
    &__total-amount {
      display: grid;
      grid-row-gap: .5rem;
      &__discount, &__total {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        &__amount {
          font-family: $font-secondary;
          justify-self: end;
          align-self: center;
          line-height: 2rem;
        }
        &__title {
          letter-spacing: 0.05em;
          font-family: $font-primary;
          font-weight: 500;
          justify-self: start;
          align-self: center;
        }
      }
      &.open {
        border-top: 1px dashed #1E0F00;
        padding-top: rem(16px);
      }
      &__discount {
        &__amount {
          font-family: $font-secondary;
          justify-self: end;
          font-size: 1.5rem;
          color: $red-color2;
        }
      }
      &__total {
        &__title {
          align-self: end;
        }
        &__amount {
          font-family: $font-secondary;
          justify-self: end;
          font-size: 2rem;
        }
      }
      &:not(.open) {
        .mobile-summary__total-amount {
          border-top: none;
          padding: 0 !important;
          &__discount {
            display: none;
          }
        }
      }
    }
  }
</style>
