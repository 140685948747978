<template>
  <products-earth selected-product="cabins">
    <div class="product-summary__details" v-if="currentProduct && information">
      <gallery>
        <template slot="small-image">
          <img :src="require('@/assets/products/cabins/cabin_' + gallery[0] + '.jpg')" alt="">
        </template>
        <template slot="gallery-images">
          <img class="slide" v-for="(picture, i) in gallery" :key="i" :src="require('@/assets/products/cabins/cabin_' + picture + '.jpg')" alt="">
        </template>
      </gallery>
      <div class="product-summary__details__title">
        <h1>{{ $t(currentProduct.title) }}</h1>
      </div>
      <div class="product-summary__details__content">
        <div class="product-summary__details__content__information">
          <div class="product-summary__details__content__information__description" :class="{ hidden: informationHidden }">
            <p>{{ $t(information[currentProduct.imageName].description.text1) }}</p>
            <p>{{ $t(information[currentProduct.imageName].description.text2) }}</p>
          </div>
          <span class="hide-description" @click="informationHidden = !informationHidden">{{ informationHidden ? $t('readMore') : $t('hide') }}</span>
        </div>
        <div class="product-summary__details__content__services">
          <h1 class="product-summary__details__content__services__title">{{$t('services')}}</h1>
          <div class="product-summary__details__content__services__item" v-for="service in information[currentProduct.imageName].services" :key="service.id">
            <img class="icon" :src="require('@/assets/products/services/'+service.iconName+'.svg')" alt="">
            <span>{{ $t(service.name) }}</span>
          </div>
        </div>
      </div>
    </div>
  </products-earth>
</template>

<script>
  import Gallery from '@/components/Gallery';
  import ProductsEarth from '@/views/products/ProductsEarth';

  const information = {
    cabins: {
      priceDescription: 'pricePerCabin',
      priceDescriptionMobile: 'perCabin',
      labels: ['priceFor', 'capacity1To6People', 'thePriceDoesNotIncludeAdmissionFees'],
      services: [
        { id:'9', name: 'productServices.hotRunningWater', iconName:'hot-water' },
        { id:'10', name: 'productServices.drainage', iconName:'sewer-system' },
        { id:'11', name: 'productServices.electrical', iconName:'electricity-outlets' },
        { id:'12', name: 'productServices.sink', iconName:'dishwasher' },
        { id:'13', name: 'productServices.shower', iconName:'shower' },
        { id:'14', name: 'productServices.restroomAndSink', iconName:'bathroom' },
        { id:'15', name: 'productServices.parking', iconName:'parking-lot' },
        { id:'16', name: 'productServices.sofaBed', iconName:'sofa' },
        { id:'17', name: 'productServices.bunkBeds', iconName:'bunk-beds' },
        { id:'18', name: 'productServices.campingTable', iconName:'table' },
        { id:'19', name: 'productServices.grill', iconName:'steakhouse' },
      ],
      description: {
        text1: 'productPages.cabins.text1',
        text2: 'productPages.cabins.text2'
      }
    }
  }

  export default {
    name: 'Cabins',
    components: {ProductsEarth, Gallery},
    data() {
      return {
        currentProduct: null,
        informationHidden: true,
        gallery: [1,2,3]
      }
    },
    computed: {
        information() {
            return information;
        }
    },
    mounted() {
      this.currentProduct = { title:'cabins', imageName: 'cabins' };
    }
  };
</script>