<template>
  <products-water selected-product="lake">
    <div class="product-summary__details" v-if="currentProduct && information">
      <gallery>
        <template slot="small-image">
          <img :src="require('@/assets/products/lake/lake_' + gallery[0] + '.jpg')" alt="">
        </template>
        <template slot="gallery-images">
          <img class="slide" v-for="(picture, i) in gallery" :key="i" :src="require('@/assets/products/lake/lake_' + picture + '.jpg')" alt="">
        </template>
      </gallery>
      <div class="product-summary__details__title">
        <h1>{{ $t(currentProduct.title) }}</h1>
      </div>
      <div class="product-summary__details__content">
        <div class="product-summary__details__content__information">
          <div class="product-summary__details__content__information__description">
            <p v-for="(text, index) in information[currentProduct.id].description" :key="index">{{ $t(text) }}</p>
          </div>
        </div>
      </div>
    </div>
  </products-water>
</template>

<script>
  import Gallery from '@/components/Gallery';
  import ProductsWater from '@/views/products/ProductsWater';

  const information = {
    lake: {
      description: {
        text1: 'productPages.lake.text1',
        text2: 'productPages.lake.text2',
        text3: 'productPages.lake.text3',
        text4: 'productPages.lake.text4'
      }
    }
  }

  export default {
    name: 'IndianVillage',
    components: {ProductsWater, Gallery},
    metaInfo() {
      return {title: this.$t("tabs.lake")}
    },
    data() {
      return {
        currentProduct: null,
        gallery: [1,2,3]
      }
    },
    computed: {
        information() {
            return information;
        }
    },
    mounted() {
      this.currentProduct = { id: 'lake', title:'lake', imageName: 'lake' };
    },
    methods: {}
  };
</script>

<style lang="scss" scoped>
  .product-summary {
    &__details {
      &__content {
        &__services {
          height: rem(250px);
        }
      }
      .image-1 { background-position: bottom }
    }
  }
</style>