<template>
    <div class="visit-dates" :class="{disabled: step < 2}" v-if="quote">
        <div class="visit-dates__arrival" :class="{selected: showArrivalCalendar}">
            <div class="selector">
                <div class="label" @click.prevent="showArrival()">{{ $t('arrival') }}</div>
                <div class="date" @click.prevent="showArrival()">
                    <img class="icon" v-if="startTime && arrivalTime" :src="require('@/assets/' + startTime.toLowerCase() + '--gray.svg')" alt="">
                    <img class="icon" v-else src="@/assets/day--gray.svg" alt="">
                    <h4 v-if="!arrivalDate">{{$t('chooseYourDate')}}</h4>
                    <h4 v-if="arrivalDate">{{ arrivalDate | date }}</h4>
                    <img class="triangle" src="@/assets/date-picker/triangle-down.svg" alt="">
                </div>
                <div class="visit-dates__arrival__date-picker" v-if="showArrivalCalendar" :class="{open: showArrivalCalendar && !showDepartureCalendar, empty: !startTime || !startDate, 'empty-string': startTimeString && (!startTime || !startDate)}">
                    <div :key="keyCalendar">
                        <date-picker-single id="arrivalCalendar"  class="single" v-model="arrivalDate"
                                            :prices-per-day="pricesPerDay" :stay-key="stayKey"
                                            :maxDate="maxDate" :no-init="true" @change="updateArrivalDate"
                                            :disabled-dates="disabledDates" :unavailable-lodging-dates="unavailableLodgingDates">
                        </date-picker-single>
                    </div>
                </div>
            </div>
            <span class="date-time-message">{{ $t(startTimeString) }}</span>
        </div>
        <div class="overlay" v-if="showArrivalCalendar || showDepartureCalendar" @click.prevent="hideCalendar()"></div>
        <div class="visit-dates__departure" v-if="quote.visit && quote.visit.numberOfDays > 0 && quote.visit.stayType != 'DAY_PASS'"
            :class="{selected: showDepartureCalendar, disabled: step >= 2 && !arrivalDate}">
            <div class="selector">
                <div class="label" @click.prevent="showDeparture()">{{ $t('departure') }}</div>
                <div class="date" @click.prevent="showDeparture()">
                    <img class="icon" src="@/assets/day--gray.svg" alt="">
                    <h4 v-if="!departureDate || !departureDateSet">{{$t('chooseYourDate')}}</h4>
                    <h4 v-else>{{ departureDate | date }}</h4>
                    <img class="triangle" src="@/assets/date-picker/triangle-down.svg" alt="">

                </div>
                <div class="visit-dates__departure__date-picker" v-if="showDepartureCalendar" :class="{open: showDepartureCalendar && !showArrivalCalendar, empty: !startTime || !startDate, 'empty-string': startTimeString && (!startTime || !startDate)}">
                    <div :key="keyCalendar">
                        <date-picker-single id="departureCalendar" class="single" v-model="departureDateTmp"
                                            :prices-per-day="pricesPerDay" :stay-key="stayKey"
                                            :maxDate="maxDate" :minDate="minDepartureDate" :no-init="true"
                                            @change="updateDepartureDate" :disabled-dates="disabledDates">
                        </date-picker-single>
                    </div>
                </div>
            </div>
            <span class="date-time-message">{{ $t(endTimeString) }}</span>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import {mapState} from 'vuex';
import DatePickerSingle from '@/components/calendars/DatePickerSingle';

export default {
    name: 'VisitDates',
    props: [ 'pricesPerDay', 'step', 'unavailableLodgingDates'],
    components: { DatePickerSingle},
    data() {
        return {
            startDate: null,
            endDate: null,
            startTime: null,
            endTime: 'day',
            startDateUTF: null,
            endDateUTF: null,
            showArrivalCalendar: false,
            showDepartureCalendar: false,
            arrivalDate: null,
            departureDate: null,
            departureDateTmp: null,
            disabledDates: null,
            keyCalendar: 0,
            departureDateSet: null
        };
    },
    watch: {
        '$i18n.locale'() {
            moment.locale(this.$i18n.locale);
        },
        'quote.visit.arrivalDate': function() {
            if(!this.quote.visit.departureDate) {
                this.quote.visit.arrivalTime = null;
            }

            if(this.departureDateTmp) {
              const isDepartureBeforeArrival = moment(this.departureDateTmp).isSameOrBefore(moment(this.quote.visit.arrivalDate));
              if(isDepartureBeforeArrival) {
                this.departureDateTmp = null;
              }
            }
            this.analyticsArrivalDate();
            this.initDates();
        },
        'quote.visit.departureDate': function() {
            this.analyticsDepartureDate();
            this.initDates();
        },
        'quote.visit.arrivalTime'() {
            this.startTime = this.quote && this.quote.visit && this.quote.visit.departureDate ? this.quote.visit.arrivalTime : null;
        },
        'quote.visit.stayType'() {
            this.departureDateTmp = null;
        },
        unavailableLodgingDates() {
          this.updateUnavailableDates();
        }
    },
    mounted() {
        this.initDates();
        this.startTime = this.quote && this.quote.visit && this.quote.visit.departureDate ? this.quote.visit.arrivalTime : null;
        this.departureDateSet = localStorage.getItem('DEPARTURE_DATE') != null;
        this.updateUnavailableDates();
        moment.locale(this.$i18n.locale);
    },
    computed: {
        arrivalTime() {
            return this.quote && this.quote.visit && this.quote.visit.arrivalTime;
        },
        minDepartureDate() {
            return this.arrivalDate ? moment(this.arrivalDate).add(1, 'day').toDate() : new Date();
        },
        maxArrivalDate() {
            let departure = moment(this.departureDate);
            return this.arrivalTime == 'NIGHT' ? this.departureDate ? departure.subtract(1, 'day').toDate() : this.maxDate : this.maxDate;
        },
        ...mapState([ 'quote', 'isUnavailableCabins', 'isUnavailableTipis', 'isUnavailableLakeVillage']),
        existsPromotion() {
            return this.quote && !!this.quote.promotion;
        },
        promotion() {
            return this.quote && this.quote.promotion;
        },
        startTimeString() {
            return this.getStartTimeString(this.startTime);
        },
        endTimeString() {
            return this.getEndTimeString('DAY');
        },
        currentArrivalDate() {
            return this.quote && this.quote.visit && this.quote.visit.arrivalDate ? this.quote.visit.arrivalDate : null;
        },
        currentDepartureDate() {
            return this.quote && this.quote.visit && this.quote.visit.departureDate ? this.quote.visit.departureDate : null;
        },
        haveReservations() {
            return this.quote && this.quote.products
                && (this.quote.products.cabins > 0 || this.quote.products.tipis > 0 || this.quote.products.lakeVillages > 0)
        },
        maxMoment() {
            let mountsAfter = process.env.VUE_APP_10;
            return moment().endOf('year').add(mountsAfter, "M");
        },
        maxDate() {
            return this.maxMoment.toDate();
        },
        stayKey() {
          let key = 'dayPassPrice';
          const stayType = this.quote.visit.stayType;
          switch (stayType) {
            case 'DAY_PASS':
              key = 'dayPassPrice';
              break;
            case 'CAMPING_PASS':
              key = 'campingNightPrice';
              break;
            case 'CABIN':
              key = 'cabinPrice';
              break;
            case 'TIPI':
              key = 'tipiPrice';
              break;
            case 'LAKE_VILLAGE':
              key = 'lakeVillagePrice';
              break;
            default:
              break;
          }
          return key;
        }
    },
    methods: {
        async updateUnavailableDates() {
            let dates = {
                start: moment().format('YYYY-MM-DD'),
                end: this.maxMoment.format('YYYY-MM-DD')
            }
            let disabledDates = await this.$store.dispatch('daysWithOutPrices', dates);

            this.$set(this, 'disabledDates', disabledDates);

            setTimeout(() => {
                this.keyCalendar++;
            }, 300);
        },
        validateVisitDates() {
          if(this.disabledDates.includes(this.arrivalDate)) {
            this.arrivalDate = null;
            this.departureDate = null;
          }

          if(this.disabledDates.includes(this.departureDate)) {
            this.departureDate = null;
          }
        },
        updateArrivalDate(date) {
            let arrivalDate = date ? moment(date).format('YYYY-MM-DD') : null;
            let departureDate = this.quote && this.quote.visit && this.quote.visit.departureDate ? this.quote.visit.departureDate : null;
            if (arrivalDate != this.currentArrivalDate) {
                this.showArrivalCalendar = false;
            }
            if (this.currentArrivalDate == departureDate) {
                departureDate = arrivalDate;
            }
            let numberOfDays = moment(arrivalDate).diff(moment(departureDate), 'days');

            if (numberOfDays >= 0 && this.quote.visit.stayType !== 'DAY_PASS') {
                departureDate = null;
            }

            this.checkUpdate(arrivalDate, departureDate);
        },
        updateDepartureDate(date) {
          let departureDate = date ? moment(date).format('YYYY-MM-DD') : null;

          if(departureDate){
            localStorage.setItem('DEPARTURE_DATE', departureDate);
            this.$emit('setDeparture');

            if(departureDate !== this.currentDepartureDate) {
              this.showDepartureCalendar = false;
              this.departureDateTmp = date;
              this.departureDateSet = true;

            } else {
              if(this.departureDateTmp) {
                this.departureDateSet = true;
              } else {
                this.departureDateTmp = date;
                this.showDepartureCalendar = false;
                this.departureDateSet = true;
              }
            }
            let arrivalDate = this.quote && this.quote.visit && this.quote.visit.arrivalDate ? this.quote.visit.arrivalDate : null;
            let numberOfDays = moment(arrivalDate).diff(moment(departureDate), 'days');
            if (numberOfDays >= 0) {
                departureDate = moment(arrivalDate).add(1, "days").format('YYYY-MM-DD');
                this.showDepartureCalendar = true;
            }

            this.checkUpdate(arrivalDate, departureDate);
          }


        },
        showArrival() {
            this.keyCalendar++;
            this.showArrivalCalendar = !this.showArrivalCalendar;
            this.showDepartureCalendar = false;
        },
        showDeparture() {
            this.keyCalendar++;
            this.showDepartureCalendar = !this.showDepartureCalendar;
            this.showArrivalCalendar = false;
        },
        initDates() {
            this.arrivalDate = this.quote && this.quote.visit && this.quote.visit.arrivalDate ? this.quote.visit.arrivalDate : null;
            this.departureDate = this.quote && this.quote.visit && this.quote.visit.departureDate ? this.quote.visit.departureDate : null;
            this.startTime = this.quote && this.quote.visit && this.quote.visit.departureDate ? this.quote.visit.arrivalTime : null;
        },
        hideCalendar() {
            this.showArrivalCalendar = false;
            this.showDepartureCalendar = false;
        },
        checkUpdate(newArrivalDate, newDepartureDate) {
            if (newArrivalDate != this.currentArrivalDate || newDepartureDate != this.currentDepartureDate) {
                let arrivalTime = this.quote.visit.arrivalTime;
                if (!arrivalTime && newArrivalDate == newDepartureDate) { arrivalTime = 'DAY' }
                if (this.existsPromotion) {
                    let data = {
                        arrivalTime: arrivalTime,
                        arrivalDate: newArrivalDate,
                        departureDate: newDepartureDate
                    }
                    this.changeDatesOpen(data);
                } else {
                    this.setVisitDates(newArrivalDate, newDepartureDate);
                }
            }
        },
        changeDatesOpen(data) {
            this.$emit('openModal', data);
        },
        isNotSameDates(newArrivalDate, newDepartureDate) {
            return newArrivalDate != this.quote.visit.arrivalDate || newDepartureDate != this.quote.visit.departureDate
        },
        async setVisitDates(newArrivalDate, newDepartureDate) {
            let arrivalTime = this.quote.visit.arrivalTime;
            if (!arrivalTime && newArrivalDate == newDepartureDate) { arrivalTime = 'DAY' }
            await this.$store.dispatch('updateVisit', {arrivalDate: newArrivalDate, departureDate: newDepartureDate, arrivalTime: arrivalTime});
            this.initDates();
        },
        getDateString(date) {
            if (!date) return "";
            moment.locale(this.$t('lang'));
            return (
                moment(date).format(this.$t('dateFormat')).replace(".", "")
            );
        },
        getStartTimeString(time) {
            if (!time) return "";
            let timeOptions = [
                { id: "DAY", message: "between7pm6pm" },
                { id: "NIGHT", message: "between6pm12am" }
            ];
            let t = timeOptions.find(item => item.id == time);
            return t && t.message;
        },
        getEndTimeString(time) {
            if (!time) {
                return;
            }
            let timeOptions = [
                { id: "DAY", message: this.haveReservations ? 'before12pm' : 'before6pm' },
                { id: "NIGHT", message: "between6pm12am" }
            ]
            let t = timeOptions.find(item => item.id == time);
            return t && t.message;
        },
        analyticsArrivalDate() {
            this.onGenericEvent(`selección fecha llegada`, { event_category: 'cotizador', event_label: `calendario` });
        },
        analyticsArrivalTime() {
            this.onGenericEvent(`selección hora llegada`, { event_category: 'cotizador', event_label: `` });
        },
        analyticsDepartureDate() {
            this.onGenericEvent(`selección fecha salida`, { event_category: 'cotizador', event_label: `calendario` });
        },
        onGenericEvent(event, command) {
            this.$root.$emit('onGenericEvent', event, command);
        },
    }
};
</script>
<style lang="scss" scoped>
.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
}
.date-picker { display: none;}
.visit-dates {
    letter-spacing: 0.05em;
    display: grid;
    grid-row-gap: 1rem;
    &__arrival-time {
        padding-top: 1rem;
        border-top: 1px dashed rgba($dark-brown-color, 0.2);
    }
    &__arrival, &__departure {
        position: relative;
        display: grid;
        grid-row-gap: rem(8px);
        width: auto;
        z-index: 2;
        cursor: pointer;
        .date-time-message {
            font-weight: 500;
            font-size: rem(16px);
            line-height: rem(12px);
            letter-spacing: 0.05em;
            color: $light-gray;
            margin-left: 33px;
            @include respond-to("small and down") {
                line-height: unset;
            }
        }
        .label {
            font-weight: 500;
            font-size: rem(16px);
            line-height: rem(16px);
            letter-spacing: 0.05em;
            margin-bottom: 8px;
            color: rgba($dark-brown-color, 0.6);
        }
        .date {
            display: grid;
            grid-template-columns: rem(24px) auto 1fr;
            @include respond-to("medium and down") {
                grid-template-columns: rem(24px) auto auto;
            }
            grid-column-gap: rem(8px);
            grid-template-areas: "icon date triangle" ". time time";
            align-items: center;
            .icon { grid-area: icon }
            .empty { grid-column: 1/3 }
            .triangle {
                grid-area: triangle;
                width: .5rem;
                margin-bottom: .25rem;
            }
            h4 {
                grid-area: date;
                font-size: 1rem;
                line-height: rem(22px);
            }

        }
        &.selected {
            border-bottom: rem(3px) solid $orange-color;
            z-index: 10;
        }
        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
        &__date-picker {
            width: 100vh;
            width: calc(var(--vh, 1vh) * 100);
            @include respond-to("medium and up") { max-width: rem(358px) !important }
            @include respond-to("small and down") { max-width: rem(342px) !important }
            position: absolute;
            display: none;
            text-align: center;
            padding: 1.5rem;
            background-color: #fff;
            box-shadow: 0px 0px 1rem rgba(0, 0, 0, 0.16);
            border-radius: rem(8px);
            top: rem(75px);
            &.empty {
                top: rem(57px);
            }
            &.empty-string {
                top: rem(73px);
            }
            &.position-departure {
                top: rem(155px);
                @media screen and (max-height: 924px) {
                    top: rem(-135px);
                }
                &.empty {
                    top: rem(119px);
                }
                &.empty-string {
                    top: rem(135px);
                }
            }
            &.open {
                display: grid;
                grid-row-gap: 1.5rem;
                z-index: 10;
            }
            &__calendar {
                font-family: $font-secondary;
                text-transform: uppercase;
                font-weight: 400;
                color: $dark-brown-color;
                display: inline-block;
            }

            p {
                text-align: left;
                font-weight: 500;
                font-size: rem(16px);
                line-height: rem(16px);
                letter-spacing: 0.05em;
                margin-bottom: rem(16px);
                color: rgba($dark-brown-color, 0.6);
            }
            .vc-border {
                border: unset;
            }
            @media screen and (max-height: 880px) {
                @include respond-to ("large and up") {
                    top: -4rem;
                    &.position-departure {
                        top: -3rem;
                    }
                }
            }
        }
    }
    @include respond-to("small and down") {
        &__arrival-time {
            &:not(.no-promotion) {
                margin-top: 4px;
                padding: 1.5rem 0;
                margin-bottom: 1.5rem;
                border-top: 1px dashed rgba($dark-brown-color, 0.2);
                border-bottom: 1px dashed rgba($dark-brown-color, 0.2);
            }
        }
    }
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
}
</style>