import axios from 'axios';

let apiUrl = process.env.VUE_APP_;
let localMode = process.env.VUE_APP_LOCAL_MODE;
let getUrl = function(path) {
  if(localMode != 'true') {
      return apiUrl + path;

  } else {
      if(path.startsWith('/api/core'))
          return 'http://localhost:9090' + path;

      if(path.startsWith('/api/catalog'))
          return 'http://localhost:9091' + path;

      if(path.startsWith('/api/ecommerce'))
          return 'http://localhost:9092' + path;

      if(path.startsWith('/auth'))
          return 'http://localhost:9999' + path;
  }
}

let addSecurity = function(config) {
  config = config || {};
  config.authentication = 'yokozuna';
  return config;
};

let apiClient = {
  get: function(path, config) {
    return axios.get(getUrl(path), config);
  },
  put: function(path, data, config) {
    return axios.put(getUrl(path), data, config);
  },
  post: function(path, data, config) {
    return axios.post(getUrl(path), data, config);
  },
  delete: function(path, config) {
    return axios.delete(getUrl(path), config);
  },
  fileUpload: function(path, data, config) {
    config.cancelToken = config.cancelToken || axios.CancelToken.source().token;
    return axios.post(getUrl(path), data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      ...config,
    })
        ;
  },

  secure: {
    get: function(path, config) {
      return apiClient.get(path, addSecurity(config));
    },
    put: function(path, data, config) {
      return apiClient.put(path, data, addSecurity(config));
    },
    post: function(path, data, config) {
      return apiClient.post(path, data, addSecurity(config));
    },
    delete: function(path, config) {
      return apiClient.delete(path, addSecurity(config));
    },
    fileUpload: function(path, data, config) {
      return apiClient.fileUpload(path, data, addSecurity(config));
    },
  },
};

export default apiClient;
