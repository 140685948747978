<template>
  <base-layout ref="baseLayout" :class="{'no-scroll': mobileDrawerVisible || sideHeaderBarVisible}">
    <div class="default-layout">
      <div ref="header" id="header-side-bar" class="header-and-side-bar animated faster" :class="{'no-banner': !activeBanner}">
        <header-bar ref="headerBar" class="header-bar"></header-bar>
        <side-bar class="side-bar--desktop"></side-bar>
        <visit-drawer ref="visitDrawer" class="visit-drawer"></visit-drawer>
      </div>
      <transition enter-active-class="animated faster fadeInLeft"
                  leave-active-class="animated faster fadeOutLeft">
        <side-bar class="side-bar--mobile" v-if="sideHeaderBarVisible"></side-bar>
      </transition>
      <main v-if="$route.name == 'homepage'" class="homepage">
          <slot></slot>
      </main>
      <main v-if="$route.name != 'homepage'">
        <slot name="header"></slot>
        <column-block>
          <slot></slot>
        </column-block>
      </main>
      <mobile-drawer class="mobile-drawer" v-if="showMobileDrawer" ref="mobileDrawer"></mobile-drawer>
    </div>
    <see-conditions-modal ref="conditionsModal"></see-conditions-modal>
  </base-layout>
</template>

<script>
  import BaseLayout from '@/layouts/BaseLayout';
  import HeaderBar from '@/components/bars/HeaderBar';
  import MobileDrawer from '@/components/MobileDrawer';
  import SideBar from '@/components/bars/SideBar';
  import ColumnBlock from '@/components/columns/ColumnBlock';
  import VisitDrawer from '@/components/VisitDrawer';
  import SeeConditionsModal from '@/components/visit-drawer/SeeConditionsModal';
  import moment from 'moment';
  import {mapState} from 'vuex';

  const routes = [
    'product',
    'homepage',
    'prices',
    'faq'
  ]

  export default {
    name: 'DefaultLayout',
    components: {SeeConditionsModal, VisitDrawer, MobileDrawer, HeaderBar, SideBar, BaseLayout, ColumnBlock },
    watch: {
      'visitDrawerVisible'() {
        this.addEventListener();
      },
      'conditions2x3Visible'() {
        if(this.conditions2x3Visible) {
          document.removeEventListener("keyup", this.closeVisitDrawer, false);
          this.$refs.conditionsModal.openModal();
        } else {
          this.$refs.conditionsModal.closeModal();
          setTimeout(() => {
            document.addEventListener("keyup", this.closeVisitDrawer);
          }, 100);
        }
      },
      'dates'() {
        this.checkIfCapacityIsFull(this.dates);
      }
    },
    computed: {
      showMobileDrawer() {
        return routes.filter(route => this.$route.name.indexOf(route) != -1).length > 0;
      },
      ...mapState(['quote', 'sideHeaderBarVisible', 'visitDrawerVisible', 'mobileDrawerVisible', 'conditions2x3Visible']),
      dates() {
        let start = this.quote && this.quote.visit && this.quote.visit.arrivalDate;
        let end = this.quote && this.quote.visit && this.quote.visit.departureDate;
        let dates = {
          start: start ? start : moment().format('YYYY-MM-DD'),
          end: end ? end : moment().format('YYYY-MM-DD')
        }
        return dates;
      },
      banner() {
        return this.$store.getters.banner;
      },
      activeBanner() {
        let locale = this.$store.getters.locale;
        let name = locale === 'es' ? 'BANNER_ES' : 'BANNER_EN';
        let banner = this.banner && this.banner.find(b => b.name === name);
        return banner && banner.active;
      }
    },
    data() {
      return {
        lastScrollTop: 0,
        displayMaintenanceView: process.env.VUE_APP_false
      }
    },
    created () {
        window.addEventListener('scroll', this.scroll);
        window.addEventListener('resize', this.resize);

    },
    destroyed () {
      window.removeEventListener('scroll', this.scroll);
      window.removeEventListener('resize', this.resize);
      document.removeEventListener("keyup", this.closeVisitDrawer);
    },
    mounted() {
      if(this.$refs.mobileDrawer && this.$refs.mobileDrawer.$el.classList && this.displayMaintenanceView != 'true') {
        this.$refs.mobileDrawer.$el.classList.add("position-fixed")
      }
    },
    methods: {
      async checkIfCapacityIsFull(dates) {
        let arrivalTime = this.quote && this.quote.visit && this.quote.visit.arrivalTime;
        if(dates && dates.start && dates.end && arrivalTime) {
          Object.assign(dates, {arrivalTime: arrivalTime});
          const capacityIsFull = await this.$store.dispatch('checkIfCapacityWasExceeded', dates);
          this.$store.dispatch('updateCapacityFull', capacityIsFull);
        }
      },
      resize() {
        if(screen.width >= 992) {
          this.$refs.header.classList.remove("fadeOutUp");
          this.$refs.header.classList.add("fadeInDown");
          this.$refs.header.style.opacity = 1;
        }
      },
      scroll() {
        let headerHeigth = this.getHeaderHeigth();
        let heightCurrent = Math.ceil((window.scrollY + window.innerHeight));
        let sub = Math.abs(heightCurrent - this.lastScrollTop);
        if((sub > headerHeigth && screen.width < 992 && window.scrollY > 0) && !this.activeBanner) {
          if (this.lastScrollTop > heightCurrent) {
            this.$refs.header.classList.remove("fadeOutUp");
            this.$refs.header.classList.add("fadeInDown");
            this.$refs.header.style.opacity = 1;
          } else {
            this.$refs.header.classList.add("fadeOutUp");
            this.$refs.header.classList.remove("fadeInDown");
            this.$refs.header.style.opacity = 0;
          }
          this.lastScrollTop = heightCurrent;
        } else if (this.displayMaintenanceView != 'true') {
          this.$refs.header.style.opacity = 1;
        }
      },
      getHeaderHeigth() {
        let headerElement = document.getElementById('header-side-bar');
        return headerElement ? headerElement.clientHeight : 80;
      },
      addEventListener() {
        if(this.quote && this.visitDrawerVisible) {
          document.addEventListener("keyup", this.closeVisitDrawer);
        } else {
          document.removeEventListener("keyup", this.closeVisitDrawer);
        }
      },
      closeVisitDrawer: function (evt) {
          if (evt.keyCode === 27) {
              this.close();
          }
      },
      close() {
        this.$store.dispatch('hideVisitDrawer');
      }
    }
  };
</script>

<style lang="scss" scoped>
  main {
    &:not(.homepage) { display: grid }
  }
  .opacity {
    opacity: 0;
  }
  .position-fixed {
    position: fixed;
    bottom: 0;
  }
  .header-and-side-bar {
      width: 100%;
      top: 0;
      z-index: 10 !important;
      .banner {
        z-index: 11;
      }
  }
  .side-bar--desktop {
    z-index: 5px;
  }
  .visit-drawer {
    z-index: 5;
  }
  @include respond-to("xlarge and up") {
    .side-bar--mobile { display: none }
    .header-and-side-bar {
      position: sticky;
      position: -webkit-sticky;
      opacity: 1;
    }
  }
  @include respond-to("large and down") {
    .side-bar--desktop { display: none }
    .header-and-side-bar {
      &.no-banner {
        position: sticky;
        position: -webkit-sticky;
      }
    }
    .mobile-drawer {
      position: sticky;
      position: -webkit-sticky;
    }
  }
</style>
