<template>
    <products-lodging selected-product="ecotouristCabins">
      <div class="product-summary__details" v-if="currentProduct && information">
        <gallery>
          <template slot="small-image">
            <img :src="require('@/assets/products/cabins/cabin_' + gallery[0] + '.jpg')" alt="">
          </template>
          <template slot="gallery-images">
            <img class="slide" v-for="(picture, i) in gallery" :key="i" :src="require('@/assets/products/cabins/cabin_' + picture + '.jpg')" alt="">
          </template>
        </gallery>
        <div class="product-summary__details__title">
          <h1>{{ $t(currentProduct.title) }}</h1>
        </div>
        <div class="product-summary__details__content">
          <div class="product-summary__details__content__information">
            <div class="product-summary__details__content__information__description" :class="{ hidden: informationHidden }">
              <ul>
                <li v-for="(text, index) in information[currentProduct.imageName].description" :key="index" v-show="text">{{ $t(text) }}</li>
              </ul>
              
            </div>
            <span class="hide-description" @click="informationHidden = !informationHidden">{{ informationHidden ? $t('readMore') : $t('hide') }}</span>
          </div>
          <div class="product-summary__details__content__services">
            <h1 class="product-summary__details__content__services__title">{{$t('services')}}</h1>
            <div class="product-summary__details__content__services__item" v-for="service in information[currentProduct.imageName].services" :key="service.id">
              <img class="icon" :src="require('@/assets/products/services/'+service.iconName+'.svg')" alt="">
              <span>{{ $t(service.name) }}</span>
            </div>
          </div>
        </div>
      </div>
    </products-lodging>
  </template>
  
  <script>
    import Gallery from '@/components/Gallery';
    import ProductsLodging from './ProductsLodging.vue';
  
    const information = {
      cabins: {
        priceDescription: 'pricePerCabin',
        priceDescriptionMobile: 'perCabin',
        labels: ['priceFor', 'capacity1To6People', 'thePriceDoesNotIncludeAdmissionFees'],
        services: [
          { id:'29', name: 'productServices.shower', iconName:'shower' },
          { id:'27', name: 'productServices.electricalOutlets110V', iconName:'electricity-outlets' },
          { id:'28', name: 'productServices.sink', iconName:'dishwasher' },
          { id:'30', name: 'productServices.restroom', iconName:'bathroom' },
          { id:'36', name: 'productServices.hotWaterSink', iconName:'sink' },
          { id:'32', name: 'productServices.sofaBed', iconName:'sofa' },
          { id:'33', name: 'productServices.bunkBeds', iconName:'bunk-beds' },
          { id:'34', name: 'productServices.campingTable', iconName:'table' },
          { id:'35', name: 'productServices.grill', iconName:'steakhouse' },
          { id:'31', name: 'productServices.parking', iconName:'parking-lot' }
        ],
        description: {
          text1: 'productPages.cabins.text1',
          text2: 'productPages.cabins.text2',
          text3: 'productPages.cabins.text3',
          text4: 'productPages.cabins.text4',
          text5: 'productPages.cabins.text5',
        }
      }
    }
  
    export default {
      name: 'Cabins',
      components: {Gallery, ProductsLodging},
      metaInfo() {
        return {title: this.$t("tabs.ecoTourismCabins")}
      },
      data() {
        return {
          currentProduct: null,
          informationHidden: true,
          gallery: [1,2,3,4,5,6]
        }
      },
      computed: {
          information() {
              return information;
          }
      },
      mounted() {
        this.currentProduct = { title:'ecotouristCabins', imageName: 'cabins' };
      }
    };
  </script>

<style lang="scss" scoped>
.product-summary {
  &__details {
    &__content {
      &__information {
        &__description {
          li {
            margin-bottom: 1.5rem;
          }
        }
      }
    }
  }
}
</style>