<template>
    <products-earth selected-product="echoViewpoint">
      <div class="product-summary__details" v-if="currentProduct">
        <gallery>
          <template slot="small-image">
            <img :src="require('@/assets/products/echo-viewpoint/echo-viewpoint-' + gallery[0] + '.jpg')" alt="">
          </template>
          <template slot="gallery-images">
            <img class="slide" v-for="(picture, i) in gallery" :key="i" :src="require('@/assets/products/echo-viewpoint/echo-viewpoint-' + picture + '.jpg')" alt="">
          </template>
        </gallery>
        <div class="product-summary__details__title">
          <h1>{{ $t(currentProduct.title) }}</h1>
        </div>
        <div class="product-summary__details__content">
          <div class="product-summary__details__content__information">
            <div class="product-summary__details__content__information__description" :class="{ hidden: informationHidden }">
              <p>{{ $t(description.text1) }}</p>
              <p>{{ $t(description.text2) }}</p>
              <p>
                {{ $t(description.text3) }}
                <span @click.prevent="goTo()">
                  <span class="link">{{ $t(description.link) }}</span>,
                </span>
                {{ $t(description.text4) }} 
              </p>
              <p>{{ $t(description.text5) }}</p>
            </div>
            <span class="hide-description" @click="informationHidden = !informationHidden">{{ informationHidden ? $t('readMore') : $t('hide') }}</span>
          </div>
        </div>
      </div>
    </products-earth>
  </template>
  
  <script>
    import Gallery from '@/components/Gallery';
    import ProductsEarth from '@/views/products/ProductsEarth';

    export default {
      name: 'EchoViewpoint',
      components: {ProductsEarth, Gallery},
      metaInfo() {
        return {title: this.$t("tabs.echoViewpoint")}
      },
      data() {
        return {
          currentProduct: null,
          informationHidden: true,
          gallery: [1,2,3],
          description: {
            text1: 'productPages.echoViewpoint.text1',
            text2: 'productPages.echoViewpoint.text2',
            text3: 'productPages.echoViewpoint.text3',
            text4: 'productPages.echoViewpoint.text4',
            text5: 'productPages.echoViewpoint.text5',
            link: 'productPages.echoViewpoint.link',
          }
        }
      },
      mounted() {
        this.currentProduct = { title:'echoViewpoint', imageName: 'echo-viewpoint' };
      },
      methods: {
        goTo() {
          this.$router.push({name: 'product.earth.horseback-riding'})
        }
      }
    };
  </script>

<style lang="scss" scoped>
.product-summary {
    &__details {
      &__content {
        &__information {
          &__description {
            .link {
              color: $blue-color2;
              font-weight: 400;
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
      }
    }
}

</style>