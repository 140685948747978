<template>
  <article>
    <h1 class="content__title">{{ $t('faqPages.generalServices.title') }}</h1>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.generalServices.question1.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.generalServices.question1.answer.text1') }}</p>
      <br>
      <ul class="question__answer-list question__answer-list">
        <li>{{ $t('faqPages.generalServices.question1.answer.text2') }}</li>
        <br>
        <li>{{ $t('faqPages.generalServices.question1.answer.text3') }}</li>
        <br>
        <li>{{ $t('faqPages.generalServices.question1.answer.text4') }}</li>
        <br>
        <li>{{ $t('faqPages.generalServices.question1.answer.text5') }}</li>
      </ul>
      <br>
      <p class="question__answer">
        {{ $t('faqPages.generalServices.question1.answer.text6') }}
        <router-link class="question__answer--link" :to="'/prices'" target="_blank">{{ $t('priceCalendar') }}</router-link>.
      </p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.generalServices.question2.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.generalServices.question2.answer') }}</p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.generalServices.question3.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.generalServices.question3.answer') }}</p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.generalServices.question4.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.generalServices.question4.answer') }}</p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.generalServices.question5.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.generalServices.question5.answer') }}</p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.generalServices.question6.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.generalServices.question6.answer') }}</p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.generalServices.question7.title') }}</h3>
      <p class="question__answer">
        {{ $t('faqPages.generalServices.question7.answer.text1') }}
        <br>
        {{ $t('faqPages.generalServices.question7.answer.text2') }}
      </p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.generalServices.question8.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.generalServices.question8.answer') }}</p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.generalServices.question9.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.generalServices.question9.answer') }}</p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.generalServices.question10.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.generalServices.question10.answer') }}</p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.generalServices.question11.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.generalServices.question11.answer') }}</p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.generalServices.question12.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.generalServices.question12.answer') }}</p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.generalServices.question13.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.generalServices.question13.answer') }}</p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.generalServices.question14.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.generalServices.question14.answer') }}</p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.generalServices.question15.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.generalServices.question15.answer') }}</p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.generalServices.question16.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.generalServices.question16.answer') }}</p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.generalServices.question17.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.generalServices.question17.answer') }}</p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.generalServices.question18.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.generalServices.question18.answer') }}</p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.generalServices.question19.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.generalServices.question19.answer') }}</p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.generalServices.question20.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.generalServices.question20.answer') }}</p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.generalServices.question21.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.generalServices.question21.answer') }}</p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.generalServices.question22.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.generalServices.question22.answer') }}</p>
    </div>
  </article>
</template>

<script>
  export default {
    name: 'GeneralServices'
  }
</script>