<template>
  <products-earth selected-product="rvPark">
    <div class="product-summary__details" v-if="currentProduct && information">
      <gallery>
        <template slot="small-image">
          <img :src="require('@/assets/products/' + currentProduct.imageName + '--full.png')" class="small-image">
        </template>
        <template slot="gallery-images">
          <div class="slide" :style="'background-image: url(' + require('@/assets/products/' + currentProduct.imageName + '--full.png') +')'">
          </div>
        </template>
      </gallery>
      <div class="product-summary__details__title">
        <h1>{{ $t(currentProduct.title) }}</h1>
      </div>
      <div class="product-summary__details__content">
        <div class="product-summary__details__content__information">
          <div class="product-summary__details__content__information__description">
            <p>{{ $t(information[currentProduct.title].description.text1) }}</p>
            <p>{{ $t(information[currentProduct.title].description.text2) }}</p>
            <p>{{ $t(information[currentProduct.title].description.text3) }}</p>
          </div>
        </div>
        <div class="product-summary__details__content__services">
          <h1 class="product-summary__details__content__services__title">{{$t('services')}}</h1>
          <div class="product-summary__details__content__services__item" v-for="(service, index) in information[currentProduct.title].services" :key="index">
            <img class="icon" :src="require('@/assets/products/services/'+service.iconName+'.svg')" alt="">
            <span>{{ $t(service.name) }}</span>
          </div>
        </div>
      </div>
    </div>
  </products-earth>
</template>

<script>
  import Gallery from '@/components/Gallery';
  import ProductsEarth from '@/views/products/ProductsEarth';

  const information = {
    rvPark: {
      priceDescription: 'pricePerCabin',
      priceDescriptionMobile: 'perCabin',
      labels: ['priceFor', 'capacity1To6People', 'thePriceDoesNotIncludeAdmissionFees'],
      services: [
        { name: 'productServices.electrical',   iconName:'electricity-outlets'  },
        { name: 'productServices.campingTable', iconName:'table'                },
        { name: 'productServices.grill',        iconName:'steakhouse'           }
      ],
      description: {
        text1: 'productPages.rvPark.text1',
        text2: 'productPages.rvPark.text2',
        text3: 'productPages.rvPark.text3'
      }
    }
  }

  export default {
    name: 'RvPark',
    components: {ProductsEarth, Gallery},
    data() {
      return {
        currentProduct: null,
        informationHidden: true,
        gallery: [1,2,3]
      }
    },
    computed: {
        information() {
            return information;
        }
    },
    mounted() {
      this.currentProduct = { title:'rvPark', imageName: 'rv-park' };
    }
  };
</script>

<style lang="scss" scoped>
  .product-summary {
    &__details {
      .text {
        @include respond-to("small and down") {
            line-height: 24px;
            }
        }
      .small-image {
          height: 100%;
          object-fit: cover;
          width: 103%;
      }
      &__title {
            @include respond-to("medium and up"){
                margin-top: 6px;
            }
      }
      &__content {
          &__information {
              &__description {

                  grid-template-rows: auto;

                  @include respond-to("medium and up"){
                      margin-top: 6px;
                  }
                  @include respond-to("small and down"){
                      margin-top: -1px;
                      letter-spacing: 0.05em;
                      p {
                        line-height: 1.5rem;
                      }
                  }
              }
          }
        &__services {
          height: rem(203px);
          &__title {
            padding-top: 0.6rem;
          }
        }
      }
    }
  }

</style>