<template>
    <product-summary :products="products" :selected-product="selectedProduct" sectionTitle="lodging" :show-section-links="true">
      <slot></slot>
    </product-summary>
  </template>

  <script>
    import ProductSummary from '@/views/products/ProductSummary';

    export default {
      components: {ProductSummary},
      name: 'ProductsEarth',
      props: ['selectedProduct'],
      data() {
        return {
          products: [
            { id:'camping', title:'camping', price: 200, priceDescription:'pricePerPerson', imageName:'camping', state: 'lodging.camping', callback: this.analyticsSeeDetails, select: true, eventName: 'acampado' },
            { id:'ecotouristCabins', title:'ecotouristCabins', price: 2000, priceDescription:'pricePerCabin', imageName:'cabins', state: 'lodging.cabins.ecotouristic', callback: this.analyticsSeeDetails, select: false, eventName: 'cabañas ecoturísticas' },
            { id:'lakeVillage', title:'lakeVillages', price: 2000, priceDescription:'pricePerCabin', imageName:'lake', state: 'lodging.cabins.lake', callback: this.analyticsSeeDetails, select: false, eventName: 'cabañas del lago' },
            { id:'indian-village', title:'indianVillage', price: 650, priceDescription:'pricePerTipee', imageName:'teepee', state: 'lodging.indian-village', select: false, callback: this.analyticsSeeDetails, eventName: 'villa india' },
        ],
          currentProduct: null
        }
      },
      methods: {
        analyticsSeeDetails(title) {
          this.onGenericEvent(`${title} menú sidebar`, { event_category: 'Hospedaje', event_label: `menu` });
        },
        onGenericEvent(event, command) {
          this.$root.$emit('onGenericEvent', event, command);
        },
      }
    };
  </script>