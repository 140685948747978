import Vue from 'vue';
import VueRouter from 'vue-router';
import Homepage from '@/views/Homepage.vue';
import ShoppingCart from '@/views/ShoppingCart';
import ContactPayment from '@/views/checkout/ContactPayment';
import ThankYou from '@/views/checkout/ThankYou';
import ProductCamping from '@/views/products/Camping';
import ProductCabins from '@/views/products/Cabins';
import ProductRvPark from '@/views/products/RvPark';
import ProductLiveMusic from '@/views/products/LiveMusic';
import ProductHorseBackRiding from '@/views/products/HorseBackRiding';
import ProductWaveAdventure from '@/views/products/WaveAdventure';
import ProductInteractiveFountain from '@/views/products/InteractiveFountain'
import ProductPirateFountain from '@/views/products/PirateFountain';
import ProductDivingBoardPool from '@/views/products/DivingBoardPool';
import ProductSuperWaterSlide from '@/views/products/SuperWaterSlide';
import ProductPedalBoats from '@/views/products/PedalBoats';
import ProductWaterFallsPool from '@/views/products/WaterFallsPool';
import Lake from '@/views/products/Lake';
import CanopyTour from '@/views/products/CanopyTour';
import Safety from '@/views/products/Safety';
import ZipLines from '@/views/products/ZipLines';
import SuspensionBridges from '@/views/products/SuspensionBridges';
import Faqs from '@/views/faqs/Faqs';
import Events from '@/views/events/Events';
import Terms from '@/views/terms/Terms';
import Privacy from '@/views/privacy/Privacy';
import Calendar from '@/views/calendar/Calendar';
import Contact from '@/views/information/Contact';
import Directions from '@/views/information/Directions';
import Careers from '@/views/information/Careers.vue'
import EcoCabins from '@/views/products/EcoCabins.vue'
import LakeVillage from '@/views/products/LakeVillage.vue'
import LodgingCamping from '@/views/products/LodgingCamping.vue'
import LodgingIndianVillage from '@/views/products/LodgingIndianVillage.vue'
import ATVTrack from '@/views/products/ATVTrack.vue'
import SportAreas from '@/views/products/SportAreas.vue'
import FlagViewpoint from '@/views/products/FlagViewpoint.vue'
import EchoViewpoint from '@/views/products/EchoViewpoint.vue'
import TrojaRestaurant from '@/views/products/TrojaRestaurant.vue'
import GroceryStores from '@/views/products/GroceryStores.vue'
import AtvRentals from '@/views/products/AtvRentals.vue'
import JobApplication from '@/views/information/JobApplication.vue';
import Farm from '@/views/products/Farm.vue'
import { fbq } from '@/main.js';

Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    redirect: '/',
  },
  {
    path: '/',
    name: 'homepage',
    component: Homepage,
  },
  {
    path: '/product-earth',
    name: 'product.earth',
    redirect: '/product-earth/camping'
  },
  {
    path: '/product-water',
    name: 'product.water',
    redirect: '/product-water/wave-adventure'
  },
  {
    path: '/product-earth/camping',
    name: 'product.earth.camping',
    component: ProductCamping,
  },
  {
    path: '/product-earth/cabins',
    name: 'product.earth.cabins',
    component: ProductCabins,
  },
  {
    path: '/product-earth/rv-park',
    name: 'product.earth.rv-park',
    component: ProductRvPark,
  },
  {
    path: '/product-earth/live-music',
    name: 'product.earth.live-music',
    component: ProductLiveMusic,
  },
  {
    path: '/product-earth/horseback-riding',
    name: 'product.earth.horseback-riding',
    component: ProductHorseBackRiding,
  },
  {
    path: '/product-earth/atv-track',
    name: 'product.earth.atv-track',
    component: ATVTrack,
  },
  {
    path: '/product-earth/sport-areas',
    name: 'product.earth.sport-areas',
    component: SportAreas,
  },
  {
    path: '/product-earth/flag-viewpoint',
    name: 'product.earth.flag-viewpoint',
    component: FlagViewpoint,
  },
  {
    path: '/product-earth/echo-viewpoint',
    name: 'product.earth.echo-viewpoint',
    component: EchoViewpoint,
  },
  {
    path: '/product-earth/farm',
    name: 'product.earth.farm',
    component: Farm,
  },
  {
    path: '/product-water/wave-adventure',
    name: 'product.water.wave-adventure',
    component: ProductWaveAdventure,
  },
  {
    path: '/product-water/interactive-fountain',
    name: 'product.water.interactive-fountain',
    component: ProductInteractiveFountain
  },
  {
    path: '/product-water/pirate-fountain',
    name: 'product.water.pirate-fountain',
    component: ProductPirateFountain,
  },
  {
    path: '/product-water/diving-board-pool',
    name: 'product.water.diving-board-pool',
    component: ProductDivingBoardPool,
  },
  {
    path: '/product-water/super-water-slide',
    name: 'product.water.super-water-slide',
    component: ProductSuperWaterSlide,
  },
  {
    path: '/product-water/pedal-boats',
    name: 'product.water.pedal-boats',
    component: ProductPedalBoats,
  },
  {
    path: '/product-water/waterfalls-pool',
    name: 'product.water.waterfalls-pool',
    component: ProductWaterFallsPool,
  },
  {
    path: '/product-water/lake',
    name: 'product.water.lake',
    component: Lake,
  },
  {
    path: '/product-air',
    name: 'product.air',
    redirect: '/product-air/canopy-tour'
  },
  {
    path: '/product-air/canopy-tour',
    name: 'product.air.canopy-tour',
    component: CanopyTour,
  },
  {
    path: '/product-air/zip-lines',
    name: 'product.air.zip-lines',
    component: ZipLines,
  },
  {
    path: '/product-air/suspension-bridges',
    name: 'product.air.suspension-bridges',
    component: SuspensionBridges,
  },
  {
    path: '/product-air/safety',
    name: 'product.air.safety',
    component: Safety,
  },
  {
    path: '/product-lodging',
    name: 'lodging',
    redirect: '/product-lodging/camping'
  },
  {
    path: '/product-lodging/camping',
    name: 'lodging.camping',
    component: LodgingCamping
  },
  {
    path: '/product-lodging/ecotourist-cabins',
    name: 'lodging.cabins.ecotouristic',
    component: EcoCabins
  },
  {
    path: '/product-lodging/lake-village',
    name: 'lodging.cabins.lake',
    component: LakeVillage
  },
  {
    path: '/product-lodging/indian-village',
    name: 'lodging.indian-village',
    component: LodgingIndianVillage
  },
  {
    path: '/product-services',
    name: 'services',
    redirect: '/product-services/restaurant'
  },
  {
    path: '/product-services/restaurant',
    name: 'services.restaurant',
    component: TrojaRestaurant
  },
  {
    path: '/product-services/grocery-store',
    name: 'services.grocery',
    component: GroceryStores
  },
  {
    path: '/product-services/atv-rentals',
    name: 'services.atv-rentals',
    component: AtvRentals
  },
  {
    path: '/cart',
    name: 'shopping-cart',
    component: ShoppingCart,
  },
  {
    path: '/checkout/contact-payment',
    name: 'contact-payment',
    component: ContactPayment,
  },
  {
    path: '/order/:code',
    name: 'order.details',
    component: ThankYou,
  },
  {
    path: '/faq',
    name: 'faq',
    component: Faqs
  },
  {
    path: '/events',
    name: 'events',
    component: Events
  },
  {
    path: '/terms',
    name: 'terms',
    component: Terms
  },
  {
    path: '/privacy-notice',
    name: 'privacy',
    component: Privacy
  },
  {
    path: '/prices',
    name: 'prices',
    component: Calendar
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact
  },
  {
    path: '/directions',
    name: 'directions',
    component: Directions
  },
  {
    path: '/careers',
    name: 'careers',
    component: Careers
  },
  {
    path: '/careers/job-details/:id',
    name: 'job-details',
    component: () => import('@/views/information/JobDetails.vue')
  },
  {
    path: '/careers/job-details/:id/apply',
    name: 'job-application',
    component: JobApplication
  }


];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,

  scrollBehavior (_to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }

});

router.beforeEach((to, _from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);
  const store = require('./store').default;
  if (_from.name !== "shopping-cart") {
    store.dispatch('hideVisitDrawer');
  }
  store.dispatch('hideAllMessages');

  if (!requiresAuth || store.getters.currentUser != null) {
    next();
  } else {
    next('/login');
  }
});

router.afterEach(() => {
  fbq('track', 'ViewContent');
});

export default router;
