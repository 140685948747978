<template>
  <product-summary :products="products" :selected-product="selectedProduct" sectionTitle="fun.air" :show-section-links="true">
    <slot></slot>
  </product-summary>
</template>

<script>
  import ProductSummary from '@/views/products/ProductSummary';

  export default {
    components: {ProductSummary},
    name: 'ProductsAir',
    props: ['selectedProduct'],
    data() {
      return {
        products: [{
          id:'canopy-tour',
          title:'canopyTour',
          imageName:'canopy-tour',
          state: 'product.air.canopy-tour',
          select: false,
          subproduct: false,
          callback: this.analyticsSeeDetails,
          eventName: 'canopy',
        },
        {
          id: 'zip-lines',
          title: 'zipLines',
          imageName: 'zip-lines',
          state: 'product.air.zip-lines',
          select: false,
          subproduct: true,
          callback: this.analyticsSeeDetails,
          eventName: 'zipLines'
        },
        {
          id: 'suspension-bridges',
          title: 'suspensionBridges',
          imageName: 'suspension-bridges',
          state: 'product.air.suspension-bridges',
          select: false,
          subproduct: true,
          callback: this.analyticsSeeDetails,
          eventName: 'suspensionBridges'
        },
        {
          id: 'safety',
          title: 'safety',
          imageName: 'safety',
          state: 'product.air.safety',
          select: false,
          subproduct: true,
          callback: this.analyticsSeeDetails,
          eventName: 'safety'
        }],
        currentProduct: null
      }
    },
    methods: {
      analyticsSeeDetails(title) {
        this.onGenericEvent(`${title} menú sidebar`, { event_category: 'diversión en aire', event_label: `menu` });
      },
      onGenericEvent(event, command) {
        this.$root.$emit('onGenericEvent', event, command);
      },
    }
  };
</script>