<template>
    <product-summary :products="products" :selected-product="selectedProduct" sectionTitle="other-services" :show-section-links="true">
      <slot></slot>
    </product-summary>
  </template>

  <script>
    import ProductSummary from '@/views/products/ProductSummary';

    export default {
      components: {ProductSummary},
      name: 'ProductsServices',
      props: ['selectedProduct'],
      data() {
        return {
          products: [
            { id:'trojaRestaurant', title:'trojaRestaurant', price: 200, priceDescription:'pricePerPerson', imageName:'restaurant', state: 'services.restaurant', callback: this.analyticsSeeDetails, select: true, eventName: 'la troja restaurante' },
            { id:'groceryStores', title:'groceryStores', price: 200, priceDescription:'pricePerPerson', imageName:'grocery-store', state: 'services.grocery', callback: this.analyticsSeeDetails, select: false, eventName: 'tiendas de abarrotes'},
            { id:'atvRentals', title:'atvRentals', imageName:'atv-track', state: 'services.atv-rentals', callback: this.analyticsSeeDetails, select: false},
          ],
          currentProduct: null
        }
      },
      methods: {
        analyticsSeeDetails(title) {
          this.onGenericEvent(`${title} menú sidebar`, { event_category: 'otros servicios', event_label: `menu` });
        },
        onGenericEvent(event, command) {
          this.$root.$emit('onGenericEvent', event, command);
        },
      }
    };
  </script>