<template>
  <base-information :title="title">
    <section class="contact">
      <article class="contact__form">
        <validation-observer ref="form" v-slot="{ invalid, handleSubmit }">
          <h2 class="contact__form__title">{{ $t("write-to-us") }}</h2>
          <form
            class="form"
            @submit.prevent="handleSubmit(sendFeedback)"
            novalidate
          >
            <validation-provider
              v-slot="{ errors }"
              class="field"
              rules="required"
              mode="lazy"
            >
              <label class="field__title" for="name"
                >{{ $t("name") }}<span class="required">*</span>
              </label>
              <input
                v-model.trim="form.name"
                class="field__input"
                :class="{ 'field__input--error': errors && errors[0] }"
                v-capitalize
                type="text"
                maxlength="80"
                name="name"
                id="name"
                required
              />
              <span class="field__error">{{ $t(errors[0]) }}</span>
            </validation-provider>

            <validation-provider v-slot="{ errors }" class="field">
              <label class="field__title" for="email">
                {{ $t("email") }}<span class="required">*</span>
              </label>
              <input
                class="field__input"
                :class="{ 'field__input--error': errors && errors[0] }"
                type="email"
                name="email"
                id="email"
                maxlength="320"
                v-model.trim="form.email"
                required
              />
              <span class="field__error">{{ $t(errors[0]) }}</span>
            </validation-provider>
            <validation-provider
              rules="required"
              v-slot="{ errors }"
              class="field field--full-width"
            >
              <label class="field__title"
                >{{ $t("subject") }}<span class="required">*</span>
              </label>
              <drop-down-contact
                :options="options"
                v-model="form.subject"
              ></drop-down-contact>
              <span class="field__error">{{ $t(errors[0]) }}</span>
            </validation-provider>

            <validation-provider
              rules="required"
              v-slot="{ errors }"
              class="field field--full-width"
            >
              <label for="message" class="field__title"
                >{{ $t("message") }}<span class="required">*</span>
              </label>
              <textarea
                class="field__input message"
                :class="{ 'field__input--error': errors && errors[0] }"
                name="message"
                id="message"
                v-model.trim="form.message"
                cols="30"
                rows="10"
                maxlength="1500"
                required
              ></textarea>
              <span class="field__error">{{ $t(errors[0]) }}</span>
            </validation-provider>

            <input
              type="submit"
              :value="$t('send')"
              class="form__submit form__submit--full-width"
              :disabled="invalid || isSendingRequest"
            />
          </form>
        </validation-observer>
      </article>
      <hr class="separator" />
      <aside class="contact__information">
        <h2 class="contact__information__title">{{ $t("contact-us") }}</h2>
        <dl class="information">
          <dd class="information__item">
            <img
              class="image"
              src="@/assets/social_networks/maps-icon.svg"
              alt="Maps Icon"
            />
            <p class="title">
              Km. 31.5 Carretera Transpeninsular Ensenada - La Paz
            </p>
          </dd>
          <dd class="information__item">
            <img
              class="image"
              src="@/assets/social_networks/mobile-icon.svg"
              alt="Mobile Icon"
            />
            <p class="title">(646) 153-1055</p>
          </dd>
          <dd class="information__item">
            <img
              class="image"
              src="@/assets/social_networks/email-icon.svg"
              alt="Email Icon"
            />
            <p class="title">info@lascanadas.com</p>
          </dd>
        </dl>
        <div class="icons">
          <a
            class="icon"
            target="_blank"
            href="https://es-la.facebook.com/LasCanadasCampamento/"
            ><img
              src="@/assets/social_networks/facebook-icon.svg"
              alt="Facebook"
          /></a>
          <a
            class="icon"
            target="_blank"
            href="https://www.instagram.com/lascanadascampamento"
            ><img
              src="@/assets/social_networks/instagram-icon.svg"
              alt="Instagram"
          /></a>
          <a
            class="icon"
            target="_blank"
            href="https://vimeo.com/lascanadascampamento"
            ><img src="@/assets/social_networks/vimeo-icon.svg" alt="Vimeo"
          /></a>
        </div>
      </aside>
    </section>
  </base-information>
</template>

<script>
import BaseInformation from "./BaseInformation";
import DropDownContact from "@/components/dropdowns/DropDownContact";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "validations.requiredField",
});

extend("email", {
  ...email,
  message: "validations.invalidRmailAddress",
});

const GTM_EVENT_SUCCESS = "contact-success";
const GTM_EVENT_ERROR = "contact-error";

export default {
  components: {
    BaseInformation,
    DropDownContact,
    ValidationProvider,
    ValidationObserver,
  },
  metaInfo() {
    return { title: this.$t("tabs.contact") };
  },
  computed: {
    title() {
      return this.$t("contact");
    },
  },
  name: "Contact",
  data() {
    return {
      form: {
        name: "",
        email: "",
        subject: "",
        message: "",
      },
      options: [
        { id: "QUESTIONS", label: "feedback.doubts-and-questions" },
        { id: "RESERVATIONS", label: "feedback.cabin-reservation" },
        { id: "COMPANIES_QUOTES", label: "feedback.company-quotation" },
        { id: "GROUP_QUOTES", label: "feedback.group-quotation" },
        { id: "SUGGESTIONS", label: "feedback.comments-or-suggestions" },
        { id: "COMPLAINTS", label: "feedback.complaints" },
      ],
      isSendingRequest: false,
    };
  },
  methods: {
    async sendFeedback() {
      try {
        this.isSendingRequest = true;
        this.form.subject = this.form.subject.id;
        await this.$store.dispatch("sendFeedback", this.form);
        this.resetForm();
        this.$store.dispatch("message", {
          type: "success",
          message: this.$t("feedback-sent"),
        });
        this.emitContactEvent(GTM_EVENT_SUCCESS);
      } catch (error) {
        this.emitContactEvent(GTM_EVENT_ERROR);
        return error;
      } finally {
        this.isSendingRequest = false;
      }
    },
    emitContactEvent(event) {
      this.$root.$emit("onContactEvent", event);
    },
    resetForm() {
      this.form.name = "";
      this.form.email = "";
      this.form.message = "";
      requestAnimationFrame(() => {
        this.$refs.form.reset();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.contact {
  display: grid;
  grid-template-columns: 1.1fr 0.7fr;
  grid-column-gap: rem(139px);
  @include respond-to("medium and down") {
    grid-template-columns: 1fr;
  }
  &__form {
    &__title {
      margin-bottom: rem(24px);
      font-size: rem(24px);
      line-height: rem(33px);
      @include respond-to("medium and down") {
        font-size: rem(16px);
        line-height: rem(22px);
      }
    }
    .form {
      display: grid;
      grid-row-gap: rem(14px);
      grid-column-gap: rem(16px);
      grid-template-columns: 1fr 1fr;
      @include respond-to("medium and down") {
        grid-template-columns: 1fr;
      }
      .field {
        &--full-width {
          width: 100%;
          grid-column: 1 / 3;
          @include respond-to("medium and down") {
            grid-column: unset;
          }
        }
        &__title {
          opacity: 0.6;
          color: $dark-brown-color;
          letter-spacing: 0.05em;
          line-height: rem(16px);
          font-size: rem(16px);
          font-weight: 500;
          font-style: normal;
        }
        .message {
          resize: none;
          height: rem(100px);
          box-sizing: border-box;
          border-radius: rem(8px);
          font-family: $font-primary;
          font-style: normal;
          font-weight: 500;
          font-size: rem(16px);
          line-height: rem(16px);
          padding: rem(16px);
          @include respond-to("medium and down") {
            height: rem(192px);
          }
        }
        &__input {
          width: 100%;
          margin-bottom: 0;
          height: rem(48px);
          padding-left: rem(16px);
          font-family: $font-primary;
          border: 1px solid $brown-opacity-2-color;
          &--error {
            border: solid 1px $red-color2;
          }
        }
        &__error {
          display: block;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: $red-color2;
        }
      }
      &__submit {
        margin: rem(0px);
        cursor: pointer;
        &:disabled {
          opacity: 0.4;
          cursor: not-allowed;
        }
        &--full-width {
          width: 100%;
          grid-column: 2 / 3;
          height: rem(48px);
          background: $orange-color;
          border-radius: rem(8px);
          font-size: rem(20px);
          line-height: rem(27px);
          align-items: center;
          text-align: center;
          text-transform: uppercase;
          letter-spacing: 0.05em;
          color: white;
          font-family: $font-secondary;
          @include respond-to("medium and down") {
            grid-column: unset;
            margin-top: rem(4px);
          }
        }
      }
      .required {
        color: red;
      }
    }
  }
  .separator {
    display: none;
    @include respond-to("medium and down") {
      display: block;
      border: 1px dashed $dark-brown-color;
      width: 100%;
      margin: rem(24px) 0 rem(24px);
    }
  }
  &__information {
    &__title {
      margin-bottom: rem(24px);
      font-size: rem(24px);
      line-height: rem(33px);
      @include respond-to("medium and down") {
        font-size: rem(16px);
        line-height: rem(22px);
      }
    }
    .information {
      display: grid;
      grid-row-gap: rem(16px);
      &__item {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-column-gap: rem(10px);
        font-weight: 500;
        font-size: rem(16px);
        line-height: rem(24px);
        letter-spacing: 0.05em;
        @include respond-to("medium and down") {
          width: 70%;
        }
        .title {
          margin-bottom: -3.5px;
          color: $dark-brown-color;
        }
      }
    }
    .icons {
      display: grid;
      grid-template-columns: rem(26px) rem(26px) rem(26px);
      grid-column-gap: rem(22.67px);
      align-items: end;
      margin-top: rem(26px);
    }
  }
}
</style>
