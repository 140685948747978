import Vue from 'vue';
import Vuex from 'vuex';

import moment from 'moment';

import {denormalize, schema} from 'normalizr';

import apiClient from '@/service/api-client';

Vue.use(Vuex);

const messagesSchema = new schema.Entity('messages');

const SHOPPING_CART_KEY = 'CANADAS_SHOPPING_CART';

const SHOPPING_CART_EMPTY_KEY = 'CANADAS_SHOPPING_CART_EMPTY';

const PENDING_STAY_LIST_KEY = 'CANADAS_PENDING_STAY_LIST';

const LOCALE_KEY = 'CANADAS_LOCALE';

const DEPARTURE_DATE = 'DEPARTURE_DATE';

const YELLOW_COLOR = 'YELLOW';

const handleRemoteActivity = async (commit, activity) => {
  let result;
  try {
    commit('updateInRemoteActivity', true);
    result = await activity();
  } finally {
    commit('updateInRemoteActivity', false);
  }
  return result.data;
};

const store = new Vuex.Store({
  state: {
    conditions2x3Visible: false,
    visitDrawerVisible: false,
    datesForApply: null,
    resetDatesForPromotion: false,
    mobileDrawerVisible: false,
    inRemoteActivity: false,
    sideHeaderBarVisible: false,
    clickToFinishPayment: false,
    capacityFull: {day: false, night: false},
    messages: {},
    quote: null,
    visit: null,
    locale: null,
    isUnavailableCabins: false,
    isUnavailableTipis: false,
    isUnavailableLakeVillage: false,
    reservationPrices: null,
    pendingStays: null,
    bannerClicksSeeMore: 0,
    banner: [],
    bannerSeeMore: false,
    activitiesModalVisible: false,
    activityModalType: null,
    chooseTipiVisible: false,
    chooseCabinVisible: false,
    chooseLakeVillageVisible: false,
    reservationAvailableFlag: false,
    reservationsAvailable: {tipis: [], cabins:[]},
    unavailableCabins: [],
    unavailableTipis: [],
    unavailableLakeVillages: [],
    fullTipis: false,
    fullCabins: false,
    fullLakeVillages: false,
    mobileSumatyVisible: true,
    activeModal: false,
    yellowDateSelected: false,
    currency: { currency: 'MXN', exchangeRate: 1 }
  },
  mutations: {
    updateReservationAvailableFlag(state) {
      state.reservationAvailableFlag = !state.reservationAvailableFlag;
    },
    updateReservationsAvailable(state, reservationsAvailable) {
      if (reservationsAvailable) {
        state.fullCabins = reservationsAvailable.cabins && reservationsAvailable.cabins.filter(c => !c.available).length === 20;
        state.fullTipis = reservationsAvailable.tipis && reservationsAvailable.tipis.filter(c => !c.available).length === 6;
        state.fullLakeVillages = reservationsAvailable.lakeVillages && reservationsAvailable.lakeVillages.filter(c => !c.available).length === 5;
      }
      state.reservationsAvailable = reservationsAvailable;
    },
    updateChooseTipiVisible(state, val) {
      state.chooseTipiVisible = val;
    },
    updateActivitiesModalVisible(state, val) {
      state.activitiesModalVisible = val;
    },
    updateActivityModalType(state, val) {
      state.activityModalType = val;
    },
    updateYellowDateSelected(state, val) {
      state.yellowDateSelected = val;
    },
    updateChooseCabinVisible(state, val) {
      state.chooseCabinVisible = val;
    },
    updateChooseLakeVillageVisible(state, val) {
      state.chooseLakeVillageVisible = val;
    },
    updateBanner(state, banner) {
      state.banner = banner
    },
    updateBannerSeeMore(state) {
      state.bannerSeeMore = !state.bannerSeeMore;
      state.bannerClicksSeeMore++;
    },
    updatePendingStays(state, val) {
      state.pendingStays = val
    },
    updateCapacityFull(state, val) {
      state.capacityFull = val;
    },
    cleanQuoteState(state) {
      state.quote = null;
      state.visit = null;
      state.locale = null;
      state.isUnavailableCabins = false;
      state.isUnavailableTipis = false;
      state.isUnavailableLakeVillage = false;
      state.reservationPrices = null;

      if(localStorage.getItem(DEPARTURE_DATE))
        localStorage.removeItem(DEPARTURE_DATE)

      if(localStorage.getItem(SHOPPING_CART_KEY))
        localStorage.removeItem(SHOPPING_CART_KEY);

      if(localStorage.getItem(PENDING_STAY_LIST_KEY))
        localStorage.removeItem(PENDING_STAY_LIST_KEY);

      if(localStorage.getItem(SHOPPING_CART_EMPTY_KEY)) {
        localStorage.removeItem(SHOPPING_CART_EMPTY_KEY);
      }
    },
    mobileSumatyVisible(state, val) {
      state.mobileSumatyVisible = val;
    },
    activeModal(state, val) {
      state.activeModal = val;
    },
    onClickToFinishPayment(state) {
      state.clickToFinishPayment = !state.clickToFinishPayment;
    },
    updateInRemoteActivity(state, val) {
      state.inRemoteActivity = val;
    },
    updateReservationPrices(state, val) {
      state.reservationPrices = val;
    },
    updateVisitDrawerVisible(state, val) {
      state.visitDrawerVisible = val;
    },
    updateMobileDrawerVisible(state, val) {
      state.mobileDrawerVisible = val;
    },
    updateSideHeaderBarVisible(state, val) {
      state.sideHeaderBarVisible = val;
    },
    addMessage(state, message) {
      Vue.set(state.messages, message.id, message);
    },
    removeMessage(state, message) {
      Vue.delete(state.messages, message.id);
    },
    removeAllMessages(state) {
      state.messages = {};
    },
    updateQuote(state, quote) {
      state.unavailableCabins = [];
      state.unavailableTipis = [];
      state.unavailableLakeVillages = [];

      if (!quote) {
        localStorage.removeItem(SHOPPING_CART_KEY);
        state.quote = null;
        return;
      }

      if (quote && quote.id) {
        localStorage.setItem(SHOPPING_CART_KEY, quote.id);
      }

      let existingReservations = null;
      if(existingReservations == null && quote.reservations && quote.reservations.length > 0) {
        existingReservations = quote.reservations.find(reservation => reservation.staying.stayingType == 'CABIN');
      }
      if(existingReservations == null && quote.pendingReservations && quote.pendingReservations.length > 0) {
        existingReservations = quote.pendingReservations.find(reservation => reservation.staying.stayingType == 'CABIN');
      }
      quote.products.cabinCost = existingReservations && existingReservations.totalAmount || 0;

      existingReservations = null;
      if(existingReservations == null && quote.reservations && quote.reservations.length > 0) {
        existingReservations = quote.reservations.find(reservation => reservation.staying.stayingType == 'TIPI');
      }
      if(existingReservations == null && quote.pendingReservations && quote.pendingReservations.length > 0) {
        existingReservations = quote.pendingReservations.find(reservation => reservation.staying.stayingType == 'TIPI');
      }
      quote.products.tipiCost = existingReservations && existingReservations.totalAmount || 0;

      let existingItemCanopy = null;
      if(existingItemCanopy == null && quote.items && quote.items.length > 0) {
        existingItemCanopy = quote.items.find(item => item.product.key == 'canopy_pass');
      }
      quote.products.canopyPassCost =
          (existingItemCanopy && existingItemCanopy.totalAmount && existingItemCanopy.quantity &&
              (existingItemCanopy.totalAmount / existingItemCanopy.quantity)) || 0;

      let existingItemHorsebackRiding = null;
      if(existingItemHorsebackRiding == null && quote.items && quote.items.length > 0) {
        existingItemHorsebackRiding = quote.items.find(item => item.product.key == 'horseback_riding_pass');
      }
      quote.products.horsebackRidingCost =
          (existingItemHorsebackRiding && existingItemHorsebackRiding.totalAmount && existingItemHorsebackRiding.quantity &&
              (existingItemHorsebackRiding.totalAmount / existingItemHorsebackRiding.quantity)) || 0;

      try {
        state.isUnavailableCabins = quote.unavailableCabinsDates.length > 0;
        state.isUnavailableTipis = quote.unavailableTipisDates.length > 0;
        state.isUnavailableLakeVillages = quote && quote.unavailableLakeVillagesDates
          ? quote.unavailableLakeVillagesDates.length > 0 : false;

        const colors = (quote.colors) ? quote.colors : [];
        this.dispatch('updateYellowDateSelected', colors.includes(YELLOW_COLOR));
        state.quote = quote;

      } catch(error) {
        if(localStorage.getItem(SHOPPING_CART_EMPTY_KEY)) {
          localStorage.removeItem(SHOPPING_CART_EMPTY_KEY);
        }
      }
    },
    updateLocale(state, locale){
      localStorage.setItem(LOCALE_KEY, JSON.stringify({locale: locale}));
      state.locale = locale;
    },
    initLocale(state) {
      let locale;
      if(localStorage.getItem(LOCALE_KEY)) {
        locale = JSON.parse(localStorage.getItem(LOCALE_KEY));
      } else {
        locale = {locale : 'es'};
        localStorage.setItem(LOCALE_KEY, JSON.stringify(locale));
      }
      this.replaceState(Object.assign(state, locale));
    },
    updateCondition3x2Visible(state, val) {
      state.conditions2x3Visible = val;
    },
    updateUnavailableReservations(state, unavailable) {
      state.unavailableCabins = unavailable ? unavailable.cabins : [];
      state.unavailableTipis = unavailable ? unavailable.tipis : [];
      state.unavailableLakeVillages = unavailable ? unavailable.lakeVillages : [];
    },
    updateCurrency(state, val) {
      state.currency = val;
    }
  },
  getters: {
    activitiesModalVisible: (state) => state.activitiesModalVisible,
    activityModalType: (state) => state.activityModalType,
    chooseTipiVisible: (state) => state.chooseTipiVisible,
    chooseCabinVisible: (state) => state.chooseCabinVisible,
    chooseLakeVillageVisible: (state) => state.chooseLakeVillageVisible,
    conditions2x3Visible: (state) => state.conditions2x3Visible,
    visitDrawerVisible: (state) => state.visitDrawerVisible,
    mobileDrawerVisible: (state) => state.mobileDrawerVisible,
    mobileSumatyVisible: (state) => state.mobileSumatyVisible,
    reservationAvailableFlag: (state) => state.reservationAvailableFlag,
    reservationsAvailable: (state) => state.reservationsAvailable,
    inRemoteActivity: (state) => state.inRemoteActivity,
    unavailableCabins: (state) => state.unavailableCabins,
    unavailableTipis: (state) => state.unavailableTipis,
    unavailableLakeVillages: (state) => state.unavailableLakeVillages,
    fullCabins: (state) => state.fullCabins,
    fullTipis: (state) => state.fullTipis,
    fullLakeVillage: (state) => state.fullLakeVillages,
    activeModal: (state) => state.activeModal,
    capacityFullDay: (state) => state.capacityFull.day,
    capacityFullNight: (state) => state.capacityFull.night,
    messages: (state) => {
      return denormalize(Object.keys(state.messages), [messagesSchema], state);
    },
    quote: (state) => state.quote,
    locale: (state) => state.locale,
    banner: (state) => state.banner,
    currency: (state) => state.currency,
  },
  actions: {
    message({commit}, message) {
      message.id = new Date().getTime().toString();
      commit('addMessage', message);
      setTimeout(() => {commit('removeMessage', message);}, 5000);
    },
    hideMessage({commit}, message) {
      commit('removeMessage', message);
    },
    hideAllMessages({commit}) {
      if (this.getters.messages > 0) {
        commit('removeAllMessages');
      }
    },
    cleanQuoteState({commit}) {
      commit('cleanQuoteState');
    },
    clickToFinishPayment({commit}) {
      commit('onClickToFinishPayment');
    },
    hideChangeDatePromotionModal({commit}) {
      commit('cancelChangeDatesForApply')
    },
    async showVisitDrawer({commit}) {
      await this.dispatch('loadQuote');
      commit('updateVisitDrawerVisible', true);

      const pendingStays = JSON.parse(localStorage.getItem(PENDING_STAY_LIST_KEY));
      commit('updatePendingStays', pendingStays);
    },
    hideVisitDrawer({commit}) {
      commit('updateVisitDrawerVisible', false);
    },
    async showMobileDrawer({commit}) {
      await this.dispatch('loadQuote');
      commit('updateMobileDrawerVisible', true);
    },
    hideMobileDrawer({commit}) {
      commit('updateMobileDrawerVisible', false);
    },
    showMobileSumatyVisible({commit}) {
      commit('mobileSumatyVisible', true);
    },
    hideMobileSumatyVisible({commit}) {
      commit('mobileSumatyVisible', false);
    },
    updateChooseTipiVisible({commit}, val) {
      commit('updateChooseTipiVisible', val);
    },
    updateActivitiesModalVisible({commit}, val) {
      commit('updateActivitiesModalVisible', val);
    },
    updateActivityModalType({commit}, val) {
      commit('updateActivityModalType', val);
    },
    updateYellowDateSelected({commit}, val) {
      commit('updateYellowDateSelected', val);
    },
    updateChooseCabinVisible({commit}, val) {
      commit('updateChooseCabinVisible', val);
    },
    updateChooseLakeVillageVisible({commit}, val) {
      commit('updateChooseLakeVillageVisible', val);
    },
    updateBannerSeeMore({commit}) {
      commit('updateBannerSeeMore');
    },
    updateReservationAvailableFlag({commit}) {
      commit('updateReservationAvailableFlag');
    },
    updateReservationsAvailable({commit}, reservations) {
      commit('updateReservationsAvailable', reservations);
    },
    showSideHeaderBar({commit}) {
      commit('updateSideHeaderBarVisible', true);
    },
    hideSideHeaderBar({commit}) {
      commit('updateSideHeaderBarVisible', false);
    },
    deleteQuote({commit}) {
      commit('updateQuote', null);
    },
    showConditions3x2({commit}) {
      commit('updateCondition3x2Visible', true);
    },
    hideConditions3x2({commit}) {
      commit('updateCondition3x2Visible', false);
    },
    updateReservationPrices({commit}, prices) {
      commit('updateReservationPrices', prices)
    },
    async createQuote({commit}) {
      let quote = await handleRemoteActivity(commit, () => {
        return apiClient.post('/api/ecommerce/quotes/', {
          visit: {
            arrivalDate: moment().toDate(),
            departureDate: moment().add(1, 'days').toDate(),
            numberOfPersons: 0,
            arrivalTime: 'NIGHT',
            stayType: 'CAMPING_PASS',
          },
        });
      });
      localStorage.removeItem(SHOPPING_CART_EMPTY_KEY);
      this.dispatch('updateQuote', quote.id);
      let pendingStays = JSON.parse(localStorage.getItem(PENDING_STAY_LIST_KEY));

      if (quote.visit.numberOfDays > 0 && pendingStays) {
        let request = {
          quoteId: quote.id,
          stayIds: pendingStays
        }
        setTimeout(() => {
          this.dispatch('saveReservations', request)
        }, 1000);
      }
      localStorage.removeItem(PENDING_STAY_LIST_KEY);
      commit('updatePendingStays', null);
    },
    createEmptyQuote({commit}) {
      let quote = {
        visit: {
          arrivalDate: null,
          departureDate: null,
          numberOfPersons: 0,
          arrivalTime: null,
          stayType: null,
          numberOfDays: 1,
          code: null,
          firstTime: false
        },
        entry: [],
        totalAmount: 0,
        discountAmount: 0,
        paymentIntentId: null,
        status: null,
        contact: null,
        products: {
          cabins: 0,
          canopyPasses: 0,
          tipis: 0,
          horsebackRidingPasses: 0,
          lakeVillages: 0,
        },
        offer3x2: false,
        pendingAmount: null,
        reservations: [],
        pendingReservations: [],
        items: [],
        unavailableCabinsDates: [],
        unavailableTipisDates: [],
        unavailableLakeVillagesDates: []
      }
      localStorage.setItem(SHOPPING_CART_EMPTY_KEY, JSON.stringify(quote));

      if(localStorage.getItem(SHOPPING_CART_KEY)) {
        localStorage.removeItem(SHOPPING_CART_KEY);
      }

      commit('updateQuote', quote);
    },
    async loadQuote({commit}) {
      const storedShoppingCartId = localStorage.getItem(SHOPPING_CART_KEY);
      const storedShoppingCartEmpty = JSON.parse(localStorage.getItem(SHOPPING_CART_EMPTY_KEY));

      if (!storedShoppingCartId) {

        if(storedShoppingCartEmpty) {
          commit('updateQuote', storedShoppingCartEmpty);
          return;
        }

        localStorage.removeItem(PENDING_STAY_LIST_KEY);
        commit('updatePendingStays', null);

        return this.dispatch('createEmptyQuote');
      }

      try {
        const quote = await handleRemoteActivity(commit, () => {
            return apiClient.get(`/api/ecommerce/quotes/${storedShoppingCartId}`);
        });

        commit('updateQuote', quote);
        this.dispatch('updateQuote', quote.id);
      } catch (error) {
        return this.dispatch('createEmptyQuote');
      }

    },
    async getQuote({commit}, quoteId){
      return handleRemoteActivity(commit, () => {
        return apiClient.get(`/api/ecommerce/quotes/${quoteId}`);
      });
    },
    async getQuoteByVisitCode({commit}, code){
      return handleRemoteActivity(commit, () => {
        return apiClient.get(`/api/ecommerce/quotes/code/${code}`);
      });
    },
    async updateQuote({commit}, quoteId) {

      if (!quoteId) {
        commit('updateQuote', null);
        return;
      }

      const quote = this.getters.quote;
      quote.id = quoteId
      localStorage.setItem(SHOPPING_CART_KEY, quoteId);

      const updatedQuote = await handleRemoteActivity(commit, () => {
        return apiClient.put(`/api/ecommerce/quotes/${quoteId}`, quote);
      });
      commit('updateQuote', updatedQuote);
    },
    async updateVisit({commit}, visit) {

      const quote = this.getters.quote;

      Object.assign(quote.visit, visit);
      quote.visit.numberOfPersons = Math.max(quote.visit.numberOfPersons, 0);

      const lodgings = quote && quote.products
        ? quote.products.cabins + quote.products.tipis + quote.products.lakeVillages
        : 0;

      if (quote && quote.id && quote.visit.numberOfPersons > 0 && quote.visitType == visit.stayType) {
        const updatedQuote = await handleRemoteActivity(commit, () => {
          return apiClient.put(`/api/ecommerce/quotes/${quote.id}`, quote);
        });

        commit('updateQuote', updatedQuote);

      } else if (quote && quote.visit && quote.visit.arrivalDate && quote.visit.departureDate && quote.visit.numberOfPersons && (quote.visit.arrivalTime || lodgings > 0)) {
        if (lodgings > 0 && !quote.visit.arrivalTime) {
          quote.visit.arrivalTime = 'DAY';
        }
        this.dispatch('createQuote', quote);
      } else {
        localStorage.setItem(SHOPPING_CART_EMPTY_KEY, JSON.stringify(quote));
        commit('updateQuote', quote);
      }
    },
    saveReservationForApply({commit}, stayIds) {
      localStorage.setItem(PENDING_STAY_LIST_KEY, JSON.stringify(stayIds));
      commit('updatePendingStays', stayIds);
    },
    async updateProducts({commit}, products) {
      const quote = this.getters.quote;
      if (quote.visit.arrivalDate && quote.visit.departureDate && quote.visit.arrivalDate == quote.visit.departureDate
        && (products.cabins || products.tipis || products.lakeVillages)) {

        const arrivalDate = quote && quote.visit && quote.visit.arrivalDate ? quote.visit.arrivalDate : moment().format('YYYY-MM-DD');
        const visit = {
          arrivalDate: arrivalDate,
          departureDate: (moment(arrivalDate).add(1, 'd')).format("YYYY-MM-DD")
        }
        Object.assign(quote.visit, visit);
      }

      function canUpdate(products) {
        let update = true;
        for (const key in products) {
          if (Array.isArray(products[key])) {
            update = false;
          }
        }
        return update;
      }

      if(canUpdate(products)) {
        Object.assign(quote.products, products);
        if (quote && quote.id) {
          let updatedQuote = await handleRemoteActivity(commit, () => {
            return apiClient.put(`/api/ecommerce/quotes/${quote.id}`, quote);
          });

          commit('updateQuote', updatedQuote);
        } else {
          localStorage.setItem(SHOPPING_CART_EMPTY_KEY, JSON.stringify(quote));
          commit('updateQuote', quote);
        }
      }

    },
    async updatePromotion3x2({commit}, offer3x2) {
      const quote = this.getters.quote;
      quote.offer3x2 = offer3x2;

      let updatedQuote = await handleRemoteActivity (commit, () => {
        return apiClient.put(`/api/ecommerce/quotes/${quote.id}`, quote);
      });

      commit('updateQuote', updatedQuote);
    },
    async validReservations({commit}, quoteId) {
      if (quoteId) {
        let unavailableReservations = await handleRemoteActivity(commit, () => {
          return apiClient.get(`/api/ecommerce/reservations/valid/by-quote/${quoteId}`);
        });
        commit('updateUnavailableReservations', unavailableReservations);
        return unavailableReservations;
      }
    },
    activeModal({commit}, val) {
      commit('activeModal', val)
    },
    makePayment({commit}, payment) {
      return handleRemoteActivity(commit, () => {
        return apiClient.post('/api/ecommerce/payment/', payment);
      });
    },
    loadIntent({commit}) {
      const quote = this.getters.quote;

      return handleRemoteActivity(commit, () => {
        return apiClient.get(`/api/ecommerce/quotes/${quote.id}/intent`);
      })
    },
    loadQuoteById({commit}, quoteId) {
      return handleRemoteActivity(commit, () => {
        return apiClient.get(`/api/ecommerce/payment/quotes/${quoteId}`);
      })
    },
    loadQuoteByCode({commit}, visitCode) {
      return handleRemoteActivity(commit, () => {
        return apiClient.get(`/api/ecommerce/payment/quotes/code/${visitCode}`);
      })
    },
    loadPricesByRange({commit}, dates) {
      return handleRemoteActivity(commit, () => {
        return apiClient.get(`/api/catalog/visit-prices?startDate=${dates.start}&endDate=${dates.end}`);
      })
    },
    loadPricesByDate({commit}, date) {
      return handleRemoteActivity(commit, () => {
        return apiClient.get(`/api/catalog/visit-prices/${date}`);
      })
    },
    loadPricesByYear({commit}, year) {
      return handleRemoteActivity(commit, () => {
        return apiClient.get(`/api/catalog/visit-prices-per-year/${year}`);
      })
    },
    loadAvailablesSpecialPromotions({commit}) {
      return handleRemoteActivity(commit, async () => {
        return apiClient.get(`/api/core/promotions/specials-available-today`);
      });
    },
    async applyPromotion({commit}, data) {
      let applyPromotion = await handleRemoteActivity (commit, () => {
        return apiClient.post(`/api/ecommerce/promotions/apply/${data.code}?quoteId=${data.quoteId}`);
      });
      commit('updateQuote', applyPromotion.quote);
      return applyPromotion;
    },
    async cancelPromotion({commit}, data) {
      let quote = await handleRemoteActivity(commit, async () => {
        return apiClient.secure.delete(`/api/ecommerce/promotions/apply/${data.promotionId}?quoteId=${data.quoteId}`);
      });
      commit('updateQuote', quote);
      return quote;
    },
    loadVehicleProperties({commit}) {
      return handleRemoteActivity(commit, () => {
        return apiClient.get('/api/catalog/vehicle/property');
      })
    },
    createVisitPlanned({commit}, entryPlanned) {
      return handleRemoteActivity(commit, () => {
        return apiClient.post(`/api/ecommerce/entry?visitCode=${entryPlanned.visit.code}`, entryPlanned);
      })
    },
    checkIfExistVisitPlannedByCode({commit}, code) {
      return handleRemoteActivity(commit, () => {
        return apiClient.get(`/api/ecommerce/entry/exist/${code}`);
      })
    },
    beginRemoteActivity({commit}) {
      commit('updateInRemoteActivity', true);
    },
    endRemoteActivity({commit}) {
      commit('updateInRemoteActivity', false);
    },
    updateLocale({commit}, locale) {
      commit('updateLocale', locale);
    },
    loadPrices({commit}, dates) {
      return handleRemoteActivity(commit, () => {
        return apiClient.get(`/api/catalog/visit-prices?startDate=${dates.start}&endDate=${dates.end}`);
      });
    },
    daysWithOutPrices({commit}, dates) {
      return handleRemoteActivity(commit, () => {
        return apiClient.get(`/api/catalog/visit-prices/no-prices?startDate=${dates.start}&endDate=${dates.end}`);
      });
    },
    updateCapacityFull({commit}, val) {
      commit('updateCapacityFull', val);
    },
    checkIfCapacityWasExceeded({commit}, dates) {
      if (dates && dates.end === 'Invalid date') {
        dates.end = dates.start ? dates.start : moment().format('YYYY-MM-DD');
      }
      return handleRemoteActivity(commit, () => {
        return apiClient.get(`/api/ecommerce/capacities/full?startDate=${dates.start}&endDate=${dates.end}&arrivalTime=${dates.arrivalTime}`);
      });
    },
    loadCountries({commit}) {
      return handleRemoteActivity(commit, () => {
        return apiClient.get(`/api/catalog/country`);
      });
    },
    downloadSummary({commit}, quoteId) {
      return handleRemoteActivity(commit, () => {
        return apiClient.get(`/api/ecommerce/quotes/${quoteId}/summary`);
      });
    },
    downloadActivitiesSummary({commit}, quoteId) {
      return handleRemoteActivity(commit, () => {
        return apiClient.get(`/api/ecommerce/quotes/${quoteId}/activities/summary`);
      });
    },
    sendFeedback ( { commit }, feedback ) {
      return handleRemoteActivity (commit, () => {
        return apiClient.post('/api/ecommerce/feedbacks', feedback);
      })
    },
    async getBanner({commit}) {
      let banner = await handleRemoteActivity(commit, () => {
        return apiClient.get(`/api/core/banners`);
      });
      commit('updateBanner', banner)
    },
    async getReservationsAvailables({commit}, dates) {
      let reservations = await handleRemoteActivity(commit, () => {
          return apiClient.secure.get(`/api/ecommerce/reservations/availables/?startDate=${dates.startDate}&endDate=${dates.endDate}`);
      });
      commit('updateReservationsAvailable', reservations);
      return reservations;
    },
    async saveReservations({commit}, request) {
      let data = await handleRemoteActivity(commit, () => {
          return apiClient.secure.put(`/api/ecommerce/reservations/`, request);
      });
      commit('updateQuote', data);
      return data;
    },
    getNextEvents({commit}) {
      return handleRemoteActivity(commit, () => {
        return apiClient.get(`/api/core/events/next`);
      });
    },
    getEventPicture({commit}, id) {
      return handleRemoteActivity(commit, () => {
        return apiClient.secure.get(`/api/core/events/${id}/picture`);
      });
    },
    async getExchangeRate({commit}) {
      return handleRemoteActivity(commit, () => {
        return apiClient.get(`/api/core/exchange-rate`);
      });
    },
    getJobPostsForWeb({commit}) {
      return handleRemoteActivity(commit, () => {
        return apiClient.get(`/api/core/job-post/web`)
      })
    },
    getJobPostsForWebById({commit}, id) {
      return handleRemoteActivity(commit, () => {
        return apiClient.get(`/api/core/job-post/web/${id}`)
      })
    },
    getJobImage({commit}, postId) {
      return handleRemoteActivity(commit, () => {
        return apiClient.get(`/api/core/job-post/${postId}/image`)
      })
    },
    updateCurrency({commit}, val) {
      commit('updateCurrency', val);
    },
    async getVisitActivitySchedule({commit}, data) {
      return handleRemoteActivity(commit, () => {
        return apiClient.get(`/api/ecommerce/visit-activities/schedule?date=${data.date}&type=${data.type}&quantity=${data.quantity}${data.hours ? '&hours=' + data.hours : ''}&validation=ECOMMERCE`);
      });
    },
    async updateVisitActivity({commit}, data) {
      return handleRemoteActivity(commit, () => {
        return apiClient.put(`/api/ecommerce/visit-activities`, data);
      });
    },
    async getVisitActivityPrices({commit}, date) {
      return handleRemoteActivity(commit, () => {
        return apiClient.get(`/api/catalog/activity-prices/date/${date}`);
      });
    },
    async getCanopyNightAvailabilityByDate({commit}, {arrivalDate, departureDate} ) {
      return handleRemoteActivity(commit, () => {
        return apiClient.get(`/api/ecommerce/visit-activities/dates/available?arrivalDate=${arrivalDate}&departureDate=${departureDate}`);
      });
    },
    getOriginCities({commit}, {data}) {
      return handleRemoteActivity(commit, () => {
        return apiClient.get(`/api/core/origin?country=${data.country}&city=${data.city}&state=${data.state}`);
      });
    },
    sendJobApplication ( { commit }, form ) {
      return handleRemoteActivity (commit, () => {
        return apiClient.post('/api/core/job-application', form);
      })
    },
    sendJobApplicationEmails({commit}, {id, files}) {
      return handleRemoteActivity(commit, () => {
        return apiClient.secure.fileUpload(`/api/core/job-application/${id}/files`, files);
      });
    },
    getUnavailableLodging({commit}, data) {
      return handleRemoteActivity(commit, () => {
        if(typeof data.quantity !== 'number' || data.quantity <= 0) {
          return [];
        }
        return apiClient.secure.get(`/api/core/rentals/unavailable?startDate=${data.startDate}&endDate=${data.endDate}&quantity=${data.quantity}&stayType=${data.stayType}`);
      });
    }
  },
  modules: {},
});

export default store;
