<template>
  <article>
    <h1 class="content__title">{{ $t('faqPages.canopyTour.title') }}</h1>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.canopyTour.question1.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.canopyTour.question1.answer') }}</p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.canopyTour.question2.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.canopyTour.question2.answer.text1') }}</p>
      <br>
      <p class="question__answer">{{ $t('faqPages.canopyTour.question2.answer.text2') }}</p>
      <br>
      <p class="question__answer">{{ $t('faqPages.canopyTour.question2.answer.text3') }}</p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.canopyTour.question3.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.canopyTour.question3.answer.text1') }}</p>
      <p class="question__answer">{{ $t('faqPages.canopyTour.question3.answer.text2') }}</p>
      <ul class="question__answer-list">
        <li>{{ $t('faqPages.canopyTour.question3.options.option1') }}</li>
        <li>{{ $t('faqPages.canopyTour.question3.options.option2') }}</li>
      </ul>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.canopyTour.question4.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.canopyTour.question4.answer') }}</p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.canopyTour.question5.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.canopyTour.question5.answer') }}</p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.canopyTour.question6.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.canopyTour.question6.answer') }}</p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.canopyTour.question7.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.canopyTour.question7.answer') }}</p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.canopyTour.question8.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.canopyTour.question8.answer') }}</p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.canopyTour.question9.title') }}</h3>
      <p class="question__answer">
        {{ $t('faqPages.canopyTour.question9.answer.text1') }}
        <router-link class="question__answer--link" :to="'/prices'" target="_blank">{{ $t('priceCalendar') }}</router-link>.
      </p>
      <p class="question__answer">{{ $t('faqPages.canopyTour.question9.answer.text2') }}</p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.canopyTour.question10.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.canopyTour.question10.answer') }}</p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.canopyTour.question11.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.canopyTour.question11.answer') }}</p>
    </div>
  </article>
</template>

<script>
export default {
    name: 'CanopyTour'
}
</script>