<template>
  <div class="visit-resume">
    <div
      class="visit-resume__content"
      :class="{
        'shopping-cart': isOnShoppingCart,
        'add-text': quote.totalAmount != 0,
      }"
    >
      <div class="unavailable">
        <div class="unavailable__content" v-if="capacityNotFull">
          <img
            class="unavailable__content__info"
            src="@/assets/info.svg"
            alt=""
          />
          <div class="unavailable__content__message">
            <unavailable-reservation />
          </div>
        </div>
        <div class="unavailable__content" v-if="capacityFull">
          <img
            class="unavailable__content__info"
            src="@/assets/info.svg"
            alt=""
          />
          <div class="unavailable__content__message">
            {{ $t("capacityFull") }}
          </div>
        </div>
        <div class="unavailable__content" v-if="yellowDateSelected">
          <img
            class="unavailable__content__info"
            src="@/assets/info.svg"
            alt=""
          />
          <div class="unavailable__content__message">
            {{ $t("advertYellowDates") }}
          </div>
        </div>
      </div>
      <div class="visit-resume__total-label" v-if="currentStep == '2'">
        {{ $t("total") }}
      </div>
      <div class="visit-resume__total" v-if="currentStep == '2'">
        <span v-if="showTotalAmount"
          >{{ quote.totalAmount | currency }}
          <span class="nationallity">MXN</span></span
        >
        <span v-else>$ -</span>
      </div>
      <div class="visit-resume__promotion" :class="{ promotion: promotion }">
        <visit-promotion
          v-if="showVisitPromotion"
          :promotion="promotion"
          class="drawer"
        ></visit-promotion>
      </div>
      <div class="visit-resume__book">
        <a
          v-if="currentStep == '2'"
          href=""
          class="gtm-step3 btn btn--primary"
          :class="{ disabled: disabledButton2 || disabled }"
          @click.prevent="goToShoppingCart()"
          >{{ $t("pay") }}</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import VisitPromotion from "@/components/visit-drawer/VisitPromotion";
import UnavailableReservation from "@/components/UnavailableReservation";

const STAYINGS = {
  CABIN: "cabins",
  TIPI: "tipis",
  LAKE_VILLAGE: "lakeVillages",
};

export default {
  name: "VisitResume",
  components: { VisitPromotion, UnavailableReservation },
  props: ["isSelected", "currentStep", "stayOptionSelected"],
  data() {
    return {
      maxLodgeCapacity: 6,
      disabled: true,
    };
  },
  computed: {
    ...mapState([
      "quote",
      "isUnavailableCabins",
      "isUnavailableTipis",
      "isUnavailableLakeVillage",
      "inRemoteActivity",
      "yellowDateSelected",
      "fullCabins",
      "fullTipis",
      "fullLakeVillages",
      "unavailableCabins",
      "unavailableTipis",
      "unavailableLakeVillages",
    ]),
    capacityNotFull() {
      return (
        !this.capacityFull &&
        ((this.fullCabins === false &&
          this.unavailableCabins &&
          this.unavailableCabins.length > 0) ||
          (this.fullTipis === false &&
            this.unavailableTipis &&
            this.unavailableTipis.length > 0) ||
          (this.fullLakeVillages === false &&
            this.unavailableLakeVillages &&
            this.unavailableLakeVillages.length > 0))
      );
    },
    showTotalAmount() {
      return (
        this.quote &&
        this.quote.visit &&
        this.quote.totalAmount > 0 &&
        this.quote.visit.departureDate
      );
    },
    disabledButton2() {
      return (
        this.unavailableReservations ||
        this.capacityFull ||
        this.isUnavailableCabins ||
        this.isUnavailableTipis ||
        this.isUnavailableLakeVillage ||
        this.remoteActivity ||
        this.reservationsWithOutPersons ||
        !this.quote ||
        !this.quote.visit ||
        !this.quote.visit.departureDate ||
        this.quote.totalAmount == 0 ||
        !this.quote.visit.numberOfPersons ||
        !this.quote.visit.arrivalTime ||
        this.missingStays
      );
    },
    remoteActivity() {
      return this.inRemoteActivity;
    },
    subTotal() {
      return this.quote.discountAmount + this.quote.totalAmount;
    },
    reservationsWithOutPersons() {
      return (
        this.quote &&
        this.quote.products &&
        this.quote.products.cabins +
          this.quote.products.tipis +
          this.quote.products.lakeVillages >
          0 &&
        this.quote.visit.numberOfPersons == 0
      );
    },
    arrivalTime() {
      return this.quote.visit.arrivalTime;
    },
    promotion() {
      return this.quote ? this.quote.promotion : null;
    },
    unavailableReservations() {
      return (
        (this.unavailableCabins && this.unavailableCabins.length > 0) ||
        (this.unavailableTipis && this.unavailableTipis.length > 0) ||
        (this.unavailableLakeVillages &&
          this.unavailableLakeVillages.length > 0)
      );
    },
    capacityFull() {
      if (!this.quote) {
        return false;
      }

      if (
        (this.quote.cabinNumbers && this.quote.cabinNumbers.length > 0) ||
        (this.quote.tipiNumbers && this.quote.tipiNumbers.length > 0) ||
        (this.quote.lakeVillageNumbers &&
          this.quote.lakeVillageNumbers.length > 0)
      ) {
        return false;
      }

      const haveDates =
        this.quote &&
        this.quote.visit &&
        this.quote.visit.arrivalDate &&
        this.quote.visit.departureDate &&
        this.quote.visit.arrivalTime;
      return haveDates
        ? this.quote.visit.arrivalTime === "DAY"
          ? this.$store.getters.capacityFullDay
          : this.$store.getters.capacityFullNight
        : false;
    },
    isOnShoppingCart() {
      return this.$route.name === "shopping-cart";
    },
    isLodge() {
      if (!this.quote) {
        return false;
      }

      return ["CABIN", "TIPI", "LAKE_VILLAGE"].includes(
        this.quote.visit.stayType
      );
    },
    showVisitPromotion() {
      if (this.isLodge) {
        return (
          this.needMoreRentals(STAYINGS[this.quote.visit.stayType]) ||
          this.promotion
        );
      }

      return (
        (this.quote.visit && this.quote.visit.numberOfPersons > 0) ||
        this.promotion
      );
    },
    missingStays() {
      if (!this.isLodge) {
        return false;
      }

      const stayType = STAYINGS[this.quote.visit.stayType];
      const rentals = this.quote.products[stayType];

      return rentals <= 0;
    },
  },
  mounted() {
    if (this.currentStep == "1") {
      this.onViewItemListEvent();
    }
  },
  watch: {
    disabledButton2() {
      this.updateDisabled();
    },
    currentStep() {
      if (this.currentStep == "1") {
        this.onViewItemListEvent();
      } else if (this.currentStep == "2") {
        this.onViewItemEvent();
      }
    },
  },
  methods: {
    updateDisabled() {
      setTimeout(() => {
        this.disabled = this.disabledButton2;
      }, 400);
    },
    goToShoppingCart() {
      if (
        this.quote.totalAmount != 0 &&
        !this.isUnavailableCabins &&
        !this.isUnavailableTipis &&
        !this.isUnavailableLakeVillage &&
        !this.disabledButton2
      ) {
        this.analyticsAddToCart();
        this.onSuccessfulReservationEvent();

        if (this.isOnShoppingCart) {
          this.$store.dispatch("hideVisitDrawer");
        } else {
          this.onAddToCartEvent();
          this.$router.push({ name: "shopping-cart" });
        }
      }
    },
    nextStep() {
      this.$emit("changeStep", "2");
    },
    analyticsAddToCart() {
      this.onGenericEvent(`agregar al carrito`, {
        event_category: "cotizador",
        event_label: `cta - agregar al carrito`,
      });
    },
    analyticsBook() {
      this.onGenericEvent(`reservar`, {
        event_category: "cotizador",
        event_label: `cta - reservar`,
      });
    },
    onViewItemListEvent() {
      this.$emit("onViewItemListEvent");
    },
    onViewItemEvent() {
      this.$emit("onViewItemEvent");
    },
    onAddToCartEvent() {
      this.$root.$emit("onAddToCartEvent", this.quote.productItems);
    },
    onSuccessfulReservationEvent() {
      this.$root.$emit("onSuccessfulReservationEvent", this.quote);
    },
    onGenericEvent(event, command) {
      this.$root.$emit("onGenericEvent", event, command);
    },
    needMoreRentals(stayType) {
      let rentals = this.quote.products[stayType];
      if (rentals && rentals > 0) {
        let numberOfPersons = this.quote.visit.numberOfPersons;
        let personsPerRentals = rentals * this.maxLodgeCapacity;
        return numberOfPersons > personsPerRentals;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.visit-resume {
  &__content {
    border-top: 1px dashed rgba($dark-brown-color, 0.6);
    padding-top: 1rem;
    display: grid;
    grid-template-areas: "unavailable unavailable unavailable unavailable unavailable" "total amount promotion promotion goPayment";
    grid-template-rows: min-content 1fr;
    grid-template-columns: rem(64px) auto auto auto rem(192px);
    grid-row-gap: 1rem;
    grid-column-gap: 1.5rem;
    align-items: center;
    &.shopping-cart {
      .visit-resume__add-to-cart {
        display: none;
      }
    }
  }
  .unavailable {
    grid-area: unavailable;
    &__content {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-column-gap: 4px;
      background-color: #ffdede;
      border-radius: rem(8px);
      padding: rem(8px) rem(10px);
      margin-bottom: rem(12px);

      &__message {
        max-width: rem(800px);
        font-weight: 500;
        font-size: rem(16px);
        letter-spacing: 0.05em;
        line-height: rem(24px);
        color: $red-color2;
      }
      &__info {
        padding: 2.4px;
      }
    }
  }
  &__total-label {
    grid-area: total;
    text-transform: uppercase;
    font-family: $font-secondary;
    font-size: rem(24px);
    color: rgba($dark-brown-color, 0.6);
    letter-spacing: 0.05em;
  }
  &__total {
    grid-area: amount;
    font-size: 2rem;
    font-family: $font-secondary;
    text-align: right;
    display: flex;
    flex-direction: row;
    span {
      width: 100%;
      text-align: left;
    }
    .nationallity {
      font-size: 16px;
      width: 40px;
    }
  }
  &__promotion {
    grid-area: promotion;
    padding-left: 0.5rem;
    margin-bottom: rem(-5px);
    width: 100%;
  }
  &__add-to-cart {
    grid-area: goCart;
  }
  &__book {
    grid-area: goPayment;
  }
  .add-text {
    grid-template-columns: rem(64px) auto auto auto rem(192px);
  }
}
</style>
