<template>
  <div class="file">
    <p class="file__title">{{ attr.title }}</p>
    <div v-if="attr.bothSides">
      <p class="file__instructions" v-if="attr.instructions">
        {{ attr.instructions }}
      </p>
      <radio-buttons
        class="file__upload-type"
        :options="uploadOpts"
        v-model="fileUploadType"
      ></radio-buttons>
    </div>

    <div v-if="!attr.bothSides || fileUploadType === 'ONE_SIDE'">
      <label class="file__button" v-if="!files.length > 0">
        <input
          type="file"
          name="file-upload"
          accept=".jpg, .pdf"
          @change="onUploadFiles"
        />SUBIR DOCUMENTO
      </label>
      <div class="file__data" v-if="uploaded['standard']">
        <div class="file__data__content">
          <img src="@/assets/document.svg" alt="" />
          <p class="file__data__name">{{ uploaded["standard"].name }}</p>
          <p class="file__data__delete" @click.prevent="deleteFile()">
            Eliminar archivo
          </p>
        </div>
      </div>
    </div>
    <div v-if="fileUploadType === 'BOTH_SIDES'">
      <p class="file__subtitle">Frente</p>
      <label class="file__button" v-if="!uploaded['front']">
        <input
          type="file"
          name="file-upload"
          accept=".jpg, .pdf"
          @change="onUploadFiles($event, 'front')"
        />
        SUBIR DOCUMENTO
      </label>
      <div class="file__data" v-else>
        <div class="file__data__content">
          <img src="@/assets/document.svg" alt="" />
          <p class="file__data__name">{{ uploaded["front"].name }}</p>
          <p class="file__data__delete" @click.prevent="deleteFile('front')">
            Eliminar archivo
          </p>
        </div>
      </div>

      <p class="file__subtitle">Reverso</p>
      <label class="file__button" v-if="!uploaded['back']">
        <input
          type="file"
          name="file-upload"
          accept=".jpg, .pdf"
          @change="onUploadFiles($event, 'back')"
        />
        SUBIR DOCUMENTO
      </label>
      <div class="file__data" v-else>
        <div class="file__data__content">
          <img src="@/assets/document.svg" alt="" />
          <p class="file__data__name">{{ uploaded["back"].name }}</p>
          <p class="file__data__delete" @click.prevent="deleteFile('back')">
            Eliminar archivo
          </p>
        </div>
      </div>
    </div>
    <mini-loader v-show="loading" class="file__loading" />
  </div>
</template>

<script>
const MAX_ALLOWED_SIZE = 2 * 1024 * 1024;

import RadioButtons from "@/components/forms/RadioButtons.vue";

export default {
  name: "FileUploader",
  props: {
    attr: Object,
    value: FormData,
  },
  components: { RadioButtons },
  data() {
    return {
      files: [],
      loading: false,
      fileUploadType: "",
      uploadOpts: [
        { label: "Frente y reverso en \narchivos separados", id: "BOTH_SIDES" },
        { label: "Frente y reverso en un \nsólo archivo", id: "ONE_SIDE" },
      ],
      uploaded: {
        standard: null,
        front: null,
        back: null,
      },
    };
  },
  watch: {
    fileUploadType() {
      this.deleteFile("standard");
      this.deleteFile("front");
      this.deleteFile("back");
    },
  },
  methods: {
    onUploadFiles(event, type = "standard") {
      this.loading = true;
      try {
        const newFile = [...event.target.files][0];
        if (newFile && MAX_ALLOWED_SIZE > newFile.size) {
          const uniqueId = Date.now();
          const uniqueFile = new File(
            [newFile],
            `${uniqueId}-${newFile.name}`,
            { type: newFile.type }
          );
          this.uploaded[type] = uniqueFile;
          this.files.push(uniqueFile);
          this.addFile(uniqueFile);
        } else {
          this.displayMessage("error", "El archivo pesa más de 2MB");
        }
      } catch (error) {
        this.displayMessage("error", "Error al subir el archivo");
      } finally {
        this.loading = false;
      }
    },
    getFilename() {
      return !this.value.has(this.attr.name)
        ? this.attr.name
        : this.attr.name.concat("_2");
    },
    addFile(newFile) {
      const filename = this.getFilename();
      this.value.append(newFile.name, newFile, filename);
      this.$emit("onInput", this.value);
    },
    deleteFile(type = "standard") {
      let file = this.uploaded[type];
      if (!file) return;
      let fileIndex = this.files.findIndex(
        (f) => f.name === file.name && f.size === file.size
      );

      if (fileIndex > -1) {
        this.value.delete(file.name);
        this.files.splice(fileIndex, 1);
      }
      this.uploaded[type] = null;
    },
    displayMessage(type, message) {
      this.$store.dispatch("message", {
        type,
        message,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.file {
  margin-top: 24px;

  &__upload-type::v-deep {
    white-space: pre-line;

    &.button-container {
      max-width: 168px;
      button {
        padding: 24px;
      }
    }
  }

  &__instructions {
    font-family: $font-primary;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: $light-gray;
    padding: 16px 0;
  }

  &__subtitle {
    font-family: $font-primary;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: $light-gray;
    padding: 16px 0 0;
  }

  &__button {
    input[type="file"] {
      display: none;
    }

    margin-top: 16px;
    font-family: $font-secondary;
    font-size: 20px;
    line-height: 27px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #ffffff;
    width: 200px;
    height: 43px;
    padding: 8px;
    background: $light-gray;
    border-radius: 8px;
    display: inline-block;
    cursor: pointer;
    @include respond-to("small and down") {
      width: 100%;
    }
  }

  &__data {
    height: 43px;
    margin-top: 16px;
    width: auto;

    &__content {
      position: absolute;
      display: grid;
      background: #e9e7e5;
      grid-template-columns: 24px 1fr 180px;
      align-items: center;
      padding: 4px 8px;
      border-radius: 8px;
      gap: 16px;
    }

    &__name {
      font-family: $font-primary;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.05em;
      color: #272727;
    }

    &__delete {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.05em;
      text-decoration-line: underline;
      color: $blue-great-color;
      cursor: pointer;
    }
  }
}
</style>
