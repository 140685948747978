<template>
  <base-layout>
    <div class="checkout-layout">
      <checkout-header-bar class="checkout-layout__header"></checkout-header-bar>
      <main class="checkout-layout__main">
        <slot></slot>
      </main>
    </div>
  </base-layout>
</template>

<script>
  import BaseLayout from '@/layouts/BaseLayout';
  import CheckoutHeaderBar from '@/components/bars/CheckoutHeaderBar';

  const styleDesktop = `
      z-index: 10000 !important;
      position: fixed !important;
      bottom: 0px !important;
      right: 0px !important;
      display: block !important;
  `;

  const styleMobile = `
      z-index: 10000 !important;
      position: fixed !important;
      bottom: 156px !important;
      right: 0px !important;
      display: block !important;
  `;

  export default {
    name: 'CheckoutLayout',
    components: {CheckoutHeaderBar, BaseLayout},
    computed: {
      styleDesktop() {
        return styleDesktop;
      },
      styleMobile() {
        return styleMobile;
      }
    },
    mounted() {
      this.updateTawkto();
    },
    methods: {
      updateTawkto() {
        let element = document.getElementById('tawkchat-container');
        if (this.$route.name != 'order.details') {
          setTimeout(() => {
            if (element) {
              if (screen.width < 992) {
                element.setAttribute('style', this.styleMobile);
              } else {
                element.setAttribute('style', this.styleDesktop);
              }
            } else {
              this.updateTawkto();
            }
          }, 500);
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
.checkout-layout {
  position: relative;
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  &__main {
    display: grid;
  } 
}
</style>
