<template>
    <div class="add-to-cart" v-if="disableAddToCart">
        <button class="add-to-cart__button" @click="addToQuote">{{ $t('addToQuote') }}</button>
        <hr class="add-to-cart__separator">
        <p class="add-to-cart__info">{{ $t(product.info) }}</p>
      </div>
</template>

<script>
import { mapState } from "vuex";

    const addToCart = {
        name: 'AddToCart',
        props: ['product'],
        data() {
            return {
                disableAddToCart: false,
            }
        },
        computed: mapState(['quote']),
        mounted() {},
        methods: {
            async addToQuote() {
                try {
                    await this.$store.dispatch('updateProducts', {canopyPasses: this.quote.products.canopyPasses + 1});
                    if (window.screen.width > 767) {
                        await this.$store.dispatch('showVisitDrawer');
                    } else {
                        await this.$store.dispatch('showMobileDrawer');
                    }
                } catch(e) {
                    return e;
                }

            }
        }
    };
    export default addToCart;
</script>


<style scoped lang="scss">
    .add-to-cart {
        border-radius: 16px;
        background: #FFFFFF;
        display: block;
        box-shadow: 0px 0px 1rem rgba(0, 0, 0, 0.16);
        height: fit-content;
        margin-top: rem(24px);
        &__button {
            margin: 24px;
            padding: 6px;
            width: calc(100% - 48px);
            border: 4px solid #FF9900;
            background: #FFFFFF;
            color: #FF9900;
            box-sizing: border-box;
            border-radius: 8px;
            cursor: pointer;
        }
        &__separator {
            border: 1px solid $brown-opacity-color;
            margin: 0 24px;
        }
        &__info {
            font-family: 'Montserrat', sans-serif;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.05em;
            color: #1E0F00;
            opacity: 0.6;
            margin: 24px;
        }
        @include respond-to('medium and down') {
            margin-top: 0;
            &__button {
                margin: 1rem;
                margin-bottom: rem(22px);
                width: calc(100% - 32px);
            }
            &__separator {
                margin: 0 1rem;
            }
            &__info {
                margin: rem(23px) 1rem 1.5rem 1rem;
            }
        }
    }
</style>