<template>
  <article>
    <h1 class="content__title">{{ $t('faqPages.admissionPolicies.title') }}</h1>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.admissionPolicies.question1.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.admissionPolicies.question1.answer.text1') }}</p>
      <p class="question__answer">{{ $t('faqPages.admissionPolicies.question1.answer.text2') }}</p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.admissionPolicies.question2.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.admissionPolicies.question2.answer') }}</p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.admissionPolicies.question3.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.admissionPolicies.question3.answer') }}</p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.admissionPolicies.question4.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.admissionPolicies.question4.answer') }}</p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.admissionPolicies.question5.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.admissionPolicies.question5.answer.text1') }}</p>
      <p class="question__answer">{{ $t('faqPages.admissionPolicies.question5.answer.text2') }}</p>
      <p class="question__answer">{{ $t('faqPages.admissionPolicies.question5.answer.text3') }}</p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.admissionPolicies.question6.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.admissionPolicies.question6.answer') }}</p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.admissionPolicies.question7.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.admissionPolicies.question7.answer.text1') }}</p>
      <br>
      <p class="question__answer">{{ $t('faqPages.admissionPolicies.question7.answer.text2') }}</p>
      <br>
      <p class="question__answer">{{ $t('faqPages.admissionPolicies.question7.answer.text3') }}</p>
      <ul class="question__answer-list question__answer-list">
        <li>
          {{ $t('faqPages.admissionPolicies.question7.options.option1') }}
        </li>
        <li>
          {{ $t('faqPages.admissionPolicies.question7.options.option2') }}
        </li>
      </ul>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.admissionPolicies.question8.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.admissionPolicies.question8.answer') }}</p>
      <ol type="1" class="question__answer-list question__answer-list--numbered">
        <li>
          {{ $t('faqPages.admissionPolicies.question8.options.option1') }}
        </li>
        <li>
          {{ $t('faqPages.admissionPolicies.question8.options.option2') }}
        </li>
        <li>
          {{ $t('faqPages.admissionPolicies.question8.options.option3') }}
        </li>
      </ol>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.admissionPolicies.question9.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.admissionPolicies.question9.answer.text1') }}</p>
      <br>
      <p class="question__answer">{{ $t('faqPages.admissionPolicies.question9.answer.text2') }}</p>
      <br>
      <p class="question__answer">
        {{ $t('faqPages.admissionPolicies.question9.answer.text3') }}
        <a class="question__answer--link" href="mailto:info@lascanadas.com" target="_blank">info@lascanadas.com</a>
        {{ $t('faqPages.admissionPolicies.question9.answer.text4') }}
      </p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.admissionPolicies.question10.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.admissionPolicies.question10.answer') }}</p>
    </div>
    <div class="question">
      <h3 class="question__title">{{ $t('faqPages.admissionPolicies.question11.title') }}</h3>
      <p class="question__answer">{{ $t('faqPages.admissionPolicies.question11.answer') }}</p>
    </div>
  </article>
</template>

<script>
export default {
}
</script>