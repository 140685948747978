<template>
  <modal ref="modal" @close="accept()" v-show="isOpen" disableCloseModal="true">
    <div class="modal__content changeCurrency">
      <div class="changeCurrency__title">
        <span>{{$t('currencyExchange')}}</span>
        <span @click="accept()">&times;</span>
      </div>
      <span class="changeCurrency__description">{{$t('currencyExchangeMessage')}}</span>
      <span class="changeCurrency__rate">{{ $t('currencyExchangeRate', { rate: rate })}}</span>
      <button class="changeCurrency__button" @click="accept()">{{ $t('accept') }}</button>
    </div>
  </modal>
</template>

<script>
import Modal from '@/components/modal/Modal'

export default {
  name: 'ChangeDateCancelPromo',
  components: { Modal },
  props: ['exchangeRate'],
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    rate() {
      return this.$options.filters.currency(this.exchangeRate);
    }
  },
  methods: {
    openModal() {
      this.$refs.modal && this.$refs.modal.open();
      this.isOpen = true;
      this.$store.dispatch('activeModal', true);
    },
    closeModal() {
      this.$refs.modal && this.$refs.modal.close();
      this.isOpen = false;
      this.$store.dispatch('activeModal', false);
    },
    accept() {
      this.$emit('currencyChanged');
      this.closeModal();
    }
  }
}
</script>

<style lang="scss" scoped>
.changeCurrency {
  background-color: $visit-drawer__bg;
  border-radius: 1rem;
  display: grid;
  cursor: default;
  span, button { letter-spacing: 0.05em; }
  &__title, &__description{
    display: grid;
    align-items: center;
    grid-gap: .5rem;
  }
  &__title {
    grid-template-columns: 1fr 1.5rem;
    text-transform: uppercase;
    font-family: $font-secondary;
    font-size: 1.5rem;
    line-height: 33px;
    a {
      display: grid;
    }
  }
  &__description {
    font-weight: 400;
    line-height: 1.5rem;
    font-family: $font-primary;
    font-size: 1rem;
    letter-spacing: 0.06em !important;
  }
  &__rate {
    margin-top: rem(24px);
    font-weight: bold;
    text-transform: uppercase;
  }
  &__button {
    cursor: pointer;
  }
  @include respond-to("medium and up") {
    grid-row-gap: 1.5rem;
    padding: 1.5rem;
    max-width: rem(533px);
  }
  @include respond-to("small and down") {
    grid-row-gap: 1rem;
    padding: 1rem;
    max-width: rem(343px);
  }
}
</style>
