<template>
    <div class="choose-tipi">
        <div class="choose-tipi__content desktop">
            <img :src="require(`@/assets/reservations/teepees-map--${locale}.png`)" alt="">
            <svg class="svg" width="151" height="103" viewBox="0 0 151 103" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g class="tipi" v-for="(tipi, index) in tipiList" :key="index" @click.prevent="tipi.available && !pendingTipisSelected(tipi.staying.id) && updateTipis(tipi.staying.id, 'Tipi')" :class="{
                    disabled: !tipi.available,
                    selected: pendingTipisSelected(tipi.staying.id)
                }">
                    <circle class="circle" :class="tipi.staying.name" :cx="tipis[tipi.staying.name].circleCx" :cy="tipis[tipi.staying.name].circleCy" r="14.5" fill="white" stroke="#1E0F00"/>
                    <path class="path" :d="tipis[tipi.staying.name].pathD" fill="#1E0F00"/>
                </g>
            </svg>
        </div>
        <div class="choose-tipi__content mobile">
            <img :src="require(`@/assets/reservations/teepees-map-mobile--${locale}.png`)" alt="">
            <svg class="svg" width="98" height="142" viewBox="0 0 98 142" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g class="tipi" v-for="(tipi, index) in tipiList" :key="index" @click.prevent="tipi.available && !pendingTipisSelected(tipi.staying.id) && updateTipis(tipi.staying.id, 'Tipi')" :class="{
                    disabled: !tipi.available,
                    selected: pendingTipisSelected(tipi.staying.id)
                }">
                    <circle class="circle" :class="tipi.staying.name" :cx="tipis[tipi.staying.name].mobileCircleCx" :cy="tipis[tipi.staying.name].mobileCircleCy" r="13.6103" fill="white" stroke="#1E0F00"/>
                    <path class="path" :d="tipis[tipi.staying.name].mobilePathD" fill="#1E0F00"/>
                </g>
            </svg>
        </div>
    </div>
</template>

<script>
    const tipis = {
        T1: {
            circleCx: 15,
            circleCy: 26,
            mobileCircleCx: 72.8462,
            mobileCircleCy: 14.2661,
            pathD: "M18.493 32H13.069L12.813 31.744V30.016H14.637V22.576L13.213 22.864L12.653 20.96L16.413 19.968L16.669 20.224L16.925 25.088L16.941 30.016H18.237L18.493 30.272V32Z",
            mobilePathD: "M75.6282 19.9414H70.537L70.2967 19.7011V18.0791H72.0088V11.0957L70.6722 11.366L70.1465 9.57882L73.6758 8.64769L73.9161 8.88798L74.1564 13.4535L74.1714 18.0791H75.3879L75.6282 18.3194V19.9414Z"
        },
        T2: {
            circleCx: 46,
            circleCy: 51,
            mobileCircleCx: 49.3775,
            mobileCircleCy: 43.3638,
            pathD: "M46.5871 48.136L46.6031 47.496C46.6031 47.2933 46.5818 47.1707 46.5391 47.128C46.4965 47.0853 46.4431 47.048 46.3791 47.016C46.2725 46.9627 46.1071 46.936 45.8831 46.936C45.6698 46.936 45.4991 46.9413 45.3711 46.952C45.2431 46.952 45.1365 46.9573 45.0511 46.968C44.9765 46.968 44.8965 46.9787 44.8111 47C44.7365 47.0107 44.6831 47.0213 44.6511 47.032C44.4911 47.0853 44.3685 47.1227 44.2831 47.144H43.4991L43.0511 45.528C43.6271 45.1547 44.5071 44.968 45.6911 44.968C46.4271 44.968 47.0085 45.0213 47.4351 45.128C47.8618 45.224 48.1818 45.416 48.3951 45.704C48.6085 45.992 48.7418 46.312 48.7951 46.664C48.8591 47.0053 48.8911 47.3467 48.8911 47.688C48.8911 48.0187 48.8911 48.264 48.8911 48.424C48.9018 48.584 48.9018 48.6907 48.8911 48.744C48.8911 48.7867 48.8805 48.8987 48.8591 49.08C48.8378 49.2507 48.8058 49.3947 48.7631 49.512C48.7311 49.6187 48.6725 49.7893 48.5871 50.024C48.5125 50.248 48.4165 50.4613 48.2991 50.664C48.1925 50.8667 48.0538 51.1227 47.8831 51.432C47.7125 51.7413 47.5151 52.0613 47.2911 52.392C46.6191 53.4267 46.0378 54.264 45.5471 54.904H48.9071L49.1631 55.16V57H42.8271V55.016C44.4485 52.7013 45.4778 51.16 45.9151 50.392C46.3631 49.6133 46.5871 48.8613 46.5871 48.136Z",
            mobilePathD: "M49.4012 40.719L49.4162 40.1182C49.4162 39.928 49.3962 39.8129 49.3561 39.7728C49.3161 39.7328 49.266 39.6977 49.206 39.6677C49.1058 39.6176 48.9506 39.5926 48.7404 39.5926C48.5402 39.5926 48.38 39.5976 48.2598 39.6076C48.1397 39.6076 48.0395 39.6126 47.9594 39.6226C47.8894 39.6226 47.8143 39.6326 47.7342 39.6527C47.6641 39.6627 47.614 39.6727 47.584 39.6827C47.4338 39.7328 47.3187 39.7678 47.2386 39.7878H46.5027L46.0822 38.271C46.6228 37.9206 47.4488 37.7453 48.5602 37.7453C49.251 37.7453 49.7967 37.7954 50.1972 37.8955C50.5977 37.9856 50.898 38.1659 51.0983 38.4362C51.2985 38.7065 51.4237 39.0069 51.4737 39.3373C51.5338 39.6577 51.5638 39.9781 51.5638 40.2984C51.5638 40.6088 51.5638 40.8391 51.5638 40.9893C51.5738 41.1395 51.5738 41.2396 51.5638 41.2896C51.5638 41.3297 51.5538 41.4348 51.5338 41.605C51.5138 41.7652 51.4837 41.9004 51.4437 42.0105C51.4136 42.1106 51.3586 42.2708 51.2785 42.4911C51.2084 42.7014 51.1183 42.9016 51.0081 43.0918C50.908 43.2821 50.7779 43.5224 50.6177 43.8127C50.4575 44.1031 50.2723 44.4034 50.062 44.7138C49.4312 45.685 48.8856 46.4709 48.425 47.0717H51.5788L51.8191 47.312V49.0391H45.8719V47.1768C47.3938 45.0042 48.3599 43.5574 48.7704 42.8365C49.1909 42.1056 49.4012 41.3998 49.4012 40.719Z"
        },
        T3: {
            circleCx: 92,
            circleCy: 51,
            mobileCircleCx: 49.3775,
            mobileCircleCy: 86.5415,
            pathD: "M91.917 46.936C91.0957 46.936 90.4503 47.0053 89.981 47.144H89.197L88.749 45.528C89.325 45.1547 90.205 44.968 91.389 44.968C92.861 44.968 93.8423 45.2453 94.333 45.8C94.589 46.088 94.765 46.4293 94.861 46.824C94.957 47.2187 95.005 47.7253 95.005 48.344C95.005 49.3147 94.781 50.0933 94.333 50.68C94.9943 51.224 95.325 52.2 95.325 53.608C95.325 54.1733 95.2663 54.664 95.149 55.08C95.0423 55.4853 94.845 55.8587 94.557 56.2C93.981 56.904 92.8717 57.256 91.229 57.256C90.7383 57.256 89.997 57.2027 89.005 57.096V55.112C90.029 55.176 90.8397 55.208 91.437 55.208C92.0343 55.208 92.4183 55.112 92.589 54.92C92.7703 54.7173 92.861 54.2747 92.861 53.592V52.824C92.861 52.5573 92.845 52.4027 92.813 52.36C92.781 52.3173 92.7383 52.264 92.685 52.2C92.6423 52.1253 92.589 52.0827 92.525 52.072C92.461 52.0613 92.3757 52.04 92.269 52.008C92.1197 51.976 91.8423 51.96 91.437 51.96H90.285V49.912C90.9997 49.912 91.485 49.88 91.741 49.816C92.0077 49.752 92.1783 49.6613 92.253 49.544C92.445 49.288 92.541 48.8827 92.541 48.328C92.541 47.4 92.333 46.936 91.917 46.936Z",
            mobilePathD: "M48.7722 82.7703C48.0013 82.7703 47.3955 82.8354 46.955 82.9656H46.2191L45.7986 81.4487C46.3392 81.0983 47.1652 80.9231 48.2766 80.9231C49.6583 80.9231 50.5794 81.1834 51.0399 81.704C51.2802 81.9744 51.4454 82.2947 51.5355 82.6652C51.6257 83.0356 51.6707 83.5112 51.6707 84.0919C51.6707 85.003 51.4605 85.7339 51.0399 86.2846C51.6607 86.7952 51.9711 87.7113 51.9711 89.0329C51.9711 89.5636 51.916 90.0241 51.8059 90.4146C51.7058 90.7951 51.5205 91.1455 51.2502 91.4659C50.7095 92.1267 49.6683 92.4571 48.1264 92.4571C47.6658 92.4571 46.97 92.407 46.0389 92.3069V90.4446C47 90.5047 47.761 90.5348 48.3216 90.5348C48.8823 90.5348 49.2428 90.4446 49.403 90.2644C49.5732 90.0742 49.6583 89.6587 49.6583 89.0179V88.297C49.6583 88.0467 49.6432 87.9016 49.6132 87.8615C49.5832 87.8215 49.5431 87.7714 49.4931 87.7113C49.453 87.6412 49.403 87.6012 49.3429 87.5912C49.2828 87.5812 49.2027 87.5611 49.1026 87.5311C48.9624 87.5011 48.7021 87.4861 48.3216 87.4861H47.2403V85.5637C47.9111 85.5637 48.3667 85.5337 48.607 85.4736C48.8573 85.4135 49.0175 85.3284 49.0876 85.2183C49.2678 84.978 49.3579 84.5975 49.3579 84.0769C49.3579 83.2059 49.1627 82.7703 48.7722 82.7703Z"
        },
        T4: {
            circleCx: 107,
            circleCy: 15,
            mobileCircleCx: 83.1704,
            mobileCircleCy: 100.621,
            pathD: "M110.462 16.072H109.534V21H107.486L107.23 20.744V16.072H103.662L103.406 15.816L103.422 8.968H105.166L105.422 9.224L105.678 14.088H107.23L107.246 8.968H109.022L109.278 9.224L109.534 14.088H110.206L110.462 14.344V16.072Z",
            mobilePathD: "M85.8926 101.67H85.0215V106.296H83.0992L82.8589 106.056V101.67H79.5098L79.2695 101.43L79.2846 95.0022H80.9215L81.1618 95.2425L81.4021 99.808H82.8589L82.8739 95.0022H84.5409L84.7812 95.2425L85.0215 99.808H85.6523L85.8926 100.048V101.67Z"
        },
        T5: {
            circleCx: 136,
            circleCy: 42,
            mobileCircleCx: 57.8306,
            mobileCircleCy: 127.841,
            pathD: "M133.165 35.968H138.365L138.621 36.224V37.952H135.117L135.389 40.736C135.496 40.7467 135.672 40.7627 135.917 40.784C136.173 40.7947 136.36 40.8053 136.477 40.816C136.594 40.8267 136.749 40.848 136.941 40.88C137.133 40.9013 137.282 40.928 137.389 40.96C137.666 41.024 137.874 41.1147 138.013 41.232C138.674 41.776 139.005 42.8267 139.005 44.384C139.005 46.9653 137.554 48.256 134.653 48.256C134.44 48.256 134.21 48.2347 133.965 48.192C133.73 48.16 133.474 48.128 133.197 48.096V46.112C134.221 46.176 134.744 46.208 134.765 46.208C135.533 46.208 136.018 46.0853 136.221 45.84C136.434 45.584 136.541 45.1787 136.541 44.624C136.541 44.0587 136.53 43.6907 136.509 43.52C136.488 43.3387 136.418 43.1787 136.301 43.04C136.184 42.8907 136.018 42.8 135.805 42.768C135.592 42.736 135.277 42.72 134.861 42.72H133.421L133.165 42.464V35.968Z",
            mobilePathD: "M55.1421 122.223H60.023L60.2633 122.463V124.085H56.9743L57.2297 126.698C57.3298 126.708 57.495 126.723 57.7253 126.743C57.9655 126.753 58.1408 126.763 58.2509 126.773C58.361 126.783 58.5062 126.803 58.6864 126.833C58.8666 126.854 59.0068 126.879 59.1069 126.909C59.3673 126.969 59.5625 127.054 59.6926 127.164C60.3134 127.675 60.6238 128.661 60.6238 130.122C60.6238 132.545 59.2621 133.757 56.5388 133.757C56.3386 133.757 56.1233 133.737 55.893 133.697C55.6728 133.667 55.4325 133.637 55.1722 133.607V131.744C56.1333 131.805 56.6239 131.835 56.6439 131.835C57.3648 131.835 57.8204 131.719 58.0106 131.489C58.2108 131.249 58.311 130.868 58.311 130.348C58.311 129.817 58.301 129.472 58.2809 129.311C58.2609 129.141 58.1958 128.991 58.0857 128.861C57.9756 128.721 57.8204 128.636 57.6201 128.606C57.4199 128.576 57.1245 128.561 56.7341 128.561H55.3824L55.1421 128.32V122.223Z"
        },
        T6: {
            circleCx: 133,
            circleCy: 88,
            mobileCircleCx: 14.6548,
            mobileCircleCy: 125.026,
            pathD: "M129.887 90C129.887 89.1573 129.989 88.2027 130.191 87.136C130.394 86.0587 130.597 85.1787 130.799 84.496C131.002 83.8027 131.29 82.8747 131.663 81.712L133.599 82.192L133.855 82.448L133.535 83.2C133.098 84.064 132.821 84.976 132.703 85.936L133.087 85.776C133.93 85.776 134.57 85.888 135.007 86.112C135.455 86.336 135.781 86.752 135.983 87.36C136.186 87.9573 136.287 88.8373 136.287 90C136.287 91.6 136.058 92.7093 135.599 93.328C135.141 93.9467 134.303 94.256 133.087 94.256C131.871 94.256 131.034 93.9467 130.575 93.328C130.117 92.7093 129.887 91.6 129.887 90ZM132.063 90C132.063 91.792 132.234 92.688 132.575 92.688C133.045 92.688 133.391 92.6133 133.615 92.464C133.733 92.3787 133.813 92.224 133.855 92C133.941 91.552 133.983 90.8853 133.983 90C133.983 88.208 133.813 87.312 133.471 87.312C132.831 87.312 132.437 87.4613 132.287 87.76C132.138 88.0587 132.063 88.8053 132.063 90Z",
            mobilePathD: "M11.7058 126.947C11.7058 126.156 11.8009 125.26 11.9912 124.258C12.1814 123.247 12.3716 122.421 12.5618 121.78C12.7521 121.13 13.0224 120.258 13.3728 119.167L15.19 119.618L15.4303 119.858L15.13 120.564C14.7195 121.375 14.4592 122.231 14.349 123.132L14.7095 122.982C15.5004 122.982 16.1011 123.087 16.5116 123.297C16.9322 123.507 17.2375 123.898 17.4278 124.469C17.618 125.029 17.7131 125.855 17.7131 126.947C17.7131 128.448 17.4978 129.49 17.0673 130.07C16.6368 130.651 15.8508 130.941 14.7095 130.941C13.5681 130.941 12.7821 130.651 12.3516 130.07C11.9211 129.49 11.7058 128.448 11.7058 126.947ZM13.7483 126.947C13.7483 128.629 13.9085 129.47 14.2289 129.47C14.6694 129.47 14.9948 129.4 15.2051 129.259C15.3152 129.179 15.3903 129.034 15.4303 128.824C15.5104 128.403 15.5505 127.778 15.5505 126.947C15.5505 125.265 15.3903 124.424 15.0699 124.424C14.4692 124.424 14.0987 124.564 13.9585 124.844C13.8184 125.124 13.7483 125.825 13.7483 126.947Z"
        }
    }

    export default {
    name: 'ChooseTipiModal',
        props: ['tipiList'],
        data() {
            return {
                currentPendingTipis: [],
                auxCurrentPendingTipis: []
            }
        },
        watch: {
            '$store.getters.chooseTipiVisible'() {
                this.openModal();
            }
        },
        computed: {
            locale() {
                return this.$i18n.locale;
            },
            tipis() {
                return tipis;
            },
            quote() {
                return this.$store.getters.quote;
            },
            hasSpecialTipi() {
              if(this.currentPendingTipis.length <= 0)
                return false;
              const specialTipis = ['6'];
              return this.currentPendingTipis.filter(pendingTipi => specialTipis.includes(pendingTipi.slice(-1))).length > 0;
            }
        },
        mounted() {
            this.getCurrentPendingTipis();
            this.$emit('hasSpecialTipi', this.hasSpecialTipi);
        },
        methods: {
            getCurrentPendingTipis() {
                this.currentPendingTipis = this.quote ? this.quote.tipiNumbers.map(t => t.id) : [];
                this.currentPendingCabins = this.quote ? this.quote.cabinNumbers.map(c => c.id) : [];
                this.auxCurrentPendingTipis = [].concat(this.currentPendingTipis);
            },
            pendingTipisSelected(id) {
                return this.currentPendingTipis.find(stayingId => stayingId === id);
            },
            updateTipis(id) {
                this.currentPendingTipis.shift();
                this.currentPendingTipis = this.currentPendingTipis.concat([id]);
                this.$emit('valid', !this.checkIfhaveNewTipis());
                this.$emit('hasSpecialTipi', this.hasSpecialTipi);
            },
            getArray(list) {
                let array = [];
                list.forEach(element => {
                    if(array.indexOf(element) == -1) {
                        array.push(element);
                    }
                });
                return array;
            },
            async save() {
                let request = {
                    quoteId: this.quote.id,
                    stayIds: this.currentPendingTipis.concat(this.currentPendingCabins)
                }
                await this.$store.dispatch('saveReservations', request);
                this.validReservation();
            },
            async validReservation() {
                let unavailable = await this.$store.dispatch('validReservations', this.quote.id);
                if (unavailable.cabins.length == 0 && unavailable.tipis.length == 0) {
                    this.$store.dispatch('updateChooseTipiVisible', false);
                } else {
                    this.getCurrentPendingTipis();
                    this.$emit('valid', false);
                }
            },
            checkIfhaveNewTipis() {
                let list = [].concat(this.currentPendingTipis);
                let auxList = [].concat(this.auxCurrentPendingTipis);
                list.sort();
                auxList.sort();
                return list.length == auxList.length && list.every(function(v,i) { return v === auxList[i] } );
            }
        }
    }
</script>


<style lang="scss" scoped>
.choose-tipi {
    background-color: white;
    display: grid;
    grid-row-gap: 1.2rem;
    &__content {
        position: relative;
        .svg {
            position: absolute;
            .tipi {
                cursor: pointer;
                .cirlce {
                    stroke: $dark-brown-color;
                    fill: white;
                    cursor: pointer;
                }
                .path { fill: $dark-brown-color; }
                &.disabled {
                    cursor: not-allowed;
                    .circle { stroke: #D2CFCC; }
                    .path { fill: #D2CFCC; }
                }
                &.selected {
                    .circle { fill: $red-color2; }
                    .path { fill: white; }
                }
            }

        }
        @include respond-to("medium and up") {
            &.mobile { display: none }
            img {
                width: 650px;
            }
            .svg {
                width: 24%;
                height: 31%;
                top: 50%;
                left: 19%;
            }
        }
        @include respond-to("small and down") {
            &.desktop { display: none }
            .svg {
                width: 31%;
                height: 23.2%;
                top: 19.5%;
                left: 18.5%;
            }
        }
    }
}
</style>
