<template>
  <header class="side-bar" :class="{ open: sideHeaderBarVisible }">
    <div class="side-bar__content">
      <img class="close-side-bar" src="@/assets/bars/close.svg" @click="closeSideBar" alt="">
      <div class="side-bar__links" :class="isMobile ? '--open-mobile' : '--open-desktop'">
        <div class="link" v-for="(link, index) in links" :key="index" :id="link.id" 
          :class="link.pageTitle == linkSelected && dropdown && isMobile ? 'open' : ''">
          <a class="nav-option" href="" v-if="!link.sublinks" @click.prevent="$route.name != link.routeName && $router.push({name: link.routeName})">
            {{ $t(link.pageTitle) }}
          </a>
          <div v-else>
            <div class="nav-option">
              <a class="side-bar__links__dropdown" @click.prevent="!isMobile ? toggleDropdown() : toggleDropdown(link.pageTitle)">
                <span>{{ $t(link.pageTitle) }}</span>
                <img class="arrow" src="@/assets/dropdown-arrow.svg">
              </a>
            </div> 
            <div v-if="!isMobile || (isMobile && link.pageTitle == linkSelected )" class="option-list">
              <a class="option" href="" v-for="(sublink, index) in link.sublinks" :key="index" :id="sublink.id" 
                @click.prevent="$route.name != sublink.routeName && $router.push({name: sublink.routeName})">
                <div></div>
                {{$t(sublink.pageTitle)}}
              </a>
            </div>
          </div>
        </div>
      </div>
      <language-drop-down id="nav-bar_lang_drpdwn" class="drop-down" :links="links" :select="{id:2, label:'Español'}"></language-drop-down>
    </div>
  </header>
</template>

<script>
  import {mapState} from 'vuex';
  import LanguageDropDown from '@/components/dropdowns/LanguageDropDown';

  export default {
    name: 'HeaderBar',
    components: {LanguageDropDown},
    computed: mapState([
      'sideHeaderBarVisible'
    ]),
    destroyed () {
      this.$store.dispatch('hideSideHeaderBar');
    },
    data() {
        return {
          links: [
            { 
              id: "nav-bar_activities",
              pageTitle: "toDo", 
              sublinks:[
                {
                  id: "activities-earth",
                  pageTitle: "fun.earth",
                  routeName: "product.earth"
                },
                {
                  id: "activities-water",
                  pageTitle: "fun.water",
                  routeName: "product.water"
                },
                {
                  id: "activities-air",
                  pageTitle: "fun.air",
                  routeName: "product.air"
                }
              ]

            },
            { 
              id:"nav-bar_prices",
              pageTitle: "prices", 
              routeName: "prices",   
            },
            { 
              id: "nav-bar_lodging",
              pageTitle: "lodging",
              sublinks:[
                {
                  id: "lodging_ecotouristico",
                  pageTitle: "ecotouristCabins",
                  routeName: "lodging.cabins.ecotouristic"
                },
                {
                  id: "lodging_campañas-de-lujo",
                  pageTitle: "lakeVillages",
                  routeName: "lodging.cabins.lake"
                },
                {
                  id: "lodging_villa-india",
                  pageTitle: "indianVillage",
                  routeName: "lodging.indian-village"
                },
                {
                  id: "lodging_acampar",
                  pageTitle: "lodgingCamping",
                  routeName: "lodging.camping"
                },
              ]
            },
            {
              id: "nav-bar_services",
              pageTitle: "other-services",
              sublinks:[
                {
                  id: "services_restaurante",
                  pageTitle: "trojaRestaurant",
                  routeName: "services.restaurant"
                },
                {
                  id: "services_abarrotes",
                  pageTitle: "groceryStores", 
                  routeName: "services.grocery"
                },
                {
                  id: "services_cuatrimotos",
                  pageTitle: "atvRentals",
                  routeName: "services.atv-rentals"
                }
              ]
            },
            { 
              id:"nav-bar_events",
              pageTitle: "events", 
              routeName: "events",   
            },
            { 
              id:"nav-bar_contact", 
              pageTitle: "contact",   
              sublinks:[
                {
                  id: "contact_write-to-us",
                  pageTitle: "write-to-us",
                  routeName: "contact"
                },
                {
                  id: "contact_directions",
                  pageTitle: "directions",
                  routeName: "directions"
                },
                {
                  id: "contact_title",
                  pageTitle: "faqPages.title",
                  routeName: "faq"
                },
                {
                  id: "hospedaje_ecoturistico",
                  pageTitle: "info.careers",
                  routeName: "careers"
                },
              ]        
            },
          ],
          linkSelected: '',
          dropdown: false,
          isMobile: false,
        }
    },
    mounted() {
      this.checkUserDevice(navigator.userAgent.toLowerCase());
      
    },
    methods: {
      toggleDropdown(link) { 
        if(link != this.linkSelected) {
          this.dropdown = true
        } else {
          this.dropdown = !this.dropdown;
        }
        this.linkSelected = link;
      },
      closeSideBar() {
        this.$store.dispatch('hideSideHeaderBar');
      },
      checkUserDevice(device) {
        if(device.includes('mobile') || device.includes('tablet')) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      }
    },

  };
</script>

<style lang="scss" scoped>
  .side-bar {
    background-color: $forest-green-color;
    display: grid;
    letter-spacing: 0.05em;
    &__links {
      display: grid;
      grid-area: links;
      height: 105%;
      &__dropdown {
        display: flex;
        flex-direction: row;
        gap: 8px;
        cursor: pointer;
      }
      a, span {
        text-decoration: none;
        color: white;
        line-height: 24px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
      }
      .link {
        display: grid;
        align-items: center;
        padding: 10px 16px;
        height: 100%;
      }
      .open {
        background: #037754;
        .option-list {
          display: block;
        }
      }
      .option-list {
        position: absolute;
        top: 136px;
        margin-left: -16px;
        display: flex;
        flex-direction: column;
        background: #05B37E;
        display: none;
        .option {
          display: grid;
          padding: 10px;
          height: 48px;
        }
        .option:hover{
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #049569;
        }
      }
    }
    .--open-mobile {
      .link {
        &:not(.open) {
          .arrow {
            -moz-transform: rotate(-180deg);
            -o-transform: rotate(-180deg);
            -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
          }
        }
      }
    }
    .--open-desktop {
      .arrow {
        rotate: (-180deg);
      }
      .link:hover  {
        background: #037754;
        .option-list {
          display: block;
        }
        .arrow {
          transform: rotate(-180deg);
        }
      }
    }
    @include respond-to("medium and down") {
      z-index: 20000;
      position: fixed;
      height: 100vh;
      top: 0;
      width: 0;
      &__links {
        display: flex;
        flex-direction: column;
        position: relative;
      }
      &__content {
        display: none;
      }
      &.open {
        width: rem(272px) !important;
        .side-bar {
          &__content {
            display: grid;
            // grid-template-rows: min-content min-content 36%;
            grid-template-rows: repeat(3, min-content);
            grid-template-areas: "close" "links" "lenguage";
            gap: 16px;
            .close-side-bar {
              grid-area: close;
              padding: 1.5rem;
              justify-self: right;
            }
            .side-bar__links {
              grid-area: links;
              grid-template-rows: repeat(6, min-content);
              grid-row-gap: 16px;
              .nav-option {
                width: 100%;
                padding: 10.5px 24px;
              }
              .link {
                padding: 0;
              }
              span {
                width: calc(100% - 16px);
              }
              .option-list {
                top: auto;
                position: relative;
                .option {
                  padding-left: 40px;
                }
              }
            }
            .drop-down {
              grid-area: lenguage;
              align-self: center;
              margin-top:15px;
            }
          }
        }
      }
    }
    @include respond-to("large and up") {
      height: rem(56px);
      &__content {
        padding: 0rem 0.5rem 1rem 1rem;
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        grid-column-gap: 1.5rem;
        grid-template-areas: "empty links lenguage";
        align-self: center;
        justify-self: center;
        width: 100%;
        max-width: rem(1440px);
        .close-side-bar { display: none }
        .drop-down {
          grid-area: lenguage;
          justify-self: right;
          margin-right: -8px;
          margin-top: 5px;
        }
      }
      &__links {
        grid-column-gap: 10px;
        grid-template-columns: repeat(6, auto);
        @media (max-height: 1390px) {
          gap: 0;
        }
      }
    }
  }
</style>