<template>
  <div class="visit-drawer">
    <transition enter-active-class="animated faster fadeInDown"
                leave-active-class="animated faster fadeOutUp">
      <column-block  v-if="visitDrawerVisible && quote">
        <div class="visit-drawer__content" :class="{disabled: inRemoteActivity}" >

          <div class="visit-drawer__content__stays-first-step" v-show="currentStep == '1'">
            <div class="visit-drawer__stays" >
              <stay-options
                @staySelected="staySelected"
                @setCabins="setCabins"
                @setTipis="setTipis"
                @setLakeVillages="setLakeVillages"
                :stayType="stayType"
                :analyticsCabin="analyticsCabin"
              ></stay-options>
            </div>
          </div>

          <div class="visit-drawer__content__stays-second-step"
              v-show="currentStep == '2'"
              :class="{disabled: inRemoteActivity, 'without-options': stayOptionSelected == 'DAY_PASS' || stayOptionSelected == 'CAMPING_PASS' }" >

            <div class="visit-drawer__content__header">
              <div class="title">
                <img :src="getStayImage(stayOptionSelected, 'iconSrc')" alt="">
                <p>{{ $t(getStayImage(stayOptionSelected, "name")) }}</p>
              </div>
              <a href="" @click.prevent="changeStep('1')">{{$t('activities.back')}}</a>
            </div>

            <div class="visit-drawer__content__form">
                <div class="visit-drawer__content__form__image">
                  <div class="stay-image" :class="{'lake-village': stayOptionSelected == 'LAKE_VILLAGE'}" :style="'background-image: url(' + (getStayImage(stayOptionSelected, 'imgSrc')) +')'"></div>
                  <div class="options" >
                    <counter :ref="counterRef"
                      :on-change="onChangeByStay"
                      :max-allowed="maxAllowedByStay"
                      :min="stayLimitByPeople">
                    </counter>
                    <span @click.prevent="stayOptionSelected == 'CABIN' ?
                      openChooseCabinModal() : stayOptionSelected == 'TIPI' ?
                      openChooseTipiModal() : openChooseLakeVillageModal()"
                      v-show="step == 5">
                      {{
                          stayOptionSelected == 'CABIN' ? $t('modal.chooseReservation.pickYourCabin') :
                          stayOptionSelected == 'TIPI' ? $t('modal.chooseTepee.pickYourTepee') : $t('modal.chooseVillage.pickYourVillage')
                      }}
                    </span>
                  </div>
                  <div class="unavailable" v-if="(fullCabins || fullTipis || fullLakeVillages) ">
                    <div class="unavailable__reservation" v-if="fullCabins && quote && quote.id && quote.products.cabins > 0">
                      <img src="@/assets/date-picker/unavailable.svg" alt="">
                      <span>{{$t('noCabins')}}</span>
                    </div>
                    <div class="unavailable__reservation" v-if="fullTipis && quote && quote.id && quote.products.tipis > 0">
                      <img src="@/assets/date-picker/unavailable.svg" alt="">
                      <span>{{$t('noTipis')}}</span>
                    </div>
                    <div class="unavailable__reservation" v-if="fullLakeVillages && quote && quote.id && quote.products.lakeVillages > 0">
                      <img src="@/assets/date-picker/unavailable.svg" alt="">
                      <span>{{$t('noLakeVillages')}}</span>
                    </div>
                  </div>

                  <div class="limit-space">
                    <div
                      v-show="
                        quote &&
                        quote.tipiNumbers &&
                        quote.tipiNumbers.length > 0 &&
                        quote.tipiNumbers.some(tipi => tipi.number == 6)
                      "
                      class="limit-space__reservation"
                    >
                      <img src="@/assets/date-picker/unavailable.svg" alt="">
                      <span>{{$t('modal.chooseTepee.warningAlt')}}</span>
                    </div>
                  </div>

                </div>
                <div class="visit-drawer__dates" >
                  <h2 v-if="quote.visit.stayType == 'DAY_PASS'">{{ $t('whichDayAreYouStaying') }}</h2>
                  <h2 v-else>{{ $t('whichDaysAreYouStaying') }}</h2>
                  <visit-dates :quote="quote" :prices-per-day="pricesPerDay" :step="step"
                               :unavailable-lodging-dates="unavailableLodgingDates"
                               @setDeparture="setDeparture"></visit-dates>
                </div>
                <div class="visit-drawer__time">
                  <h2>{{ $t('arrivalTime') }}</h2>
                  <visit-arrival :step="step"></visit-arrival>
                </div>
                <div class="visit-drawer__persons" >
                  <h2>{{ $t('howManyPeople') }}</h2>
                  <visit-nop :step="step"></visit-nop>
                </div>
            </div>
          </div>

          <div class="visit-drawer__content__summary" v-show="step != '1'">
            <div class="resume">
              <visit-resume @changeStep="changeStep" @onViewItemListEvent="onViewItemListEvent" @onViewItemEvent="onViewItemEvent"
                :isSelected="isSelected" :currentStep="currentStep" :stayOptionSelected="stayOptionSelected">
              </visit-resume>
            </div>
          </div>
        </div>


      </column-block>
    </transition>
    <div class="overlay" v-if="!inRemoteActivity && visitDrawerVisible" @click.prevent="$store.dispatch('hideVisitDrawer')"></div>
    <changes-date-cancel-promo class="modal" ref="changesDateCancelPromo" :promotion="promotion" @confirm="confirmUpdate"></changes-date-cancel-promo>
  </div>
</template>

<script>
  import {mapState} from 'vuex';
  import VisitNop from '@/components/visit-drawer/VisitNop';
  import VisitArrival from '@/components/visit-drawer/VisitArrival';
  import VisitDates from '@/components/visit-drawer/VisitDates';
  import VisitResume from '@/components/visit-drawer/VisitResume';
  import ColumnBlock from '@/components/columns/ColumnBlock';
  import ChangesDateCancelPromo from '@/components/modal/ChangesDateCancelPromo';
  import StayOptions from '@/components/StayOptions.vue';
  import Counter from '@/components/visit-drawer/Counter';
  import moment from 'moment';

  const PRICES_BY_COLOR = [
    'BLACK', 'BLUE', 'RED', 'YELLOW'
  ];

  const COUNTER_REF = {
    'CABIN': 'cabinCounter',
    'TIPI': 'tipiCounter',
    'LAKE_VILLAGE': 'lakeVillageCounter'
  };

  export default {
    name: 'VisitDrawer',
    components: {
      VisitNop,
      VisitArrival,
      VisitDates,
      VisitResume,
      ColumnBlock,
      ChangesDateCancelPromo,
      StayOptions,
      Counter
    },
    computed: {
      ...mapState([ 'visitDrawerVisible', 'quote', 'inRemoteActivity', 'pendingStays',
        'fullCabins', 'fullTipis', 'fullLakeVillages' ]),
      counterRef() {
        return COUNTER_REF[this.stayOptionSelected];
      },
      onChangeByStay() {
        return this.stayOptionSelected === 'CABIN' ? this.setCabins
              : this.stayOptionSelected === 'TIPI' ? this.setTipis
              : this.setLakeVillages;
      },
      maxAllowedByStay() {
        return this.stayOptionSelected === 'CABIN' ? this.maxCabins
              : this.stayOptionSelected === 'TIPI' ? this.maxTipis
              : this.maxLakeVillages;
      },
      promotion() {
        return this.quote && this.quote.promotion;
      },
      maxDate() {
        let mountsAfter = process.env.VUE_APP_10;
        return moment().endOf('year').add(mountsAfter, "M").format("YYYY-MM-DD");
      },
      visitActivityReservations() {
        let activities = this.quote && this.quote.visitActivityReservations ? this.quote.visitActivityReservations : [];
        let atvs = activities.filter(activity => activity.visitActivitySchedule.visitActivity.type == 'ATV');
        if(atvs.length > 1){
          let horsebackRiding = activities.filter(activity => activity.visitActivitySchedule.visitActivity.type == 'HORSEBACK_RIDING');
          let mergedAtv = {
            hours: 0,
            quantity: 0,
            visitActivitySchedule: null,
            visitDate: '',
            borrowedUnits: 0
          };
          atvs.forEach(atv => {
            mergedAtv.hours += atv.hours;
            mergedAtv.quantity = atv.quantity + atv.borrowedUnits;
            mergedAtv.visitActivitySchedule = mergedAtv.visitActivitySchedule ? mergedAtv.visitActivitySchedule.order > atv.visitActivitySchedule.order ? atv.visitActivitySchedule : mergedAtv.visitActivitySchedule : atv.visitActivitySchedule;
            mergedAtv.visitDate = atv.visitDate;
          });
          activities = horsebackRiding.length > 0 ? [mergedAtv].concat(horsebackRiding) : [mergedAtv];
        }

        return this.sortActivities(activities);
      },
      step() {
        let step = 1;
        let visit = this.quote && this.quote.visit;

        if (visit){
          if (visit.stayType) {
            step = 2;
          }
          if(visit.arrivalDate && (visit.stayType == "DAY_PASS" || this.departureDateSet) && visit.arrivalTime) {
            step = 3;
          }
          if(visit.arrivalDate && (visit.stayType == "DAY_PASS" || this.departureDateSet) || visit.numberOfPersons) {
            step = 4;
          }
          if(visit.arrivalDate && (visit.stayType == "DAY_PASS" || this.departureDateSet) && visit.arrivalTime && visit.numberOfPersons) {
            step = 5;
          }
        }
        return step;
      },
      isOnShoppingCart() {
          return this.$route.name === 'shopping-cart'
      },
      visitStayType() {
        return this.quote && this.quote.visit ? this.quote.visit.stayType : null;
      },
      isLodging() {
        return ['CABIN', 'TIPI', 'LAKE_VILLAGE'].includes(this.visitStayType);
      },
      stayLimitByPeople() {
        if (!this.isLodging) {
          return 1;
        }

        const minStaysQuantity = this.quote.visit.numberOfPersons / this.stayMaxCapacity;
        const minStaysQuantityInt = parseInt(Math.ceil(minStaysQuantity));

        return Math.max(1, minStaysQuantityInt)
      },
    },
    watch: {
      'quote.visit.stayType': function() {
        this.stayOptionSelected = this.quote.visit.stayType;
      },
      'quote.visit.departureDate': function() {
        this.initPricesLoading();
      },
      'quote.visit.arrivalDate': function() {
        this.initPricesLoading();
      },
      'quote'() {
        this.key++;
      },
      'isSelected'() {
        this.isSelected;
      },
      'quote.products.cabins': function() {
        this.updateCabinsNumber();
      },
      'quote.products.tipis': function() {
        this.updateTipisNumber();
      },
      'quote.products.lakeVillages': function() {
        this.updateLakeVillageNumber();
      },
      '$store.state.visitDrawerVisible': async function () {
        await this.updateUnavailableLodgingDates();
        if (this.visitDrawerVisible) {
          if (this.quote.visit.stayType === 'CABIN') {
            this.updateCabinsNumber();
          } else if (this.quote.visit.stayType === 'TIPI') {
            this.updateTipisNumber();
          } else if (this.quote.visit.stayType === 'LAKE_VILLAGE') {
            this.updateLakeVillageNumber();
          }
        }
      }
    },
    data() {
      return {
        prices: {
          dayPass: null,
          campingPass: null,
          canopyPass: null,
          cabin: null,
          tipi: null
        },
        key: 0,
        timeout: null,
        pricesPerDay: null,
        datesForApply: null,
        departureDateSet: false,
        isSelected: false,
        currentStep: '1',
        stayOptionSelected: null,
        stayType: [
          {id: "DAY_PASS", name: "oneDayPass", iconSrc: require('../assets/products/day-pass--icon--gray.svg'), hour: "7:30am - 6:00pm", imgSrc: require('@/assets/products/wave-adventure/wave-adventure_1.jpg')},
          {id: "CAMPING_PASS", name: "camping", iconSrc: require('../assets/products/camping--icon--gray.svg'), hour: "6:00pm - 6:00pm", imgSrc: require('@/assets/presentation/camping.jpg')},
          {id: "CABIN", name: "ecotouristCabins", iconSrc: require('../assets/products/cabins--icon--gray.svg'), hour: "2:00pm - 12:00pm", imgSrc: require('@/assets/presentation/eco-cabins.jpg')},
          {id: "TIPI", name: "teepees", iconSrc: require('../assets/products/tipee--icon--gray.svg'), hour: "2:00pm - 12:00pm", imgSrc: require('@/assets/products/tipee.jpg')},
          {id: "LAKE_VILLAGE", name: "lakeVillages", iconSrc: require('../assets/products/lake--icon--gray.svg'), hour: "2:00pm - 12:00pm", imgSrc: require('@/assets/presentation/lake-cabin.jpg')},
        ],
        maxCabins: 20,
        maxTipis: 6,
        maxLakeVillages: 5,
        analyticsCabin: '',
        unavailableLodgingDates: [],
        stayMaxCapacity: 6
      }
    },
    mounted() {
      this.initPricesLoading();
      this.getPricesPerDay();
      this.setDeparture();
      this.updateCabinsNumber();
      this.updateTipisNumber();
      this.updateLakeVillageNumber();

      if(this.quote) {
        this.staySelected(this.quote.visitType);

        if (this.isOnShoppingCart) {
          this.changeStep('2');
        }
      }
    },
    methods: {
      async setCabins(newNumberOfCabins, options) {
        if(newNumberOfCabins > this.maxCabins){
            return;
        }

        if(options) {
            if(options.action == 'sub') this.analyticsCabin = 'Subtract'
            else if(options.action == 'add') this.analyticsCabin = 'Add'
            else if(options.action == 'manual') this.analyticsCabin = 'Manual'
        }

        const products = this.quote.products;
        products.cabins = newNumberOfCabins;
        products.tipis = 0;
        products.lakeVillages = 0;

        await this.updateProducts(products);
      },
      async setTipis(newNumberOfTipis) {
        const products = this.quote.products;
        products.tipis = newNumberOfTipis;
        products.cabins = 0;
        products.lakeVillages = 0;

        await this.updateProducts(products);
      },
      async setLakeVillages(newNumberOfLakeVillages) {
        const products = this.quote.products;
        products.lakeVillages = newNumberOfLakeVillages;
        products.cabins = 0;
        products.tipis = 0;

        await this.updateProducts(products);
      },
      async updateProducts(products) {
        await this.$store.dispatch('updateProducts', products);
        await this.updateUnavailableLodgingDates();
      },
      async updateUnavailableLodgingDates(stay = null) {
        this.unavailableLodgingDates = await this.getUnavailableLodgingDates(stay);
      },
      updateCabinsNumber() {
        setTimeout(() => {
            this.$refs.cabinCounter && this.$refs.cabinCounter.setQuantity(this.quote.products.cabins);
        }, 300);
      },
      updateTipisNumber() {
        setTimeout(() => {
          this.$refs.tipiCounter && this.$refs.tipiCounter.setQuantity(this.quote.products.tipis);
        }, 300);
      },
      updateLakeVillageNumber() {
        setTimeout(() => {
            this.$refs.lakeVillageCounter && this.$refs.lakeVillageCounter.setQuantity(this.quote.products.lakeVillages);
        }, 300);
      },
      confirmUpdate() {
        this.updateVisitDates();
        this.$refs.changesDateCancelPromo.closeModal();
      },
      changeDatesOpen(data) {
        this.datesForApply = data;
        this.$refs.changesDateCancelPromo.openModal();
      },
      async updateVisitDates() {
        await this.$store.dispatch('updateVisit', this.datesForApply);
      },
      initPrices() {
        this.$set(this, 'prices', {
            dayPass: null,
            campingPass: null,
            canopyPass: null,
            cabin: null,
            tipi: null});
      },
      initPricesLoading() {
        if(this.$refs && this.$refs.dates && this.$refs.dates.startDateUTF && this.$refs.dates.endDateUTF) {
          this.loadPrices(this.$refs.dates.startDateUTF);
          clearTimeout(this.timeout);
        } else {
          if (!this.timeout) {
            this.initEmptyState();
          }
          this.timeout = setTimeout(this.initPricesLoading, 200);
        }
      },
      async loadPrices(startDate) {
        try {
          this.initPrices();
          let prices = await this.$store.dispatch("loadPricesByDate", startDate);
          this.$set(this, 'prices', prices);
        } catch (e) {
          return e;
        }
      },
      async loadPricesByYear(year) {
        try {
          this.pricesPerYear = await this.$store.dispatch('loadPricesByYear', year);
        } catch (e) {
          return e;
        }
      },
      async initEmptyState() {
        try {
          const year = new Date().getFullYear();
          const pricesPerYear = await this.$store.dispatch('loadPricesByYear', year);
          const currentYear = new Date().getFullYear();
          const firstDayOfYear = `${currentYear}-01-01`;
          const prices = await this.$store.dispatch('loadPrices', {start: firstDayOfYear, end: this.maxDate});
          const pricesByColor = this.groupBy(prices, 'color');
          const hasColor = PRICES_BY_COLOR.find(color => pricesByColor.hasOwnProperty(color));
          if (!hasColor) {
            return;
          }
          this.assignPrices(pricesByColor[hasColor], pricesPerYear);
        } catch (error) {
          return error;
        }
      },
      assignPrices(datePrice, pricesPerYear) {
        this.prices = {
          dayPassPrice: datePrice[0].datePrices.dayPassPrice,
          campingPassPrice: datePrice[0].datePrices.campingPassPrice + datePrice[0].datePrices.dayPassPrice,
          canopyPrice: datePrice[0].datePrices.canopyPrice,
          cabinPrice: datePrice[0].datePrices.cabinPrice,
          tipiPrice: datePrice[0].datePrices.tipiPrice,
          horsebackRidingPrice: pricesPerYear.horsebackRidingPrice
        }
      },
      groupBy(prices, property) {
        return prices.reduce((group, currentValue) => {
          let item = currentValue.datePrices[property];
          group[item] = group[item] || [];
          group[item].push(currentValue);

          return group;
        }, {});
      },
      async getPricesPerDay() {
          try {
              let currentYear = new Date().getFullYear();
              let firstDayOfYear = `${currentYear}-01-01`;
              let prices = await this.$store.dispatch('loadPrices', {start: firstDayOfYear, end: this.maxDate});
              this.pricesPerDay = prices;
          } catch (error) {
          return error;
          }
      },
      setDeparture() {
        this.departureDateSet = localStorage.getItem('DEPARTURE_DATE') != null;
      },
      sortActivities(activities) {
        let sorting = {
          "CANOPY_TOUR": 1,
          "CANOPY_TOUR_NIGHT": 2,
          "HORSEBACK_RIDING": 3,
          "ATV": 4
        };

        return activities.sort((a,b) => (sorting[a.visitActivitySchedule.visitActivity.type] > sorting[b.visitActivitySchedule.visitActivity.type]) ? 1
            : ((sorting[b.visitActivitySchedule.visitActivity.type] > sorting[a.visitActivitySchedule.visitActivity.type]) ? -1 : 0))
      },
      async staySelected(value) {
        if (value) {
          this.isSelected = true;
          await this.updateUnavailableLodgingDates(value);
          this.stayOptionSelected = value;
          this.onSelectItemEvent(value);
          this.currentStep = '2'
        }
      },
      async getUnavailableLodgingDates(stay) {
        let stayType = stay;
        if(!stay) {
          let visit = this.quote && this.quote.visit;
          stayType = visit.stayType;
        }

        const isLodging = ["CABIN", "TIPI", "LAKE_VILLAGE"].includes(stayType);
        if(!isLodging) {
          return [];
        }

        let quantity = this.getQuantityByStay(stayType);
        let command = {
          startDate: moment().format("YYYY-MM-DD"),
          endDate: this.maxDate,
          quantity: quantity,
          stayType: stayType
        }
        return await this.$store.dispatch('getUnavailableLodging', command);

      },
      getQuantityByStay(stayType) {
        let quantity = 0;
        const products = this.quote.products;
        switch (stayType) {
          case 'CABIN': quantity = products.cabins; break;
          case 'TIPI':  quantity = products.tipis; break;
          case 'LAKE_VILLAGE': quantity = products.lakeVillages; break;
          default: break;
        }
        return quantity;
      },
      changeStep(value) {
        this.currentStep = value;
      },
      getStayImage(option, imageType) {
        if(option) {
          if(imageType == "imgSrc") {
            return this.stayType.filter(stay => stay.id == option)[0].imgSrc
          } else if(imageType == "iconSrc") {
            return this.stayType.filter(stay => stay.id == option)[0].iconSrc
          } else {
            return this.stayType.filter(stay => stay.id == option)[0].name
          }

        }
      },
      openChooseTipiModal() {
        this.$store.dispatch('updateChooseTipiVisible', true);
      },
      openChooseCabinModal() {
        this.$store.dispatch('updateChooseCabinVisible', true);
      },
      openChooseLakeVillageModal() {
        this.$store.dispatch('updateChooseLakeVillageVisible', true);
      },
      onViewItemListEvent() {
        this.$root.$emit('onViewItemListEvent', this.stayType);
      },
      onSelectItemEvent(item) {
        if (this.visitDrawerVisible) {
          this.$root.$emit('onSelectItemEvent', item);
        }
      },
      onViewItemEvent() {
        this.$root.$emit('onViewItemEvent', this.stayOptionSelected);
      }
    }
  };
</script>


<style lang="scss" scoped>
  .overlay {
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .visit-drawer {
    left: 0;
    right: rem(12px);
    margin: -56px auto;
    position: absolute;
    width: 100%;
    display: grid;
    max-width: rem(1280px);

    @include respond-to('small and down') {
      display: none;
    }

    @include respond-to("medium") {
      padding: 0;
    }

    @include respond-to("large and up") {
      padding: 0;
    }

    @include respond-to("xlarge and up") {
      left: rem(12px);
    }

    &__content {
      z-index: 2;
      background-color: $visit-drawer__bg;
      width: 100%;
      padding: 32px;
      padding-top: 40px;
      &__form {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 2rem;
        grid-template-areas:
          "image dates persons time"
          "image button button button"
          "resume resume resume resume";
        height: auto;
        margin-bottom: 32px;
        &__image {
          .stay-image {
            width: 100%;
            background-size: 100% 125%;
            border-radius: 16px 16px 0 0;
            height: 170px;
            background-position: center;
          }

          .lake-village {
            background-size: 120%;
            background-position-y: -40px;
          }

          .options {
            display: grid;
            grid-template-columns: 1fr 150px;
            gap: 8px;
            margin-top: 16px;
            align-items: center;
            .counter::v-deep {
              height: 30px;
              grid-template-columns: repeat(3, 1fr);
              .counter__section {
                height: 100%;
                width: 30px;
              }
              img {
                height: 14px;
                width: 14px;
                margin: 9px;
              }
              input {
                height: 100%;
                background: transparent;
              }
            }
            span {
              margin-right: 0;
              margin-left: auto;
              color: #0075FF;
              font-size: 16px;
              text-align: right;
              cursor: pointer;
            }
          }
          .unavailable, .limit-space {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 0px;
            gap: 8px;
            height: auto;
            width: 280px;
            margin-top: 20px;
            &__reservation {
              display: grid;
              grid-template-columns: 24px 1fr;
              gap: 8px;
              height: 100%;
              letter-spacing: 0.05em;
              line-height: 24px;
              span {
                color: #FF3030;
              }
              img {
                height: 24px;
                width: 100%;
              }
            }
          }
        }
      }

      &__header {
        width: 100%;
        height: 42px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-bottom: 32px;
        gap: 4px;
        position: relative;
        .title {
          display: flex;
          flex-direction: row;
          margin: 0 auto;
          gap: 9.05px;
          img {
            width: 32px;
            height: 32px;
            margin: 5px 0;
          }
        }

        p {
          font-family: 'Chau Philomene One';
          font-style: normal;
          font-weight: 400;
          font-size: 32px;
          text-align: center;
          letter-spacing: 0.05em;
          text-transform: uppercase;
          color: $light-gray;
          height: 41px;
          width: 100%;
        }
        a {
          position: absolute;
          color: #0075FF;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          text-decoration: none;
        }
      }
      &.disabled {
        pointer-events: none;
      }
      h2 {
        font-size: 24px;
        margin-bottom: rem(16px);
        color: rgba($dark-brown-color, 0.6);
      }
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
      &__stays-first-step, &__stays-second-step {
        margin-bottom: 32px;
      }
      .without-options {
        .stay-image {
          border-radius: 16px;
        }
        .options {
          display: none;
        }
      }
    }

    &__image {
      grid-area: image;
    }
    &__persons {
      grid-area: persons;
    }
    &__dates {
      grid-area: dates;
      @media(max-height: 800px) {
        .visit-dates::v-deep {
          z-index:50;
          .visit-dates__arrival {
            .open {
              top: -10rem;
              left: 185px;
            }
          }
          .visit-dates__departure {
            .open {
              top: -12rem;
              left: 185px;
            }
          }
          .visit-dates__arrival, .visit-dates__departure {

            .calendar-drawer {
              span {
                font-size: 16px;
              }
              .vc-weeks {
                row-gap: 10px !important;
              }
              .vc-title {
                margin-top: 6px;
              }
            }
          }
        }
      }
    }
    &__time {
      grid-area: time;
    }
    &__button {
      grid-area: button;
      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
    &__resume {
      grid-area: resume;
    }
    &__link {
      color: $blue-great-color;
      font-size: rem(16px);
      font-weight: 500;
      margin-bottom: 0.5rem;
      text-decoration: none;
    }

    @include respond-to("small and down") {
        &__unavaible { padding-top: .5rem; }
    }
    @media (min-width: 1440px) {
      &__content { min-width: 1280px;}
    }
  }
</style>
