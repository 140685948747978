<template>
  <div class="messages">
    <transition-group tag="ul"     enter-active-class="animated fadeInDown"
                      leave-active-class="animated bounceOutRight">
      <li class="messages__msg" :class="msg.type" v-for="msg in messages" :key="msg.id">
        {{(msg.message == 'infoSent' ? $t(msg.message) : msg.message)}}
        <a class="messages__close" href="" @click.prevent="close(msg)">&times;</a>
      </li>
    </transition-group>
  </div>
</template>

<script>
  export default {
    name: 'MessageCenter',
    computed: {
      messages: {
        get() {
          return this.$store.getters.messages;
        },
      },
    },
    methods: {
      close(message) {
        this.$store.dispatch('hideMessage', message);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .messages {
    position: fixed;
    z-index: 10000;
    width: 100%;
    color: white;

    top: 0;

    &__msg {
      padding: 1.5rem;
      text-align: center;
      font-weight: normal !important;
      font-size: 1.5rem;
      font-family: 'Chau Philomene One', sans-serif;
      letter-spacing: 0.05em;
      line-height: rem(33px);
      background-color: $message-color;
      text-transform: uppercase;
      &.error {
        background-color: $alert-color;
      }
      &.success {
        background-color: $success-color;
      }


      font-weight: bold;

      display: grid;
      grid-template-columns: 1fr 10px;

      margin-bottom: rem(2px);
    }

    &__close {
      text-decoration: none;
      color: white;
      font-size: rem(20px);
    }
  }
</style>
