<template>
  <default-layout>
    <div class="faq">
      <aside class="faq__navigation">
        <h2 class="navigation__title">{{ $t(information.title) }}</h2>
        <div class="navigation__menu">
          <h2 class="navigation__menu__header">
            {{ $t(selected.title) }}
          </h2>
          <div @click="openMenu = true" class="navigation__menu__drop-down">
            <img src="../../assets/chevron-down--white.svg" alt="alt">
          </div>
        </div>
        <ul class="navigation__list" :class="{'hide': !openMenu}">
          <li id="faq-menu_prices" class="navigation__link" @click="selectSection('pricesAndDiscounts')" :class="{'navigation__link--selected': selected.id === information.pricesAndDiscounts.id}"><span>{{ $t(information.pricesAndDiscounts.title) }}</span><img @click="openMenu = false" src="../../assets/chevron-up--white.svg"></li>
          <li id="faq-menu_services" class="navigation__link" @click="selectSection('generalServices')" :class="{'navigation__link--selected': selected.id === information.generalServices.id}">{{ $t(information.generalServices.title) }}</li>
          <li id="faq-menu_cabins" class="navigation__link" @click="selectSection('cabins')" :class="{'navigation__link--selected': selected.id === information.cabins.id}">{{ $t(information.cabins.title) }}</li>
          <li id="faq-menu_canopy" class="navigation__link" @click="selectSection('canopyTour')" :class="{'navigation__link--selected': selected.id === information.canopyTour.id}">{{ $t(information.canopyTour.title) }}</li>
          <li id="faq-menu_admissions" class="navigation__link" @click="selectSection('admissionPolicies')" :class="{'navigation__link--selected': selected.id === information.admissionPolicies.id}">{{ $t(information.admissionPolicies.title) }}</li>
        </ul>
      </aside>
      <section class="faq__content">
        <cabins v-show="selected.id === information.cabins.id"></cabins>
        <canopy-tour v-show="selected.id === information.canopyTour.id"></canopy-tour>
        <general-services v-show="selected.id === information.generalServices.id"></general-services>
        <prices-and-discounts v-show="selected.id === information.pricesAndDiscounts.id"></prices-and-discounts>
        <admission-policies v-show="selected.id === information.admissionPolicies.id"></admission-policies>
      </section>
    </div>
  </default-layout>
</template>

<script>
  import DefaultLayout from '@/layouts/DefaultLayout';
  import PricesAndDiscounts from './PricesAndDiscounts';
  import Cabins from './Cabins';
  import GeneralServices from './GeneralServices';
  import AdmissionPolicies from './AdmissionPolicies';
  import CanopyTour from './CanopyTour';

  const information = {
    title: "faqPages.title",
    admissionPolicies: {
      id: "admissionPolicies",
      title: "faqPages.admissionPolicies.title"
    },
    pricesAndDiscounts: {
      id: "pricesAndDiscounts",
      title: "faqPages.pricesAndDiscounts.title"
    },
    generalServices: {
      id: "generalServices",
      title: "faqPages.generalServices.title"
    },
    cabins: {
      id: "cabins",
      title: "faqPages.cabins.title"
    },
    canopyTour: {
      id: "canopyTour",
      title: "faqPages.canopyTour.title"
    }
  }

  export default {
    components: {DefaultLayout, PricesAndDiscounts, Cabins, GeneralServices, CanopyTour, AdmissionPolicies},
    name: 'FAQ',
    metaInfo() {
      return {title: this.$t("tabs.faq")}
    },
    data() {
      return {
        openMenu: false,
        selected: information.pricesAndDiscounts
      }
    },
    computed: {
      information() {
        return information;
      }
    },
    methods: {
      selectSection(section) {
        this.selected = information[section];
        this.openMenu = false;
        this.scrollToTop();
      },
      scrollToTop() {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      },
    }
  }
</script>

<style lang="scss">
  .faq {
    display: grid;
    grid-template-areas:
      "navigation content";
    grid-template-columns: 25% 75%;
    padding: rem(48px) 0 rem(120px);
    position: relative;
    &__navigation {
      grid-area: navigation;
      border-right: 1px $dark-brown-color solid;
      height: rem(614px);
      .navigation {
        &__title {
          margin-bottom: rem(27px);
        }
        &__menu {
          display: none;
        }
        &__link {
          font-family: $font-primary;
          font-weight: 500;
          font-size: 16px;
          line-height: 16px;
          letter-spacing: 0.05em;
          color: $dark-brown-color;
          opacity: 0.6;
          margin-bottom: rem(16px);
          &:last-child {
            margin-bottom: 0;
          }
          cursor: pointer;
          span {
            width: 100%;
          }
          img {
            display: none;
          }
          &--selected {
            position: relative;
            opacity: 1;
            margin-bottom: rem(19px);
            &::before {
              display: inline-block;
              content: '';
              width: 7px;
              height: 12px;
              mask: url('../../assets/arrow-right--selected.svg') no-repeat 50% 50%;
              mask-size: cover;
              background-color: $orange-color;
              margin-right: 8px;
            }
          }
        }
      }
    }
    &__content {
      grid-area: content;
      padding-left: rem(24.5px);
      .content {
        &__title {
          font-size: 32px;
          line-height: 24px;
          margin-bottom: rem(48px);
        }

      }
      .question {
        &__title {
          margin-bottom: rem(16px);
        }
        &__answer {
          font-family: $font-primary;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.05em;
          color: $dark-brown-color;
        }
        a {
          color: $blue-color2;
          font-weight: normal;
          text-decoration: none;
        }
        &__answer-list {
          margin-top: rem(8px);
          li {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.05em;
          }
          &--numbered {
            padding-left: 14px;
            list-style: decimal;
          }
          &--empty-bullets {
            padding-left: 14px;
            list-style: circle;
          }
          &--bullets {
            padding-left: 14px;
            list-style: disc;
          }
          &--alphabet {
            padding-left: 14px;
            list-style: lower-alpha;
          }
        }
        &:not(:last-child) {
          margin-bottom: rem(48px);
        }
      }
    }
    @include respond-to('large and up') {
      &__navigation {
        position: sticky;
        top: 15%;
      }
    }
    @include respond-to('medium and down') {
      grid-template-areas:
        "navigation"
        "content";
      grid-template-columns: auto;
      padding: rem(64px) 0 rem(167px);
      &__navigation {
        height: auto;
        border: none;
        .navigation {
          &__title {
            display: none;
          }
          &__menu {
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            background-color: $orange-color;
            height: 48px;
            width: 100vw;
            margin: 0 -1rem;
            padding: rem(14px) rem(16px) rem(16px);
            &__header {
              display: inline-block;
              vertical-align: middle;
              width: calc(100% - 13px);
              color: white;
            }
            &__drop-down {
              display: inline-block;
              vertical-align: unset;
              width: rem(13px);
              height: rem(13px);
            }
          }
          &__list {
            position: absolute;
            padding: rem(16px);
            left: 0;
            top: 0;
            margin: 0 -1rem;
            width: 100vw;
            background-color: $orange-color;
            &.hide {
              display: none;
            }
          }
          &__link {
            color: white;
            margin-bottom: rem(32px);
            &--selected {
              font-family: $font-secondary;
              font-size: 16px;
              line-height: 22px;
              text-transform: uppercase;
            }
            &::before {
              display: none;
            }
            &:last-child {
              margin-bottom: 0;
            }
            span {
              display: inline-block;
              vertical-align: middle;
              width: calc(100% - #{rem(13px)});
            }
            img {
              display: inline-block;
              vertical-align: middle;
              width: rem(13px);
            }
          }
        }
      }
      &__content {
        padding-left: unset;
        .content {
          &__title {
            font-size: 24px;
            line-height: 33px;
            margin-bottom: rem(16px);
          }
        }
        .question {
          &:not(:last-child) {
            margin-bottom: rem(16px);
          }
          &__title {
            margin-bottom: rem(8px);
          }
        }
      }
    }
  }
</style>