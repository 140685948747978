<template>
    <div class="thank-you__information-vehicle">
                <div class="thank-you__card thank-you__contact-info">
                <h2>{{ $t('vehicleData') }}</h2>
                <div class="elements">
                    <dl class="thank-you__contact-info_data" >
                        <dt>{{ $t('vehicle.type')}}</dt>
                        <dd v-if="vehicle.properties[0].value">{{ $t(vehicle.properties[0].text) }}</dd>
                        <dd v-else>{{ vehicle.properties[0].otherValue }}</dd>
                    </dl>
                    <dl class="thank-you__contact-info__data" >
                        <dt>{{ $t('vehicle.brand')}}</dt>
                        <dd v-if="vehicle.properties[1].value">{{ $t(vehicle.properties[1].text) }}</dd>
                        <dd v-else>{{ vehicle.properties[1].otherValue }}</dd>
                    </dl>
                    <dl class="thank-you__contact-info__data" >
                        <dt>Color</dt>
                        <dd v-if="vehicle.properties[2].value">{{ $t(vehicle.properties[2].text) }}</dd>
                        <dd v-else>{{ vehicle.properties[2].otherValue }}</dd>
                    </dl>
                    <dl class="thank-you__contact-info__data" >
                        <dt>{{ $t('vehicle.licensePlate') }}</dt>
                        <dd v-if="vehicle.properties[3].value">{{ vehicle.properties[3].value }}</dd>
                        <dd v-else>{{ vehicle.properties[3].otherValue }}</dd>
                    </dl>
                    <dl class="thank-you__contact-info__data" >
                        <dt>{{ $t('vehicle.licensePlates.type')}}</dt>
                        <dd v-if="vehicle.properties[4].value">{{ $t(vehicle.properties[4].text) }}</dd>
                        <dd v-else>{{ vehicle.properties[4].otherValue }}</dd>
                    </dl>
                </div>
            </div>
    </div>
</template>

<script>
export default {
    name: "DataVehicle",
    props: {
        vehicle: Object
    }
}
</script>

<style lang="scss" scoped>

  .thank-you {
    max-width: rem(1096px);
    grid-row-gap: 1.5rem;
    & > h2 {
        font-size: 1.5rem;
        @include respond-to("small and down") {
            margin-top: rem(-3px);
    }
    }
    &__card {
        display: grid;
        grid-template-columns: 1fr;
        background-color: rgba(#fff, 0.8);
        width: 100%;
        border: 1px solid rgba($dark-brown-color, 0.2);
        border-radius: rem(16px);
        padding: rem(24px);

    }

    .elements {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        @include respond-to("small and down") {
            grid-template-columns: repeat(1, 1fr);
        }

        @include respond-to("medium and large"){
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &__contact-info {
        display: grid;
        @include respond-to("medium and down") {
            padding: rem(14px) rem(16px);
        }
        padding: rem(21px) rem(22px);
        h2 {
            font-size: rem(24px);
            margin-bottom: rem(30px);
        }
        div {
            dl {
                font-weight: 500;
                line-height: rem(16px);
                letter-spacing: 0.05em;
                margin-bottom: rem(24px);
                dt {
                    color: rgba($dark-brown-color, 0.5);
                    margin-bottom: rem(8px);
                }
            }
            @include respond-to("medium and down") {
                max-width: 100%;
            }
            @include respond-to("large and up") {
                grid-template-columns: rem(364px) auto;
            }
        }
    }

    &__information-vehicle {
        display: grid;
        grid-template-columns: 1fr;
    }
  }
</style>