<template>
    <products-lodging selected-product="indianVillage">
      <div class="product-summary__details" v-if="currentProduct && information">
        <gallery>
          <template slot="small-image">
            <img :src="require('@/assets/products/indian-village/indian-village_' + gallery[0] + '.jpg')" alt="">
          </template>
          <template slot="gallery-images">
            <img class="slide" v-for="(picture, i) in gallery" :key="i" :src="require('@/assets/products/indian-village/indian-village_' + picture + '.jpg')" alt="">
          </template>
        </gallery>
        <div class="product-summary__details__title">
          <h1>{{ $t(currentProduct.title) }}</h1>
        </div>
        <div class="product-summary__details__content">
          <div class="product-summary__details__content__information">
            <div class="product-summary__details__content__information__description" :class="{hidden: informationHidden}">
              <p>{{ $t(information[currentProduct.imageName].description.text1) }}</p>
              <h3>{{ $t(information[currentProduct.imageName].description.text2) }}</h3>
              <ul>
                <li class="add-space-bottom">{{ $t(information[currentProduct.imageName].description.text3) }}</li>
                <li class="add-space-bottom">{{ $t(information[currentProduct.imageName].description.text4) }}</li>
                <li>{{ $t(information[currentProduct.imageName].description.text5) }}</li>
              </ul>
              <p>{{ $t(information[currentProduct.imageName].description.text6) }}</p>
            </div>
            <span class="hide-description" @click="informationHidden = !informationHidden">{{ informationHidden ? $t('readMore') : $t('hide') }}</span>
          </div>
          <div class="product-summary__details__content__services">
            <h1 class="product-summary__details__content__services__title">{{$t('services')}}</h1>
            <div class="product-summary__details__content__services__item" v-for="service in information[currentProduct.imageName].services" :key="service.id">
              <img class="icon" :src="require('@/assets/products/services/'+service.iconName+'.svg')" alt="">
              <span>{{ $t(service.name) }}</span>
            </div>
          </div>
        </div>
      </div>
    </products-lodging>
  </template>
  
  <script>
    import Gallery from '@/components/Gallery';
    import ProductsLodging from './ProductsLodging.vue';
  
    const information = {
      teepee: {
        priceDescription: 'pricePerTeepee',
        priceDescriptionMobile: 'perTeepee',
        labels: ['priceFor', 'capacity1To6People', 'thePriceDoesNotIncludeAdmissionFees'],
        services: [
          { id:'20', name: 'productServices.sleepingMats', iconName:'mats' },
          { id:'19', name: 'productServices.grill', iconName:'steakhouse' },
          { id:'18', name: 'productServices.campingTable', iconName:'table' },
          { id:'15', name: 'productServices.parking', iconName:'parking-lot' },
        ],
        description: {
          text1: 'productPages.indianVillage.text1',
          text2: 'productPages.indianVillage.text2',
          text3: 'productPages.indianVillage.text3',
          text4: 'productPages.indianVillage.text4',
          text5: 'productPages.indianVillage.text5',
          text6: 'productPages.indianVillage.text6',
        }
      }
    }
  
    export default {
      name: 'IndianVillage',
      components: {ProductsLodging, Gallery},
      metaInfo() {
        return {title: this.$t("tabs.indianVillage")}
      },
      data() {
        return {
          currentProduct: null,
          informationHidden: true,
          gallery: [1,2,3,4,5]
        }
      },
      computed: {
          information() {
              return information;
          }
      },
      mounted() {
        this.currentProduct = { title:'indianVillage', imageName: 'teepee' };
      }
    };
  </script>
  
  <style lang="scss" scoped>
    .product-summary {
      &__details {
        &__content {
          &__services {
            height: rem(300px);
            &__item {
              height: auto;
            }
          }
          &__information__description {
            li {
              list-style-type:circle;
              list-style-image: url('../../assets/orb.svg');
              margin-left: 20px;
            }
            .add-space-bottom {
              margin-bottom: 16px;
            }
          }
        }
      }
    }
  
  </style>