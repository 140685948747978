<template>
    <div class="activity-card">
        <div class="activity-card__name">
            <img :src="require('@/assets/activities/'+activity.image)" :alt="activity.name">
            <span class="text">{{ $t(activity.name) }}</span>
        </div>
        <div class="activity-card__price">
            <span class="activity-card__price__concept--tooltip"><p>{{ $t('regularPrice') }}</p><img v-tooltip.top="$t('regularPriceTooltip')" src="@/assets/important.svg" alt="Important information icon"></span>
            <div class="activity-card__price__amount">
                <p>{{ activity.normalPrice | currencyWithoutDecimals }}</p>
                <p v-if="isAtvRental" class="label"> {{ $t('hour') }}</p>
                <p v-else class="label"> {{ $t('person') }}</p>
            </div>
        </div>
        <div class="activity-card__price">
            <span class="activity-card__price__concept--tooltip">
              <p>{{ $t('specialPrice') }}</p>
              <img v-tooltip.top="$t('specialPriceTooltip')" src="@/assets/important.svg" alt="Important information icon">
            </span>
            <div class="activity-card__price__amount">
                <p>{{ activity.specialPrice | currencyWithoutDecimals }}</p>
                <p v-if="isAtvRental" class="label"> {{ $t('hour') }}</p>
                <p v-else class="label"> {{ $t('person') }}</p>
            </div>
        </div>
        <div v-if="activity.nightPrice" class="activity-card__price">
            <span class="activity-card__price__concept"><p>{{ $t('nightly') }}</p></span>
            <div class="activity-card__price__amount">
                <p>{{ activity.nightPrice | currencyWithoutDecimals }}</p>
                <p class="label"> {{ $t('person') }}</p>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'ActivityInfoCard',
    props: ['activity'],
    data: function() {
        return {
        };
    },
    filters: {
      currencyWithoutDecimals(value) {
        return '$' + value;
      }
    },
    computed: {
      isAtvRental() {
        return this.activity.name && this.activity.name === 'quadbikeRental';
      }
    }
};
</script>

<style lang="scss" scoped>
.activity-card {
    display: grid;
    grid-row-gap: 1rem;
    grid-template-rows: repeat(3, 24px);
    width: 100%;
    padding: rem(22px) 1.5rem;
    border: 1px dashed #A59F99;
    border-radius: .5rem;
    min-width: 346px;

    &__name {
        display: grid;
        gap: 0.5rem;
        grid-template-columns: auto 1fr;
        margin-bottom: 0.5rem;
        .text {
            align-self: center;
            font-family: $font-secondary;
            font-size: 1.5rem;
            line-height: rem(33px);
            padding-bottom: .5rem;
            text-transform: uppercase;
            @include respond-to("medium and down") {
                letter-spacing: 0.04em;
            }
        }
    }
    &__price {
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;
        &__concept {
            font-weight: 500;
            font-size: 1rem;
            letter-spacing: 0.05em;
            color: $dark-brown-color;
            justify-self: left;
            &--tooltip {
                display: flex;
                justify-content: space-between;
                max-width: 155px;
            }
        }
        &__amount {
            display: flex;
            align-items: baseline;
            gap: 0.5rem;
            font-family: $font-secondary;
            font-weight: normal;
            font-size: 1rem;
            letter-spacing: 0.05em;
            color: $dark-brown-color;
            justify-self: right;
            .label {
                color: #786F66;
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
            }
        }
    }
    &__details {
        display: grid;
        align-items: center;
        height: 4rem;

        &__advert {
            font-weight: 500;
            font-size: rem(12px);
            line-height: 1rem;
            color: #272727;
            opacity: 0.6;
            display: flex;
            letter-spacing: 0.05em;
        }
    }
}
@include respond-to("small and down") {
    .activity-card {
        min-width: 346px;
    }
}
</style>