<template>
    <div></div>
</template>

<script>
    import jQuery from 'jquery';
    const tawkSrc = process.env.VUE_APP_https://embed.tawk.to/5e23a5c6daaca76c6fceb97a/default;

    export default {
        components: {},
        computed: {
            tawkSrc() {
                return tawkSrc;
            }
        },
        mounted() {
            this.tawkTo();
            this.updateTawkto()
        },
        methods: {
            tawkTo() {
                if (this.tawkSrc && this.tawkSrc != "") {
                    var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
                    s1.async=true;
                    s1.src=this.tawkSrc;
                    s1.charset='UTF-8';
                    s1.setAttribute('crossorigin','*');
                    s0.parentNode.insertBefore(s1,s0);
                }
            },
            updateTawkto() {
                jQuery("iframe[title='chat widget']").eq(0).addClass("custom-chat-widget");

                const tawkIcon = jQuery("iframe[title='chat widget']").contents().find(".tawk-min-chat-icon");
                tawkIcon.eq(0).addClass("gtm-chats gtm-contact");

                setTimeout(() => {
                    const chatButton = jQuery("iframe[title='chat widget']").eq(0)
                    const chatFrame = jQuery("iframe[title='chat widget']").eq(1)

                    chatButton.css({'z-index': '100000', 'position': 'fixed', 'right': '20px'})
                    chatFrame.css({'z-index': '100000'})

                    if (['shopping-cart','contact-payment', 'order.details'].indexOf(this.$route.name) > -1) {
                        if(window.innerWidth < 765) {
                            chatButton.css({ 'display': 'none' });
                        } else {
                            chatButton.css({'bottom': '200px', 'display': 'initial' });
                            chatFrame.css({'bottom': '265px'})
                        }
                    } else {
                        if(window.innerWidth > 1330) {
                            chatButton.css({'bottom': '20px' });
                        } else {
                            chatButton.css({'bottom': '120px'});
                            chatFrame.css({'bottom': '185px' });
                        }
                    }
                    this.updateTawkto();

                }, 100);
            }
        }
    };
</script>

<style lang="scss">
</style>
