// main.js or a separate module
import Vue from 'vue';

Vue.directive('capitalize', {
  bind(el) {
    // Add an input event listener
    el.addEventListener('blur', (event) => {
      // Capitalize the input value
      const capitalizedValue = capitalize(event.target.value);

      // Set the capitalized value back to the input element
      event.target.value = capitalizedValue;

      // Emit an input event so that v-model updates the bound data
      event.target.dispatchEvent(new Event('input'));
    });
  },
});

function capitalize(inputValue) {
  if (!inputValue) return '';

  inputValue = inputValue.toLowerCase();

  return inputValue
    .split(' ')
    .map(word => {
      return word.length > 0
        ? word.charAt(0).toUpperCase() + word.slice(1)
        : word;
    })
    .join(' ');
}