<template>
  <product-summary :products="products" :selected-product="selectedProduct" sectionTitle="fun.water" :show-section-links="true">
    <slot></slot>
  </product-summary>
</template>

<script>
  import ProductSummary from '@/views/products/ProductSummary';

  export default {
    components: {ProductSummary},
    name: 'ProductsWater',
    props: ['selectedProduct'],
    data() {
      return {
        products: [
          { id:'wave-adventure', title:'waveAdventure', imageName:'wave-adventure', state: 'product.water.wave-adventure', select: false },
          { id:'interactive-fountain', title:'interactiveFountain', imageName:'interactive-fountain', state: 'product.water.interactive-fountain', select: false },
          { id:'pirate-fountain', title:'pirateFountain', imageName:'pirate-fountain', state: 'product.water.pirate-fountain', select: false },
          { id:'diving-board-pool', title:'divingBoardPool', imageName:'diving-board-pool', state: 'product.water.diving-board-pool', select: false },
          { id:'super-water-slide', title:'superWaterSlide', imageName:'super-water-slide', state: 'product.water.super-water-slide', select: false },
          { id:'waterfalls-pool', title:'waterFallsPool', imageName:'waterfalls-pool', state: 'product.water.waterfalls-pool', select: false },
          { id:'pedal-boats', title:'pedalBoats', imageName:'pedal-boats', state: 'product.water.pedal-boats', select: false },
          { id:'the-lake', title:'lake', imageName:'lake', state: 'product.water.lake', select: false }

        ],
        currentProduct: null
      }
    },
    methods: {
      analyticsSeeDetails(title) {
        this.onGenericEvent(`${title} menú sidebar`, { event_category: 'diversión en agua', event_label: `menu` });
      },
      onGenericEvent(event, command) {
        this.$root.$emit('onGenericEvent', event, command);
      },
    }
  };
</script>