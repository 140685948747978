<template>
    <default-layout>
        <div class="privacy-notice">
            <div class="privacy-notice__header">
                <h2 class="">{{$t('privacyNoticePage.title')}}</h2>
                <ul class="privacy-notice__header__header__menu">
                    <li v-for="(section, index) in pageData.sections" :key="index">
                        <a :href="'#' + section.id">{{ getSectionTitle(index) }}</a>
                    </li>
                </ul>
            </div>
            <ol class="privacy-notice__content">
                <li class="privacy-notice__content__section"
                :id="section.id" v-for="(section, index) in pageData.sections" :key="index">
                    <h2>{{ getSectionTitle(index) }}</h2>
                    <p>{{getSectionDescription(index)}}</p>
                    <div class="privacy-notice__content__section__chapter" v-for="(chapter, chIndex) in section.chapters" :key="chIndex">
                        <h3>{{chIndex + 1}}.- {{getChapterTitle(index, chIndex)}}</h3>
                        <p v-for="(paragraph, pIndex) in chapter.description" :key="pIndex">{{getChapterDescription(index, chIndex, pIndex)}}</p>
                    </div>
                </li>
            </ol>
        </div>
    </default-layout>
</template>

<script>
    import DefaultLayout from '@/layouts/DefaultLayout';
    export default {
        components: { DefaultLayout },
        metaInfo() {
            return {title: this.$t("tabs.privacyNotice")}
        },
        data() {
            return {
                pageData: this.loadPageData()
            }
        },
        methods: {
            loadPageData(){
                return this.$t(`privacyNoticePage`);
            },
            getSectionTitle(index) {
                return this.$t(`privacyNoticePage.sections[${index}].title`);
            },
            getSectionDescription(index) {
                return this.$t(`privacyNoticePage.sections[${index}].description`);
            },
            getChapterTitle(sectionIndex, chapterIndex) {
                return this.$t(`privacyNoticePage.sections[${sectionIndex}].chapters[${chapterIndex}].title`);
            },
            getChapterDescription(sectionIndex, chapterIndex, paragraphIndex) {
                return this.$t(`privacyNoticePage.sections[${sectionIndex}].chapters[${chapterIndex}].description[${paragraphIndex}]`);
            }
        }
    }
</script>

<style lang="scss" scoped>
    $general-pad: 3rem;
    :target::before {//offsets page when clicking on an anchor
        content: "";
        display: block;
        height: 150px; /* fixed header height*/
        margin: -150px 0 0; /* negative fixed header height */
    }
    .privacy-notice{
        padding: $general-pad 0;
        max-width: 1037px;
        justify-self: center;

        h2 {
            font-size: 32px;
            line-height: 24px;
        }
        p {
            margin-top: 1rem;
            font-weight: 500;
            letter-spacing: 0.05em;
        }
        &__header{
            &__header__menu{
                margin-top: $general-pad;
                li {
                    a{
                        color: $blue-great-color;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 24px;
                        text-decoration: none;
                        letter-spacing: 0.05em;
                    }
                    &:not(:last-child) { margin-bottom: 1rem; }
                }
            }
        }
        &__content{
            &__section {
                margin-top: 2rem;
                h2{ color: $brown-opacity-color; }

                &__chapter{
                    margin-top: $general-pad;
                    h3 {
                        letter-spacing: 0.05em;
                        text-transform: uppercase;
                        font-size: 24px;
                        line-height: 33px;
                    }
                }
            }
        }
        @include respond-to("small and down") {
            padding: 1.5rem 0;
            overflow: hidden;
            justify-self: unset;
            h2 {
                font-size: 32px;
                line-height: 2.063rem;
                font-size: 1.5rem;
            }
            p {
                margin-top: 0.5rem;
            }
            &__header{
                &__header__menu{
                    margin-top: 1rem;
                }
            }
            &__content {
                &__section {
                    margin-top: 1rem;
                    h3 { font-size: 1rem }
                    &__chapter{
                        margin-top: 1rem;
                    }
                }
            }
        }
    }
</style>