<template>
<section class="activities" v-if="isMounted">
  <activity-card
    v-for="(activity, index) in activities"
    :activity="activity" :key="index" open/>
</section>
</template>

<script>
import ActivityCard from '@/components/shopping-cart/ActivityCard';

export default {
  name: 'Activities',
  components: {
    ActivityCard,
  },
  computed: {
    quote() {
      return this.$store.getters.quote ? this.$store.getters.quote : {};
    },
  },
  watch: {
    'quote.visit.arrivalDate': function() {
        this.updateArrivalDate(this.quote.visit.arrivalDate)
    },
  },
  async mounted() {
    this.updateArrivalDate(this.quote.visit.arrivalDate);
  },
  methods: {
    assignPrices(prices) {
        let pricesInfo = [
          {name: "specialHorsebackRidingPrice", price: prices.specialHorsebackRidingPrice},
          {name: "specialCanopyPrice", price: prices.specialCanopyPrice},
          {name: "specialAtvsPrice", price: prices.specialAtvsPrice},
          {name: "nightCanopyPrice", price: prices.nightCanopyPrice},

        ]

        for(let i in this.activities) {
          for(let pricesName of pricesInfo) {
            if(this.activities[i].priceId == pricesName.name) {
              this.activities[i].price = pricesName.price
            }
          }
        }

    },
    async updateArrivalDate(arrivalDate) {
      const activitiesPrice = await this.$store.dispatch('loadPricesByDate', arrivalDate);
      this.assignPrices(activitiesPrice);
      this.isMounted = true;
    }
  },
  data() {
    return {
      isMounted: false,
      activities: [
        {
          priceId: 'specialCanopyPrice',
          name: 'canopy_tour',
          counterType: 'persons',
          imageName: 'activities.canopyImg',
          price: 0,
          productDesc: 'activities.canopyDesc',
          productPer: 'activities.pricePerPerson',
          rentalProperties: ['date']
        },
        {
          priceId: 'nightCanopyPrice',
          name: 'canopy_tour_night',
          counterType: 'persons',
          imageName: 'activities.nightCanopyImg',
          price: 0,
          productDesc: 'activities.nightCanopyDesc',
          productPer: 'activities.pricePerPerson',
          rentalProperties: ['date', 'schedule']
        },
        {
          priceId: 'specialHorsebackRidingPrice',
          name: 'horseback_riding',
          counterType: 'horses',
          imageName: 'activities.horsebackRidingImg',
          price: 0,
          productDesc: 'activities.horsebackRidingDesc',
          productPer: 'activities.pricePerPerson',
          rentalProperties: ['date', 'schedule'],
        }
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
.activities {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));

  @include respond-to("medium and up") {
    grid-gap: 1.5rem;

    padding: 1.5rem;
    background-color: white;
    border: 1px solid $lighter-gray;
    border-radius: 16px;
  }
  @include respond-to("small and down") {
    grid-gap: 2rem;
  }
}
</style>