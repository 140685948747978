import Vue from 'vue';
import App from './App.vue';
import routes from './routes';
import store from './store';
import VueMeta from 'vue-meta';
import VCalendar from 'v-calendar';
import VueI18n from 'vue-i18n';
import messages from './lang';
import VueGtag from "vue-gtag";
import VueSimpleMarkdown from 'vue-simple-markdown'
import Tooltip from 'vue-directive-tooltip';
import VueExpandableImage from 'vue-expandable-image';
import VueTheMask from 'vue-the-mask';

import MiniLoader from '@/components/loaders/MiniLoader';

import './filters/currency';
import './filters/date';
import './filters/lowerCase';
import './filters/amount';

import './error-handler';

import './scss/main.scss';
import './scss/components/_calendar.scss';
import './scss/components/_markdown.scss';
import 'vue-directive-tooltip/dist/vueDirectiveTooltip.css';
import './directives/capitalize';

Vue.config.productionTip = false;

Vue.use(Tooltip);
Vue.use(VueTheMask);
Vue.use(VueSimpleMarkdown);

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

Vue.use(VCalendar, { componentPrefix: 'vc' });

Vue.use(VueExpandableImage)

Vue.use(VueI18n);
export const i18n = new VueI18n({
  locale: 'es',
  fallbackLocale: 'es',
  messages
});

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_UA-9234636-1 },
  enabled: true
}, routes);

Vue.component('MiniLoader', MiniLoader);

new Vue({
  router: routes,
  store,
  i18n,
  render: h => h(App),
  beforeCreate() {
    this.$store.commit('initLocale');
  }
}).$mount('#app');

const pixelId = process.env.VUE_APP_987501427976652;

export function fbq(param1, param2, param3) {
  if (pixelId && pixelId !== '') {
    window.fbq(param1, param2, param3);
  }
}