import store from "../store";

const NUMBER_OF_PASSES = 'NUMBER_OF_PASSES';
const STAY_TYPE = 'STAY_TYPE';
let messages = {
    es: {
        day_pass_from_camping: 'Al eliminar (NUMBER_OF_PASSES) pase de "solo día" se cambiará la hora de ingreso al campamento a partir de las 6:00 pm.',
        rental: 'Al eliminar (NUMBER_OF_PASSES) STAY_TYPE, tu visita se cambiará a "Acampado + Día".',
        day_pass_from_rental: 'Al eliminar (NUMBER_OF_PASSES) pase de "solo día" se cambiará la hora de ingreso al campamento de (NUMBER_OF_PASSES) persona a partir de las 6:00 pm.'
    },
    en: {
        day_pass_from_camping: 'If you remove (NUMBER_OF_PASSES) day pass, your time of arrival will be updated to after 6:00 pm.',
        rental: 'If you remove (NUMBER_OF_PASSES) STAY_TYPE, your type of visit will be updated to “Camping + Day Pass”.',
        day_pass_from_rental: 'If you remove (NUMBER_OF_PASSES) day pass, the time of arrival of (NUMBER_OF_PASSES) person will be updated to after 6:00 pm.'
    }
}

function validateRemoveProduct(quote, product) {
    if(!product || !product.name)
        return;

    let message = null;
    let visit = quote.visit;

    switch (product.name) {
        case 'ecotouristCabins':
            message = getMessage('rental', quote.products.cabins, product.name);
            break;
        case 'teepees':
            message = getMessage('rental', quote.products.tipis, product.name);
            break;
        case 'lakeVillages':
            message = getMessage('rental', quote.products.lakeVillages, product.name);
            break;
        case 'campingPass':
        case 'canopy':
        case 'canopyNight':
        case 'horseRide':
        case 'atvs':
            break;
        case 'oneDayPass':
            if("CAMPING_PASS" == visit.stayType) {
                message = getMessage('day_pass_from_camping', getProductItemQty(quote,'day_pass'));
            } else if(visit.stayType != 'DAY_PASS') {
                message = getMessage('day_pass_from_rental', getProductItemQty(quote,'day_pass'));
            }
            break;
    }

    return message;
}
async function removeProduct(quote, product) {
    if(!product || !product.name)
        return;

    switch (product.name) {
        case 'ecotouristCabins':
            quote.products.cabins = 0;
            await store.dispatch('updateProducts', quote.products);
            quote.visit.stayType = 'CAMPING_PASS';
            await store.dispatch('updateVisit', quote.visit);
            break;
        case 'teepees':
            quote.products.tipis = 0;
            await store.dispatch('updateProducts', quote.products);
            quote.visit.stayType = 'CAMPING_PASS';
            await store.dispatch('updateVisit', quote.visit);
            break;
        case 'lakeVillages':
            quote.products.lakeVillages = 0;
            await store.dispatch('updateProducts', quote.products);
            quote.visit.stayType = 'CAMPING_PASS';
            await store.dispatch('updateVisit', quote.visit);
            break;
        case 'campingPass':
            if(quote.visit.stayType == "CAMPING_PASS") {
                quote.visit.departureDate = quote.visit.arrivalDate;
                quote.visit.numberOfDays = 0;
                quote.visit.stayType = 'DAY_PASS';
                await store.dispatch('updateVisit', quote.visit);
            } else if(quote.visit.stayType != 'DAY_PASS') {
                quote.visit.numberOfPersons = quote.visit.numberOfPersons - getProductItemQty(quote, 'camping_pass');
                await store.dispatch('updateVisit', quote.visit);
            } else
                await store.dispatch('createEmptyQuote');
            break;
        case 'oneDayPass':
            if(quote.visit.stayType == 'DAY_PASS') {
                await store.dispatch('createEmptyQuote');
            } else if(quote.visit.arrivalTime == "DAY"){
                quote.visit.arrivalTime = "NIGHT";
                await store.dispatch('updateVisit', quote.visit);
            }
            break;
        case 'canopy':
            quote.products.canopyPasses = 0;
            await store.dispatch('updateProducts', quote.products);
            break;
        case 'canopyNight':
            quote.products.canopyNightPasses = 0;
            await store.dispatch('updateProducts', quote.products);
            break;
        case 'horseRide':
            quote.products.horsebackRidingPasses = 0;
            await store.dispatch('updateProducts', quote.products);
            break;
        case 'atvs':
            quote.products.atvs = 0;
            await store.dispatch('updateProducts', quote.products);
            break;
    }
}

function getMessage(message, passes, product) {
    const locale = store.getters.locale;
    let msg = messages[locale][message];
    msg = msg.replaceAll(NUMBER_OF_PASSES, passes);
    if(product) {
        let products = {
            en: {
                teepees: 'tepee',
                ecotouristCabins: 'cabin',
                lakeVillages: 'villa'
            },
            es: {
                teepees: 'tipi',
                ecotouristCabins: 'cabaña',
                lakeVillages: 'villa'
            }
        }
        msg = msg.replaceAll(STAY_TYPE, products[locale][product]);
    }
    return msg;
}

function getProductItemQty(quote, item) {
    if(!quote || !quote.productItems)
        return 0;
    let index = quote.productItems.map(i => i.key).indexOf(item);
    if(index > -1)
        return quote.productItems[index].quantity;
    return 0;
}

const cartManager = {
    removeProduct,
    validateRemoveProduct
};

export default cartManager;