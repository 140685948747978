<template>
    <div class="visit-nop" v-if="quote" :class="{disabled: step < 3 || noDepartureDate}">
      <div class="visit-nop__content">
          <div class="visit-nop__persons">{{ $t('persons') }}</div>
          <div class="visit-nop__counter">
              <counter ref="counter" :on-change="setNumberOfPersons" :max-allowed="peopleLimitByStay">
                <add-more class="add-more"
                  :number="quote.visit.numberOfPersons"
                  :number-min=0
                  :number-max="peopleLimitByStay"
                  :onChangeNumber="setNumberOfPersons"
                />
              </counter>
          </div>
      </div>
      <div class="notice">{{ campingPassNotice }}</div>
      <div class="notice">{{ $t('heightNotice') }}</div>
      <div v-show="needMoreCabins" class="visit-nop__need-more-cabins">
        <img src="@/assets/date-picker/unavailable.svg" alt="">
        <span class="visit-nop__need-more-cabins__max-people">{{$t('maxCabins')}} <span class="visit-nop__need-more-cabins__suggetion">{{ $t('suggestion') }}</span> </span>
        <span class="visit-nop__need-more-cabins__add" @click.prevent="addCabin">{{$t('addMoreCabin')}}</span>
      </div>
      <div v-show="needMoreTipis" class="visit-nop__need-more-tipis">
        <img src="@/assets/date-picker/unavailable.svg" alt="">
        <span class="visit-nop__need-more-cabins__max-people">{{$t('maxTipis')}} <span class="visit-nop__need-more-cabins__suggetion">{{ $t('suggestion') }}</span>  </span>
        <span class="visit-nop__need-more-tipis__add" @click.prevent="addTipi">{{$t('addMoreTipi')}}</span>
      </div>
      <div v-show="needMoreVillages" class="visit-nop__need-more-villages">
        <img src="@/assets/date-picker/unavailable.svg" alt="">
        <span class="visit-nop__need-more-cabins__max-people">{{$t('maxVillages')}} <span class="visit-nop__need-more-cabins__suggetion">{{ $t('suggestion') }}</span> </span>
        <span class="visit-nop__need-more-villages__add" @click.prevent="addVillage">{{$t('addMoreVillage')}}</span>
      </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import Counter from '@/components/visit-drawer/Counter';
import AddMore from '@/views/components/AddMore';

export default {
    name: 'VisitNop',
    props: ['step'],
    components: { Counter, AddMore },
    computed: {
        ... mapState(['quote']),
        needMoreCabins() {
          return this.needMoreRentals('cabins');
        },
        needMoreTipis() {
          return this.needMoreRentals('tipis');
        },
        needMoreVillages() {
          return this.needMoreRentals('lakeVillages');
        },
        isCabin() {
          if (!this.quote) {
            return false;
          }

          return this.quote ? this.quote.products.cabins : null;
        },
        isVillages() {
          if (!this.quote) {
            return false;
          }

          return this.quote ? this.quote.products.lakeVillages : null;
        },
        isTipi() {
          if (!this.quote) {
            return false;
          }

          return this.quote ? this.quote.products.tipis : null;
        },
        campingPassNotice() {
          if (this.isCabin) {
            return `${this.$t('campingPassCabinNotice')}`;
          } else if (this.isVillages) {
            return `${this.$t('campingPassVillageNotice')}`;
          } else if (this.isTipi) {
            return `${this.$t('campingPassTipiNotice')}`;
          }

          return '';
        },
        noDepartureDate() {
          return this.quote && this.quote.visit && !this.quote.visit.departureDate
        },
        stayType() {
          return this.quote && this.quote.visit ? this.quote.visit.stayType : null;
        },
        peopleLimitByStay() {
          if (!this.isCabin && !this.isTipi && !this.isVillages) {
            return 99;
          }

          const productKey = this.productsMap[this.stayType];
          const rentals = this.quote.products[productKey];
          return rentals * this.stayMaxCapacity;
        },
    },
    watch: {
      async 'step'() {
        if (this.step == 4) {
          await this.setNumberOfPersons(1);
          this.$refs.counter.setQuantity(this.quote.visit.numberOfPersons);
        }
      }
    },
    data() {
        return {
            timeoutObj: null,
            stayMaxCapacity: 6,
            productsMap: {
              CABIN: 'cabins',
              TIPI: 'tipis',
              LAKE_VILLAGE: 'lakeVillages'
            }
        }
    },
    mounted() {
        if(this.$refs.counter) this.initCounter();
        else setTimeout(() => this.initCounter, 200);
    },
    methods: {
        async setNumberOfPersons(newNumberOfPersons, options) {
            if(options) {
                if(options.action == 'sub') this.analyticsPeopleSubstract();
                else if(options.action == 'add') this.analyticsPeopleAdd();
                else if(options.action == 'manual') this.analyticsPeopleManual();
            }

            const gtmVisit = JSON.parse(JSON.stringify(this.quote.visit));

            const stayType = this.quote && this.quote.visit ? this.quote.visit.stayType : null;
            await this.$store.dispatch('updateVisit', { numberOfPersons: newNumberOfPersons, stayType });

            if (gtmVisit.numberOfPersons) {
                this.onAddToCartEvent();
            }
        },
        async addCabin() {
            let products = this.quote.products;
            products.cabins = products.cabins + 1;
            products.tipis = this.quote.products.tipis;
            products.lakeVillages = this.quote.products.lakeVillages;
            this.analyticsCabinAdd();
            await this.$store.dispatch('updateProducts', products);
        },
        async addTipi() {
          let products = this.quote.products;
          products.tipis = products.tipis + 1;
          products.cabins = this.quote.products.cabins;
          products.lakeVillages = this.quote.products.lakeVillages;
          this.analyticsTipiAdd();
          await this.$store.dispatch('updateProducts', products);
        },
        async addVillage() {
            let products = this.quote.products;
            products.lakeVillages = products.lakeVillages + 1;
            products.tipis = this.quote.products.tipis;
            products.cabins = this.quote.products.cabins;
            this.analyticsVillageAdd();
            await this.$store.dispatch('updateProducts', products);
        },
        analyticsCabinAdd() {
            this.onGenericEvent(`control aumentar cantidad cabañas`, { event_category: 'cotizador', event_label: `control` });
        },
        analyticsTipiAdd() {
          this.onGenericEvent(`control aumentar cantidad tiís`, { event_category: 'cotizador', event_label: `control` });
        },
        analyticsVillageAdd() {
            this.onGenericEvent(`control aumentar cantidad villas`, { event_category: 'cotizador', event_label: `control` });
        },
        initCounter() {
            this.$refs.counter.setQuantity(this.quote.visit.numberOfPersons);
        },
        analyticsPeopleSubstract() {
            this.onGenericEvent(`control disminuir cantidad personas`, { event_category: 'cotizador', event_label: `control` });
        },
        analyticsPeopleAdd() {
            this.onGenericEvent(`control aumentar cantidad personas`, { event_category: 'cotizador', event_label: `control` });
        },
        analyticsPeopleManual() {
            this.onGenericEvent(`input cantidad personas`, { event_category: 'cotizador', event_label: `input` });
        },
        onAddToCartEvent() {
            setTimeout(() => {
                this.$root.$emit('onAddToCartEvent', this.quote.productItems);
            }, 5000);
        },
        onGenericEvent(event, command) {
            this.$root.$emit('onGenericEvent', event, command);
        },
        needMoreRentals(stayType) {
          const rentals = this.quote.products[stayType];
          if (rentals && rentals > 0) {
            const numberOfPersons = this.quote.visit.numberOfPersons;
            const personsPerRentals = rentals * this.stayMaxCapacity;
            return numberOfPersons > personsPerRentals;
          }

          return false;
        }
    },
};
</script>

<style lang="scss" scoped>
.visit-nop {
    &__content {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr rem(100px);
        padding-right: rem(5px);
        @include respond-to('large and down') {
            grid-template-columns: auto rem(100px);
        }
    }

    &__persons {
        font-family: $font-secondary;
        text-transform: uppercase;
        letter-spacing: 0.05em;
    }
    &.mobile {
        .counter {
            align-self: start;
            padding-right: .5rem;
        }
    }
    &__need-more-cabins, &__need-more-tipis, &__need-more-villages {
        padding-top: 16px;
        letter-spacing: 0.05em;
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.5rem;
        display: grid;
        grid-template-columns: 1.5rem 1fr;
        grid-column-gap: .5rem;
        align-items: start;
        text-align: justify;

        &__max-people {
          color: $red-color2;
        }

        &__suggetion {
          color: $light-gray;
        }

        &__add {
            font-weight: 500;
            text-decoration: none;
            color: #0075FF;
            cursor: pointer;
            width: 200px;
            margin-left: 30px;
        }
    }
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
}
.notice {
  margin-top: 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.05em;
  color: $light-gray;
  text-align: justify;
}
</style>
