<template>
  <div class="container">
    <div class="buttons">
      <button :class="{ selected: isSelected(option) }" v-for="option in options" :key="option.id" @click.prevent="changeValue(option)">
        <div v-if="isSelected(option)">{{getIndex(option)}}</div>
        {{ option.label }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
    name: "RadioButtonMultipleSelection",
    props: {
        options: { type: Array, required: true },
        value: { type: Array, required: true },
        max: { type: Number, required: false }
    },
    methods: {
        changeValue(option) {
            if (this.isSelected(option)) {
                const index = this.value.indexOf(option.id);
                this.value.splice(index, 1);
            } else {
                if(this.value.length < this.max)
                  this.value.push(option.id);
            }
        },
        isSelected(option) {
            return this.value.includes(option.id);
        },
        getIndex(option) {
          return this.value.indexOf(option.id) + 1;
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
  display: grid;
  grid-template-columns: 1fr 0.5fr;

  @include respond-to("medium and down") {
    grid-template-columns: 1fr;
  }
}

.buttons {
    grid-column-gap: 16px;
    justify-content: start;
    display: grid;
    grid-template-columns: 1fr 1fr;

    @include respond-to("small and down") {
      grid-template-columns: 1fr;
    }

    button {
        display: flex;
        width: 100%;
        border: 1px solid $dark-brown-color;
        background-color: transparent;
        border-radius: 8px;
        color: $dark-brown-color;
        font-family: $font-primary;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.05em;
        height: 56px;
        align-items: center;
        cursor: pointer;
        padding: 16px;
        text-align: left;
        text-transform: none;

        @include respond-to("medium and down") {
          font-size: 12px;
        }

      div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 12px;
        min-width: 32px;
        min-height: 32px;
        text-align: center;
        color: #FFFFFF;
        background: #FF9900;
        border-radius: 16px;
      }

      &.selected {
            background: linear-gradient(0deg, rgba(255, 153, 0, 0.2), rgba(255, 153, 0, 0.2)), #FFFFFF;
            border: 1px solid $orange-color;
        }
    }

}
</style>
