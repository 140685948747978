var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vehicle"},[_c('h1',{staticClass:"vehicle__title"},[_vm._v(_vm._s(_vm.$t('expediteYourEntry')))]),_c('span',{staticClass:"vehicle__instructions"},[_vm._v(_vm._s(_vm.$t('fillTheFields')))]),_c('validation-observer',{ref:"validationObserver",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"vehicle__form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.saveEntry)}}},[(_vm.vehicle)?_c('fieldset',[_c('div',{staticClass:"vehicle__form__field"},[_c('div',{staticClass:"select"},[_c('validation-provider',{staticClass:"field vehicle__form__field__type",attrs:{"rules":"required","mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"type"}},[_vm._v(_vm._s(_vm.$t('vehicle.type'))),_c('span',{staticClass:"required"},[_vm._v("*")])]),_c('drop-down-refactor',{ref:"type",attrs:{"options":_vm.properties.vehicleTypes},model:{value:(_vm.vehicle.type),callback:function ($$v) {_vm.$set(_vm.vehicle, "type", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"vehicle.type"}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)}),(_vm.vehicle.type && _vm.vehicle.type.id && _vm.vehicle.type.id == 'OTHER')?_c('validation-provider',{key:_vm.keyForm + 1,staticClass:"field field-block other",attrs:{"rules":{required: !_vm.vehicleTypeIsPedestrian},"mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.vehicle.otherType),expression:"vehicle.otherType",modifiers:{"trim":true}}],ref:"otherType",attrs:{"maxlength":"100","id":"otherType","placeholder":_vm.$t('placeholder.otherVehicle'),"type":"text"},domProps:{"value":(_vm.vehicle.otherType)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.vehicle, "otherType", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)}):_vm._e()],1),_c('div',{key:_vm.keyForm + 2,staticClass:"select",class:{disabled: _vm.vehicleTypeIsPedestrian}},[_c('validation-provider',{staticClass:"field vehicle__form__field__brand",attrs:{"rules":{required: !_vm.vehicleTypeIsPedestrian},"mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"brand"}},[_vm._v(_vm._s(_vm.$t('vehicle.brand'))),_c('span',{staticClass:"required"},[_vm._v("*")])]),_c('drop-down',{ref:"brand",staticClass:"drop-down",attrs:{"options":_vm.properties.brands,"placeholder":"placeholder.selectBrand"},model:{value:(_vm.vehicle.brand),callback:function ($$v) {_vm.$set(_vm.vehicle, "brand", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"vehicle.brand"}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)}),(_vm.vehicle.brand && _vm.vehicle.brand == 'other')?_c('validation-provider',{staticClass:"field field-block other",attrs:{"rules":{required: !_vm.vehicleTypeIsPedestrian},"mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.vehicle.otherBrand),expression:"vehicle.otherBrand",modifiers:{"trim":true}}],ref:"otherBrand",attrs:{"maxlength":"100","id":"otherBrand","placeholder":_vm.$t('placeholder.otherBrand'),"type":"text"},domProps:{"value":(_vm.vehicle.otherBrand)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.vehicle, "otherBrand", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)}):_vm._e()],1),_c('div',{key:_vm.keyForm + 3,staticClass:"select",class:{disabled: _vm.vehicleTypeIsPedestrian}},[_c('validation-provider',{staticClass:"field vehicle__form__field__color",attrs:{"rules":{required: !_vm.vehicleTypeIsPedestrian},"mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"color"}},[_vm._v("Color"),_c('span',{staticClass:"required"},[_vm._v("*")])]),_c('drop-down',{ref:"color",staticClass:"drop-down",attrs:{"options":_vm.properties.colors,"placeholder":"placeholder.selectColor"},model:{value:(_vm.vehicle.color),callback:function ($$v) {_vm.$set(_vm.vehicle, "color", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"vehicle.color"}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)}),(_vm.vehicle.color && _vm.vehicle.color == 'OTHER')?_c('validation-provider',{staticClass:"field field-block other",attrs:{"rules":{required: !_vm.vehicleTypeIsPedestrian},"mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.vehicle.otherColor),expression:"vehicle.otherColor",modifiers:{"trim":true}}],ref:"otherColor",attrs:{"maxlength":"100","id":"otherColor","placeholder":_vm.$t('placeholder.otherColor'),"type":"text"},domProps:{"value":(_vm.vehicle.otherColor)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.vehicle, "otherColor", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)}):_vm._e()],1),_c('validation-provider',{key:_vm.keyForm + 4,staticClass:"field field-block",class:{disabled: _vm.vehicleTypeIsPedestrian},attrs:{"rules":{required: !_vm.vehicleTypeIsPedestrian},"mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"plate"}},[_vm._v(_vm._s(_vm.$t('vehicle.licensePlate'))),_c('span',{staticClass:"required"},[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.vehicle.plate),expression:"vehicle.plate",modifiers:{"trim":true}}],ref:"plate",attrs:{"maxlength":"100","id":"plate","type":"text"},domProps:{"value":(_vm.vehicle.plate)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.vehicle, "plate", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)}),_c('div',{key:_vm.keyForm + 5,staticClass:"select",class:{disabled: _vm.vehicleTypeIsPedestrian}},[_c('validation-provider',{staticClass:"field vehicle__form__field__brand",attrs:{"rules":{required: !_vm.vehicleTypeIsPedestrian},"mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"plateType"}},[_vm._v(_vm._s(_vm.$t('vehicle.licensePlates.type'))),_c('span',{staticClass:"required"},[_vm._v("*")])]),_c('drop-down',{ref:"brand",staticClass:"drop-down",attrs:{"options":_vm.properties.licenses,"placeholder":"placeholder.selectPlate"},model:{value:(_vm.vehicle.plateType),callback:function ($$v) {_vm.$set(_vm.vehicle, "plateType", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"vehicle.plateType"}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)}),(_vm.vehicle.plateType && _vm.vehicle.plateType == 'OTHER')?_c('validation-provider',{staticClass:"field field-block other",attrs:{"rules":{required: !_vm.vehicleTypeIsPedestrian},"mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.vehicle.otherPlateType),expression:"vehicle.otherPlateType",modifiers:{"trim":true}}],ref:"otherPlateType",attrs:{"maxlength":"100","id":"otherPlateType","placeholder":_vm.$t('placeholder.otherPlate'),"type":"text"},domProps:{"value":(_vm.vehicle.otherPlateType)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.vehicle, "otherPlateType", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)}):_vm._e()],1)],1)]):_vm._e(),_c('validation-provider',{staticClass:"vehicle__form__first-time",attrs:{"rules":"required","mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.vehicle.firstTime),expression:"vehicle.firstTime"}],attrs:{"type":"hidden","name":"firstTime","id":"firstTime"},domProps:{"value":(_vm.vehicle.firstTime)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.vehicle, "firstTime", $event.target.value)}}}),_c('span',{staticClass:"text_first_time"},[_vm._v(_vm._s(_vm.$t('itsYourFirstVisit'))+" "),_c('span',{staticClass:"required"},[_vm._v("*")])]),_c('div',{staticClass:"text-wrapper"},[_c('span',{staticClass:"sp option",class:{'option--selected': _vm.vehicle.firstTime === true},on:{"click":function($event){_vm.vehicle.firstTime = true}}},[_vm._v(_vm._s(_vm.$t('affirmative')))]),_c('span',{staticClass:"separator"}),_c('span',{staticClass:"option",class:{'option--selected': _vm.vehicle.firstTime === false },on:{"click":function($event){_vm.vehicle.firstTime = false}}},[_vm._v(_vm._s(_vm.$t('negative')))])]),_c('span',{staticClass:"error"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)}),_c('button',{staticClass:"btn btn--primary vehicle__form__action",class:{ disabled: invalid }},[_vm._v(_vm._s(_vm.$t('sendInfo')))])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }