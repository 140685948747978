<template>
    <modal ref="modal" v-show="isOpen">
        <div class="modal__content process-payment">
            <div class="process-payment__content">
                <div class="process-payment__content__loading loadingio-spinner-rolling-xuexty8lwx"><div class="ldio-24f7an7dik8"><div></div></div></div>
                <h1 class="process-payment__content__title">{{$t('modal.processingPayment.title')}}</h1>
                <div class="process-payment__content__description">
                    <span>{{$t('modal.processingPayment.description1')}}</span>
                    <span>{{$t('modal.processingPayment.description2')}}</span>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from '@/components/modal/Modal'

    export default {
    name: 'ProcessingPaymentModal',
        components: { Modal },
        props: [],
        data() {
            return {
                isOpen: false,
            }
        },
        computed: {},
        mounted() {},
        methods: {
            openModal() {
                this.$refs.modal && this.$refs.modal.open();
                this.isOpen = true;
                window.addEventListener("keydown", this.blockKeys);
            },
            closeModal() {
                this.$refs.modal && this.$refs.modal.close();
                this.isOpen = false;
                window.removeEventListener("keydown", this.blockKeys);
            },
            blockKeys(event) {
              event.preventDefault();
            },
        },
        beforeDestroy() {
          window.removeEventListener("keydown", this.blockKeys);
        },
    }
</script>

<style lang="scss" scoped>
.process-payment {
    background-color: white;
    color: $dark-brown-color;
    border-radius: 1rem;
    max-width: rem(467px);
    padding: 1.5rem;
    letter-spacing: 0.05em;
    &__content {
        display: grid;
        grid-row-gap: 1.5rem;
        justify-items: center;
        text-align: center;
        &__title {
            font-family: $font-secondary;
            font-weight: normal;
            font-size: 1.5rem;
            line-height: rem(33px);
        }
        &__description {
            display: grid;
            font-family: $font-primary;
            font-weight: 500;
            font-size: 1rem;
            line-height: 1.5rem;
            color: $dark-brown-color;
        }
    }
    @include respond-to("medium and down"){
        max-width: rem(343px);
        padding: 1rem 1.5rem;
        &__content {
            grid-row-gap: 1rem;
            &__description {
                line-height: 1.5rem;
            }
        }
    }
}

@keyframes ldio-24f7an7dik8 {
  0% { transform: translate(-50%,-50%) rotate(0deg); }
  100% { transform: translate(-50%,-50%) rotate(360deg); }
}
.ldio-24f7an7dik8 div {
  position: absolute;
  width: 2.5rem;
  height: 2.5rem;
  border: 5px solid #ff9900;
  border-top-color: transparent;
  border-radius: 50%;
}
.ldio-24f7an7dik8 div {
  animation: ldio-24f7an7dik8 1s linear infinite;
  top: 2rem;
  left: 2rem
}
.loadingio-spinner-rolling-xuexty8lwx {
  width: 4rem;
  height: 4rem;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-24f7an7dik8 {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
}
.ldio-24f7an7dik8 div { box-sizing: content-box; }
</style>
