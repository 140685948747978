<template>
  <products-water selected-product="divingBoardPool">
    <div class="product-summary__details" v-if="currentProduct && information">
      <gallery>
        <template slot="small-image">
          <img :src="require('@/assets/products/diving-board-pool/Trampolin_' + gallery[0] + '.jpg')" alt="">
        </template>
        <template slot="gallery-images">
          <img class="slide" v-for="(picture, i) in gallery" :key="i" :src="require('@/assets/products/diving-board-pool/Trampolin_' + picture + '.jpg')" alt="">
        </template>
      </gallery>
      <div class="product-summary__details__title">
        <h1>{{ $t(currentProduct.title) }}</h1>
      </div>
      <div class="product-summary__details__content">
        <div class="product-summary__details__content__information">
          <div class="product-summary__details__content__information__description">
            <p>{{ $t(information[currentProduct.id].description.text1) }}</p>
          </div>
        </div>
      </div>
    </div>
  </products-water>
</template>

<script>
  import Gallery from '@/components/Gallery';
  import ProductsWater from '@/views/products/ProductsWater';

  const information = {
    divingBoardPool: {
      description: {
        text1: 'productPages.divingBoardPool.text1'
      }
    }
  }

  export default {
    name: 'DivingBoardPool',
    components: {ProductsWater, Gallery},
    metaInfo() {
      return {title: this.$t("tabs.divingBoardPool")}
    },
    data() {
      return {
        currentProduct: null,
        gallery: [1,2,3]
      }
    },
    computed: {
        information() {
            return information;
        }
    },
    mounted() {
      this.currentProduct = { id: 'divingBoardPool', title:'divingBoardPool', imageName: 'diving-board-pool' };
    },
    methods: {}
  };
</script>

<style lang="scss" scoped>
  .product-summary {
    &__details {
      &__content {
        &__services {
          height: rem(250px);
        }
      }
    }
  }
</style>