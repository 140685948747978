<template>
    <modal ref="modal" class="modal" v-show="isOpen" @close="updateChooseCabinVisible(false)">
        <div class="modal__content choose-cabins-modal">
            <div class="choose-cabins-modal__header">
                <h1 class="choose-cabins-modal__header__title">{{$t('modal.chooseReservation.pickYourCabin')}}</h1>
                <a href="" class="icon" @click.prevent="updateChooseCabinVisible(false)" ><img src="@/assets/deletesign.svg"></a>
            </div>
            <ul class="choose-cabins-modal__info-marks">
                <li><img src="@/assets/reservations/selected.svg" alt=""><span>{{$t('modal.chooseReservation.selected')}}</span></li>
                <li><img src="@/assets/reservations/available.svg" alt=""><span>{{$t('modal.chooseReservation.available')}}</span></li>
                <li><img src="@/assets/reservations/occupied.svg" alt=""><span>{{$t('modal.chooseReservation.occupied')}}</span></li>
            </ul>
            <div class="choose-cabins-modal__choose-cabin">
                <choose-cabin ref="chooseCabin" :cabin-list="cabins" @valid="validateForm"></choose-cabin>
            </div>
            <div class="choose-cabins-modal__button">
                <unavailable-reservation class="unavailable"></unavailable-reservation>
                <button class="button" :class="{disabled: !validForm}" @click.prevent="$refs.chooseCabin.save()">{{$t('modal.chooseReservation.save')}}</button>
            </div>
        </div>
    </modal>
</template>

<script>
    import Modal from '@/components/modal/Modal';
    import ChooseCabin from "@/components/reservations/ChooseCabin";
    import UnavailableReservation from '@/components/UnavailableReservation';

    export default {
    name: 'ChooseCabinModal',
        components: { Modal, ChooseCabin, UnavailableReservation},
        data() {
            return {
                isOpen: false,
                validForm: false
            }
        },
        watch: {
            '$store.getters.chooseCabinVisible'() {
                this.$store.getters.chooseCabinVisible ? this.openModal() : this.closeModal();
            }
        },
        mounted() {},
        computed: {
            dates() {
                if (this.quote) {
                    return {startDate: this.quote.visit.arrivalDate, endDate: this.quote.visit.departureDate}
                }
                return null;
            },
            quote() {
                return this.$store.getters.quote;
            },
            cabins() {
                return this.$store.getters.reservationsAvailable && this.$store.getters.reservationsAvailable.cabins;
            },
            unavailableCabins() {
                return this.$store.getters.unavailableCabins;
            },
            unavailableTipis() {
                return this.$store.getters.unavailableTipis;
            },
            unavailableReservations() {
                return this.unavailableCabins && this.unavailableCabins.length > 0 || this.unavailableTipis && this.unavailableTipis.length > 0;
            }
        },
        methods: {
            openModal() {
                this.$store.dispatch('updateReservationAvailableFlag');
                this.$refs.modal && this.$refs.modal.open();
                this.isOpen = true;
                this.validateForm();
            },
            closeModal() {
                this.$refs.modal && this.$refs.modal.close();
                this.isOpen = false;
            },
            updateChooseCabinVisible(val) {
                setTimeout(() => {
                    this.$store.dispatch('updateChooseCabinVisible', val);
                }, 500);
            },
            validateForm(value) {
                this.validForm = value;
            }
        }
    }
</script>


<style lang="scss" scoped>
.modal {
    margin-top: 1rem;
    margin-bottom: 1rem;
    max-height: calc(100% - 2rem);
}
.choose-cabins-modal {
    max-width: rem(698px);
    border-radius: 1rem;
    background-color: white;
    padding: 1.5rem;
    display: grid;
    grid-row-gap: 1rem;
    &__header {
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;
        h1 {
            font-size: 1.5rem;
            line-height: 33px;
            padding-bottom: 5px;
        }
    }
    &__choose-cabin {
        padding-top: 2px;
    }
    ul {
        display: grid;
        grid-template-columns: repeat(3, min-content);
        grid-column-gap: 1.5rem;
        align-items: center;
        li {
            display: grid;
            grid-template-columns: 1rem 1fr;
            align-items: center;
            grid-column-gap: .5rem;
        }
    }
    &__button {
        justify-self: end;
        width: 100%;
        display: grid;
        grid-template-areas: "unavailable button";
        grid-template-columns: 1fr rem(192px);
        grid-column-gap: 1.5rem;
        justify-items: end;
        padding-top: rem(4px);
        .unavailable {
            grid-area: unavailable;
        }
        button {
            grid-area: button;
            width: 100%;
            max-width: rem(192px);
            font-size: rem(20px);
            letter-spacing: 0.05em;
            cursor: pointer;
        }
    }
    @include respond-to("small and down") {
        max-width: rem(343px);
        height: 100%;
        max-height: rem(851px);
        padding: 1rem;
        grid-row-gap: .5rem;
        overflow: hidden;
        ul {
            grid-template-columns: 1fr;
            grid-template-rows: repeat(3, min-content);
            grid-row-gap: .5rem;
        }
        &__choose-cabin {
            height: 100%;
            overflow-y: scroll;
        }
        &__button {
            grid-template-areas: "unavailable" "button";
            grid-template-columns: 1fr;
            grid-row-gap: 1rem;
            button {
                max-width: 100%;
            }
        }
    }
}

.ios {
    .choose-cabins-modal {
        @include respond-to("small and down") {
            &__choose-cabin {
                height: auto;
            }
        }
    }
}
</style>
