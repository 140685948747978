<template>
    <div class="mini-loader">
        <div class="mini-loader__indicator">
            <div class="mini-loader__indicator__spinner">
                <div></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Miniloader',
}
</script>

<style lang="scss" scoped>
.mini-loader {
    &__indicator {        
        @keyframes spinner {
            0% { transform: translate(-50%,-50%) rotate(0deg); }
            100% { transform: translate(-50%,-50%) rotate(360deg); }
        }

        width: 4rem;
        height: 4rem;
        display: inline-block;
        overflow: hidden;
        background: none;

        &__spinner {
            width: 100%;
            height: 100%;
            position: relative;
            transform: translateZ(0) scale(1);
            backface-visibility: hidden;
            transform-origin: 0 0;

            div {
                box-sizing: content-box;
                animation: spinner 1s linear infinite;
                top: 2rem;
                left: 2rem;
                position: absolute;
                width: 2.5rem;
                height: 2.5rem;
                border: 5px solid #ff9900;
                border-top-color: transparent;
                border-radius: 50%;
            }
        }

    }
}

</style>
