<template>
  <div class="drop-down" >
    <li ref="status" class="select">

      <input id="search" type="text" :value="city" @input="city = $event.target.value" @keyup="search" @keydown.delete="onDelete">
        <ul v-if="origins.length > 0" class="option-list">
            <li class="option" v-for="origin in origins" :value="origin.city" :key="origin.id" @click="selectOrigin(origin)">
              <div><strong >{{ city | capitalize }}</strong>{{ splitRecommendation(origin.city)}}<span v-if="origin.county && origin.county !== 'Nevada' && origin.county !== 'Las Vegas'">, {{origin.county}}</span></div>
            </li>
        </ul>
    </li>
  </div>
</template>
<script>

export default {
    name: "dropDown",
    props: [ 'country', 'state'],
    data() {
        return { 
            showList: false,
            city: null,
            origins: [],
            selectedOrigin: null,
            cities: {
              nevada: {
                code: 'NV',
                label: 'Nevada'
              },
              vegas: {
                code: 'LV',
                label: 'Las Vegas'
              }
            }
        }
    },
    mounted() {},
    watch: {
        'country'() {
          if(this.country.code !== 'USA') {
            this.closeList();
          }
        },
        'state'() {
          this.closeList();

        }
    },
    methods: {
      selectOrigin (origin) {
          this.selectedOrigin = origin;
          this.$emit("onSelectOrigin", origin);
          this.closeList(origin);
        },
      closeList(origin) {
        this.city = origin ? origin.city + ', ' + origin.county : null;
        this.origins = [];
      },
      async search() {
        this.origins = [];
        const data = { country: 'USA', city: this.city, state: this.state.label }
        if(this.selectedOrigin && this.origins.length <= 0) {
          this.selectedOrigin = null;
          this.$emit("onSelectOrigin", null);
          this.city = '';
        }
        if(this.state.id !== 'other' && this.state.code !== this.cities.nevada.code) {
          if (this.city && this.city.length > 2) {
            this.origins = await this.$store.dispatch('getOriginCities', { data: data });
          } else {
            this.$emit("onSelectOrigin", null);
          }
        } else {
          let selectedCity = this.city;
          if(this.state.code === this.cities.nevada.code && this.city && this.city.toLowerCase() !== this.cities.vegas.label.toLowerCase())
            selectedCity = this.cities.nevada.label;
          this.$emit("onSelectOrigin", selectedCity);
        }
      },
      onDelete(){
        if(this.origins.length <= 0) {
          this.$emit("onSelectOrigin", null);
          this.city = '';
        }
      },
      splitRecommendation(recommendation) {
        return recommendation.substring(this.city.length);
      },
    },
    filters: {
        capitalize(word) {
          if(!word || word.length <= 0)
            return '';
          const words = word.split(" ");
          return words.map((word) => {
            if(word[0]) return word[0].toUpperCase() + word.substring(1);
          }).join(" ");
        }
    }
};
</script>

<style scoped lang="scss">
    .drop-down {
      letter-spacing: 0.05em;
      font-family: $font-primary;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      a, span { font-weight: 500 }
      .overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
      }
    }
    .select {
        width: 100%;
        height: 3rem;
        border-radius: .5rem;
        -moz-border-radius: .5rem;
        -webkit-border-radius: .5rem;
        list-style: none;
        font-size: 1rem;
        letter-spacing: 0.05em;
        position: relative;
        input {
            width: 100%;
            height: 100%;
            padding: rem(8px) rem(13px);
            display: grid;
            grid-template-columns: 1fr 1rem;
            align-items: center;
            text-decoration: none;
        }
        .option-list {
            z-index: 1;

            width: 100%;
            overflow-y: auto;
            font-weight: normal;
            top: 3.1em;
            border-radius: .5rem;
            -moz-border-radius: .5rem;
            -webkit-border-radius: .5rem;
            border: solid 1px #{$brown-opacity-2-color};
            position: absolute;
            overflow-x: hidden;
            max-height: 15rem;
            .option {
              padding: 16px;
              line-height: auto;
              width: 100%;
              height: rem(45px);
              color: $dark-brown-color;
              background-color: white;
              cursor: pointer;

              strong {
                color: #7E7E7E;
              }
              span {
                  font-weight: 500;
              }
              &:hover {
                  background-color:#f1f1f1;
              }
            }
        }
    }
    .drop-down-error > .select {
        border: 1px solid $red-color2 !important;
    }
    .icon {
        margin-left: 4px;
    }

</style>