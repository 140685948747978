<template>
  <products-air selected-product="canopyTour">
    <div class="product-summary__details" v-if="currentProduct && information">
      <gallery>
        <template slot="small-image">
          <img :src="require('@/assets/products/canopy/canopy_' + gallery[0] + '.jpg')" alt="">
        </template>
        <template slot="gallery-images">
          <img class="slide" v-for="(picture, i) in gallery" :key="i" :src="require('@/assets/products/canopy/canopy_' + picture + '.jpg')" alt="">
        </template>
      </gallery>
      <div class="product-summary__details__title">
        <h1>{{ $t(currentProduct.title) }}</h1>
      </div>
      <add-to-cart class="product-summary__details__add" :product="information[currentProduct.id]"></add-to-cart>
      <div class="product-summary__details__content">
        <div class="product-summary__details__content__information">
          <div class="product-summary__details__content__information__description">
            <p v-for="(text, index) in information[currentProduct.id].description" :key="index">{{ $t(text) }}</p>
          </div>
        </div>
      </div>
    </div>
  </products-air>
</template>

<script>
  import Gallery from '@/components/Gallery';
  import ProductsAir from '@/views/products/ProductsAir';
  import AddToCart from '@/views/components/AddToCart';

  const information = {
    canopyTour: {
      description: {
        text1: 'productPages.canopyTour.text1',
        text2: 'productPages.canopyTour.text2',
        text3: 'productPages.canopyTour.text3',
        text4: 'productPages.canopyTour.text4'
      },
      info: 'productPages.canopyTour.info'
    }
  }

  export default {
    name: 'CanopyTour',
    components: {ProductsAir, AddToCart, Gallery},
    metaInfo() {
      return {title: this.$t("tabs.canopyTour")}
    },
    data() {
      return {
        currentProduct: null,
        gallery: [1, 2, 3, 4, 5, 6]
      }
    },
    computed: {
        information() {
            return information;
        }
    },
    mounted() {
      this.currentProduct = { id: 'canopyTour', title:'canopyTour', imageName: 'canopy-tour' };
    },
    methods: {}
  };
</script>

<style lang="scss" scoped>
  .product-summary {
    &__details {
      &__content {
        &__services {
          height: rem(250px);
        }
      }
    }
  }
</style>