<template>
    <div class="container">
        <p class="details__descriptio">
            {{ $t('termsConditions.title') }} 
            <router-link class="details__links" :to="'/terms'" target="_blank">{{ $t('termsConditions.termsPage')}}</router-link> 
            {{ $t('termsConditions.and')}}
            <router-link class="details__links" :to="'/privacy-notice'" target="_blank"> {{ $t('termsConditions.privacyNoticePage') }}.</router-link>
        </p>
    </div>
</template>

<script>
export default {
    name: 'TermsConditions',
}
</script>

<style lang="scss" scoped>
    .container {
        background: #FFFFFF;
        opacity: 0.8;
        border: 1px solid rgba(10, 15, 0, 0.2);
        box-sizing: border-box;
        border-radius: rem(16px);
        margin-top: rem(23px);
        letter-spacing: 0.062em;
        @include respond-to("small and down"){
            margin-top: 0;
            margin-bottom: rem(24px);
        }
    }

    .details {
        &__descriptio {
            font-family: $font-primary;
            font-size: $base-size;
            line-height: 24px;
            color: #1E0F00;
            font-weight: 500;
            padding: 1.5rem;
            @include respond-to("small and down"){
                padding: 1rem;
            }
        }
        &__links {
            text-decoration: none;
            color: $blue-great-color;
            font-weight: 500;
        }
    }
</style>