<template>
  <div class="drop-down" :class="{'no-click': !options}">
    <li ref="status" class="select">
        <a class="option-selected" @click="openList()" :class="{open: showList}">
            <span>{{ currentSelect ? $t(currentSelect.label) : "Select one" }}<img class="icon" v-if="currentSelect && currentSelect.icon" :src="getImgUrl(currentSelect.icon)"></span>
            <img src="@/assets/dropdown-arrow--black.svg" alt="">
        </a>
        <div v-show="showList" class="overlay" @click="closeList()"></div>
        <ul v-if="showList" class="option-list">
            <a class="option" v-for="option in availableOptions" :value="option.id" :key="option.id" @click="selectOption(option)"> 
                <div v-if="option" :title="$t(option.label)">
                    <span>{{ $t(option.label)}}</span>
                </div>
            </a>
        </ul>
    </li>
  </div>
</template>
<script>

export default {
    name: "dropDown",
    props: [ 'select', 'options' ],
    data() {
        return { 
            showList: false,
            currentSelect: {},
        }
    },
    computed: {
        availableOptions() {
            if(!this.currentSelect) {
                return this.options;
            }
            let opts = [];
            Object.keys(this.options).forEach(key => {
                if(this.options[key].id != this.currentSelect.id){
                    opts.push(this.options[key]);
                }
            });
            return opts;
        }
    },
    methods: {
        selectOption (optionChanged) {
            this.$set(this, 'currentSelect', optionChanged);
            this.$emit("input", optionChanged);
            this.$emit("change");
            this.closeList();
        },
        openList() {
          this.showList = true;
        },
        closeList() {
          this.showList = false;
        }
    }
};
</script>
<style scoped lang="scss">
    .no-click {
        pointer-events: none;
    }
    .option-selected {
        color: black;
    }
    .drop-down {
      z-index: 2;
      letter-spacing: 0.05em;
      font-family: $font-primary;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      a, span { font-weight: 500 }
      .overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
      }
    }
    .select {
        width: 100%;
        height: 3rem;
        border-radius: .5rem;
        -moz-border-radius: .5rem;
        -webkit-border-radius: .5rem;
        border: solid 1px #{$brown-opacity-2-color};
        list-style: none;
        font-size: 1rem;
        letter-spacing: 0.05em;
        position: relative;
        a {
            width: 100%;
            height: 100%;
            padding: rem(8px) rem(13px);
            display: grid;
            grid-template-columns: 1fr 1rem;
            align-items: center;
            text-decoration: none;
            span, img { pointer-events: none }
            &:not(.open) > img {
              -moz-transform: rotate(180deg);
              -o-transform: rotate(180deg);
              -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
            }
        }
        .option-list {
            width: 100%;
            overflow-y: auto;
            font-weight: normal;
            top: 3em;
            border-radius: .5rem;
            -moz-border-radius: .5rem;
            -webkit-border-radius: .5rem;
            z-index: 1;
            border: solid 1px $border-black-opacity;
            position: absolute;
            .option {
                line-height: auto;
                width: 100%;
                height: rem(45px);
                color: black;
                background-color: white;
                cursor: pointer;
                &:hover {
                    background-color:#f1f1f1;
                }
            }
        }
    }

    .icon {
        margin-left: 4px;
    }

    .company {
        .select {
            border: none;
            .option-selected {
                .triangle {
                    visibility: hidden;
                }
            }
            &:hover {
                background-color: #F1F1F1;
                border-radius: 3px;
                .option-selected {
                    .triangle {
                        visibility: visible;
                    }
                }   
            }
        }
        .icon {
            margin-left: 8px;
        }
    }

</style>