import Vue from 'vue';
import moment from 'moment';

Vue.filter('date', function (date) {
    if(!date) return '';
    let customDate = moment(date);
    let customDateValue = customDate.format("ddd").replace('.', '') + ' ' + customDate.format("DD MMM YYYY").replace('.', '');
    return customDateValue.charAt(0).toUpperCase() + customDateValue.slice(1);
});

Vue.filter('dateTypeB', function (date, locale = 'es') {
    if(!date) return '';
    return moment(date).locale(locale).format(locale == 'es' ? 'dddd DD MMM YYYY' : 'dddd MMM DD, YYYY').replace('.', '');
});

Vue.filter('gtmDate', function (date) {
    if(!date) return '';
    moment.locale('es');
    const customDate = moment(date);
    const customDateValue = customDate.format("ddd").replace('.', '') + ' ' + customDate.format("DD MMM YYYY").replace('.', '');
    return customDateValue.toUpperCase();
});