<template>
    <div v-if="quote && (quote.visit.numberOfDays > 1 || promotion)" href="" class="visit-promotion" :class="{'apply3x2': quote && quote.offer3x2 || promotion, 'promotion': promotion}">
        <div v-if="promotion" class="visit-promotion__first-section promo">
            <img src="@/assets/true--icon.svg"/>
            <div class="message">
                <span class="title">{{ $t('promotionTitle') }}</span>
                <span class="code">{{promotion.code}}</span>
            </div>
            <a href="" v-if="promotion" @click.prevent="applyPromotion('promo')" class="visit-promotion__action cancel">{{$t('cancel')}}</a>
        </div>
        <div class="visit-promotion__first-section" :class="{'promotion-applied': quote && quote.offer3x2}" v-if="!promotion">
            <img src="@/assets/true--icon.svg" alt="" v-show="quote && quote.offer3x2">
            <span class="visit-promotion__title" v-if="!promotion">{{ $t('promotion3x2') }}</span>
            <a href="" v-if="quote.offer3x2" @click.prevent="applyPromotion('offer')" class="visit-promotion__action cancel">{{$t('cancel')}}</a>
            <a href="" v-else @click.prevent="applyPromotion('offer')" class="visit-promotion__action">{{$t('apply')}}</a>
        </div>
        <div class="visit-promotion__second-section" :class="{'visit-promotion__second-section-promo': promotion}">
            <div class="content" v-if="promotion">
                <span class="third-column two-rows special" >
                    <span>{{ itemPricePerPerson | currency}}</span>
                    <span>{{ promotionPricePerPerson | currency}} {{ $t('perPerson') }}</span>
                </span>
            </div>
            <div class="content" v-else>
                <span v-if="promotion">{{$t('promoIsBeingApplied')}}</span>
                <span v-else>{{ $t('promotion3x2Description') }} <a href="" @click.prevent="showConditions3x2()">{{$t('seeConditions')}}</a></span>
            </div>
        </div>

    </div>
</template>
<script>
import {mapState} from 'vuex';

export default {
    name: 'VisitPromotion',
    props: ['promotion'],
    data() {
        return {
            checkPromotion: false
        }
    },
    computed: {
        ...mapState(['quote']),
        itemPricePerPerson() {
            let items = this.quote ? this.quote.items : [];
            let dayPass = items.find(i => i.product.key === "day_pass");
            let nigthPass = items.find(i => i.product.key === "camping_pass");
            let totalPerPerson = 0;
            if(dayPass && dayPass.totalAmount && dayPass.quantity) {
            totalPerPerson+= this.getPricePerItem(dayPass);
            }
            if(nigthPass && nigthPass.totalAmount && nigthPass.quantity) {
            totalPerPerson+= this.getPricePerItem(nigthPass);
            }
            return totalPerPerson;
        },
        promotionPricePerPerson() {
            let pricePerPerson = 0;
            let promotion = this.quote ? this.quote.promotion : null;
            let dayPass = this.getItemByKey('day_pass');
            if (promotion) {
                let dayPasses = (dayPass && dayPass.quantity ? dayPass.quantity : 0);
                pricePerPerson+= (dayPasses > 0 ? 1 : 0) * (promotion.pricePerDay ? promotion.pricePerDay : 0);
                pricePerPerson+= this.quote.visit.numberOfDays * ((promotion.pricePerDay ? promotion.pricePerDay : 0) + (promotion.pricePerNight ? promotion.pricePerNight : 0));
            }
            return pricePerPerson;
        }
    },
    methods: {
        async applyPromotion(type) {
            if(type == 'offer') {
                let offer3x2 = this.quote && this.quote.offer3x2 ? false : true;
                await this.$store.dispatch('updatePromotion3x2', offer3x2);
                if (offer3x2) {
                    this.analyticsAddPromotion();
                }
            } else {
                let data = {
                    promotionId: this.promotion.id,
                    quoteId: this.quote.id
                }
                try {
                    this.$store.dispatch('cancelPromotion', data);
                } catch (e) {
                    return e;
                }
            }
        },
        analyticsAddPromotion() {
            this.onGenericEvent(`aplicar promoción`, { event_category: 'cotizador', event_label: 'cta - aplicar promoción' });
        },
        showConditions3x2() {
            this.$store.dispatch('showConditions3x2');
        },
        onGenericEvent(event, command) {
            this.$root.$emit('onGenericEvent', event, command);
        },
        getPricePerItem(item) {
            return (item && item.quantity && item.totalAmount) ? item.totalAmount / item.quantity : 0;
        },
        getItemByKey(key) {
            let item = this.quote && this.quote.items.find(item => item.product.key === key);
            return item ? {quantity: item.quantity, totalAmount: item.totalAmount } : null;
        },
    }
};
</script>
<style lang="scss" scoped>
.visit-promotion {
    letter-spacing: 0.05em;
    border: 1px dashed $brown-opacity-2-color;
    border-radius: 1rem;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    padding: rem(16px) 1rem;
    &.apply3x2 {
        grid-template-columns: auto 1.05fr;
        border: 1px dashed #049569;
        box-sizing: border-box;
        border-radius: rem(16px);
        background: linear-gradient(
            0deg,
            rgba(28, 225, 119, 0.1),
            rgba(28, 225, 119, 0.1)
        ),
        #ffffff;
    }
    &__first-section {
        display: grid;
        grid-template-columns: 1fr 1fr;
        span {
            font-family: $font-secondary;
            text-transform: uppercase;
        }
        a {
            padding-right: 1rem;
            text-align: right;
        }
    }
    .promo {
        .message {
            display: grid;
            grid-template-rows: auto auto;
            row-gap: 6px;
        }
        .title {
            font-family: $font-primary;
            text-transform: capitalize;
        }
    }
    .promotion-applied {
        display: grid;
        grid-template-columns: 24px 123px 1fr;
        gap: 16px;
        align-items: center;
        span {
            font-family: $font-secondary;
            text-transform: uppercase;
            padding-top: 5px;
        }
        a {
            padding-right: 1rem;
            text-align: right;
            padding-top: 5px;
        }
    }
    &__second-section {
        padding-left: 1rem;
        border-left: 1px solid $brown-opacity-color;
        font-size: 12px;
        .content {
            width: 243px;
        }
        .third-column {
            grid-area: third-column;
            display: grid;
            grid-row-gap: 6px;
            font-weight: 500;
            &.special {
                text-transform: uppercase;
                :first-child {
                    opacity: 0.6;
                    font-weight: 500;
                    text-decoration-line: line-through;
                }
                :last-child {
                    font-family: $font-secondary;
                    font-size: 16px;
                    @include respond-to("small and down"){
                    font-size: 16px;
                    }
                }
                @include respond-to("small and down"){
                    padding-bottom: 1rem;
                    line-height: 22px;
                    grid-row-gap: 0px;
                    margin-top: 0.5rem;
                }
            }
        }
    }
    &__first-section, &__second-section {
        a {
            font-weight: 500;
            text-decoration: none;
            color: $blue-great-color;
            &.cancel {
                color: $red-color2;
                text-decoration-line: underline;
            }
        }
    }
    &.promotion {
        grid-template-columns: min-content 3fr;
        .visit-promotion {
            &__first-section {
                grid-template-columns: 1rem 144px 1fr;
                grid-column-gap: .5rem;
                align-items: center;
                padding-right: rem(21px);
            }
            &__second-section { line-height: 1.2rem;}
        }
    }
    @include respond-to("medium and down") {
        grid-template-columns: 1fr;
        grid-row-gap: 1rem;
        &__first-section {
            align-items: center;
            a { padding: 0 }
            padding-bottom: 1rem;
            border-bottom: 1px solid $brown-opacity-color;
        }
        &__second-section {
            padding-left: 0;
            border-left: none;
            line-height: 1rem;
        }
        &.promotion {
            grid-template-columns: 1fr;
            .visit-promotion {
                &__first-section {
                    grid-template-columns: 1.5rem auto;
                    grid-column-gap: .5rem;
                    align-items: center;
                    padding-right: rem(21px);
                }
                &__second-section { line-height: 1.2rem;}
            }
        }
    }
}
</style>