<template>
  <div class="drop-down" ref="option" :class="{'no-click': !options}">
      <div class="drop-down__current" :class="{open: showList}" @click="openOptions()">
        <input class="drop-down__input"
            type="text"
            :value="currentOption.text"
            @input="updateValue($event.target.value)">
      </div>
      <div v-if="showList && filteredOptions.length !== 0" class="drop-down__list">
        <a href="" class="drop-down__list__option" v-for="(option, index) in filteredOptions" :key="index" @click.prevent="selectOption(option)" :class="{ hide: currentOption && currentOption.id == option.id}" >
          <span>{{ $t(option.text) }}</span>
        </a>
      </div>
  </div>
</template>

<script>

export default {
    name: "dropDown",
    props: ['currentOptions', 'options', 'placeholder'],
    data() {
        return {
            showList: false,
            currentOption: {
                text: ""
            },
        }
    },
    computed: {
        filteredOptions() {
            let options = this.options.filter(option => {
                return this.$t(option.text).toLowerCase().includes(this.currentOption.text.toLowerCase());
            });

            return options.sort((a, b) => {
                let optionA = this.$t(a.text).toUpperCase();
                let optionB = this.$t(b.text).toUpperCase();

                return optionA < optionB ? -1 : optionA > optionB ? 1 : 0;
            });
        }
    },
    mounted() {},
    methods: {
        openOptions() {
            setTimeout(() => {
                if(this.showList) {
                    document.removeEventListener('click', this.onClick, false);
                    document.removeEventListener("keyup", this.closeDropDown, false);
                } else {
                    document.addEventListener('click', this.onClick);
                    document.addEventListener("keyup", this.closeDropDown);
                }
                this.showList = !this.showList;
            } , 100);
        },
        updateValue(value) {
            this.currentOption.text = value;
            let tmp = this.options.find(option => {
                return this.$t(option.text).toLowerCase() === value.toLowerCase() || option.id.toLowerCase() === value.toLowerCase();
            });
            if (tmp) {
                value = tmp.id;
            }
            this.$emit('input', value);
        },
        selectOption (option) {
            if(this.$refs.option) {
                this.currentOption.text = this.$t(option.text);
                this.$emit("input", option.id);
                this.$emit("change");
                this.showList = false;
            }
        },
        onClick: function (evt) {
            if(this.$el && !this.$el.contains(evt.target)) {
                this.close();
            }
        },
        closeDropDown: function (evt) {
            if (evt.keyCode === 27) {
                this.close();
            }
        },
        close() {
            document.removeEventListener('click', this.onClick, false);
            document.removeEventListener("keyup", this.closeDropDown, false);
            this.$emit("change");
            this.showList = false;
        },
        init() {
            this.currentOption = {};
            this.close();
            this.selectOption();
        }
    }
};
</script>
<style scoped lang="scss">
    .no-click {
        pointer-events: none;
    }
    .no-select {
        color: $brown-opacity-color;
    }
    .drop-down {
        height: 3rem;
        width: 100%;
        position: relative;
        &__input {
            height: rem(48px);
            font-family: $font-primary;
            font-weight: 500;
            font-size: 16px;
            line-height: 16px;
            letter-spacing: 0.05em;
            color: $dark-brown-color;
            background: white;
            border: 1px solid rgba(30, 15, 0, 0.2);
            border-radius: 8px;
            padding: rem(16px);
        }
        a {
            height: 3rem;
            width: 100%;
            text-decoration: none;
            display: grid;
            grid-template-columns: 1fr .5rem;
            align-items: center;
            color: #1E0F00;
            cursor: pointer;
            font-family: $font-primary;
            font-weight: 500;
            font-size: 1rem;
            line-height: 1rem;
            align-items: center;
            &:not(.open) > .arrow {
                -moz-transform: rotate(-180deg);
                -o-transform: rotate(-180deg);
                -webkit-transform: rotate(-180deg);
                transform: rotate(-180deg);
            }
            &.hide {
                display: none;
            }
        }
        &__list {
            background-color: white;
            width: 100%;
            max-height: 15rem;
            overflow-y: auto;
            position: absolute;
            box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
            border-radius: .5rem;
            border: 1px solid rgba(30, 15, 0, 0.2);
            left: 0;
            top: calc(#{rem(48px)} + #{rem(2px)});
            z-index: 100;
            a {
                padding: 0 1rem;
                color: #1E0F00 !important;
                &:hover {
                    background: linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), #FFFFFF;
                }
            }
        }
    }
</style>