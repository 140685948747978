<template>
  <div class="promotions" v-if="displayComponent" >
    <h2 class="promotions__title">{{ $t('promotionHeader') }}</h2>
    <transition enter-active-class="animated faster fadeInLeft" leave-active-class="animated faster fadeOutLeft">
      <div class="promotions__container mobile-size" :class="{active : quote.offer3x2}" v-if="displayPromotions">
        <div class="first-column mobile" :class="{apply: checkPromotion}">
          <img src="@/assets/true--icon.svg" v-if="checkPromotion"/>
          <label class="title" for>{{$t('promotionTitle')}}</label>
          <label for class="code">3X2</label>
        </div>
        <a class="second-column btn btn--primary--outer" v-if="!quote.offer3x2" @click.prevent="applyPromotion3x2()">{{ $t('apply') }}</a>
        <a class="second-column cancel" @click.prevent="applyPromotion3x2()" v-else>{{ $t('cancel') }}</a>
        <span class="third-column two-rows">
          <span>{{ $t('promotion3x2Description') }}</span>
          <a class="details-promotions" href @click.prevent="showConditions3x2()">{{$t('seeConditions')}}</a>
        </span>
      </div>
    </transition>
    <transition enter-active-class="animated faster fadeInLeft" leave-active-class="animated faster fadeOutLeft">
      <div class="promotions__container mobile-special" v-if="updateCartPromotion" :class="{active: existPromotion, error: !existsSpecialPromotion }">
        <validation-observer v-if="!existPromotion" :class="{disable: quote.offer3x2}" ref="validationObserverCode" v-slot="{ handleSubmit }" slim>
            <form class="type-promotion__special"  @submit.prevent="handleSubmit(intentApplySpecialPromotion)">
                <section class="first-column one-column">
                    <validation-provider ref="validateProdider" :rules="{required: !existPromotion, special_code_regex: /^$|^\w{8}$/}" v-slot="{ errors, failedRules }" mode="lazy">
                        <label class="text-promotions" for="code">{{existPromotion ? $t('promotionTitle') : $t('applyPromotion') }}</label>
                        <input class="input-promotions" id="code" type="text" :placeholder="$t('code')" v-model="code" maxlength="8" :class="{'input__error': !existsSpecialPromotion  || errors  && errors.length > 0 }" @change="validate">
                        <a href="" class="code--apply" v-if="existSPromotion" @click.prevent="">
                            <span>{{code}}</span>
                        </a>
                        <small class="field-error" v-if="failedRules.special_code_regex">{{ $t('codeInvalid') }}</small>
                        <small class="field-error" v-if="failedRules.required">{{$t('enterCode')}}</small>
                        <small class="field-error" v-if="errors && errors.length === 0 && !existsSpecialPromotion"> {{ $t('notExist') }} </small>
                    </validation-provider>
                </section>
            </form>
          </validation-observer>
          <a class="second-column btn btn--primary--outer" :class="{disable: quote.offer3x2}" v-if="!existPromotion" @click.prevent="intentApplySpecialPromotion">{{ $t('apply') }}</a>
          <div class="first-column apply" v-if="existPromotion">
            <img src="@/assets/true--icon.svg"/>
            <span class="title">{{ $t('promotionTitle') }}</span>
            <span class="code">{{promotionCode}}</span>
          </div>
          <a class="second-column cancel special-code" v-if="existPromotion" @click.prevent="cancelPromotion"> {{ $t('cancel') }} </a>

          <span class="third-column two-rows special" v-if="existPromotion">
            <span>{{ itemPricePerPerson | currency}}</span>
            <span>{{ promotionPricePerPerson | currency}} {{ $t('perPerson') }}</span>
          </span>
          <span class="third-column description__promotion" v-if="checkPromotion"> {{ $t('promotionsNoApply')}}</span>

        <change-departure-date-modal :promotion="currentPromotion" @applyPromotion="applySpecialPromotion" ref="changeDepartureDateModal"></change-departure-date-modal>
      </div>
    </transition>
  </div>
</template>

<script>
import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import {regex, required} from "vee-validate/dist/rules";
import {mapState} from "vuex";
import moment from 'moment';
import ChangeDepartureDateModal from "@/components/ChangeDepartureDateModal";


extend("required", { ...required, message: "validations.requiredField" });
extend("special_code_regex", { ...regex, message: "invalid.code" });

const STAYINGS = {
    CABIN: 'cabins',
    TIPI: 'tipis',
    LAKE_VILLAGE: 'lakeVillages'
};


export default {
  name: "Promotions",
  data() {
    return {
      promotions: [],
      code: null,
      currentPromotion: null,
      updateCartPromotion3x2: true,
      updateCartPromotion: true,
      maxLodgeCapacity: 6
    };
  },
  watch: {
    "quote.offer3x2"() {
      this.updateCartPromotion3x2 = false;
      setTimeout(() => {
        this.updateCartPromotion3x2 = true;
      }, 500);
    },
    "quote.promotion.code"() {
      this.updateCartPromotion = false;
      setTimeout(() => {
        this.updateCartPromotion = true;
      }, 500);
    },
    "quote.promotion"() {
      if(!this.quote.promotion) {
        this.resetInput()
      }
    }
  },
  components: {
    ChangeDepartureDateModal,
    ValidationObserver,
    ValidationProvider
  },
  computed: {
    ...mapState(["quote"]),
    displayComponent() {
      return this.quote && this.quote.visit && this.quote.visit.numberOfDays >= 0;
    },
    displayPromotions() {

      const flag = this.quote && this.quote.visit && this.quote.visit.numberOfDays > 1 && this.updateCartPromotion3x2;
      if(this.isLodge) {
        return this.needMoreRentals(STAYINGS[this.quote.visit.stayType]) && flag;
      }

      return flag;
    },
    isLodge() {
      if(!this.quote) {
          return false;
      }

      return ['CABIN', 'TIPI', 'LAKE_VILLAGE'].includes(this.quote.visit.stayType);
    },
    existPromotion() {
      return this.quote && !!this.quote.promotion;
    },
    checkPromotion() {
      return this.quote && this.quote.offer3x2;
    },
    promotionCode() {
      return this.quote && this.quote.promotion && this.quote.promotion.code;
    },
    existSPromotion() {
      return this.quote && this.quote.promotion && this.quote.promotion.promotionType === "SPECIAL";
    },
    existsSpecialPromotion() {
      if(this.code && this.promotions && this.promotions.length > 0) {
        let code = this.code.toUpperCase();
        return !!this.promotions.find(p => p.code === code);
      }
      return !this.code;
    },
    itemPricePerPerson() {
        let items = this.quote ? this.quote.items : [];
        let dayPass = items.find(i => i.product.key === "day_pass");
        let nigthPass = items.find(i => i.product.key === "camping_pass");
        let totalPerPerson = 0;
        if(dayPass && dayPass.totalAmount && dayPass.quantity) {
          totalPerPerson+= this.getPricePerItem(dayPass);
        }
        if(nigthPass && nigthPass.totalAmount && nigthPass.quantity) {
          totalPerPerson+= this.getPricePerItem(nigthPass);
        }
        return totalPerPerson;
      },
      promotionPricePerPerson() {
        let pricePerPerson = 0;
        let promotion = this.quote ? this.quote.promotion : null;
        let dayPass = this.getItemByKey('day_pass');
        if (promotion) {
            let dayPasses = (dayPass && dayPass.quantity ? dayPass.quantity : 0);
            pricePerPerson+= (dayPasses > 0 ? 1 : 0) * (promotion.pricePerDay ? promotion.pricePerDay : 0);
            pricePerPerson+= this.quote.visit.numberOfDays * ((promotion.pricePerDay ? promotion.pricePerDay : 0) + (promotion.pricePerNight ? promotion.pricePerNight : 0));
        }
        return pricePerPerson;
      }
  },
  mounted() {
    this.getSpecialPromotions();
  },
  methods: {
      getPricePerItem(item) {
        return (item && item.quantity && item.totalAmount) ? item.totalAmount / item.quantity : 0;
      },
      getItemByKey(key) {
        let item = this.quote && this.quote.items.find(item => item.product.key === key);
        return item ? {quantity: item.quantity, totalAmount: item.totalAmount } : null;
      },
      async getSpecialPromotions() {
        this.promotions = await this.$store.dispatch('loadAvailablesSpecialPromotions');
      },
      async applyPromotion3x2() {
        let offer3x2 = this.quote && this.quote.offer3x2 ? false : true;
        await this.$store.dispatch('updatePromotion3x2', offer3x2);
        if (offer3x2) {
          this.analyticsAddPromotion();
          this.resetInput();
        }
        await this.$refs.validationObserverCode.reset();
      },
      async intentApplySpecialPromotion() {
        let isValid = await this.$refs.validationObserverCode.validate();
        if (isValid) {
          if(this.promotions && this.promotions.length > 0) {
            let code = this.code.toUpperCase();
            let validPromotion = this.promotions.find(p => p.code === code);
            if(validPromotion) {
              this.currentPromotion = await validPromotion;
              let datesValidityOnly = validPromotion.datesValidityOnly;
              let isPromotionNight = 'NIGHT' === validPromotion.arrivalTime;
              let visitArrivalDate = moment(this.quote.visit.arrivalDate);
              let visitDepartureDate = moment(this.quote.visit.departureDate);
              let promotionStartDate = moment(validPromotion.startDate);
              let promotionEndDate = moment(validPromotion.endDate);

              let sameSchedules = isPromotionNight ? this.quote.visit.arrivalTime == 'NIGHT' : this.quote.visit.arrivalTime == 'DAY';
              let sameArrivalDate = visitArrivalDate.isSame(promotionStartDate);
              let sameDepartureDate = visitDepartureDate.isSame(promotionEndDate);
              let rangeValidation = !datesValidityOnly && visitArrivalDate.isSameOrAfter(promotionStartDate)
                  && visitDepartureDate.isSame(promotionEndDate);

              if((sameArrivalDate && sameDepartureDate && sameSchedules) || rangeValidation) {
                await this.applySpecialPromotion();
              } else {
                this.$refs.changeDepartureDateModal && this.$refs.changeDepartureDateModal.openModal();
              }
            }
          }
        }
      },
      async applySpecialPromotion() {
        let data = {
          code: this.code,
          quoteId: this.quote.id
        }
        try {
          await this.$store.dispatch('applyPromotion', data);
          this.onPromotionEvent();
        } catch (e) {return e;}
      },
      async cancelPromotion() {
        let data = {
          promotionId: this.quote.promotion.id,
          quoteId: this.quote.id
        }
        try {
          this.$store.dispatch('cancelPromotion', data);
          this.resetInput();
        } catch (e) {
          return e;
        }
      },
      needMoreRentals(stayType) {
        const rentals = this.quote.products[stayType];

        if (rentals && rentals > 0) {
          const numberOfPersons = this.quote.visit.numberOfPersons;
          const personsPerRentals = rentals * this.maxLodgeCapacity;

          return numberOfPersons > personsPerRentals;
        }

        return false;
      },
      analyticsAddPromotion() {
        this.onGenericEvent(`aplicar promoción`, { event_category: 'cotizador', event_label: 'cta - aplicar promoción' });
      },
      showConditions3x2() {
        this.$store.dispatch('showConditions3x2');
      },
      validate() {
        this.$refs.validationObserverCode.validate();
      },
      resetInput() {
        this.code = "";
      },
      onPromotionEvent() {
        this.$root.$emit('onPromotionEvent', this.code);
      },
      onGenericEvent(event, command) {
        this.$root.$emit('onGenericEvent', event, command);
      },
  }
};
</script>

<style lang="scss" scoped>
.promotions {
  color: $dark-brown-color;
  letter-spacing: 0.05em;
  font-style: normal;
  font-weight: normal;
  padding: 1.5rem 0;

  &__title {
    font-family: $font-secondary;
    font-size: 1.5rem;
    line-height: rem(33px);
    text-transform: uppercase;
    @include respond-to("small and down") {
      font-size: rem(16px);
    }
  }

  &__container {
    display: grid;
    background: #ffffff;
    border: 1px dashed rgba(30, 15, 0, 0.2);
    box-sizing: border-box;
    border-radius: rem(16px);
    padding: rem(22px) 1.5rem;
    margin-top: 1.5rem;
    @include respond-to("medium and up") {
      grid-template-columns: rem(192px) rem(120px) 1fr;
      grid-template-areas: "first-column second-column third-column";
    }
    @include respond-to("small and down") {
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 1rem;
      grid-template-areas: "first-column second-column" "third-column third-column";
      margin-top: 0.5rem;
      margin-bottom: 1rem;
      padding-left: 0.9rem;
      padding-right: rem(6px);
    }
    @include respond-to("down") {
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 1rem;
      grid-template-areas: "first-column second-column" "third-column third-column";
      border: solid 1px black;
    }
    grid-column-gap: 1rem;
    align-items: center;



    @include respond-to("small and down") {
      .description__promotion {
        border-left: none;
        margin-top: -16px;
        margin-bottom: 16px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.05em;
        margin-right: 15px;
      }
    }
    .details-promotions {
      text-decoration: none;
      color: #0075ff;
      white-space: pre;
      font-weight: 500;
      @include respond-to("small and down") {
        margin-top: -8px;
      }
    }
  }

  .mobile-size {
    @include respond-to("small and down") {
      padding-top: 0.9rem;
      padding-bottom: 0.9rem;
    }
  }
  .disable {
      pointer-events: none;
      opacity: 0.6;
    }
  .mobile-special {
    @include respond-to("small and down") {
      padding-top: 0.9rem;
      padding-bottom: 0rem;
    }
    &.error{
      @include respond-to("medium and up"){
        padding-bottom: 2.5rem;
      }
      @include respond-to("small and down"){
        padding-bottom: 1rem;
      }
    }
  }

  .active {
    border: 1px dashed #00ca69;
    box-sizing: border-box;
    border-radius: rem(16px);
    background: linear-gradient(
        0deg,
        rgba(28, 225, 119, 0.1),
        rgba(28, 225, 119, 0.1)
      ),
      #ffffff;
  }

  .first-column {
    grid-area: first-column;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "title" "code";
    align-self: stretch;
    .mobile {
    @include respond-to("small and down") {
      margin-right: 20px;
    }
    }
    &.apply {
      grid-template-columns: 1.5rem 1fr;
      grid-template-areas: "icon title" "icon code";
      img {
        grid-area: icon;
        align-self: center;
      }
      @include respond-to("small and down"){
        grid-column-gap: 1rem;
        padding-top: rem(1px);
      }
    }
    &.one-column {
      grid-template-columns: 1fr;
      grid-template-areas: "column";
      font-weight: 500;
    }
    grid-column-gap: 1.5rem;
    grid-row-gap: rem(2px);
    .title {
      grid-area: title;
      font-weight: 500;
      align-self: start;
    }
    .code {
      grid-area: code;
      font-family: $font-secondary;
      font-size: 1.5rem;
      align-self: end;

      @include respond-to ("small and down") {
        font-size: 16px;
      }
    }
    .field-error {
      position: absolute;
      color: $red-color2;
      letter-spacing: 0.05em;
      font-weight: 500;
      font-size: rem(12px);
      margin-top: 4px;
    }
    .text-promotions{
      font-weight: 500;
    }
    .input-promotions {
      text-transform: uppercase;
      height: 3rem;
      border: 1px solid rgba(30, 15, 0, 0.2);
      border-radius: 8px;
      font-size: 1rem;
      line-height: 1rem;
      font-weight: 500;
      align-items: center;
      color: #1e0f00;
      padding: 0 1rem;
      margin: 0;
      &.input__error { border: 1px solid $red-color2; }
      @include respond-to("small") {
        width: rem(183px);
      }
    }
    ::placeholder {
        text-transform: none;
      }
    label {
      width: 120%;
      font-size: 1rem;
      line-height: 1rem;
      letter-spacing: 0.05em;
      margin-bottom: .5rem;
      @include respond-to("small and down") {
        width: 171%;
        opacity: 0.6;
      }
    }
    @include respond-to("small and down") {
      grid-column-gap: 5.5rem;
    }
    @include respond-to("xsmall and down"){
      grid-column-gap: 0rem;
    }

  }
  .second-column {
    grid-area: second-column;
    align-self: end;
    margin-left: rem(-9px);
    &.cancel {
      width: 100%;
      align-self: center;
      text-align: end;
      text-decoration-line: underline;
      font-size: 1rem;
      color: $red-color2;
      cursor: pointer;
    }
    &.special-code{
        font-weight: 500;
        @include respond-to("small and down"){
          padding-top: 10px;
        }
      }
    @include respond-to("small") {
      min-width: rem(119px);
    }

    @include respond-to("xsmall and down"){
      min-width: auto;
    }
  }
  .third-column {
    grid-area: third-column;
    padding-left: 1.5rem;
    display: grid;
    grid-row-gap: .5rem;
    border-left: 1px solid black;
    font-weight: 500;
    &.special {
      text-transform: uppercase;
      :first-child {
        opacity: 0.6;
        font-weight: 500;
        text-decoration-line: line-through;
      }
      :last-child {
        font-family: $font-secondary;
        font-size: 1.5rem;
        @include respond-to("small and down"){
          font-size: 1rem;
        }
      }
      @include respond-to("small and down"){
        padding-bottom: 1rem;
        line-height: 22px;
        grid-row-gap: 0px;
        margin-top: 0.5rem;
      }
    }


    @include respond-to("small and down") {
      padding: 0;
      padding-top: 1rem;
      border-top: 1px solid $black-opacity-color;
      border-left: none;

      font-weight: 500;
      font-size: 16px;
      line-height: 24px;

    }
  }
  .description__promotion {
      font-family: $font-primary;
      font-weight: 500;
      letter-spacing: 0.05em;
      font-size: rem(16px);
      line-height: rem(24px);
      color: $red-color2;
      border-left: 1px solid black;
      align-self: end;
      max-width: 360px;
      @include respond-to("medium and down") {
        max-width: 100%;
        border-left: none;
        border-top: none;
      }
    }
  a { cursor: pointer; }

    @include respond-to("small and down") {
      padding-top: 0;
      padding-bottom: rem(180px);
    }
}
</style>