<template>
  <default-layout>
    <div class="careers" :class="{ empty: !showJobs }">
      <div class="careers__header">
        <div class="careers__header__title">
          <h1>{{ $t("jobs") }}</h1>
        </div>
        <div class="careers__header__communicate" v-if="showJobs">
          <h3>¿Dudas? Comunícate con nuestro equipo de recursos humanos.</h3>
        </div>
        <div class="careers__header__button" v-if="showJobs">
          <a class="whatsapp-button" :href="whatsAppUrl">
            <img src="@/assets/whatsapp-name-icon.svg" alt="" />
          </a>
        </div>
      </div>

      <div class="careers__table" v-if="showJobs">
        <table>
          <thead>
            <tr>
              <th class="area-title">Área</th>
              <th>Título de vacante</th>
              <th>Tipo de jornada</th>
              <th>Tipo de empleo</th>
              <th class="details-title">Detalles</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(job, index) in jobs" :key="index">
              <td class="area">{{ job.area }}</td>
              <td>{{ job.title }}</td>
              <td>
                <div
                  class="type-label"
                  v-for="(type, index) in setStyle(job.type)"
                  :key="index"
                >
                  <span>{{ type }}</span>
                  <img
                    src="@/assets/important.svg"
                    alt="Important information icon"
                    v-tooltip.top="{
                      content: getJobTypeDetails(type),
                      class: 'tooltip-custom tooltip-other-custom',
                    }"
                  />
                  <br />
                </div>
              </td>
              <td>Temporal</td>
              <td class="details">
                <router-link
                  :to="{ name: 'job-details', params: { id: job.id } }"
                  >Más información</router-link
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="careers__empty" v-if="!showJobs">
        <img src="@/assets/products/tipee--icon--gray.svg" alt="" />
        <span>{{ $t("emptyJobs") }}</span>
      </div>
    </div>
  </default-layout>
</template>

<script>
import DefaultLayout from "@/layouts/DefaultLayout";

const JOB_TYPE_DETAILS = [
  {
    id: "Turno Completo",
    label: `8 Horas Diarias\n6 Dias a la Semana\nPosibilidad de Tiempo Extra`,
  },
  {
    id: "Medio Turno",
    label:
      "Horario Flexible de Lunes a Viernes\n8 Horas Diarias Sábados y Domingos\nPosibilidad de Tiempo Extra",
  },
  {
    id: "Fin de Semana",
    label: "8 Horas Diarias Sábados y Domingos\nPosibilidad de Tiempo Extra",
  },
  {
    id: "Turno de Relevo",
    label:
      "4 a 6 Horas Diarias\nHorario Flexible\nEste puesto esta diseñado para personas que ya tienen empleo en otra empresa y tienen disponibilidad de tiempo para un segundo empleo. Nosotros nos adaptamos a tu horario laboral preexistente.",
  },
];

export default {
  components: { DefaultLayout },
  metaInfo() {
    return { title: this.$t("tabs.races") };
  },
  computed: {
    showJobs() {
      return this.jobs.length > 0;
    },
  },
  mounted() {
    this.getJobDetails();
  },
  data() {
    return {
      jobs: [],
      whatsAppUrl: `https://api.whatsapp.com/send?phone=${process.env.VUE_APP_526461625698}&text=Hola%20buen%20dia%20!`,
    };
  },
  methods: {
    async getJobDetails() {
      this.jobs = await this.$store.dispatch("getJobPostsForWeb");
    },
    setStyle(text) {
      if (text) {
        return text.split(",");
      }
    },
    setLineBreak(text) {
      if (text) {
        return text.split("*");
      }
    },
    getJobTypeDetails(job) {
      job = JOB_TYPE_DETAILS.find((d) => d.id === job).label;
      return this.setLineBreak(job);
    },
  },
};
</script>

<style lang="scss">
.careers {
  position: relative;
  letter-spacing: 0.05em;
  display: grid;
  grid-template-rows: auto 1fr;
  &__empty {
    display: grid;
    grid-row-gap: 1.5rem;
    grid-auto-rows: min-content;
    justify-items: center;
    align-items: center;
    span {
      font-family: $font-secondary;
      font-weight: normal;
      font-size: 1rem;
      text-transform: uppercase;
      color: $brown-opacity-color;
    }
    img {
      height: 120px;
      width: auto;
    }
  }

  &__header {
    display: grid;
    grid-template-columns: auto auto 200px;
    align-items: center;
    padding: 0px;
    height: 48px;
    width: 100%;
    margin: 24px 0 16px;
    &__title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 0px;
      height: 36px;
    }
    &__communicate {
      display: grid;
      align-items: center;
      padding: 0px;
      height: 48px;
      h3 {
        text-align: right;
        margin-right: 16px;
      }
    }
    &__button {
      position: relative;
      background: #25d366;
      border-radius: 8px;
      width: 200px;
      height: 48px;
      padding: 16px;
      gap: 8px;
      cursor: pointer;
      img {
        position: absolute;
        left: 31px;
        top: 8px;
      }
    }
  }
  &__table {
    overflow: auto;
    height: auto;
    padding: 0 0 rem(100px) 0;
    table {
      width: 100%;
      thead,
      tbody {
        height: 54px;
        padding: 0px;
      }
      thead {
        .area-title {
          border-top-left-radius: 8px;
        }
        .details-title {
          border-top-right-radius: 8px;
        }
      }
      tbody {
        background: #e9e7e5;
        height: 100%;
        .area {
          color: #1e0f00;
          letter-spacing: 0.05em;
          text-transform: uppercase;
        }
        .details a {
          color: #0075ff;
          text-decoration-line: underline;
          cursor: pointer;
          font-weight: normal;
        }
        tr:nth-child(even) {
          background: #ffffff;
        }
      }
      td,
      th {
        height: 54px;
        padding: 16px 12px;
        gap: 10px;
        letter-spacing: 0.05em;
        font-size: 16px;
        font-style: normal;
      }
      th {
        background: #786f66;
      }
      th,
      .area {
        text-align: left;
        color: #d2cfcc;
        text-transform: uppercase;
        font-family: $font-secondary;
        font-weight: 400;
        line-height: 22px;
      }
      td {
        font-family: $font-primary;
        font-weight: 500;
        line-height: 24px;
        vertical-align: middle;
      }
    }
  }
  &.empty {
    grid-row-gap: 0;
    align-items: center;
  }
  .type-label {
    display: grid;
    grid-template-columns: 155px 16px;
    height: 24px;
    align-items: center;
    text-align: left;

    @include respond-to("medium and down") {
      height: auto;
    }
  }

  @include respond-to("small and up") {
    min-height: rem(696px);
    &__empty {
      margin-top: -5.5rem;
    }
    &__title {
      font-size: 2rem;
    }
    &__communicate {
      &__event {
        grid-template-rows: rem(36px) 2px 1fr;
        grid-row-gap: 21px;
      }
      &__content {
        box-shadow: 0px 0px 2rem rgba(0, 0, 0, 0.24);
      }
    }
  }
  @media (max-width: 646px) {
    &__header {
      &__communicate {
        h3 {
          display: none;
        }
      }
    }
  }
  @include respond-to("xsmall and down") {
    min-height: rem(696px);
    &__empty {
      grid-row-gap: 1rem;
    }
    &__header {
      &__title {
        font-size: 24px;
      }
      &__communicate {
        position: relative;
        left: 0;
      }
      h3 {
        display: none;
      }
    }
  }
  &.empty {
    padding: 1rem 0 rem(120px) 0;
    grid-row-gap: 1.5rem;
    min-height: rem(565px);
  }
}

.vue-tooltip.tooltip-custom {
  white-space: pre-wrap;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.vue-tooltip {
  font-size: 14px !important;
}
</style>
