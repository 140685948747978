<template>
  <div class="visit-details" >
    <div class="visit-details__dates">
      <img v-if="quote.visit.arrivalTime" class="visit-details__dates__arrival-time" :src="require('@/assets/'+quote.visit.arrivalTime.toLowerCase()+'.svg')" alt="">
      <span class="visit-details__dates__arrival-title">{{ $t('arrival') }}</span>
      <span class="visit-details__dates__arrival">{{ quote.visit.arrivalDate | date}}</span>
      <img class="visit-details__dates__arrow" src="@/assets/arrow-right.svg" alt="">
      <span class="visit-details__dates__departure-title">{{ $t('departure') }}</span>
      <span class="visit-details__dates__departure">{{quote.visit.departureDate | date}}</span>
    </div>
    <div class="visit-details__visitors">
      <span class="title">{{ $t('visitors') }}</span>
      <span class="content">{{quote.visit.numberOfPersons}} {{ $t(quote.visit.numberOfPersons == 1 ? 'visitor': 'visitors') }}</span>
    </div>
    <div class="visit-details__total">
      <span class="title">{{$t('total')}}</span>
      <span class="content">{{quote.totalAmount | currency}}</span>
    </div>
  </div>
</template>

<script>
  import {mapState} from 'vuex';
  import moment from 'moment';

  export default {
    name: 'visit-details',
    computed: mapState(['quote', 'visitDrawerVisible']),
    watch: {
      '$i18n.locale'() {
        moment.locale(this.$i18n.locale);
      }
    },
    data() {
      return {};
    },
    mounted() {
      moment.locale(this.$i18n.locale);
    },
    methods: {
      getStayType(stayType) {
        let stay = 'oneDayPass';
        switch (stayType) {
          case 'CAMPING_PASS':
            stay = 'camping';
            break;
          case 'TIPEE':
            stay = 'teepee';
            break;
          default:
            break;
        }
        return stay;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .visit-details {
    letter-spacing: 0.05em;
    padding: rem(18px) 3.5rem;
    display: grid;
    width: 100%;
    grid-template-columns: auto auto 1fr;
    grid-column-gap: 1.5rem;
    color: $dark-brown-color;
    &__dates {
      display: grid;
      grid-template-areas:
      "arrivalTime arrivalTitle arrow departureTitle"
      "arrivalTime arrival arrow departure";
      grid-column-gap: .5rem;
      > * {
        display: grid;
        align-self: center;
      }
      &__arrival-title { grid-area: arrivalTitle }
      &__arrival { grid-area: arrival }
      &__arrival-time {
        grid-area: arrivalTime;
        padding: rem(4px) 0;
      }
      &__arrow {
        grid-area: arrow;
        padding: rem(4px) rem(6px);
      }
      &__arrival-time, &__arrow { align-self: end }
      &__departure-title { grid-area: departureTitle }
      &__departure { grid-area: departure }
    }
    &__visitors, &__total {
      display: grid;
      grid-row-gap: .5rem;
    }
    &__dates, &__visitors {
      padding-right: 1.5rem;
      border-right: 1px solid $dark-brown-color;
    }
    &__dates__arrival-title, &__dates__departure-title, &__dates__arrival-time, &__total > .title, &__visitors > .title {
      font-weight: 500;
      font-size: 1rem;
      line-height: 1rem;
      opacity: .6;
      font-family: $font-primary;
    }
    &__dates__arrival, &__dates__departure, &__visitors > .content, &__total > .content {
      font-size: 1rem;
      line-height: rem(22px);
      font-weight: normal;
      font-family: $font-secondary;
      text-transform: uppercase;
    }
    @include respond-to("small and down") {
      .visit-details {
        &__dates {
          border-right: 0;
          border-bottom: 1px solid $border-black-opacity ;
          grid-area: dates;
          padding: 12px 0 12px;
        }
        &__visitors {
          border-right: 0;
          grid-area: visitors;
          padding: 12px 0 12px;
        }
        &__total {
          display: none;
        }
      }
    }
  }
</style>
