<template>
  <products-water selected-product="waterFallsPool">
      <div class="product-summary__details" v-if="currentProduct">
        <gallery>
          <template slot="small-image">
            <img :src="require('@/assets/products/waterfalls-pool/waterfalls-pool-' + gallery[0] + '.jpg')" alt="">
          </template>
          <template slot="gallery-images">
            <img class="slide" v-for="(picture, i) in gallery" :key="i" :src="require('@/assets/products/waterfalls-pool/waterfalls-pool-' + picture + '.jpg')" alt="">
          </template>
        </gallery>
        <div class="product-summary__details__title">
          <h1>{{ $t(currentProduct.title) }}</h1>
        </div>
        <div class="product-summary__details__content">
          <div class="product-summary__details__content__information">
            <div class="product-summary__details__content__information__description">
              <p v-for="(text,index) in waterFallsPool.description" :key="index" class="text">{{ $t(text) }}</p>
            </div>
          </div>
        </div>
      </div>
    </products-water>
  </template>
  
  <script>
    import Gallery from '@/components/Gallery';
    import ProductsWater from '@/views/products/ProductsWater';
  
    export default {
      name: 'waterFalls-Pool',
      components: {ProductsWater, Gallery},
      metaInfo() {
        return {title: this.$t("tabs.waterfallPool")}
      },
      data() {
        return {
          currentProduct: null,
          gallery: [1,2,3],
          waterFallsPool: {
            description: {
              text1: 'productPages.waterFallsPool.text1',
              text2: 'productPages.waterFallsPool.text2'
        }
      }
        }
      },
      mounted() {
        this.currentProduct = { id: 'waterFallsPool', title:'waterFallsPool', imageName: 'waterfalls-pool' };
      },
      methods: {}
    };
  </script>
  
  <style lang="scss" scoped>
    .product-summary {
      &__details {
        .slide{ background-position: bottom; }
        .image-1 { background-position: center; }
        .text {@include respond-to("small and down") { line-height: 24px; } }
        &__content {
          &__services {
            height: rem(250px);
          }
        }
      }
    }
  </style>