var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('date-picker',{ref:"datePicker",staticClass:"visit-dates__arrival__date-picker__calendar calendar-drawer",attrs:{"locale":_vm.$t('lang'),"color":"yellow","min-date":_vm.minDateD,"max-date":_vm.maxDateD,"is-required":true,"attributes":_vm.attributes,"disabled-dates":_vm.disabledDate,"is-inline":"","is-expanded":""},scopedSlots:_vm._u([{key:"header-title",fn:function(ref){
var monthLabel = ref.monthLabel;
return _c('span',{},[_vm._v(_vm._s(monthLabel))])}},{key:"day-content",fn:function(ref){
var day = ref.day;
var dayEvents = ref.dayEvents;
return [_c('div',_vm._g({staticClass:"day-content",class:{'disabled': !_vm.isDateInRange(day) || !_vm.getPrice(day.id, _vm.stayKey)
                      || (_vm.disabledDate && _vm.disabledDate.includes(day.id))
                      || (_vm.unavailableLodgingDates && _vm.unavailableLodgingDates.includes(day.id))}},dayEvents),[_c('div',{staticClass:"day",class:{'selected': _vm.selectedDay(day)}},[_vm._v(_vm._s(day.day)+" ")]),(_vm.isDateInRange(day))?_c('div',{staticClass:"total-amount",class:{'selected': _vm.selectedDay(day)}},[_vm._v(_vm._s(_vm._f("currencyCalendar")(_vm.getPrice(day.id, _vm.stayKey))))]):_vm._e()])]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"calendar-price"},[_c('span',[_vm._v(_vm._s(_vm.$t(("calendarLabel." + (_vm.getFooterLabel())))))])])]},proxy:true}]),model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('a',{attrs:{"slot":"header-left-button"},on:{"click":function($event){$event.preventDefault();}},slot:"header-left-button"},[_c('img',{attrs:{"src":require("@/assets/arrow-left.svg"),"alt":""}})]),_c('a',{attrs:{"slot":"header-right-button"},on:{"click":function($event){$event.preventDefault();}},slot:"header-right-button"},[_c('img',{attrs:{"src":require("@/assets/arrow-rigth.svg"),"alt":""}})])])}
var staticRenderFns = []

export { render, staticRenderFns }