<template>
    <products-earth selected-product="flagViewpoint">
      <div class="product-summary__details" v-if="currentProduct && information">
        <gallery>
          <template slot="small-image">
            <img :src="require('@/assets/products/flag-viewpoint/flag-viewpoint-' + gallery[0] + '.jpg')" alt="">
          </template>
          <template slot="gallery-images">
            <img class="slide" v-for="(picture, i) in gallery" :key="i" :src="require('@/assets/products/flag-viewpoint/flag-viewpoint-' + picture + '.jpg')" alt="">
          </template>
        </gallery>
        <div class="product-summary__details__title">
          <h1>{{ $t(currentProduct.title) }}</h1>
        </div>
        <div class="product-summary__details__content">
          <div class="product-summary__details__content__information">
            <div class="product-summary__details__content__information__description" :class="{ hidden: informationHidden }">
              <p v-for="(text, index) in description" :key="index">{{ $t(text) }}</p>
            </div>
            <span class="hide-description" @click="informationHidden = !informationHidden">{{ informationHidden ? $t('readMore') : $t('hide') }}</span>
          </div>
        </div>
      </div>
    </products-earth>
  </template>
  
  <script>
    import Gallery from '@/components/Gallery';
    import ProductsEarth from '@/views/products/ProductsEarth';
  
    const information = {
      camping: {
        priceDescription: 'pricePerPerson',
        priceDescriptionMobile: 'perPerson',
        labels: ['priceFor'],
        services: [
            { id:'36', name: 'productServices.electrical', iconName: 'electricity-outlets' },
            { id:'37', name: 'productServices.tables', iconName:'table' },
            { id:'38', name: 'productServices.grills', iconName:'steakhouse' },
          
        ]
      }
    }
  
    export default {
      name: 'Camping',
      components: {ProductsEarth, Gallery},
      metaInfo() {
        return {title: this.$t("tabs.flagViewpoint")}
      },
      data() {
        return {
          currentProduct: null,
          informationHidden: true,
          gallery: [1,2,3],
          description: {
            text1: 'productPages.flagViewpoint.text1',
            text2: 'productPages.flagViewpoint.text2'
          }
        }
      },
      computed: {
          information() {
              return information;
          }
      },
      mounted() {
        this.currentProduct = { title:'flagViewpoint', imageName: 'camping' };
      }
    };
  </script>