<template>
    <v-date-picker class="prices-calendar" color="disabled" ref="calendar"
      mode="single"
      v-model="calendar"
      :from-date="new Date(new Date().getFullYear(), 0, 1)"
      :locale="$t('lang')"
      :is-required="true"
      :rows="rows"
      :columns="columns"
      :attributes="attributes"
      is-inline
      nav-visibility="hidden"
      is-expanded
      :firstDayOfWeek="2"
      :select-attribute="selectDragAttribute">
        <span slot='header-title' slot-scope='{ monthLabel, year, month }'>
          <div>{{ monthLabel }} {{ year }}</div>
          <span ref="date" :month="month" :year="year" hidden>{{ month }} {{ year }}</span>
        </span>
        <a slot='header-left-button'></a>
        <a slot='header-right-button'></a>
        <div slot="day-popover" class="popover" slot-scope="{day}">
          <div class="popover__content">
            <h2 :class="`popover__title popover__title--${day.attributes[0].popover.customData.color.toLowerCase()}`">{{ $t(color[day.attributes[0].popover.customData.color]) }}</h2>
            <div class="popover__price">
              <span class="popover__price__concept">{{ $t('oneDayPass') }}</span>
              <span class="popover__price__amount">{{ day.attributes[0].popover.customData.dayPassPrice | currency }}</span>
            </div>
            <div class="popover__price">
              <span class="popover__price__concept">{{ $t('camping') }}</span>
              <span class="popover__price__amount">{{ day.attributes[0].popover.customData.campingNightPrice | currency }}</span>
            </div>
            <div class="popover__price">
              <span class="popover__price__concept">{{ $t('oneDayCamping') }}</span>
              <span class="popover__price__amount">{{ day.attributes[0].popover.customData.campingDayPrice | currency }}</span>
            </div>
            <div class="popover__price">
              <span class="popover__price__concept">{{ $t('ecotouristCabins') }}</span>
              <span class="popover__price__amount">{{ day.attributes[0].popover.customData.cabinPrice | currency }}</span>
            </div>
            <div class="popover__price">
              <span class="popover__price__concept">{{ $t('lakeVillage') }}</span>
              <span class="popover__price__amount">{{ day.attributes[0].popover.customData.lakeVillagePrice | currency }}</span>
            </div>
            <div class="popover__price">
              <span class="popover__price__concept">{{ $t('teepee') }}</span>
              <span class="popover__price__amount">{{ day.attributes[0].popover.customData.tipiPrice | currency }}</span>
            </div>
          </div>
        </div>
    </v-date-picker>
</template>

<script>
  import vDatePicker from 'v-calendar-legacy/lib/components/date-picker.umd';

  const color = {
    'YELLOW': 'color.yellow',
    'BLACK': 'color.black',
    'BLUE': 'color.blue',
    'RED': 'color.red'
  }

  export default {
    components: {vDatePicker},
    props: {
      rows: Number,
      columns: Number,
      attributes: Array,
      tariffs: Array
    },
    data() {
        return {
          calendar: {
            start: new Date(),
            end: new Date()
          }
        };
    },
    watch: {
    },
    computed: {
      selectDragAttribute() {
        return {
          popover: {
            slot: 'day-popover',
            visibility: 'click',
            isInteractive: false,
          }
        }
      },
      color() {
        return color;
      }
    },
    mounted() {
    },
    methods: {
    }
  };
</script>
<style lang="scss" scoped>
  .popover {
    grid-row-gap: 1rem;
    padding: 24px;
    background-color: white;
    border: none;
    border-radius: rem(8px);
    &__title {
      font-family: $font-secondary;
      font-size: 24px;
      line-height: 33px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
        margin-bottom: 8px;
      &--black {
        color: #272727;
      }
      &--blue {
        color: #187DD5;
      }
      &--red {
        color: #F71C26;
      }
      &--yellow {
        color: #DDA000;
      }
    }
    &__content {
        display: grid;
        grid-template-rows: repeat(6, min-content);
        grid-row-gap: 1rem;
    }
    &__price {
      display: grid;
      grid-template-columns: rem(145px) 1fr;
      grid-column-gap: rem(14px);
      align-items: center;
      &__concept {
        font-family: $font-primary;
        font-weight: 500;
        font-size: 1rem;
        line-height: 1rem;
        letter-spacing: 0.05em;
        color: $dark-brown-color;
        justify-self: left;
      }
      &__amount {
        font-family: $font-secondary;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: $dark-brown-color;
        justify-self: right;
      }
    }
  }
  .prices-calendar {
    @include respond-to("small and down") {
      padding: 1.5rem 0 3rem 0;
    }
    @include respond-to("medium and up") {
      padding: rem(48px) rem(49px) rem(51px);
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
    }
  }
</style>