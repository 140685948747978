<template>
  <div class="current-step" :class="{'last-step': currentStep === 'order.details'}">
    <div class="current-step__line line--left"></div>
    <column-block class="current-step__step" :class="{'step--left': currentStep == 'shopping-cart', 'step--center': currentStep == 'contact-payment', 'step--right': currentStep == 'order.details'}">
      <div class="step" v-for="(step, index) in steps" :key="index" :class="'step--'+index">
          <div class="line line--left"></div>
          <span class="step__text" :class="{'step__text--current': namedSteps[step] == currentStep}">{{ $t(step) }}</span>
          <span class="step__circle" :class="'circle--' + index"></span>
          <div class="line line--right"></div>
      </div>
    </column-block>
    <div class="current-step__line line--right"></div>
  </div>
</template>

<script>
import ColumnBlock from '@/components/columns/ColumnBlock';

const steps = ['shoppingCart','payment', 'confirmation']

const namedSteps = {
  shoppingCart: 'shopping-cart',
  payment: 'contact-payment',
  confirmation: 'order.details'
}
export default {
    components: {ColumnBlock},
    computed: {
      steps() {
        return steps;
      },
      currentStep() {
        return this.$route.name;
      },
      namedSteps() {
        return namedSteps;
      }
    }
  };
</script>

<style lang="scss" scoped>
.current-step {
    position: relative;
    display: grid;
    padding: 1.5rem 0;
    justify-items: center;
    @include respond-to("large and up") {
      &__step { max-width: rem(1440px); }
      &__line {
        position: absolute;
        width: 50%;
        top: 3.5rem;
        &.line--left {
          left: 0;
          border: 2px solid $orange-color;
        }
        &.line--right {
          right: 0;
          border: 2px solid #A69D98;
        }
      }
      &.last-step > .current-step__line { border: 2px solid $orange-color }

        padding: 1.5rem 11rem;
      &.step--leff, &.step--right, &.step--center {
        padding: 0;
      }
    }
    &__step {
      display: grid;
      width: 100%;
      grid-template-columns: 1fr 1fr 1fr;
      align-items: center;
      justify-items: center;
      line-height: rem(20px);
      letter-spacing: 0.05em;
      text-transform: uppercase;
      font-family: 'Chau Philomene One', sans-serif;
      @include respond-to('medium and down') {
        grid-template-columns: 1fr 0.1fr 1fr;
      }
      .step {
        width: 100%;
        position: relative;
        display: grid;
        grid-row-gap: rem(4px);
        align-items: center;
        justify-items: center;
        &__text {
          font-size: 1rem;
          line-height: rem(22px);
          opacity: .6;
          color: $dark-brown-color;
          &--current {
            color: $dark-brown-color;
            opacity: 1;
          }
        }
        &__circle {
          width: 1rem;
          height: 1rem;
          background-color: white;
          border-radius: 50%;
          border: 2px solid rgba($color: $dark-brown-color, $alpha: 0.4);
          z-index: 2;
        }
        .line {
          position: absolute;
          width: 50%;
          top: 2rem;
          z-index: 1;
          &.line--left, &.line--right {
            border: 2px solid #A69D98;
          }
          &.line--left {
            left: 0;
          }
          &.line--right {
            right: 0;
          }
        }
      }
      &.step--left, &.step--right { padding: 0; }
      &.step--left {
        .step--0 {
          .line--left {
            border: 2px solid $orange-color;
          }
        }
        .circle--0 {
          border: none;
          background-color: $orange-color;
        }
      }
      &.step--center {
        padding: 0;
        .step--0 > .line { border: 2px solid $orange-color }
        .step--1 > .line--left { border: 2px solid $orange-color }
        .circle--0, .circle--1 {
          border: none;
          background-color: $orange-color;
        }
      }
      &.step--right {
        .step--0, .step--1, .step--2 {
          .line { border: 2px solid $orange-color }
        }
        .circle--0, .circle--1, .circle--2 {
          border: none;
          background-color: $orange-color;
        }
      }
      @include respond-to("medium and down") {
        &.step--right, &.step--center, &.step--left {
          .step--0 {
            padding-left: 1rem;
          }
          .step--2 {
            padding-right: 1rem;
          }
        }
      }
    }
}
</style>