<template>
    <noscript>
        <iframe v-if="mannagerId && mannagerId != ''" id="googleTagManager" height="0" width="0" style="display:none;visibility:hidden" :src="`https://www.googletagmanager.com/ns.html?id=${mannagerId}`"></iframe>
    </noscript>
</template>

<script>
    const mannagerId = process.env.VUE_APP_GTM-M48VZ4Z;

    const ITEMS_ARRAY = [
        {
            id: '858fee2d-8806-448b-a27e-5bb8825e509f',
            key: 'DAY_PASS',
            name: 'Pase por día',
            category: 'Estancias',
        },
        {
            id: 'b947209b-95d8-4b17-bee7-952c0e026321',
            key :'CAMPING_PASS',
            name: 'Acampar',
            category: 'Estancias',
        },
        {
            id: '54ebf34a-02f4-4964-bd31-6d8891377186',
            key: 'CABIN',
            name: 'Cabañas Ecoturísticas',
            category: 'Estancias',
        },
        {
            id: '73f1c1e3-be6f-406f-b713-35635f6fc712',
            key: 'TIPI',
            name: 'Tipi Indio',
            category: 'Estancias',
        },
        {
            id: '6cb9cbab-179f-4a18-acbe-949bff1f6452',
            key: 'LAKE_VILLAGE',
            name: 'Villas del lago',
            category: 'Estancias',
        },
        {
            id: '16ff5829-668b-4b5f-96b2-0f01013f09db',
            key: 'CANOPY_PASS',
            key2: 'CANOPY_TOUR',
            name: 'Canopy Tour',
            category: 'Actividades',
        },
        {
            id: '0f3d2b9c-e5f9-4917-90db-19fdd7c21875',
            key: 'CANOPY_NIGHT_PASS',
            key2: 'CANOPY_TOUR_NIGHT',
            name: 'Canopy Nocturno',
            category: 'Actividades',
        },
        {
            id: 'd6ac14dc-d043-4f39-9512-13b288a320f5',
            key: 'HORSEBACK_RIDING_PASS',
            key2: 'HORSEBACK_RIDING',
            name: 'Paseo a Caballo',
            category: 'Actividades',
        },
        {
            id: '9d56cde7-773d-49e6-bdff-8146d95ef701',
            key: 'ATV',
            name: 'Cuatrimoto',
            category: 'Actividades',
        }
    ];

    const ARRIVAL_TIME = {
        'DAY': 'ENTRE 7:30 AM Y 6:00 PM',
        'NIGHT': 'ENTRE 6:00 PM Y 11:30 PM'
    };

    const ITEM_ID_PREFIX = 'SKU_';
    const ITEM_BRAND = 'Las Canadas';
    const MXN = "MXN";

    export default {
        components: {},
        computed: {
            mannagerId() {
                return mannagerId;
            }
        },
        mounted() {
            this.gTag(window,document,'script','dataLayer', this.mannagerId);

            //GTM EVENTS

            this.$root.$on('onGenericEvent', (event, command) => {
                this.sendGenericEvent(event, command);
            });

            this.$root.$on('onTawkToEvent', (value) => {
                this.tawkToEvent(value);
            });

            this.$root.$on('onContactEvent', (value) => {
                this.contactEvent(value);
            });

            this.$root.$on('onPromotionEvent', (value) => {
                this.promotionEvent(value);
            });

            this.$root.$on('onViewItemListEvent', (value) => {
                this.viewItemListEvent(value);
            });

            this.$root.$on('onSuccessfulReservationEvent', (quote) => {
                this.successfulReservationEvent(quote);
            });

            this.$root.$on('onSelectItemEvent', (value) => {
                this.selectItemEvent(value);
            });

            this.$root.$on('onViewItemEvent', (value) => {
                this.viewItemEvent(value);
            });

            this.$root.$on('onAddToCartEvent', (value) => {
                this.addToCartEvent(value);
            });

            this.$root.$on('onRemoveFromCartEvent', (value) => {
                this.removeFromCartEvent(value);
            });

            this.$root.$on('onViewCartEvent', (items, amount) => {
                this.viewCartEvent(items, amount);
            });

            this.$root.$on('onBeginCheckoutEvent', (items, amount) => {
                this.beginCheckoutEvent(items, amount);
            });

            this.$root.$on('onPurchaseEvent', (items, amount) => {
                this.purchaseEvent(items, amount);
            });
        },
        methods: {
            gTag(w,d,s,l,i) {
                if (i && i != "") {
                    w[l]=w[l]||[];
                    w[l].push({
                        'gtm.start': new Date().getTime(),
                        'event': 'gtm.js'
                    });
                    let f = d.getElementsByTagName(s)[0];
                    let j = d.createElement(s);
                    let dl = l != 'dataLayer' ? '&l=' + l : '';
                    j.async=true;
                    j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
                    f.parentNode.insertBefore(j,f);
                }
            },
            contactEvent(event) {
                this.sendEvent(event);
            },
            tawkToEvent(event) {
                this.sendEvent(event);
            },
            successfulReservationEvent(quote) {
                const date = this.$options.filters.gtmDate(quote.visit.arrivalDate);
                const arrivalTime = ARRIVAL_TIME[quote.visit.arrivalTime];
                const persons = quote.visit.numberOfPersons;
                const event = `reservation-${date}-${arrivalTime}-PERSONAS ${persons}`;

                this.sendEvent(event);
            },
            promotionEvent(promo) {
                const event = `promo-${promo}`;
                this.sendEvent(event);
            },
            viewItemListEvent(items) {
                const itemList = this.buildItemList(items);
                const command = this.buildEcommerceCommand(itemList);

                this.sendEvent("view_item_list", command);
            },
            selectItemEvent(item) {
                const itemList = this.buildItemList([item]);
                const command = this.buildEcommerceCommand(itemList);

                this.sendEvent("select_item", command);
            },
            viewItemEvent(item) {
                const itemList = this.buildItemList([item]);
                const command = this.buildEcommerceCommand(itemList);

                this.sendEvent("view_item", command);
            },
            addToCartEvent(items) {
                const itemList = this.buildShoppingCartItemList(items);
                const command = this.buildEcommerceCommand(itemList);

                this.sendEvent("add_to_cart", command);
            },
            removeFromCartEvent(item) {
                const itemList = this.buildShoppingCartItemList([item]);
                const command = this.buildEcommerceCommand(itemList);

                this.sendEvent("remove_from_cart", command);
            },
            viewCartEvent(items, amount) {
                const itemList = this.buildShoppingCartItemList(items);
                const command = this.buildViewCartCommand(itemList, amount);

                this.sendEvent("view_cart", command);
            },
            beginCheckoutEvent(items, amount) {
                const itemList = this.buildShoppingCartItemList(items);
                const command = this.buildViewCartCommand(itemList, amount);

                this.sendEvent("begin_checkout", command);
            },
            purchaseEvent(items, amount) {
                const itemList = this.buildShoppingCartItemList(items);
                const command = this.buildViewCartCommand(itemList, amount);

                this.sendEvent("purchase", command);
            },
            sendEvent(event, command) {
                if (command) {
                    this.sendDataLayerEvent(event, command);
                } else {
                    this.sendGTagEvent(event);
                }
                this.cleanDataLayer();
            },
            sendDataLayerEvent(event, command) {
                window.dataLayer.push({ event, ecommerce: command });
            },
            sendGTagEvent(event) {
                this.$gtag.event(event);
            },
            sendGenericEvent(event, command) {
                this.$gtag.event(event, command);
                this.cleanDataLayer();
            },
            cleanDataLayer() {
                // Clear the previous ecommerce object.
                window.dataLayer.push({ ecommerce: null });
            },
            buildEcommerceCommand(items) {
                return {
                    items
                };
            },
            buildViewCartCommand(items, value) {
                return {
                    "currency": MXN,
                    value,
                    items
                };
            },
            findIndexItem(item) {
                return ITEMS_ARRAY.findIndex(i => i.key === item || i.key2 === item);
            },
            buildItemList(items) {
                return (items || []).reduce((newArray, item) => {
                    const key = item && item.id ? item.id : item;
                    const index = this.findIndexItem(key);

                    if (index > -1) {
                        const newItem = this.buildItem(ITEMS_ARRAY[index], index);
                        newArray.push(newItem);
                    }
                    return newArray;

                }, []);
            },
            buildShoppingCartItemList(items) {
                return (items || []).reduce((newArray, item) => {
                    const index = this.findIndexItem(item.key.toUpperCase());

                    if (index > -1) {
                        const newItem = this.buildShoppingCartItem(ITEMS_ARRAY[index], index, item.totalAmount, item.quantity);
                        newArray.push(newItem);
                    }
                    return newArray;

                }, []);
            },
            buildItem(item, index) {
                return {
                    "item_id": ITEM_ID_PREFIX + item.id,
                    "item_name": item.name,
                    "index": index,
                    "item_brand": ITEM_BRAND,
                    "item_category": item.category,
                }
            },
            buildShoppingCartItem(item, index, price, quantity) {
                const itemBuilded = this.buildItem(item, index);
                return {
                    ...itemBuilded,
                    "price": price > 0 && quantity > 0 ? Number(price) / Number(quantity) : 0,
                    "quantity": Number(quantity),
                }
            },
        }
    };
</script>

<style lang="scss">
</style>
