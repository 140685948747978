<template>
  <products-earth selected-product="liveMusic">
    <div class="product-summary__details" v-if="currentProduct">
      <gallery>
        <template slot="small-image">
          <img :src="require('@/assets/products/live-music/live-music-' + gallery[0] + '.jpg')" class="small-image">
        </template>
        <template slot="gallery-images">
          <img class="slide" v-for="(picture, i) in gallery" :key="i" :src="require('@/assets/products/live-music/live-music-' + picture + '.jpg')" alt="">
        </template>
      </gallery>
      <div class="product-summary__details__title">
        <h1>{{ $t(currentProduct.title) }}</h1>
      </div>
      <div class="product-summary__details__content">
        <div class="product-summary__details__content__information">
          <div class="product-summary__details__content__information__description" :class="{ hidden: informationHidden }">
            <p v-for="(text, index) in liveMusic.description" :key="index">{{ $t(text) }}</p>
            <ul>
              <li>
                {{ $t(liveMusic.link.text3) }}
                <a @click.prevent="goTo()">{{ $t(liveMusic.link.linkText) }}</a>
                {{ $t(liveMusic.link.text4) }}
              </li>
            </ul>
          </div>
          <span class="hide-description" @click="informationHidden = !informationHidden">{{ informationHidden ? $t('readMore') : $t('hide') }}</span>
        </div>
      </div>
    </div>
  </products-earth>
</template>

<script>
  import Gallery from '@/components/Gallery';
  import ProductsEarth from '@/views/products/ProductsEarth';

  export default {
    name: 'liveMusic',
    components: {ProductsEarth, Gallery},
    metaInfo() {
      return {title: this.$t("tabs.liveMusic")}
    },
    data() {
      return {
        currentProduct: null,
        informationHidden: true,
        gallery: [1],
        liveMusic: {
            description: {
                text1: 'productPages.liveMusic.text1',
                text2: 'productPages.liveMusic.text2',
            },
            link: {
                text3: 'productPages.liveMusic.text3',
                linkText: 'productPages.liveMusic.link',
                text4: 'productPages.liveMusic.text4'
            }
        }
      }
    },
    mounted() {
      this.currentProduct = { id: 'liveMusic', title:'liveMusic', imageName: 'live-music' };
    },
    methods: {
      goTo() {
        this.$router.push({name: 'events'})
      }
    }
  };
</script>

<style lang="scss" scoped>
  .product-summary {
    &__details {
      .text {
        @include respond-to("small and down") { 
            line-height: 24px; 
            }
        }
      .small-image {
          height: 100%;
          object-fit: cover;
          width: 103%;
      }
      &__title {
            @include respond-to("medium and up"){
                margin-top: 6px;
            }
      }
      &__content {
          &__information {
              &__description {
                  @include respond-to("medium and up"){
                      margin-top: 6px;
                  }
                  @include respond-to("small and down"){
                      margin-top: -1px;
                  }
                  li {
                    margin-bottom: 1.5rem;
                  }
                  a {
                    color: $blue-color2;
                    font-weight: 400;
                    text-decoration: underline;
                    cursor: pointer;
                  }
              }
              
          }
        &__services {
          height: rem(250px);
        }
      }
    }
  }

</style>