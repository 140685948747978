<template>
    <products-lodging selected-product="lakeVillages">
      <div class="product-summary__details" v-if="currentProduct && information">
        <gallery>
          <template slot="small-image">
            <img :src="require('@/assets/products/lake-cabins/lake-cabin-' + gallery[0] + '.jpg')" alt="">
          </template>
          <template slot="gallery-images">
            <img class="slide" v-for="(picture, i) in gallery" :key="i" :src="require('@/assets/products/lake-cabins/lake-cabin-' + picture + '.jpg')" alt="">
          </template>
        </gallery>
        <div class="product-summary__details__title">
          <h1>{{ $t(currentProduct.title) }}</h1>
        </div>
        <div class="product-summary__details__content">
          <div class="product-summary__details__content__information">
            <div class="product-summary__details__content__information__description" :class="{ hidden: informationHidden }">
              <p>{{ $t(information[currentProduct.imageName].description.text1) }}</p>
              <ul>
                <li>
                  {{ $t(information[currentProduct.imageName].description.text2) }} m&sup2; (925 ft&sup2;)
                  {{ $t(information[currentProduct.imageName].description.text3) }} m&sup2; (345 ft&sup2;)
                  {{ $t(information[currentProduct.imageName].description.text4) }}
                </li>
                <li v-for="(text, index) in information[currentProduct.imageName].description.content" :key="index"> {{ $t(text) }}</li>
              </ul>
            </div>
            <span class="hide-description" @click="informationHidden = !informationHidden">{{ informationHidden ? $t('readMore') : $t('hide') }}</span>
          </div>
          <div class="product-summary__details__content__services" v-if="information[currentProduct.imageName].services.length > 0">
            <h1 class="product-summary__details__content__services__title">{{$t('services')}}</h1>
            <div class="product-summary__details__content__services__item" v-for="service in information[currentProduct.imageName].services" :key="service.id">
              <img class="icon" :src="require('@/assets/products/services/'+service.iconName+'.svg')" alt="">
              <span>{{ $t(service.name) }}</span>
            </div>
          </div>
        </div>
      </div>
    </products-lodging>
  </template>
  
  <script>
    import Gallery from '@/components/Gallery';
    import ProductsLodging from './ProductsLodging.vue';
  
    const information = {
      cabins: {
        priceDescription: 'pricePerCabin',
        priceDescriptionMobile: 'perCabin',
        labels: ['priceFor', 'capacity1To6People', 'thePriceDoesNotIncludeAdmissionFees'],
        services: [
          {id:"43", name: "productServices.kingSizeBedrooms", iconName:'king-size-bed'},
          {id:"44", name: "productServices.kitchen", iconName:'kitchen'},
          {id:"45", name: "productServices.bathrooms", iconName:'bathroom'},
          {id:"46", name: "productServices.viewToLake", iconName:'view-to-lake'},
          {id:"47", name: "productServices.sofaBed", iconName:'sofa'},
          {id:"48", name: "productServices.projectorAndScreen", iconName:'projector'},
          {id:"51", name: "productServices.grill", iconName:'steakhouse'},
          {id:"52", name: "productServices.campingTable", iconName:'table'},
          {id:"53", name: "productServices.parking", iconName:'parking-lot'},
          {id:"54", name: "productServices.wifi", iconName:'wifi'},
          {id:"49", name: "productServices.airConditioner", iconName:'air-conditioner'},
          {id:"50", name: "productServices.fireplace", iconName:'fireplace'},
          
        ],
        description: {
          text1: 'productPages.lakeCabins.text1',
          text2: 'productPages.lakeCabins.text2',
          text3: 'productPages.lakeCabins.text3',
          text4: 'productPages.lakeCabins.text4',
          content: {
            text5: 'productPages.lakeCabins.text5',
            text6: 'productPages.lakeCabins.text6',
            text7: 'productPages.lakeCabins.text7',
            text8: 'productPages.lakeCabins.text8',
            text9: 'productPages.lakeCabins.text9',
            text10: 'productPages.lakeCabins.text10',
          }
        }
      }
    }
  
    export default {
      name: 'LakeVillage',
      components: {Gallery, ProductsLodging},
      metaInfo() {
        return {title: this.$t("tabs.lakeVillage")}
      },
      data() {
        return {
          currentProduct: null,
          informationHidden: true,
          gallery: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]
        }
      },
      computed: {
          information() {
              return information;
          }
      },
      mounted() {
        this.currentProduct = { title:'lakeVillages', imageName: 'cabins' };
      }
    };
  </script>

<style lang="scss" scoped>
.product-summary {
    &__details {
      &__content {
        &__services{
          &__item {
            height: auto;
            min-height: 42px;
          }
        }
        &__information {
          li {
            margin-bottom: 1.5rem;
          }
        }
      }
    }
}

</style>