<template>
<div class="maintenance">
    <header>
        <div class="logo">
            <div :class="getClassByWidth">
                <img src="@/assets/las-canadas__logo.svg" alt="Las Cañadas">
            </div>

        </div>
    </header>
    <div class="maintenance-message">
        <img src="@/assets/maintenance/maintenance.svg" alt="maintenance image">
        <div class="text">
            <h1>ESTAMOS EN MANTENIMIENTO</h1>
            <h4>TRABAJAMOS PARA MEJORAR TU EXPERIENCIA,</h4>
            <h4>POR FAVOR VUELVE M&Aacute;S TARDE</h4>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'Maintenance',
    data() {
        return {
            showView: process.env.VUE_APP_false
        }
    },
    computed: {
        getClassByWidth() {
            return screen.width < 992 ? 'box-mobile' : 'box';
        }
    }
}
</script>

<style lang="scss" scoped>
.maintenance {
    width: 100vw;
    height: 100vh;

    header {
        display: flex;
        flex-direction: row;
        height: 80px;
        background: #FFFFFF;
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);

        @include respond-to("medium and down") {
            justify-content: center;
        }

        .logo {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 0px 24px 24px;

            .box {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 24px;
                background: #FFFFFF;
                box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
                border-radius: 0px 0px 16px 16px;

                img {
                    width: 200px;
                    height: 68px;
                }
            }

            .box-mobile {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                padding: 16px;
                
                img {
                    width: 144px;
                    height: 48.96px;
                }
            }
        }

    }

    .maintenance-message {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 24px;
        height: calc(100% - 80px);

        img {
            width: 470.1px;
            height: 351.25px;

            @include respond-to("small and down") {
                width: 312px;
                height: 233.14px;
            }

            @media screen and (orientation: landscape) and (max-height: 480px) {
                width: 165.1px;
                height: 115.9px;
            }
        }

        .text {
            h1, h4 {
                color: #786F66;
            }

            h1 {
                font-size: 40px;
                line-height: 55px;
                margin-bottom: 16px;
            }

            h4 {
                font-size: 20px;
                line-height: 27px;
            }

            display: flex;
            flex-direction: column;
            align-items: center;
        }

        @include respond-to("small and down") {
            h1, h4 {
                text-align: center !important;
            }

            h1 {
                font-size: 24px !important;
                line-height: 33px !important;
            }

            h4 {
                font-size: 16px !important;
                line-height: 22px !important;
            }
        }

        @media screen and (orientation: landscape) and (max-height: 480px) {
            h1 {
                font-size: 20px !important;
                line-height: 33px !important;
                margin-bottom: 8px !important;
            }

            h4 {
                font-size: 14px !important;
                line-height: 22px !important;
            }
        }
    }

}
</style>