<template>
    <checkout-layout>
        <current-step class="thank-you__steps"></current-step>
        <column-block class="thank-you" v-if="reservation">
            <h2 class="thank-you__header">{{ $t('thankYouForYourPurchase') }}</h2>
            <div class="thank-you__card thank-you__order">
                <span>{{ $t('dates') }}</span>
                <div class="thank-you__order__date">
                    <div class="thank-you__order__date__dates date">
                        <span>{{ reservation.startDateString }}</span>&nbsp;
                        <img src="@/assets/arrow-right.svg" alt="">
                        <span>{{ reservation.endDateString }}</span>
                    </div>
                    <div class="thank-you__order__date__time" v-if="hasPasses">
                        <img :src="require('@/assets/'+reservation.startTime+'--gray.svg')" alt="">
                        <span>{{ $t(reservation.startTimeString) }}</span>
                    </div>
                    <div class="time-notice" v-else>
                        <span>{{ arrivalTimeNotice }}</span>
                    </div>
                </div>
                <div class="thank-you__order__arrival">
                    <div class="label">{{ $t('arrival') }}</div>
                    <div class="date">
                        <img src="@/assets/calendar--gray.svg" alt=""/>
                        <span>{{ reservation.startDateString }}</span>
                    </div>
                    <div class="time" v-if="hasPasses">
                        <img :src="require('@/assets/'+reservation.startTime+'--gray.svg')" alt="">
                        <span>{{ $t(reservation.startTimeString) }}</span>
                    </div>
                    <div class="time-notice" v-else>
                        <span>{{ arrivalTimeNotice }}</span>
                    </div>
                </div>
                <div class="thank-you__order__departure">
                    <div class="label">{{ $t('departure') }}</div>
                    <div class="date">
                        <img src="@/assets/calendar--gray.svg" alt="">
                        <span>{{ reservation.endDateString }}</span>
                    </div>
                    <div class="time">
                        <img :src="require('@/assets/'+reservation.endTime+'--gray.svg')" alt="">
                        <span>{{ $t(reservation.endTimeString) }}</span>
                    </div>
                </div>
                <div class="thank-you__order__items">
                    <div class="item item__header">
                        <span class="item__quantity">{{ $t('quantity') }}</span>
                        <span class="item__description">{{ $t('products') }}</span>
                        <span class="item__unit-price">{{ $t('unitPrice') }}</span>
                        <span class="item__total">{{ $t('total') }}</span>
                    </div>
                    <div class="item item__details" v-for="(item, index) in reservation.items" :key="index" :class="{'last-child': ((index + 1) == reservation.items.length)}">
                        <div class="item__quantity">
                            <span class="value">{{ item.quantity }}</span>
                        </div>
                        <div class="item__description">
                            <div class="value block">
                                <img :src="require('@/assets/services/' + item.icon + '.svg')" alt="">
                                <span>{{ $t(item.name) }}</span>
                            </div>
                        </div>
                        <div class="item__unit-price">
                            <span class="value">{{ item.price | currency }}</span>
                        </div>
                        <div class="item__breakdown">
                            <span>{{ item.quantity }} x {{ item.price | amount }}</span>
                        </div>
                        <div class="item__total">
                            <span class="value">{{ item.quantity * item.price * (item.hours ? item.hours : 1) | currency }}</span>
                        </div>
                    </div>
                    <div class="lodging-notice" v-if="isCabin || isTipi || isVillages">
                        <div class="notice">
                            <img src="@/assets/important-24.svg">
                            <span>{{ campingPassNotice }}</span>
                        </div>
                        <div class="notice" v-if="isVillages">
                            <img src="@/assets/important-24.svg">
                            <span>{{ $t('additionLakeVillas') }}</span>
                        </div>
                    </div>
                    <div class="item__footer">
                        <div class="item__footer__total promotion" v-if="order.quote.discountAmount!=0">
                            <span class="title">{{$t('promotion')}}</span>
                            <span class="value">-{{ order.quote.discountAmount | currency }}</span>
                        </div>
                        <div class="item__footer__total">
                            <span class="title">{{$t('total')}}</span>
                            <span class="value">{{ reservation.total | currency }}</span>
                        </div>
                    </div>
                </div>
                <div class="thank-you__order__code">
                    <div class="thank-you__order__code__container">
                        <p>{{ $t('presentThisCodeWhenArrivingToTheCamp') }}</p>
                        <qr-code :value="reservation.code" :options="{ width: 163, margin: 0 }" tag="img"></qr-code>
                        <h1>{{ reservation.code }}</h1>
                        <button @click="downloadSummary" :disabled="isSummaryButtonDisable" class="btn btn--primary print-btn" :class="{disabled: isSummaryButtonDisable}">{{ $t('printReceipt') }}</button>
                    </div>
                </div>
            </div>
            <div class="thank-you__information-contact-and-payment">
                <div class="thank-you__card__information-card thank-you__contact-info">
                    <h2>{{ $t('contactInformation') }}</h2>
                    <div class="thank-you__contact-info__data-info">
                        <div class="thank-you__contact-info__data data-name" v-if="reservation.contact && reservation.contact.name">
                            <span class="title">{{ $t('name') }}</span>
                            <span class="value">{{ reservation.contact.name }}</span>
                        </div>
                        <div class="thank-you__contact-info__data data-origin" v-if="reservation.contact && reservation.contact.origin">
                            <span class="title">{{ $t('origin') }}</span>
                            <span class="value">{{ reservation.contact.origin }}</span>
                        </div>
                        <div class="thank-you__contact-info__data data-email" v-if="reservation.contact && reservation.contact.email">
                            <span class="title">{{ $t('email') }}</span>
                            <span class="value">{{ reservation.contact.email }}</span>
                        </div>
                    </div>
                </div>
                <div class="thank-you__card__information-card thank-you__payment-method">
                    <h2>{{ $t('paymentInfo') }}</h2>
                    <div class="thank-you__payment-method__data finge" v-if="reservation.payment && reservation.payment.cardNumber">
                        <p>{{$t('card')}}</p>
                        <img v-if="reservation.payment.vendor" :src="require('@/assets/' + reservation.payment.vendor + '.svg')" alt="">
                        <span>{{ reservation.payment.cardNumber }}</span>
                    </div>
                    <div class="thank-you__payment-method__data finge" v-if="reservation.payment && order.quote.contact.name">
                        <p class="name">{{$t('holderName')}}</p>
                        <span>{{ order.paymentInfo.cardOwner }}</span>
                    </div>
                </div>
            </div>
            <data-vehicle class="vehicle" v-if="savedVehicle" :vehicle="savedVehicle"></data-vehicle>
            <update-entry-form v-if="false && !existEntryByVisitCode && !savedVehicle" :visit-code="reservation.code" @onSave="saveVehicle"></update-entry-form>
        </column-block>
    </checkout-layout>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import CheckoutLayout from "@/layouts/CheckoutLayout";
import qrCode from '@chenfengyuan/vue-qrcode';
import CurrentStep from '@/views/checkout/CurrentStep';
import ColumnBlock from '@/components/columns/ColumnBlock';
import UpdateEntryForm from '@/components/forms/UpdateEntryForm';
import DataVehicle from '@/components/forms/DataVehicle';
import pdfManager from '@/utils/pdfManager';
import { fbq } from '@/main.js';

export default {
  name: "ThankYou",
  components: { CheckoutLayout, CurrentStep, qrCode, ColumnBlock, UpdateEntryForm, DataVehicle },
  metaInfo: {
    title: "Las Cañadas Campamento",
    titleTemplate: null
  },
    computed: {
        ...mapState([
            "quote"
        ]),
        hasPasses() {
            if(!this.reservation) {
                return false;
            }

            const items = this.reservation.items;
            const campingPass = items.find(item => item.name === 'campingPass');
            const oneDayPass = items.find(item => item.name === "oneDayPass");

            return (oneDayPass && oneDayPass.quantity > 0) || (campingPass && campingPass.quantity > 0);
        },
        orderQuote() {
            if(!this.order) {
                null;
            }

            return this.order.quote;
        },
        isCabin() {
            if (!this.orderQuote) {
                return false;
            }

            return this.orderQuote ? this.orderQuote.products.cabins : null;
        },
        isVillages() {
            if (!this.orderQuote) {
                return false;
            }

            return this.orderQuote ? this.orderQuote.products.lakeVillages : null;
        },
        isTipi() {
            if (!this.orderQuote) {
                return false;
            }

            return this.orderQuote ? this.orderQuote.products.tipis : null;
        },
        arrivalTimeNotice() {
            if (this.isCabin) {
                return `${this.$t('cabinHourArrival')}`;
            } else if (this.isVillages) {
                return `${this.$t('lakeVillageHourArrival')}`;
            } else if (this.isTipi) {
                return `${this.$t('tipiHourArrival')}`;
            }

            return '';
        },
        campingPassNotice() {
            if (this.isCabin) {
                return `${this.$t('campingPassCabinNotice')}`;
            } else if (this.isVillages) {
                return `${this.$t('campingPassVillageNotice')}`;
            } else if (this.isTipi) {
                return `${this.$t('campingPassTipiNotice')}`;
            }

            return '';
        },

    },
  data() {
    return {
        order: null,
        reservation: null,
        existEntryByVisitCode: false,
        savedVehicle: null,
        isSummaryButtonDisable: false,
        mobile: false
    };
  },
  beforeDestroy() {
    this.$store.dispatch('cleanQuoteState');
  },
  async mounted() {
    try {
        await this.getQuoteByCode(this.$route.params.code);
        await this.getIfExistEntryByVisitCode(this.$route.params.code);
    } catch (error) {
        this.$store.dispatch("message", {
            type: "error",
            message: "Error al cargar los detalles"
        });
    }
  },
  methods: {
    async getQuoteById(sCartId) {
      this.$store.dispatch("beginRemoteActivity");
      try {
        let sCart = await this.$store.dispatch("loadQuoteById", sCartId);
        if(!sCart.error) {
          this.order = sCart;
          this.setReservation();
          this.$store.dispatch("endRemoteActivity");
        }
      } catch (e) {
        this.$store.dispatch("message", {
          type: "error",
          message: "Error al cargar los detalles"
        });
        this.$router.push({ name: 'homepage' });
        this.$store.dispatch("endRemoteActivity");
      }
    },

    async getIfExistEntryByVisitCode(orderCode) {
        try {
            this.existEntryByVisitCode = await this.$store.dispatch("checkIfExistVisitPlannedByCode",orderCode);
        } catch (e) {
            this.$store.dispatch("message", {
                type: "error",
                message: "Error al cargar los datos"
            });
        }
    },

    async getQuoteByCode(orderCode) {
      this.$store.dispatch("beginRemoteActivity");
      try {
        let sCart = await this.$store.dispatch("loadQuoteByCode", orderCode);
        let quote = await this.$store.dispatch('getQuoteByVisitCode', orderCode);
        if(!sCart.error) {
          this.order = sCart;
          this.order.quote = quote;
          fbq('track', 'Purchase', { value: this.order.totalAmount, currency: 'MXN'});
          this.setReservation();
          this.$store.dispatch("endRemoteActivity");
        }
      } catch (e) { return; }
    },

    getPricePerItem(item) {
        return (item && item.quantity && item.totalAmount) ? item.totalAmount / item.quantity : 0;
    },

    getItemByKey(quote, key) {
        let item = quote && quote.productItems.find(i => i.key === key);
        return item ? {price: item.price, quantity: item.quantity, totalAmount: item.totalAmount } : null;
    },
    getVisitActivityByType(type) {
      let activity = null;
      if(this.order.quote && this.order.quote.visitActivityReservations && this.order.quote.visitActivityReservations.length > 0) {
        let reservationsByType = this.order.quote.visitActivityReservations
            .filter(r => r.visitActivitySchedule.visitActivity.type == type);
        if(reservationsByType.length > 0) {
          activity = {
            quantity: 0,
            hours: 0,
            price: 0,
            type: type
          }
          reservationsByType.forEach(r => {
            activity.quantity = r.quantity + r.borrowedUnits;
            activity.hours += r.hours;
            activity.price = r.activityPrice;
          })
        }
      }
      return activity;
    },

    setReservation() {
        if(!this.order) this.reservation = {};
        let items = [];

        const dayPass = this.getItemByKey(this.order.quote, 'day_pass');
        const campingPass = this.getItemByKey(this.order.quote, 'camping_pass');
        const cabin = this.getItemByKey(this.order.quote, 'cabin');
        const tipi = this.getItemByKey(this.order.quote, 'tipi');
        const lakeVillage = this.getItemByKey(this.order.quote, 'lake_village');
        const atv = this.getVisitActivityByType('ATV');
        const canopyNightPasses = this.getVisitActivityByType('CANOPY_TOUR_NIGHT');

        if(dayPass && dayPass.quantity > 0) {
            items.push({
                order: 0,
                icon: 'day-pass',
                name: 'oneDayPass',
                quantity: dayPass.quantity,
                price: this.getPricePerItem(dayPass)
            });
        }
        if (campingPass && campingPass.quantity > 0) {
            items.push({
                order: 1,
                icon: 'camping-pass',
                name: 'campingPass',
                quantity: campingPass.quantity,
                price: this.getPricePerItem(campingPass)
            })
        }
        if(cabin) {
            items.push({ order: 2, icon: 'cottage', name: 'ecotouristCabins', quantity: cabin.quantity, price: cabin.price })
        }
        if(tipi) {
            items.push({ order: 3, icon: 'tepee', name: 'teepees', quantity: tipi.quantity, price: tipi.price })
        }

        if (lakeVillage) {
            items.push({
                order: 4,
                icon: 'lake-village',
                name: 'lakeVillages',
                quantity: lakeVillage.quantity,
                price: lakeVillage.price,
            });
        }

        if(this.order.quote.products && this.order.quote.products.canopyPasses > 0) {
            let existingItemCanopy = null;
            if(existingItemCanopy == null && this.order.quote.items && this.order.quote.items.length > 0) {
                existingItemCanopy = this.order.quote.items.find(item => item.product.key == 'canopy_pass');
            }
            this.order.quote.products.canopyPassCost =
                (existingItemCanopy && existingItemCanopy.totalAmount && existingItemCanopy.quantity &&
                (existingItemCanopy.totalAmount / existingItemCanopy.quantity)) || 0;
            items.push({ order: 5, icon: 'canopy', name: 'canopy', quantity: this.order.quote.products.canopyPasses, price: this.order.quote.products.canopyPassCost })
        }
        if(canopyNightPasses){
            items.push({
                order: 6,
                icon:'canopy-night',
                name: 'canopyNight',
                quantity: canopyNightPasses.quantity,
                price: canopyNightPasses.price,
                setProduct: async (canopyNightPassesQuantity) => {
                    await this.$store.dispatch('updateProducts', {canopyNightPasses: canopyNightPassesQuantity});
                }
            })
        }
        if(this.order.quote.products && this.order.quote.products.horsebackRidingPasses > 0) {
            let existingItemHorseback = null;
            if(existingItemHorseback == null && this.order.quote.items && this.order.quote.items.length > 0) {
                existingItemHorseback = this.order.quote.items.find(item => item.product.key == 'horseback_riding_pass');
            }
            this.order.quote.products.horsebackRidingCost =
                (existingItemHorseback && existingItemHorseback.totalAmount && existingItemHorseback.quantity &&
                (existingItemHorseback.totalAmount / existingItemHorseback.quantity)) || 0;
            items.push({ order: 7, icon: 'horse-ride', name: 'horseRide', quantity: this.order.quote.products.horsebackRidingPasses, price: this.order.quote.products.horsebackRidingCost })
        }

        if(atv) {
            items.push({
                order: 8,
                icon: 'atv',
                name: 'atvs',
                quantity: atv.quantity,
                price: atv.price,
                hours: atv.hours
            })
        }

        let originCity = (this.order.quote.contact.city !== 'Nevada')
            ? this.order.quote.contact.city + ', ' + this.order.quote.contact.state
            : this.order.quote.contact.state;
        this.reservation =  {
            code: this.order.quote.visit.code,
            purchaseVia: "online",
            paymentMethod: "creditCard",
            startDate: new Date(this.order.quote.visit.arrivalDate),
            startDateString: this.getDateString(this.order.quote.visit.arrivalDate),
            startTime: this.order.quote.visit.arrivalTime && this.order.quote.visit.arrivalTime.toLowerCase(),
            startTimeString:
                this.order.quote.visit.arrivalTime &&
                this.getTimeString(this.order.quote.visit.arrivalTime.toLowerCase()),
            endDate: new Date(this.order.quote.visit.departureDate),
            endDateString: this.getDateString(this.order.quote.visit.departureDate),
            endTime: "day",
            endTimeString: this.order.quote.products.cabins > 0 || this.order.quote.products.tipis > 0 || this.order.quote.products.lakeVillages > 0 ? 'before12pm' : 'before6pm',
            departure: true,
            items: this.sortItems(items),
            total: this.order.quote.totalAmount,
            contact: {
                name: this.order.quote.contact.name + ' ' + this.order.quote.contact.lastname,
                email: this.order.quote.contact.email,
                origin:
                    originCity + ', ' +
                    this.order.quote.contact.country + ' '
            },
            payment: {
                vendor: this.order.paymentInfo.cardType,
                cardNumber: '**** **** **** ' + this.order.paymentInfo.lastDigits
            }
        };
    },

    getDateString(date) {
        if (!date) return "";
        moment.locale(this.$t('lang'));
        return (
            moment(date).format(this.$t('dateFormat')).replace(".", "")
        );
    },

    getTimeString(time) {
        if (!time) return "";
        let timeOptions = [
            { id: "day", message: "between7pm6pm" },
            { id: "night", message: "between6pm12am" }
        ];
        let t = timeOptions.find(item => item.id == time);
        return t && t.message;
    },

    saveVehicle(vehicle) {
        this.onGenericEvent(`agilizar visita`, {event_category: 'carrito', event_label: `cta - enviar información`});
        this.$store.dispatch("message", {
          type: "success",
          message: 'infoSent'
        });
        this.savedVehicle = vehicle;
    },

    async downloadSummary() {
        this.isSummaryButtonDisable = true;
        try {
            let encodedPdf = await this.$store.dispatch('downloadSummary', this.order.quote.id);
            await pdfManager.printPdf(encodedPdf, `Las_Cañadas_Campamento_${this.order.quote.visit.code}.pdf`);
        } catch (error) {
            this.$store.dispatch('message', {type: 'error', message: 'Error al obtener resumen'});
        }

        if(this.order.quote && this.order.quote.visitActivityReservations && this.order.quote.visitActivityReservations.length > 0) {
          try {
            let encodedPdf = await this.$store.dispatch('downloadActivitiesSummary', this.order.quote.id);
            await pdfManager.printPdf(encodedPdf, `Las_Cañadas_Campamento_${this.order.quote.visit.code}_activities.pdf`);
          } catch (error) {
            this.$store.dispatch('message', {type: 'error', message: 'Error al obtener resumen de actividades'});
          }
        }

        this.isSummaryButtonDisable = false;
    },
    sortItems(items) {
      return items.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0))
    },
    onGenericEvent(event, command) {
        this.$root.$emit('onGenericEvent', event, command);
    }
  }
};
</script>

<style lang="scss" scoped>
$dark-color-table: $dark-brown-color;
.thank-you {
    max-width: rem(1096px);
    grid-row-gap: 1.6rem;
    & > h2 {
        font-size: 1.5rem;
        @include respond-to("small and down") {
            margin-top: rem(-3px);
        }
    }
    &__header {
        margin-bottom: -2px;
        @include respond-to("medium and down") {
            font-weight: bold;
            line-height: 20px;
        }
    }
    &__card {
        background-color: rgba(#fff, 0.8);
        width: 100%;
        border: 1px solid rgba($dark-brown-color, 0.2);
        border-radius: rem(16px);
        padding: rem(24px);

        &__information-card {
            width: 100%;
            background-color: rgba(#fff, 0.8);
            border: 1px solid rgba($dark-brown-color, 0.2);
            border-radius: rem(16px);
            padding: rem(24px);
        }
        @include respond-to("large and up") {
            min-width: rem(1038px);
        }
        @include respond-to("large") {
            min-width: unset;
        }
        @include respond-to("medium and down") {
            max-width: 100%;
            padding: rem(22px) 0;
        }
    }
    &__order {
        display: grid;
        @include respond-to("medium and down") {
            padding-top: 16px;
            grid-row-gap: rem(14px);
            grid-template-columns: 50% 50%;
            grid-template-areas:
                "title title"
                "date date"
                "items items"
                "code code";
            &__arrival, &__departure { display: none; }
        }
        @include respond-to("large and up") {
            grid-column-gap: rem(16px);
            grid-template-columns: 1fr 1fr 1.465fr;
            grid-template-rows: auto auto;
            grid-row-gap: 16px;
            grid-template-areas:
                "arrival departure code"
                "items items code";
            &__date { display: none; }
        }
        @include respond-to("large") {
            grid-template-columns: 1fr 1fr 1fr;
        }
        & > span {
            font-weight: 500;
            grid-area: title;
            color: rgba($dark-brown-color, 0.5);
            text-transform: capitalize;
            @include respond-to("large and up") {
                display: none;
            }
        }
        & > span, &__date, &__items, &__code {
            @include respond-to("medium and down") {
                margin: 0 rem(16px);
            }
        }
        &__date {
            grid-area: date;
            border-bottom: 1px dashed rgba($dark-brown-color, 0.2);
            padding-bottom: rem(20px);
            @include respond-to("medium and down") {
                border-bottom: 1px dashed $dark-brown-color;
                .date span{
                    line-height: 22px;
                }
            }
            &__time {
                font-weight: 500;
                line-height: rem(16px);
                letter-spacing: 0.05em;
                color: rgba($dark-brown-color, 0.5);
                img, span {
                    vertical-align: middle;
                }
                img {
                    margin-right: rem(8px);
                }
                @include respond-to("medium and down") {
                    span{ opacity: 0.5; }
                }
            }
        }
        &__arrival { grid-area: arrival; }
        &__departure { grid-area: departure; }
        &__date, &__arrival, &__departure {
            .label {
                letter-spacing: 0.05em;
                color: rgba($dark-brown-color, 0.6);
                margin-bottom: rem(16px);
            }
            .date {
                margin-bottom: rem(8px);
            }
            .date, .time {
                font-weight: 400;
                img, span { vertical-align: middle; }
                img { margin-right: rem(8px); }
                span {
                    font-family: $font-secondary;
                    font-weight: 500;
                    font-size: rem(16px);
                    line-height: 20px;
                    letter-spacing: 0.05em;
                    text-transform: uppercase;
                }
            }
            .time-notice {
                text-align: justify;
                span {
                    font-family: 'Montserrat', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 16px;
                    letter-spacing: 0.05em;
                    color: $light-gray;
                }
            }
        }
        &__items {
            grid-area: items;

            .lodging-notice {
                .notice {
                    border-bottom: 1px dashed rgba(30, 15, 0, 0.2);
                    padding: 16px 0px;
                    display: grid;
                    grid-template-columns: 24px 1fr;
                    grid-column-gap: 8px;
                    align-items: start;
                    span {
                        font-family: 'Montserrat', sans-serif;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        letter-spacing: 0.05em;
                        color: $light-gray;
                        text-align: left;
                    }
                }
            }

            .item {
                display: grid;
                padding: 1rem 0;
                border-bottom: 1px dashed rgba($dark-brown-color, 0.2);
                font-size: 1rem;
                line-height: rem(20px);
                letter-spacing: 0.05em;
                grid-template-areas:
                    "product-order"
                    "quantity-order"
                    "price-order"
                    "total-order";
                .label, .value {
                    display: inline-block;
                    vertical-align: middle;
                }
                .value-promotion {
                    display: inline-block;
                    vertical-align: middle;
                    font-size: 16px;
                    font-family: "Chau Philomene One", sans-serif;
                }
                .label {
                    &::first-letter {
                        text-transform: uppercase;
                    }
                }
                &__header {
                    font-weight: 500;
                    padding-top: 0;
                    padding-bottom: rem(13px);
                    color: rgba($dark-brown-color, 0.5);
                    text-transform: capitalize;
                    @include respond-to("medium and down") {
                        display: none;
                    }
                }
                &__footer {
                    display: grid;
                    grid-row-gap: 1.5rem;
                    font-size: 1.5rem;
                    letter-spacing: 0.05em;
                    @include respond-to("large and up") {
                        padding-top: .8rem;
                    }
                    @include respond-to("medium and down") {
                        margin-top: 16px;
                        padding-bottom: 1.5rem;
                        border-bottom: 1px dashed rgba($dark-brown-color, 0.2);
                    }
                    &__total {
                        display: grid;
                        @include respond-to("large and up") {
                            grid-template-columns: 81px 1fr rem(142px) rem(100px);
                            grid-template-areas: "empty empty title value";
                            .title {
                                grid-area: title;
                                text-align: center;
                            }
                            .value {
                                grid-area: value;
                                text-align: right;
                            }
                        }
                        .title {
                            text-transform: uppercase;
                            font-family: "Chau Philomene One", sans-serif;
                        }
                        @include respond-to("medium and down") {
                            grid-template-columns: 1fr 1fr;
                            .value { text-align: right; }
                            .title {
                                font-size: 1rem;
                            }
                        }
                        &.promotion {
                            font-size: 1rem;
                            line-height: rem(22px);
                        }
                    }
                }
                &__details {
                    @include respond-to("medium and down") {
                        margin-bottom: 1rem;
                    }
                }
                &__details, &__footer {
                    .value { font-family: $font-secondary; }
                }
                &__description {
                    img, span {
                        vertical-align: middle;
                        display: inline-block;
                    }
                    img {
                        margin-right: .5rem;
                        @include respond-to("small and down") {
                            width: 1.5rem;
                        }
                    }
                    span {
                        text-transform: uppercase;
                    }
                    @include respond-to("medium and down") {
                            grid-area: product-order;
                    }
                }
                @include respond-to("large and up") {
                    &__details .item__breakdown{ display:none; }
                }
                @include respond-to("medium and down") {
                    border-bottom: 1px dashed $dark-brown-color;
                    & > div {
                       margin-bottom: rem(18px);
                    }
                    &__details{
                        grid-template-columns: 1fr auto;
                        padding:0;
                        .item{
                            &__description{ grid-column: 1 / span 3;}
                            &__quantity,
                            &__unit-price{
                                display:none;
                            }
                            &__breakdown span{
                                font-weight: 500;
                                line-height: 1rem;
                            }
                            &__total .value{
                                line-height: 22px;
                            }
                        }
                    }
                    &__footer{
                        padding-bottom: 1rem;
                        border-bottom-color: $dark-brown-color;
                    }
                }
                @include respond-to("large and up") {
                    grid-template-columns: 81px 1fr rem(142px) rem(100px);
                    .label {
                        display: none;
                    }
                    &__quantity {
                        text-align: center;
                        @include respond-to("medium and down") {
                            grid-area: quantity-order;
                        }
                    }
                    &__description {
                        padding-left: 1rem;
                    }
                    &__unit-price, &__total {
                        text-align: right;
                    }
                    &__unit-price {
                        @include respond-to("medium and down") {
                            grid-area: price-order;
                        }
                    }
                    &__total {
                        @include respond-to("medium and down") {
                            grid-area: total-order;
                        }
                    }
                    &__details {
                        .label {
                            display: inline-block;
                            width: 0px;
                            height: rem(32px);
                            visibility: hidden;
                        }
                    }
                }
                @include respond-to("large") {
                    grid-template-columns: repeat(4, 1fr);
                }
            }
            @include respond-to("medium and down") {
                margin-bottom: 1rem;
            }
        }
        &__code {
            grid-area: code;
            @include respond-to("large and up") {
                justify-self: end;
            }
            @include respond-to("small and down") {
                padding: 0;
                justify-self: center;
            }
            &__container {
                text-align: center;
                img {
                    margin: 24px 0;
                }
                .print-btn{
                    width: 100%;
                    margin-top: rem(28px);
                    padding-left: initial;
                    padding-right: initial;
                    @include respond-to("small and down") {
                        margin-top: rem(26px);
                    }
                }

                @include respond-to("large and up") {
                    width: rem(248px);
                    display: inline-block;
                    text-align: center;
                }
                @include respond-to("medium") {
                    display: grid;
                    grid-template-columns: 100%;
                    justify-items: center;
                }
                @include respond-to("medium and down") {
                    img {
                        width: 248px;
                        margin-bottom: 18px;
                    }
                    h1 {
                        margin-top: unset;
                    }
                    button {
                        cursor: pointer;
                        margin-top: rem(27px);
                    }
                }
                @include respond-to("small and down") {
                    width: 100%;
                    margin-bottom: rem(-7px);
                }
            }
            p {
                display: inline-block;
                color: rgba($dark-brown-color, 0.5);
                font-weight: 500;
                line-height: rem(16px);
                letter-spacing: 0.05em;
                max-width: rem(209px);
                @include respond-to("large and up") {
                    margin-top: 7px;
                }
                @include respond-to("small and down") {
                    margin-top: -7px;
                }
            }
            canvas {
                @include respond-to("large") {
                    width: rem(165px) !important;
                    height: rem(165px) !important;
                }
                @include respond-to("small and down") {
                    display: inline-block;
                    width: rem(290px) !important;
                    height: rem(290px) !important;
                }
            }
            h1 {
                display: inline-block;
                font-size: rem(32px) !important;
                @include respond-to("large and up") {
                    margin-top: rem(-9px);
                }
                @include respond-to("medium and down") {
                    margin-top: rem(3px);
                    line-height: rem(24px);
                    margin-bottom: 0;
                }
            }
        }
    }
    &__contact-info {
        display: grid;
        padding: rem(21px) rem(22px);
        h2 {
            font-size: rem(24px);
            margin-bottom: rem(30px);
            @include respond-to("small and down") {
                margin-bottom: rem(23px);
            }
        }
        &__data-info {
            display: grid;
            grid-row-gap: 1.5rem;
        }
        &__data {
            display: grid;
            grid-row-gap: .5rem;
            .title { color: $brown-opacity-color; }
        }
        @include respond-to("medium and down") {
            padding: rem(14px) rem(16px);
            &__data-info {
                display: grid;
                grid-template-areas: "name" "email" "origin";
                .data-name { grid-area: name; }
                .data-origin { grid-area: origin; }
                .data-email { grid-area: email; }
            }
            dt, dl, dd {
                background-color: red;
                margin: 0;
            }
        }
    }
    &__payment-method {
        padding: rem(21px) rem(22px);
        @include respond-to("medium and down") {
            padding: rem(9px) rem(16px);
        }
        p {
            color: #1E0F00;
            opacity: 0.6;
            font-size: 16px;
        }
        .name {
            padding-top: 1.8rem;
        }
        h2 {
            font-size: rem(24px);
            margin-bottom: 2rem;
            @include respond-to("small and down"){
                margin-top: rem(5px);
                margin-bottom: rem(23px);
            }
        }
        &__data {
            img, span { vertical-align: middle; }
            img { margin-right: rem(8px); }
            span {
                letter-spacing: 0.05em;
                font-weight: 500;
            }
        }
        @include respond-to("small and down") {
            padding-bottom: 12px;
        }
    }
    &__information-contact-and-payment {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: rem(24px);

        @include respond-to("small and down") {
            grid-template-columns: repeat(1, 1fr);
        }

        @include respond-to("medium and large"){
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @include respond-to("large and up") {
        padding: 0 1.5rem  rem(224px) 1.5rem;
        &__steps {
            margin-bottom: rem(17px);
        }
    }
    @include respond-to("medium and down") {
        padding: 0 1rem  rem(120px) 1rem;
        h2 {
            font-size: rem(16px);
        }
        &__steps {
            margin-bottom: rem(0px);
        }
    }
}
</style>
