<template>
    <modal ref="modal" @close="closeModal()" v-show="isOpen">
        <div class="modal__content change-departure-date" v-if="promotion">
            <div class="change-departure-date__title">
                <img src="@/assets/modal/alert.svg" alt="">
                <span>{{$t('modal.changeDates.title')}}</span>
                <a href="" @click.prevent="closeModal()"><img src="@/assets/deletesign.svg" alt=""></a>
            </div>
            <span class="change-departure-date__description">{{$t('modal.changeDates.description')}}</span>
            <div class="change-departure-date__dates">
                <div class="change-departure-date__dates__arrival-date">
                    <span class="title">{{$t('arrival')}}</span>
                    <div><img src="@/assets/calendar--gray.svg" alt=""><span>{{startDate | date}}</span></div>
                    <div v-if="isNightArrivalTime"><img src="@/assets/night--gray.svg" alt=""><span>{{$t('between6pm12am')}}</span></div>
                    <div v-else><img src="@/assets/day--gray.svg" alt=""><span>{{$t('between7pm6pm')}}</span></div>
                </div>
                <div class="change-departure-date__dates__departure-date">
                    <span class="title">{{$t('departure')}}</span>
                    <div><img src="@/assets/calendar--gray.svg" alt=""><span>{{promotion.endDate | date}}</span></div>
                    <div>
                        <img src="@/assets/day--gray.svg" alt="">
                            <span v-if="quote.products.cabins > 0 || quote.products.tipis > 0">{{ $t('before12pm') }}</span>
                            <span v-else>{{ $t('before6pm')}}</span>
                    </div>
                </div>
            </div>
            <button class="change-departure-date__button" @click="applyPromotion">{{$t('modal.changeDates.applyPromo')}}</button>
        </div>
    </modal>
</template>

<script>
import moment from 'moment';
import Modal from '@/components/modal/Modal';

    export default {
    name: 'ChangeDepartureDateModal',
        components: { Modal },
        props: ['promotion'],
        data() {
            return {
                isOpen: false,
            }
        },
        mounted() {},
        computed: {
            quote() {
                return this.$store.getters.quote;
            },
            isNightArrivalTime() {
              let startDate = moment(this.quote.visit.arrivalDate);
              let promotionEndDate = moment(this.promotion.endDate);
              let arrivalTime = this.datesValidation ? this.promotion && this.promotion.arrivalTime : this.quote.visit.arrivalTime;
                return arrivalTime  === 'NIGHT' && !startDate.isSame(promotionEndDate);
            },
            startDate() {
              return this.datesValidation ? this.promotion.startDate : this.quote.visit.arrivalDate;
            },
            datesValidation() {
              let promotionStartDate = moment(this.promotion.startDate);
              let promotionEndDate = moment(this.promotion.endDate);
              let arrivalDateDate = moment(this.quote.visit.arrivalDate);

              return this.promotion.datesValidityOnly
                  || arrivalDateDate.isBefore(promotionStartDate)
                  || arrivalDateDate.isAfter(promotionEndDate);
            }
        },
        methods: {
            openModal() {
                this.$refs.modal && this.$refs.modal.open();
                this.isOpen = true;
            },
            closeModal() {
                this.$refs.modal && this.$refs.modal.close();
                this.isOpen = false;
            },
            applyPromotion() {
                this.$emit('applyPromotion');
                this.closeModal();
            }
        }
    }
</script>

<style lang="scss" scoped>
    .change-departure-date {
        background-color: white;
        border-radius: 1rem;
        display: grid;
        span, button { letter-spacing: 0.05em; }
        &__title, &__description, &__dates {
            display: grid;
            align-items: center;
            grid-gap: .5rem;
        }
        &__title, &__dates__arrival-date > div, &__dates__departure-date > div {
            font-family: $font-secondary;
            text-transform: uppercase;
        }
        &__title {
            grid-template-columns: 1.5rem 1fr 1.5rem;
            font-size: 1.5rem;
            line-height: 33px;
        }
        &__description {
            font-weight: 500;
            line-height: 1.5rem;
        }
        &__dates {
            &__arrival-date, &__departure-date {
                display: grid;
                grid-row-gap: rem(12px);
                .title {
                    opacity: 0.6;
                    font-weight: 500;
                }
                div {
                    display: grid;
                    grid-template-columns: 1.5rem 1fr;
                    grid-column-gap: .5rem;
                    align-items: center;
                    span {
                        line-height: 22px;
                    }
                }
            }
        }
        &__button { cursor: pointer; }
        @include respond-to("medium and up") {
            grid-row-gap: 1.5rem;
            padding: 1.5rem;
            max-width: rem(533px);
            &__dates {
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 2.5rem;
            }
        }
        @include respond-to("small and down") {
            grid-row-gap: 1rem;
            padding: 1rem;
            max-width: rem(343px);
            &__dates { grid-row-gap: 1rem; }
        }
    }
</style>
