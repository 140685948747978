<template>
  <header>
    <column-block class="header__content" :class="{'book': !visitDrawerVisible && existQuote}">
      <div class="header__menu"><img src="@/assets/bars/sandwish.svg" @click="openSideBar" alt=""></div>
      <div class="header__logo">
        <router-link to="/">
          <img class="header__logo--color" src="../../assets/las-canadas__logo.svg" alt="Las Cañadas">
        </router-link>
      </div>
      <visit-details class="header__visit-details" v-if="quote && quote.totalAmount > 0 && !visitDrawerVisible"></visit-details>
      <a key="opener" class="gtm-quoter button header__actions action__open" v-show="!visitDrawerVisible && !existQuote" href="" @click.prevent="openDrawer">{{ $t('getPrices') }}</a>
      <div class="header__actions edit" v-if="!visitDrawerVisible && existQuote">
        <a class="header__btn" href="" @click.prevent="openDrawer">{{ $t('edit') }}</a>
        <a class="gtm-step3 button header__actions action__open book" href="" @click.prevent="goToShoppingCart()"
          :class="{disabled: capacityFull || !haveDates || isUnavailableCabins || isUnavailableTipis || isUnavailableLakeVillage}">
          {{ $t('pay') }}
        </a>
      </div>
      <a key="closer" class="header__actions action__close" v-if="visitDrawerVisible" href="" @click.prevent="closeDrawer"><img src="@/assets/chevron-up.svg" alt=""></a>
      <a href="" class="header__shopping-cart" @click.prevent="goToShoppingCart()"></a>
    </column-block>
  </header>
</template>

<script>
  import ColumnBlock from '@/components/columns/ColumnBlock';
  import VisitDetails from '@/components/VisitDetails';
  import {mapState} from 'vuex';

  export default {
    name: 'HeaderBar',
    components: {ColumnBlock, VisitDetails},
    computed: {
      ...mapState(['visitDrawerVisible', 'quote', 'isUnavailableCabins', 'isUnavailableTipis', 'isUnavailableLakeVillage']),
      existQuote() {
        return !!(this.quote && this.quote.id);
      },
      capacityFull() {
        if (!this.quote) {
          return false;
        }

        if((this.quote.cabinNumbers && this.quote.cabinNumbers.length > 0)
          || (this.quote.tipiNumbers && this.quote.tipiNumbers.length > 0)
          || (this.quote.lakeVillageNumbers && this.quote.lakeVillageNumbers.length > 0)) {
          return false;
        }

        return this.haveDates ? (this.quote.visit.arrivalTime === 'DAY' ? this.$store.getters.capacityFullDay : this.$store.getters.capacityFullNight) : false;
      },
      haveDates() {
        return this.quote && this.quote.visit && this.quote.visit.arrivalDate && this.quote.visit.departureDate && this.quote.visit.arrivalTime;
      }
    },
    mounted() {
      this.$root.$on('fromShoppingCart', () => {
        setTimeout(this.openDrawer, 200);
      });
    },
    methods: {
      openSideBar() {
        this.$store.dispatch('showSideHeaderBar');
      },
      async openDrawer() {
        await this.$store.dispatch('showVisitDrawer');
        this.onGenericEvent(`abrir cotizador`, {event_category: 'header', event_label: `cta cotizar`});
      },
      closeDrawer() {
        this.$store.dispatch('hideVisitDrawer');
        this.onGenericEvent(`cerrar cotizador`, { event_category: 'cotizador', event_label: `chevron` });
      },
      goToShoppingCart() {
          this.analyticsAddToCart();
          this.onAddToCartEvent();
          this.onSuccessfulReservationEvent();
          this.$router.push({ name: 'shopping-cart' });
      },
      analyticsAddToCart() {
        this.onGenericEvent(`ir a carrito`, {event_category: 'header', event_label: `icono carrito`});
      },
      book() { //TODO: WHY ONLY CABINS !?
        if(this.quote.totalAmount != 0 && !this.isUnavailableCabins){
            this.analyticsBook();
            this.$router.push({ name: 'contact-payment' });
        }
      },
      analyticsBook() {
        this.onGenericEvent(`reservar`, { event_category: 'cotizador', event_label: `cta - reservar` });
      },
      onAddToCartEvent() {
        this.$root.$emit('onAddToCartEvent', this.quote.productItems);
      },
      onSuccessfulReservationEvent() {
        this.$root.$emit('onSuccessfulReservationEvent', this.quote);
      },
      onGenericEvent(event, command) {
        this.$root.$emit('onGenericEvent', event, command);
      },
    }
  };

</script>

<style lang="scss" scoped>
  .column {
    @include respond-to("large and up") {
      padding: 0 4px 0 1rem;
    }
  }
  header {
    display: grid;
    background-color: white;
    border-bottom: $header-bar__border;
    position: relative;
    z-index: 6;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);

    .header {
      &__content {
        display: grid;
        position: relative;

        @include respond-to("medium and down") {
          height: rem($header-bar__height--mobile);
          grid-template-columns: 1fr;
        }
        @include respond-to("large and up") {
          padding: 0 1rem 0 1.5rem;
          height: rem($header-bar__height--desktop);
          grid-template-columns: rem(248px) 1fr rem(192px) rem(40px);
          grid-template-areas: "logo details actions shopping-cart";
          grid-gap: 1rem;
          &.book {
            grid-template-columns: rem(248px) 1fr auto rem(40px);
          }
        }
      }

      &__logo {
        justify-self: center;
        position: relative;
        @include respond-to("medium and down") {
          place-self: center center;
          display: grid;
          align-items: center;
          width: rem(143px);
        }
        @include respond-to("large and up") {
          width: 100%;
          &--color {
            width: rem(200px);
          }
        }

        a {
          width: 100%;

          @include respond-to("large and up") {
            display: grid;
            place-content: center center;
            position: absolute;
            background-color: white;

            height: rem(115px);
            box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);

            border-bottom-left-radius: $big-radius;
            border-bottom-right-radius: $big-radius;
          }

        }


        @include respond-to("large and up") {
          &--color {
            display: block;
          }
          &--white {
            display: none;
          }
        }
      }

      &__visit-details {
        @include respond-to("medium and down") {
          display: none;
        }
      }

      &__actions {
        width: rem(192px);
        @include respond-to("medium and down") {
          display: none;
        }
        grid-area: actions;

        place-self: center center;
        color: white;

        &.action__close {
          text-align: right;
        }
        &.edit {
          width: 18rem;
          @include respond-to("large and down") {
            display: none;
          }
        }
        &.book {
          display: inline-block;
        }
      }

      &__menu {
        @include respond-to("medium and down") {
          padding: 1rem 1.5rem;
          height: 100%;
          position: absolute;
          img {
            height: 100%;
            width: rem(24px);
          }
        }
        @include respond-to("large and up") { display: none }
      }

      &__shopping-cart {
        width: 100%;
        height: 100%;

        grid-area: shopping-cart;

        background-image: url('../../assets/shopping-cart-black.svg');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: rem(25px) auto;

        display: block;

        @include respond-to("medium and down") {
          height: $header-bar__height--mobile;
          width: rem($header-bar__height--mobile - 30px);
          position: absolute;
          right: rem(12px);
          bottom: 0;
        }
      }
      &__btn {
        display: inline-block;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        padding-right: 32px;
        text-decoration: none;
      }
    }
  }
</style>
