<template>
  <products-air selected-product="suspensionBridges">
    <div class="product-summary__details" v-if="currentProduct">
      <gallery>
        <template slot="small-image">
          <img :src="require('@/assets/products/suspension-bridges/bridges_' + gallery[0] + '.jpg')" alt="">
        </template>
        <template slot="gallery-images">
          <img class="slide" v-for="(picture, i) in gallery" :key="i" :src="require('@/assets/products/suspension-bridges/bridges_' + picture + '.jpg')" alt="">
        </template>
      </gallery>
      <div class="product-summary__details__title">
        <h2 class="subtitle">{{ $t('canopyTour') }}</h2>
        <h1 class="title">{{ $t(currentProduct.title) }}</h1>
      </div>
      <add-to-cart class="product-summary__details__add" :product="information[currentProduct.id]"></add-to-cart>
      <div class="product-summary__details__content">
        <div class="product-summary__details__content__information">
          <div class="product-summary__details__content__information__description">
            <p>{{ $t('productPages.suspensionBridges.text') }}</p>
          </div>
          <div class="product-summary__details__content__information__subtitles">
            <li v-for="(subtitle, i) in $t('productPages.suspensionBridges.subtitles')" :key="i">
              <span class="subtitle">{{subtitle.subtitle}}</span>
              <span class="text">{{subtitle.text}}</span>
            </li>
          </div>
        </div>
      </div>
    </div>
  </products-air>
</template>

<script>
  import Gallery from '@/components/Gallery';
  import ProductsAir from '@/views/products/ProductsAir';
  import AddToCart from '@/views/components/AddToCart';

    const information = {
      suspensionBridges: {
        description: {
          text: 'productPages.suspensionBridges.text',
        },
        info: 'productPages.canopyTour.info'
      }
    }

  export default {
    name: 'SuspensionBridges',
    components: {ProductsAir, AddToCart, Gallery},
    metaInfo() {
      return {title: this.$t("tabs.hangingBridges")}
    },
    data() {
      return {
        currentProduct: null,
        informationHidden: true,
        gallery: [1, 2, 3, 4]
      }
    },
    computed: {
        information() {
            return information;
        }
    },
    mounted() {
      this.currentProduct = { id: 'suspensionBridges', title:'suspensionBridges', imageName: 'canopy-tour' };
    },
    methods: {}
  };
</script>

<style lang="scss" scoped>

  .product-summary {
    &__details {
      &__title {
        grid-template-columns: unset;
        height: auto;
        padding-top: 0;
        .subtitle {
          font-size: 24px;
          opacity: 0.6;
          @include respond-to('medium and down') {
            font-size: 16px;
          }
        }
      }
      &__content {
        &__services {
          height: rem(250px);
        }
        &__information {
          &__description {
            margin-top: 2rem;
          }
        }
      }
      .image-0 { background-position: center }
      .image-2 { background-position: bottom }
    }
  }
</style>