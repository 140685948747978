<template>
  <modal ref="modal" class="modal" v-show="isOpen" @close="closeModal(false)">
    <div class="modal__content activities-info-modal">
        <h2>{{ $t( modalName ) }}</h2>
        <p class="activities-info-modal__subtitle">{{$t('seeImpInfo')}}</p>
        <ul>
          <li v-for="(info, index) in modalInfo" :key="index" >
            <div class="bullet"></div>
            <p>{{ $t(info) }}</p>
          </li>
        </ul>
        <button @click="closeModal(false)">{{$t('close')}}</button>
    </div>
  </modal>
</template>

<script>
import Modal from '@/components/modal/Modal';

export default {
    name: 'ActivitiesInfoModal',
    components: { Modal },
    data() {
        return {
          isOpen: false,
          modalType: null,
          modalInfo: null,
          modalName: null,
          modalOptions: [
            [                    
              `activitiesInfo.canopy.text1`,
              `activitiesInfo.canopy.text2`,
              `activitiesInfo.canopy.text3`,
              `activitiesInfo.canopy.text4`
            ],
            [     
              `activitiesInfo.nightCanopy.text1`,  
              `activitiesInfo.nightCanopy.text2`,  
              `activitiesInfo.nightCanopy.text3`,   
              `activitiesInfo.nightCanopy.text4`,
              `activitiesInfo.nightCanopy.text5`,        
            ],
            [                      
              `activitiesInfo.horsebackRiding.text1`,  
              `activitiesInfo.horsebackRiding.text2`,  
              `activitiesInfo.horsebackRiding.text3`,   
              `activitiesInfo.horsebackRiding.text4`,
              `activitiesInfo.horsebackRiding.text5`,  
              `activitiesInfo.horsebackRiding.text6`, 
              `activitiesInfo.horsebackRiding.text7`, 
            ],
            [                      
              `activitiesInfo.atvRental.text1`,  
              `activitiesInfo.atvRental.text2`,  
              `activitiesInfo.atvRental.text3`,   
              `activitiesInfo.atvRental.text4`,
              `activitiesInfo.atvRental.text5`,  
            ]
          ]
        }
    },
    methods: {
        openModal() {
          this.$refs.modal && this.$refs.modal.open();
          this.isOpen = true;
          this.$store.dispatch('activeModal', true);
        },
        closeModal(val) {
          this.$store.dispatch('updateActivitiesModalVisible', val);
          this.$refs.modal && this.$refs.modal.close();
          this.isOpen = false;
          this.$store.dispatch('activeModal', false);
        },
        assignModalType() {
          switch (this.modalType) {

            case 'canopy_tour':
              this.modalName = 'activities.canopy_tour';
              this.modalInfo = {...this.modalOptions[0]};
              break;

            case 'canopy_tour_night':
              this.modalName = 'activities.canopy_tour_night';
              this.modalInfo = {...this.modalOptions[1]};
              break;

            case 'horseback_riding':
              this.modalName = 'activities.horseback_tour';
              this.modalInfo = {...this.modalOptions[2]};
              break;

            case 'atv':
              this.modalName = 'activities.atv_rental';
              this.modalInfo = {...this.modalOptions[3]};
              break;
         
            default:
              break;
          }
        }
    },
    watch: {
      '$store.getters.activitiesModalVisible'() {
        this.$store.getters.activitiesModalVisible ? this.openModal() : this.closeModal();
      },
      '$store.getters.activityModalType'() {
        this.modalType = this.$store.getters.activityModalType;
        this.assignModalType();
      }
    },
    mounted() {
      this.modalType = this.$store.getters.activityModalType;
      this.assignModalType();
    }
}
</script>

<style lang="scss" scoped>
.activities-info-modal {
  background-color: #fff;
  border-radius: 1rem;
  display: grid;
  margin: 0 auto;
  max-width: 32.25rem;
  padding: 1.5rem;
  cursor: default;
  h2 {
    font-size: 1.5rem;
    font-weight: 500;
  }
  &__subtitle {
    font-family: Chau Philomene One;
    font-size: rem(20px);
    font-weight: 500;
    margin-bottom: 1rem;
    opacity: 0.6;
    text-transform: uppercase;
  }
  ul {
    display: grid;
    align-items: center;
    margin-bottom: 1rem;
    position: relative;
    li {
      display: grid;
      grid-template-columns: 14px 1fr;
      .bullet {
        background-color: $orange-color;
        border-radius: 50%;
        justify-self: left;
        margin-top: 9px;
        height: 6px;
        width: 6px;
      }
      p {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0.25rem;
        text-transform: none;
      }
    }
    
  }
  button {
    cursor: pointer;
    letter-spacing: 0.05em;
    width: 100%;
  }
  &__description {
    display: grid;
    align-items: center;
    grid-gap: 0.5rem;
  }
  @include respond-to("small and down") {
    max-width: 370px;
    ul {
      height: 40vh;
      overflow: scroll;
    }
  }
}
</style>