<template>
  <div class="cart-summary" v-if="quote">
    <h1>{{ $t('summary') }}</h1>
    <div class="cart-summary__card">
      <div class="cart-summary__card__list" v-if="summaryItems">
        <div class="cart-summary__card__list__item double" :class="{hidden: summaryItems.day == 0}">
          <span class="cart-summary__card__list__item__pointer"></span>
          <span class="cart-summary__card__list__item__text camping" v-if="summaryItems.camping && summaryItems.camping.value > 0">{{summaryItems.camping.value}} {{$t(summaryItems.camping.name)}},</span>
          <span class="cart-summary__card__list__item__text" v-if="summaryItems.day">{{summaryItems.day.value}} {{$t(summaryItems.day.name)}}</span>
        </div>
        <div class="cart-summary__card__list__item" v-for="(item, index) in summaryItems.items" :key="index" :class="{hidden: item.value == 0}">
          <span class="cart-summary__card__list__item__pointer"></span>
          <span class="cart-summary__card__list__item__text">{{item.value}} {{$t(item.name)}}</span>
        </div>
      </div>
      <div class="cart-summary__card__hour">
        <h2>{{ $t('arrival') }}</h2>
        <div>
          <img src="@/assets/calendar.svg" alt="">
          <span>{{ quote.visit.arrivalDate | dateTypeB(this.$i18n.locale) }}</span>
        </div>
        <div class="arriva-time--mobile" v-if="hasPasses">
          <img :src="require('@/assets/'+quote.visit.arrivalTime.toLowerCase()+'--gray.svg')" alt="">
          <span>{{ $t(quote.visit.arrivalTime === 'NIGHT' ? 'between6pm12am' : 'between7pm6pm') }}</span>
        </div>
        <span class="cart-summary__card__hour__notice" v-else>{{ arrivalTimeNotice }}</span>
      </div>
      <div class="cart-summary__card__hour">
        <h2>{{ $t('departure') }}</h2>
        <div>
          <img src="@/assets/calendar.svg" alt="">
          <span>{{quote.visit.departureDate | dateTypeB(this.$i18n.locale) }}</span>
        </div>
        <div>
          <img :src="require('@/assets/day--gray.svg')" alt="">
          <span v-if="quote.products.cabins > 0 || quote.products.tipis > 0 || quote.products.lakeVillages > 0">{{ $t('before12pm') }}</span>
          <span v-else>{{ $t('before6pm')}}</span>
        </div>
      </div>
      <div class="cart-summary__entries">
        <div class="cart-summary__entry" v-for="(item, index) in items" :key="index">
          <div class="cart-summary__entry__concept">
            <span class="visit__stay-type">{{$t(item.name)}} {{item.hours ? `(${item.quantity})` : ''}}</span>
            <span class="cart-summary__entry__amount">{{item.hours ? `${item.hours}hrs` : item.quantity}} x {{ item.price | exchangeRate(currency.exchangeRate) | currency }}</span>
          </div>
          <div class="cart-summary__entry__total-amount">{{(item.quantity * item.price * ( item.hours && item.hours > 0 ? item.hours : 1 )) | exchangeRate(currency.exchangeRate) | currency}}</div>
        </div>
      </div>
      <div class="cart-summary__total-amount">
        <div v-if="quote.discountAmount && quote.discountAmount > 0" class="cart-summary__total-amount__discount">
            <span class="cart-summary__total-amount__discount__label">{{$t('promotionTitle')}}</span>
            <span class="cart-summary__total-amount__discount__amount">- {{quote.discountAmount | exchangeRate(currency.exchangeRate) | currency}}</span>
        </div>
        <div class="cart-summary__total-amount__total">
          <span class="cart-summary__total-amount__total__label">Total</span>
          <span class="cart-summary__total-amount__total__amount">{{quote.totalAmount | exchangeRate(currency.exchangeRate) | currency}} {{ currency.currency }}</span>
        </div>
      </div>
      <unavailable-reservation></unavailable-reservation>
      <button class="cart-summary__action" v-if="quote && $route.name =='shopping-cart' && !isUnavailableCabins && !isUnavailableTipis && !isUnavailableLakeVillage"
        @click="goToContactPaymentView()"
        :class="{disabled: disabledBtnToPay }">
          {{ $t('continueToPayment') }}
      </button>
      <a v-if="$route.name === 'contact-payment' && !isUnavailableCabins && !isUnavailableTipis && !isUnavailableLakeVillage" href="" class="button"
        :class="{'disabled': unavailableReservations || capacityFull || inRemoteActivity || reservationsWithOutPersons }"
        @click.prevent="continueToPayment">
          {{ $t('completePurchase') }}
      </a>
    </div>
  </div>
</template>

<script>
  import {mapState} from 'vuex';
  import UnavailableReservation from '@/components/UnavailableReservation';
  import { fbq } from '@/main.js';

  const debounce = function (func, timeout = 2000) {
    let timer;
    return function(...args) {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
}

  export default {
    name: 'CartSummary',
    props: ['items', 'summaryItems'],
    components: {UnavailableReservation},
    computed: {
      currency() {
        return this.$store.getters.currency;
      },
      reservationsWithOutPersons() {
        return this.quote && this.quote.products
          && (this.quote.products.cabins + this.quote.products.tipis + this.quote.products.lakeVillages) > 0
          && this.quote.visit.numberOfPersons == 0;
      },
      ... mapState(['quote', 'isUnavailableCabins', 'isUnavailableTipis', 'isUnavailableLakeVillage', 'inRemoteActivity']),
      unavailableCabins() {
        return this.$store.getters.unavailableCabins;
      },
      unavailableTipis() {
        return this.$store.getters.unavailableTipis;
      },
      unavailableLakeVillages() {
        return this.$store.getters.unavailableLakeVillages;
      },
      unavailableReservations() {
        return (this.unavailableCabins && this.unavailableCabins.length > 0)
          || (this.unavailableTipis && this.unavailableTipis.length > 0)
          || (this.unavailableLakeVillages && this.unavailableLakeVillages.length > 0);
      },
      capacityFull() {

        if (!this.quote) {
          return false;
        }

        if((this.quote.cabinNumbers && this.quote.cabinNumbers.length > 0)
          || (this.quote.tipiNumbers && this.quote.tipiNumbers.length > 0)
          || (this.quote.lakeVillageNumbers && this.quote.lakeVillageNumbers.length > 0)) {
          return false;
        }

        const haveDates = this.quote && this.quote.visit && this.quote.visit.arrivalDate && this.quote.visit.departureDate && this.quote.visit.arrivalTime;
        return haveDates ? (this.quote.visit.arrivalTime === 'DAY' ? this.$store.getters.capacityFullDay : this.$store.getters.capacityFullNight) : false;
      },
      disabledBtnToPay() {
        return this.unavailableReservations
          || this.capacityFull
          || this.quote.totalAmount === 0
          || this.isUnavailableCabins
          || this.isUnavailableTipis
          || this.isUnavailableLakeVillage
          || this.reservationsWithOutPersons
          || !this.quote.visit.departureDate
          || this.remoteActivity
      },
      hasPasses() {
        const campingPass = this.items.find(item => item.name === 'campingPass');
        const oneDayPass = this.items.find(item => item.name === "oneDayPass");

        return (oneDayPass && oneDayPass.quantity > 0) || (campingPass && campingPass.quantity > 0);
      },
      remoteActivity: debounce(function() {
        return this.inRemoteActivity;
      }),
      isCabin() {
        if (!this.quote) {
          return false;
        }

        return this.quote ? this.quote.products.cabins : null;
      },
      isVillages() {
        if (!this.quote) {
          return false;
        }

        return this.quote ? this.quote.products.lakeVillages : null;
      },
      isTipi() {
        if (!this.quote) {
          return false;
        }

        return this.quote ? this.quote.products.tipis : null;
      },
      arrivalTimeNotice() {
        if (this.isCabin) {
          return `${this.$t('cabinHourArrival')}`;
        } else if (this.isVillages) {
          return `${this.$t('lakeVillageHourArrival')}`;
        } else if (this.isTipi) {
          return `${this.$t('tipiHourArrival')}`;
        }

        return '';
      },
    },
    filters: {
      exchangeRate(val, exchangeRate) {
        return Math.round(val * 100.0 / exchangeRate) / 100;
      }
    },
    methods: {
      continueToPayment() {
        fbq('track', 'AddPaymentInfo');
        this.onGenericEvent(`continuar al pago`, {event_category: 'carrito', event_label: `cta - continuar al pago`});
        this.$store.dispatch('clickToFinishPayment');
      },
      goToContactPaymentView() {
        if (this.disabledBtnToPay) {
          return;
        }

        this.$router.push({name: 'contact-payment'});
      },
      onGenericEvent(event, command) {
        this.$root.$emit('onGenericEvent', event, command);
      },
    }
  };
</script>

<style lang="scss" scoped>
  .cart-summary {
    display: grid;
    grid-template-rows: auto 1fr;
    grid-row-gap: 1.5rem;
    letter-spacing: 0.05em;
    padding-bottom: 1.5rem;
    h1 {
      align-self: center;
      font-weight: normal;
      font-size: 1.5rem;
      line-height: rem(33px);
      font-family: 'Chau Philomene One', sans-serif;
      letter-spacing: 0.05em;
    }
    &__card {
      background-color: white;
      border: $light-border;
      border-radius: $medium-radius;
      padding: 1.5rem;
      display: grid;
      grid-row-gap: 1.5rem;
      color: $dark-brown-color;
      &__list {
        display: grid;
        color: $dark-brown-color;
        font-family: 'Chau Philomene One', sans-serif;
        text-transform: uppercase;
        grid-row-gap: .5rem;
        padding-bottom: 1.5rem;
        border-bottom: 1px dashed #1E0F00;
        &__item {
          display: grid;
          grid-template-columns: .5rem 1fr;
          align-items: center;
          justify-items: start;
          grid-column-gap: .5rem;
          line-height: 22px;
          &.double {
            grid-template-columns: 1rem auto 1fr;
            grid-column-gap: 0;
          }
          &__pointer {
            width: .5rem;
            height: .5rem;
            background-color: $orange-color;
            border-radius: 50%;
          }
          &.hidden {
            display: none;
          }
          .camping {
            padding-right: .5rem;
          }
        }
      }
      &__hour {
        display: grid;
        grid-template-rows: rem(22px);
        grid-row-gap: rem(10px);
        align-items: center;
        text-transform: uppercase;
        h2 {
          text-transform: none;
          font-style: normal;
          font-size: 1rem;
          font-family: 'Montserrat', sans-serif;
          line-height: 1rem;
          opacity: .6;
          padding-bottom: .5rem;
          font-weight: 500;
        }
        div {
          display: grid;
          grid-template-columns: 1.5rem 1fr;
          grid-column-gap: 0.5rem;
          align-items: center;
          span {
            font-size: 1rem;
            line-height: rem(22px);
            font-family: 'Chau Philomene One', sans-serif;
          }
        }
        &__notice {
          font-family: 'Montserrat', sans-serif;
          font-weight: 500;
          font-size: 16px;
          line-height: 16px;
          letter-spacing: 0.05em;
          color: $light-gray;
          text-align: justify;
          text-transform: none;
        }
      }
    }
    &__entries {
      border-top: 1px dashed #1E0F00;
      display: grid;
      padding-top: rem(23px);
      grid-row-gap: 1.5rem;
    }
    &__entry {
      display: grid;
      grid-template-columns: 2fr 1fr;
      grid-column-gap: .5rem;
      &__concept {
        display: grid;
        grid-template-rows: repeat(2, auto);
        grid-row-gap: .5rem;
        font-size: 1rem;
        line-height: 1rem;
      }
      &__amount {
        align-items: center;
      }
      &__total-amount {
        text-align: right;
        font-size: 1.5rem;
        line-height: rem(33px);
      }
    }
    &__total__amount, &__entry__total-amount { font-family: 'Chau Philomene One', sans-serif }
    &__total-amount {
      border-top: 1px dashed #1E0F00;
      padding-top: rem(22px);
      display: grid;
      grid-row-gap: .5rem;
      &__discount, &__total {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        &__amount {
          font-family: $font-secondary;
          justify-self: end;
          align-self: center;
          line-height: 2rem;
        }
        &__label {
          padding-top: rem(3px);
          letter-spacing: 0.05em;
          font-family: $font-primary;
          font-weight: 500;
          justify-self: start;
          align-self: start;
        }
      }
      &__discount {
        &__amount {
          font-family: $font-secondary;
          justify-self: end;
          font-size: 1.5rem;
          color: $red-color2;
        }
      }
      &__total {
        &__amount {
          font-family: $font-secondary;
          justify-self: end;
          font-size: 2rem;
        }
      }
    }
    &__action {
      cursor: pointer;
      width: 100%;
    }

  }
</style>
