<template>
    <div :class="`tariff-card tariff-card--${tariff.color.toLowerCase()}`">
        <div class="tariff-card__color">
            <span :class="`text text--${tariff.color.toLowerCase()}`">{{ $t(color[tariff.color]) }}</span>
        </div>
        <div class="tariff-card__price">
            <span class="tariff-card__price__concept--tooltip">
              <p>{{ $t('oneDayPass') }}</p>
              <img v-tooltip.top="{ref: 'tooltip-content-a'}"
                   src="@/assets/important.svg" alt="Important information icon">
              <p class="tooltip-custom" v-html="$t('oneDayPassTooltip')" ref="tooltip-content-a"></p>
            </span>
            <div class="tariff-card__price__amount">
                <p>${{ tariff.dayPassPrice | amount }} MXN<strong class="label"> {{ $t('person') }}</strong></p>
            </div>
        </div>
        <div class="tariff-card__price">
            <span class="tariff-card__price__concept--tooltip">
              <p>{{ $t('camping') }}</p>
              <img v-tooltip.top="{ref: 'tooltip-content-b'}"
                   src="@/assets/important.svg" alt="Important information icon">
              <p class="tooltip-custom" v-html="$t('campingTooltip')" ref="tooltip-content-b"></p>
            </span>
            <div class="tariff-card__price__amount">
                <p>${{ tariff.campingNightPrice | amount }} MXN<strong class="label"> {{ $t('person') }}</strong></p>
            </div>
        </div>
        <div class="tariff-card__price">
            <span class="tariff-card__price__concept camping">{{ $t('oneDayCamping') }}</span>
            <div class="tariff-card__price__amount camping">
                <p>${{ tariff.campingDayPrice | amount }} MXN<strong class="label"> {{ $t('person') }}</strong></p>
            </div>
        </div>
        <div class="tariff-card__price">
            <span class="tariff-card__price__concept--tooltip">
                <p>{{ $t('ecotouristCabin') }}</p>
                <img v-tooltip.top="{ref: 'tooltip-content-c'}"
                   src="@/assets/important.svg" alt="Important information icon">
                <p class="tooltip-custom" v-html="$t('cabinPassTooltip')" ref="tooltip-content-c"></p>
            </span>
            <div class="tariff-card__price__amount">
                <p>${{ tariff.cabinPrice | amount }} MXN<strong class="label"> {{ $t('perNight') }}</strong></p>
            </div>
        </div>
        <div class="tariff-card__price">
            <span class="tariff-card__price__concept--tooltip">
                <p>{{ $t('lakeVillage') }}</p>
                <img v-tooltip.top="{ref: 'tooltip-content-d'}"
                   src="@/assets/important.svg" alt="Important information icon">
                <p class="tooltip-custom" v-html="$t('lakeVillagePassTooltip')" ref="tooltip-content-d"></p>
            </span>
            <div class="tariff-card__price__amount">
                <p>${{ tariff.lakeVillagePrice | amount }} MXN <strong class="label">{{ $t('perNight') }}</strong> </p>
            </div>
        </div>
        <div class="tariff-card__price">
            <span class="tariff-card__price__concept--tooltip">
                <p>{{ $t('teepee') }}</p>
                <img v-tooltip.top="{ref: 'tooltip-content-e'}"
                   src="@/assets/important.svg" alt="Important information icon">
                <p class="tooltip-custom" v-html="$t('tipiPassTooltip')" ref="tooltip-content-e"></p>
            </span>
            <div class="tariff-card__price__amount">
                <p>${{ tariff.tipiPrice | amount }} MXN<strong class="label"> {{ $t('perNight') }}</strong></p>
            </div>
        </div>
        <div class="tariff-card__details">
            <span class="tariff-card__details__advert" v-show="tariff.color == 'YELLOW'">{{ $t('descriptionYellowDates') }}</span>
        </div>
    </div>
</template>

<script>

const color = {
    'YELLOW': 'color.yellow',
    'BLACK': 'color.black',
    'BLUE': 'color.blue',
    'RED': 'color.red'
}

export default {
    props: ['tariff'],
    data: function() {
        return {
        };
    },
    computed: {
        color() {
            return color
        }
    }
};
</script>

<style lang="scss" scoped>
.tariff-card {
    display: grid;
    grid-row-gap: 0.5rem;
    min-width: 330px;
    width: 100%;
    padding: 1rem;
    border: 1px solid #33272727;
    border-radius: .5rem;

    &--yellow { background-color: $tariff-yellow-card; }
    &--red { background-color: $tariff-red-card; }
    &--blue { background-color: $tariff-blue-card }
    &--black { background-color: $tariff-black-card }
    &__color {
        display: grid;
        grid-template-columns: 1rem 1fr;
        grid-column-gap: .5rem;
        .text {
            font-family: $font-secondary;
            font-size: 1.5rem;
            line-height: rem(33px);
            padding-bottom: .5rem;
            text-transform: uppercase;
            &--yellow { color: #DDA000; }
            &--red { color: $red-color; }
            &--blue { color: #187DD5; }
            &--black { color: #272727; }
        }
        .color {
            width: 1rem;
            height: 1rem;
            border-radius: 50%;
            background-color: white;
            border: 1px solid black;
            &--yellow, &--black, &--blue, &--red { border: none }
            &--yellow { background-color: #DDA000 }
            &--black { background-color: #272727 }
            &--blue { background-color: #187DD5 }
            &--red { background-color: #F71C26 }
        }
    }
    &__price {
        display: grid;
        grid-template-columns: 1fr auto;
        grid-gap: 0.5rem;
        align-items: center;

        &__concept {
            font-weight: 500;
            font-size: 1rem;
            letter-spacing: 0.05em;
            color: $dark-brown-color;

            &--tooltip {
                display: grid;
                grid-template-columns: 116px 1rem;
                align-items: center;
                min-width: 129px;
            }
        }
        &__amount {
            color: $dark-brown-color;
            font-family: $font-secondary;
            font-weight: normal;
            font-size: 1rem;
            letter-spacing: 0.05em;

            .label {
                color: $light-gray;
                font-family: $font-primary;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
            }
        }
    }
    &__details {
        display: grid;
        align-items: center;
        height: 4rem;

        &__advert {
            font-weight: 500;
            font-size: rem(12px);
            line-height: 1rem;
            color: #272727;
            opacity: 0.6;
            display: flex;
            letter-spacing: 0.05em;
        }
    }
}
</style>