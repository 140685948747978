const winparams = `dependent=yes, locationbar=no, scrollbars=yes, menubar=yes, resizable, screenX=50, screenY=50, width=850, height=1050`;

function decodePdf(encodedPdf) {
    let binaryString = window.atob(encodedPdf);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}

function isMobile() {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
}


//------------ public ------------//

function printPdf (encodedPdf, name) {
    if(isMobile()) {
        download(encodedPdf, name);
    } else {
        try {
            openInWindow(encodedPdf, name);
        } catch(error) {
            download(encodedPdf, name);
        }
    }
}


function openInWindow(encodedPdf, name) {
    let printWindow = window.open(name, name, winparams);
    printWindow.document.write( `<embed width=100% height=100% type="application/pdf" src="data:application/pdf;base64, ${escape(encodedPdf)}"></embed>` );
    printWindow.document.close();
    printWindow.focus();
    setTimeout(() =>{
        printWindow.print();
    }, 5000);
}

function download(encodedPdf, name) {
    let pdf = decodePdf(encodedPdf);
    let blob = new Blob([pdf], {type: 'application/pdf'});

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
    }

    let link = window.URL.createObjectURL(blob);
    let el = document.createElement('a');
    el.href = link;
    el.download = name;
    el.click();

    setTimeout(() => {
        window.URL.revokeObjectURL(link);
    }, 100);
}


const pdfManager = {
    printPdf,
    openInWindow,
    download,
}

export default pdfManager;