<template>
    <products-services selected-product="groceryStores">
        <div class="product-summary__details" v-if="currentProduct">
            <gallery>
                <template slot="small-image">
                <img :src="require('@/assets/products/grocery-store/grocery-store-' + gallery[0] + '.jpg')" alt="">
                </template>
                <template slot="gallery-images">
                    <img class="slide" v-for="(picture, i) in gallery" :key="i" :src="require('@/assets/products/grocery-store/grocery-store-' + picture + '.jpg')" alt="">
                </template>
            </gallery>
            <div class="product-summary__details__title">
                <h1>{{ $t(currentProduct.title) }}</h1>
            </div>
            <div class="product-summary__details__content">
                <div class="product-summary__details__content__information">
                    <div class="product-summary__details__content__information__description">
                        <ul>
                            <li v-for="(text,index) in description" :key="index" class="text">{{ $t(text) }}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </products-services>
</template>   

<script>
import Gallery from '@/components/Gallery';
import ProductsServices from '@/views/products/ProductsServices.vue';

export default {
    name: 'GroceryStores',
    components: {ProductsServices, Gallery},
    metaInfo() {
        return {title: this.$t("tabs.groceryStore")}
    },
    data() {
    return {
        currentProduct: null,
        informationHidden: true,
        gallery: [1],
        description: {
            text1: "productPages.groceryStore.text1",
            text2: "productPages.groceryStore.text2"
        }
    }
    },
    mounted() {
    this.currentProduct = { id: 'groceryStores', title:'groceryStores', imageName: 'live-music' };
    }
};
</script>

<style lang="scss" scoped>
.product-summary {
    &__details {
    &__title {
            @include respond-to("medium and up"){
                margin-top: 0px;
            }
    }
    &__content {
        &__information {
            &__description {
                @include respond-to("small and down"){
                    margin-top: -5px;
                }
            }
        }
        &__services {
        height: rem(250px);
        }
    }
    }
}

</style>