<template>
    <products-earth selected-product="farm">
        <div class="product-summary__details" v-if="currentProduct">
            <gallery>
                <template slot="small-image">
                <img :src="require('@/assets/products/farm/Farm_' + gallery[0] + '.jpg')" alt="">
                </template>
                <template slot="gallery-images">
                    <img class="slide" v-for="(picture, i) in gallery" :key="i" :src="require('@/assets/products/farm/Farm_' + picture + '.jpg')" alt="">
                </template>
            </gallery>
            <div class="product-summary__details__title">
                <h1>{{ $t(currentProduct.title) }}</h1>
            </div>
            <div class="product-summary__details__content">
                <div class="product-summary__details__content__information">
                    <div class="product-summary__details__content__information__description" :class="{ hidden: informationHidden }">
                        <p class="text">{{ $t(description.text1) }}</p>
                        <ul>
                            <li>{{ $t(description.text2) }}</li>
                            <li>{{ $t(description.text3) }}</li>
                        </ul>
                    </div>
                    <span class="hide-description" @click="informationHidden = !informationHidden">{{ informationHidden ? $t('readMore') : $t('hide') }}</span>
                </div>
            </div>
        </div>
    </products-earth>
</template>   

<script>
import Gallery from '@/components/Gallery';
import ProductsEarth from '@/views/products/ProductsEarth.vue';

export default {
    name: 'echoViewpoint',
    components: {ProductsEarth, Gallery},
    metaInfo() {
        return {title: this.$t("tabs.farm")}
    },
    data() {
    return {
        currentProduct: null,
        informationHidden: true,
        gallery: [1, 2, 3, 4],
        description: {
            text1: "productPages.farm.text1",
            text2: "productPages.farm.text2",
            text3: "productPages.farm.text3",
        }
    }
    },
    mounted() {
    this.currentProduct = { id: 'farm', title:'farm', imageName: 'farm' };
    }
};
</script>

<style lang="scss" scoped>
.product-summary {
    &__details {
    &__title {
            @include respond-to("medium and up"){
                margin-top: 0px;
            }
    }
    &__content {
        &__information {
            &__description {
                @include respond-to("small and down"){
                    margin-top: -5px;
                }
                li {
                    margin-bottom: 1.5rem;
                }
            }
        }
        &__services {
        height: rem(250px);
        }
    }
    }
}

</style>