import { render, staticRenderFns } from "./LodgingIndianVillage.vue?vue&type=template&id=450791ed&scoped=true&"
import script from "./LodgingIndianVillage.vue?vue&type=script&lang=js&"
export * from "./LodgingIndianVillage.vue?vue&type=script&lang=js&"
import style0 from "./LodgingIndianVillage.vue?vue&type=style&index=0&id=450791ed&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "450791ed",
  null
  
)

export default component.exports