<template>
    <div class="gallery" :key="id">
        <div class="gallery__picture" @click="openGallery()">
            <slot name="small-image"></slot>
            <img class="gallery__picture__more" src="@/assets/products/gallery_more.svg">
        </div>
        <div class="gallery__list" :class="{ 'closed': galleryOpened == false, 'opened': galleryOpened == true }">
            <div class="gallery__list__header">
                <a class="close" href="" @click.prevent="closeGallery()">
                    <img src="@/assets/arrow-left.svg">
                </a>
                <div></div>
                <span class="paging" v-if="slideCount">{{ currentSlide + 1 }}/{{ slideCount }}</span>
            </div>
            <slick ref="slick" :options="slickOptions"
                @afterChange="handleAfterChange"
                @init="handleAfterChange"
                @reInit="handleAfterChange">
                <slot name="gallery-images"></slot>
            </slick>
        </div>
    </div>
</template>
<script>
import Slick from 'vue-slick';
export default {
    name: "dropDown",
    components: {Slick},
    data () {
        return {
            id: 0,
            galleryOpened: null,
            currentSlide: null,
            slideCount: null,
            slickOptions: {
                infinite: true,
                speed: 500,
                fade: true,
                cssEase: 'linear'
            }
        }
    },
    methods: {
        openGallery() {
            this.galleryOpened = true;
            this.id++;
        },
        closeGallery() {
            this.galleryOpened = false;
        },
        next() {
            this.$refs.slick.next();
        },
        prev() {
            this.$refs.slick.prev();
        },
        reInit() {
            this.$nextTick(() => {
                this.$refs.slick.reSlick();
            });
        },
        handleAfterChange(_event, slick) {
            this.currentSlide = slick.currentSlide;
            this.slideCount = slick.slideCount;
        }
    },
    mounted() {}
};
</script>
<style lang="scss">
    .gallery {
        border-radius: rem(16px);
        overflow: hidden;
        height: auto;
        .slick-slide {
            height: rem(550px);
        }
        .slide {
            height: rem(550px);
            object-fit: cover;
            @include respond-to("small and down") {
                height: rem(180px);
            }
            @include respond-to("medium") {
                height: rem(400px);
            }
        }
        .slick-arrow {
            position: absolute;
            top: calc((100% - 40px) / 2);
            z-index: 1;
            font-size: 0;
            border-radius: 50%;
            border: unset;
            width: rem(40px);
            height: rem(40px);
            background-color: rgba(#000, 0.6);
            padding: 0;
            cursor: pointer;
            background-repeat: no-repeat;
            background-position: center;
            @include respond-to("medium and down") {
                visibility: hidden;
            }
        }
        .slick-prev {
            left: rem(24px);
            background-image: url(../assets/chevron-left--white.svg);
        }
        .slick-next {
            right: rem(24px);
            background-image: url(../assets/chevron-right--white.svg);
        }

        &__picture {
            position: relative;
            height: auto;
            @include respond-to("large and up") {
                display: none;
            }
            @include respond-to("small and down") {
                width: 100%;
                height: 210px;
                .interactive-fountain {
                    position: relative;
                    width: 100%;
                    &__content {
                        position: relative;
                        height: 210px !important;
                        background-image: url(../assets/interactive-fountain-fallback.png);
                        background-repeat: no-repeat;
                        background-position: center center;
                        z-index: -3;
                    }
                    &__fallback {
                        display: none;
                    }
                    &__video {
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        top: 50%;
                        height: 90vw !important;
                        width: 170vw !important;
                        transform: translate(-155px, -50%) !important;
                    }
                }
            }
            @include respond-to("medium") {
                height: rem(600px);
                img {
                    height: 600px;
                    width: 100%;
                    object-fit: cover;
                }

                .interactive-fountain {
                    position: relative;
                    &__content {
                        position: relative;
                        height: 600px !important;
                        overflow: hidden;
                        z-index: -3;
                    }
                    &__video {
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        top: 50%;
                        transform: translate(0,-50%);
                        @media (max-width: 1130px) {
                            height: 87vw !important;
                            width: 110vw !important;
                            transform: translate(-80px, -50%) !important;
                        }
                        @media (max-width: 950px) {
                            height: 117vw !important;
                            width: 140vw !important;
                            transform: translate(-190px, -50%) !important;
                        }
                        
                    }
                    &__fallback {
                        position: absolute;
                        width: 100%;
                        height: 600px;
                    }
                }
            }
            
            &__more {
                width: rem(86px) !important;
                height: auto !important;
                position: absolute;
                bottom: rem(16px);
                right: rem(16px);
                z-index: 1;
            }
        }
        &__list {
            &__header { 
                display: grid;
                height: rem(40px);
                padding: rem(29px) rem(29px) rem(26px) rem(26px);
                grid-template-rows: rem(40px);
                grid-template-columns: rem(20px) auto rem(20px);
                @include respond-to("large and up") {
                    display: none; 
                }
            }
            @include respond-to("medium and down") {
                &:not(.opened):not(.closed) {
                    height: 0;
                }
                &.opened {
                    height: 100vh;
                    width: 100vw;
                    background-color: #fff;
                    position: fixed;
                    top: 0;
                    left: 0;
                    z-index: 9000000000 !important;
                    .close {
                        img {
                            width: rem(20px);
                            height: rem(15px);
                        }
                    }
                    .paging {
                        font-weight: 500;
                        letter-spacing: 0.05em;
                        color: rgba(#000, 0.6);
                    }
                    .slide {
                        height: auto;
                        width: 100%;
                        position: absolute;
                    }
                    .slick-slider {
                        position: absolute;
                        width: 100vw;
                    }
                }
                &.closed {
                    display: none;
                }
            }
        }
        @media only screen and (orientation: portrait) {
        .gallery {
            &__list {
                &__header {
                    background: white;
                }
                &.opened {
                    background: black;
                    .slide {
                        top: calc(100vh / 4);
                        position: absolute;
                        
                        img {
                            
                            height: 100%;
                        }
                    }
                    .slick-slide {
                        height: 100vh;
                        
                            img {
                            position: relative;
                            height: 100%;
                        }
                        .interactive-fountain {
                            &__content {
                                height: 100%;
                                position: absolute;
                                display: grid;
                                place-items: center;
                                z-index: -2;
                            }
                            &__fallback {
                                display: none;
                            }
                        }
                    }
                }
            }
            }
        }
        @media only screen and (orientation: landscape) {
            &__list {
            @include respond-to("medium and down") {
                &:not(.opened):not(.closed) {
                    height: 0;
                }
                &__header {
                    background: white;
                }
                &.opened {
                    background: black;
                    position: fixed;
                    top: 0;
                    left: 0;
                    z-index: 9000000000 !important;
                    .close {
                        img {
                            width: rem(20px);
                            height: rem(15px);
                        }
                    }
                    .paging {
                        font-weight: 500;
                        letter-spacing: 0.05em;
                        color: rgba(#000, 0.6);
                        
                    }
                    .slide {
                        width: auto !important;
                        height: calc(100vh - 50px) !important;
                        bottom: 0;
                        position: absolute;
                        top: 0;
                    }
                    .slick-slider {
                        position: absolute;
                        width: 100vw;
                        
                    }
                    .slick-slide {
                        height: 100vh;
                        position: absolute;
                        
                        img {
                            position: relative;
                            height: 100%;
                            margin: 0 auto;
                            display: block !important;
                        }
                    }
                    
                }
                &.closed {
                    display: none;
                }
            }
        }
    }
}
</style>