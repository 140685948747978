<template>
  <div id="banner" ref="banner" class="banner" :class="{
      'min-height': bannerClicksSeeMore,
      'color--red': color === 'RED',
      'color--orange': color === 'ORANGE',
      'color--green': color === 'GREEN',
      'color--blue': color === 'BLUE'}">
    <column-block class="banner__content" :class="{'see-more-active': bannerSeeMore, opacity: !showBanner}">
      <div v-if="!small">
        <vue-simple-markdown id="markdown" ref="markdown" :source="banner[locale].content"></vue-simple-markdown>
        <div v-if="activeSeeMore" class="banner__content__more">
          <div>
            <a href="" class="see-more" v-if="!bannerSeeMore" @click.prevent="seeMoreClick">{{$t('seeMore')}}</a>
            <a href="" class="see-more" v-if="bannerSeeMore" @click.prevent="seeMoreClick">{{$t('seeLess')}}</a>
          </div>
        </div>
      </div>
      <div class="banner__small" v-else>
        <vue-simple-markdown :source="title"></vue-simple-markdown>
        <a href="" class="see-more" v-if="!bannerSeeMore" @click.prevent="seeMoreClick">{{$t('seeMore')}}</a>
      </div>
    </column-block>
  </div>
</template>

<script>
  import ColumnBlock from '@/components/columns/ColumnBlock';
  import {mapState} from 'vuex';

  export default {
    name: 'Banner',
    props: ['banner'],
    components: {ColumnBlock},
    data() {
      return {
        showBanner: false,
        activeSeeMore: false,
      }
    },
    computed: {
      ... mapState([ 'bannerClicksSeeMore', 'bannerSeeMore' ]),
      small() {
        return !this.bannerSeeMore && this.bannerClicksSeeMore > 0
      },
      title() {
        return this.banner[this.locale] && this.banner[this.locale].content && this.banner[this.locale].content.split('\n')[0]
      },
      color() {
        return this.banner[this.locale] ? this.banner[this.locale].color : '';
      },
      locale() {
        return this.$store.getters.locale;
      }
    },
    mounted() {
      if (this.bannerClicksSeeMore > 0) {
        this.activeSeeMore = true;
        this.showBanner = true;
      } else {
        if(document.getElementById("markdown") && document.getElementById("markdown").clientHeight > 182) {
          this.activeSeeMore = true;
        }
        this.showBanner = true;
      }
    },
    methods: {
      seeMoreClick() {
        this.$store.dispatch('updateBannerSeeMore');
      }
    }
  };

</script>

<style lang="scss" scoped>
.banner {
  position: relative;
  display: grid;
  width: 100%;
  background-color: #CDEAE1;
  .see-more {
      text-decoration: none;
      color: $blue-great-color;
      font-size: 1rem;
      padding: .5rem 0;
      width: min-content;
      white-space: nowrap;
      font-weight: 500;
  }
  &__small {
    display: grid;
    grid-template-columns: auto auto 1fr;
    grid-column-gap: 1rem;
    max-height: rem(22px);
    overflow: hidden;
    align-items: start;
    .see-more {
      padding: 0;
      padding-top: rem(4px)
    }
  }
  &__content {
    max-width: 1440px;
    padding: 1.5rem 5rem 0 5rem;
    &__more {
      background-color: #CDEAE1;
      bottom: 0;
      padding: .5rem 0 1rem 0;
      width: calc(100% - 10rem);
      div {
        padding: 1rem 0 .5rem 0;
        width: calc(100%);
      }
    }
    &.opacity {
      opacity: 0;
    }
    &:not(.see-more-active) {
      overflow: hidden;
      max-height: 230px;
      padding: 1.5rem 5rem;
      overflow-x: hidden;
      .banner__content {
        position: relative;
        &__more {
          max-width: calc(1440px - 10rem);
          background: linear-gradient(to bottom, rgba(205,234,225,0) 0%, rgba(205,234,225,0.5) 18%, rgba(205,234,225,0.83) 30%, rgba(205,234,225,1) 36%, rgba(205,234,225,1) 60%, rgba(205,234,225,1) 100%);
          position: absolute;
          margin-left: -2px;
          padding-top: 1.5rem;
        }
      }
    }
  }
  @include respond-to("medium and down") {
    &__content {
      padding: 1rem !important;
      &:not(.see-more-active) {
        max-width: calc(100%);
        .banner__content__more {
          padding-bottom: 1rem;
        }
      }
      &__more {
        width: calc(100% - 2rem);
        padding: 0;
      }
    }
  }
  &.color--red {
    background-color: #FFD6D6;
    .banner__content {
      &__more { background-color: #FFD6D6 }
      &:not(.see-more-active) {
        .banner__content__more {
          background: linear-gradient(to bottom, rgba(255,214,214,0) 0%, rgba(255,214,214,0.5) 18%, rgba(255,214,214,0.83) 30%, rgba(255,214,214,1) 36%, rgba(255,214,214,1) 60%, rgba(255,214,214,1) 100%);
        }
      }
    }
  }
  &.color--orange {
    background-color: #FFEBCC;
    .banner__content {
      &__more { background-color: #FFEBCC }
      &:not(.see-more-active) {
        .banner__content__more {
          background: linear-gradient(to bottom, rgba(255,235,204,0) 0%, rgba(255,235,204,0.5) 18%, rgba(255,235,204,0.83) 30%, rgba(255,235,204,1) 36%, rgba(255,235,204,1) 60%, rgba(255,235,204,1) 100%);
        }
      }
    }
  }
  &.color--green {
    background-color: #CDEAE1;
    .banner__content {
      &__more { background-color: #CDEAE1 }
      &:not(.see-more-active) {
        .banner__content__more {
          background: linear-gradient(to bottom, rgba(205,234,225,0) 0%, rgba(205,234,225,0.5) 18%, rgba(205,234,225,0.83) 30%, rgba(205,234,225,1) 36%, rgba(205,234,225,1) 60%, rgba(205,234,225,1) 100%);
        }
      }
    }
  }

  &.color--blue {
    background-color: #D4EDFF;
    .banner__content {
      &__more { background-color: #D4EDFF }
      &:not(.see-more-active) {
        .banner__content__more {
          background: linear-gradient(to bottom, rgba(212,237,255,0) 0%, rgba(212,237,255,0.5) 18%, rgba(212,237,255,0.83) 30%, rgba(212,237,255,1) 36%, rgba(212,237,255,1) 60%, rgba(212,237,255,1) 100%);
        }
      }
    }
  }
}
</style>
