<template>
  <date-picker class="visit-dates__arrival__date-picker__calendar calendar-drawer" ref="datePicker" :locale="$t('lang')"
               color="yellow" v-model="date" :min-date="minDateD" :max-date="maxDateD" :is-required="true"
               :attributes="attributes" :disabled-dates="disabledDate" is-inline is-expanded>
      <span slot='header-title' slot-scope='{ monthLabel }'>{{ monthLabel }}</span>
      <a slot='header-left-button' @click.prevent=""><img src="@/assets/arrow-left.svg" alt=""></a>
      <a slot='header-right-button' @click.prevent=""><img src="@/assets/arrow-rigth.svg" alt=""></a>
      <template v-slot:day-content="{ day, dayEvents }">
        <div v-on="dayEvents"
             class="day-content"
             :class="{'disabled': !isDateInRange(day) || !getPrice(day.id, stayKey)
                        || (disabledDate && disabledDate.includes(day.id))
                        || (unavailableLodgingDates && unavailableLodgingDates.includes(day.id))}">
          <div class="day" :class="{'selected': selectedDay(day)}">{{ day.day }} </div>
          <div class="total-amount" :class="{'selected': selectedDay(day)}" v-if="isDateInRange(day)">{{ getPrice(day.id, stayKey) | currencyCalendar }}</div>
        </div>
      </template>
      <template #footer>
        <div class="calendar-price">
          <span>{{ $t(`calendarLabel.${getFooterLabel()}`) }}</span>
        </div>
      </template>
  </date-picker>
</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import moment from 'moment';

export default {
    name: "date-picker-single",
    props: ['minDate', 'maxDate', 'attributes', 'value', 'pricesPerDay', 'stayKey', 'disabledDates', 'unavailableLodgingDates', 'noInit' ],
    components: {DatePicker},
    data() {
        return {
            date: null
        }
    },
    watch: {
        'date'() {
            this.$emit('change', this.date);
        }
    },
    mounted() {
        this.initDate();
    },
    computed: {
        minDateD() {
            return this.minDate ? this.minDate : new Date();
        },
        maxDateD() {
            return this.maxDate ? this.maxDate : null;
        },
        disabledDate() {
            return this.disabledDates ? this.disabledDates.map(date => { moment(date).toDate() }) : [];
        },
        momentMinDate() {
          return moment(this.minDateD).startOf('day');
        },
        momentMaxDate() {
          return moment(this.maxDateD).startOf('day');
        }
    },
    methods: {
        initDate() {
            this.date = this.value ? moment(this.value).toDate() : this.noInit ? null : new Date();
        },
        isDateInRange(date) {
          return moment(date.date).isSameOrAfter(this.momentMinDate);
        },
        selectedDay(date) {
          if(!date || !date.id) {
            return false;
          }
          return moment(this.date).format('YYYY-MM-DD') === date.id;
        },
        getPrice(date, key) {
          const element = this.pricesPerDay.find(item => item.date === date);
          return (element && element.datePrices[key] !== undefined) ? element.datePrices[key] : null;
        },
        getFooterLabel() {
          const stayKey = this.stayKey;
          let label = 'lodging';
          switch (stayKey) {
            case 'dayPassPrice':
              label = 'dayPass';
              break;
            case 'campingNightPrice':
              label = 'campingPass';
              break;
            default:
              break;
          }
          return label;
        }
    },
    filters: {
      currencyCalendar(num) {
        if (typeof num !== 'number') {
          return '';
        }

        const formattedCurrency = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(num);

        return formattedCurrency;
      }
    }
};
</script>

<style scoped lang="scss">
  .disabled {
    opacity: 0.4;
    pointer-events: none;
  }
  .day-content {

    .day {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: 0.05em;
      color: $dark-brown-color;
    }

    .total-amount {
      font-family: $font-primary;
      font-size: 9px;
      line-height: 8px;
      letter-spacing: 0.02em;
      color: $light-gray;
    }

    .selected {
      color: white;
    }

    @media (max-height: 700px) {
      .day {
        font-size: 12px;
        line-height: 18px;
      }

      .total-amount {
        font-size: 8px;
        line-height: 4px;
      }
    }
  }

  .calendar-price {
    display: grid;
    justify-content: center;
    max-width: 308px;

    span {
      font-family: $font-primary;
      text-transform: none;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.05em;
      color: $light-gray;
      max-width: 260px;

    }

    @media (max-height: 700px) {
      span {
        max-width: 220px;
        font-size: 10px !important;
      }
    }

  }
</style>