<template>
  <products-air selected-product="safety">
    <div class="product-summary__details" v-if="currentProduct && information">
      <img class="product-summary__details__picture" :src="require('@/assets/products/' + currentProduct.imageName + '--full.jpg')" alt="">
      <img class="product-summary__details__mobile" :src="require('@/assets/products/safety--full-mobile.jpg')" alt="">

      <div class="product-summary__details__title">
        <h2 class="subtitle">{{ $t('canopyTour') }}</h2>
        <h1 class="title">{{$t('safety')}}</h1>
      </div>
      <add-to-cart id="add-to-cart_safety" class="product-summary__details__add" :product="information[currentProduct.id]"></add-to-cart>
      <div class="product-summary__details__content">
        <div class="product-summary__details__content__information">
          <div class="product-summary__details__content__information__description">
            <p v-for="(text, i) in attributesText" :key="i">{{ $t(`productPages.safety.${text}`) }}</p>
          </div>
        </div>
      </div>
    </div>
  </products-air>
</template>

<script>
  import ProductsAir from '@/views/products/ProductsAir';
  import AddToCart from '@/views/components/AddToCart';

  const information = {
    safety: {
      info: 'productPages.canopyTour.info'
    }
  }

  export default {
    name: 'Safety',
    components: {ProductsAir, AddToCart},
    metaInfo() {
      return {title: this.$t("tabs.security")}
    },
    data() {
      return {
        currentProduct: null
      }
    },
    computed: {
        information() {
            return information;
        },
        attributesText() {
          return Object.keys(this.$t('productPages.safety'));
        }
    },
    mounted() {
      this.currentProduct = { id: 'safety', title:'safety', imageName: 'safety' };
    },
    methods: {}
  };
</script>

<style lang="scss" scoped>
  .product-summary {
    &__details {
      &__title {
        grid-template-columns: unset;
        height: auto;
        padding-top: 0;
        .subtitle {
          font-size: 1.5rem;
          opacity: 0.6;
          @include respond-to('medium and down') {
            font-size: 1rem;
          }
        }
      }
      &__content {
        &__services {
          height: rem(250px);
        }
        &__information {
          &__description {
            margin-top: 2rem;
          }
        }
      }
      @include respond-to("medium and up") {
        &__picture{
          grid-column: 1/ span 2;
        }
        &__mobile{
            display: none;
        }
      }
      @include respond-to("small and down") {
            &__picture{
              display: none;
            }
            &__mobile{
                display: block;
            }
      }
    }
  }
</style>