<template>
  <section v-if="isOpen" class="modal" :class="{ 'open': isOpen, 'closing': isClosing }" :key="mId">
    <div class="modal__overlay" @click="closeOnOverlay()"></div>
    <slot></slot>
  </section>
</template>

<script>
const modal = {
    name: 'modal',
    props: ['onClose', 'stopId', 'disableCloseModal'],
    data() {
        return {
            mId: null,
            isOpen: false,
            isClosing: false
        }
    },
    methods: {
        open() {
            document.body.classList.add('modal--open');
            if(!this.stopId) {
                this.mId = Math.floor(Math.random() * 2000) + 1;
            }            
            setTimeout(() => this.isOpen = true, 100);
        },
        close() {
            document.body.classList.remove('modal--open');
            if(!this.closeOnOverlay) window.scrollTo(0, 0);
            this.isOpen = false;
            this.isClosing = true;
            this.onClose && this.onClose();
            setTimeout(() => this.isClosing = false, 400);
        },
        closeOnOverlay() {
          if(!this.disableCloseModal) this.$emit('close');
        }
    }
};

export default modal;
</script>
<style scoped lang="scss">
    .modal {
        position: fixed;
        width: 100%;
        height: 100%;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        opacity: 0;
        z-index: -1;
        &.closing {
            transition: opacity 0.3s ease-out;
            z-index: 6;
        }
        &.open {
            opacity: 1;
            transition: opacity 0.3s ease-in;
            z-index: 10000;
        }
        &__overlay {
            position: fixed;
            top: 0;
            left: 0;
            background-color: rgba(black, 0.7);
            width: 100%;
            height: 100%;
        }
        &__content {
            border-radius: rem(3px);
            -moz-border-radius: rem(3px);
            -webkit-border-radius: rem(3px);
            margin: 0 auto;
            max-height: 95%;
            max-width: 95%;
            right: 0;
            left: 0;
            top: 50%;
            position: absolute;
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
        }
    }
</style>