<template>
  <div class="base-layout" :class="{'no-footer': isVisible($route.name)}">
    <banner class="banner" :class="{'no-banner': !$store.getters.mobileDrawerVisible}" v-if="activeBanner" :banner="banner"></banner>
    <div class="base-layout__content">
      <slot></slot>
    </div>
    <choose-tipi-modal ref="chooseTipi"></choose-tipi-modal>
    <choose-cabin-modal ref="chooseCabin"></choose-cabin-modal>
    <choose-lake-village-modal ref="chooseVillage"></choose-lake-village-modal>
    <activities-info-modal ref="seeMoreInfo"></activities-info-modal>
    <footer-bar v-show="!isVisible($route.name)" ref="footerBar" :class="{ padding: $route && $route.name == 'homepage'}"></footer-bar>
  </div>
</template>

<script>
  import FooterBar from '@/components/bars/FooterBar';
  import Banner from '@/components/bars/Banner';
  import ChooseTipiModal from '@/components/modal/ChooseTipiModal'
  import ChooseCabinModal from '@/components/modal/ChooseCabinModal'
  import ChooseLakeVillageModal from '../components/modal/ChooseLakeVillageModal.vue';
  import ActivitiesInfoModal from '@/components/visit-drawer/ActivitiesInfoModal'

  export default {
    name: 'BaseLayout',
    components: {FooterBar, ActivitiesInfoModal, Banner, ChooseTipiModal, ChooseCabinModal, ChooseLakeVillageModal},
    watch: {
      'dates'() {
          this.getReservations();
      },
      '$store.getters.reservationAvailableFlag'() {
          this.getReservations();
      }
    },
    computed: {
      banner() {
        return  {
          es: this.$store.getters.banner.find(b => b.name == 'BANNER_ES'),
          en: this.$store.getters.banner.find(b => b.name == 'BANNER_EN')
        }
      },
      activeBanner() {
        let locale = this.$store.getters.locale;
        return this.banner[locale] && this.banner[locale].active;
      },
      quote() {
        return this.$store.getters.quote;
      },
      dates() {
          if (this.quote) {
              return {startDate: this.quote.visit.arrivalDate, endDate: this.quote.visit.departureDate}
          }
          return null;
      }
    },
    methods: {
      isVisible(routeName) {
        const pages = ['shopping-cart', 'order.details', 'contact-payment'];
        return pages.findIndex(page => page === routeName) > -1;
      },
      async getReservations() {        
        if (this.dates && this.dates.startDate && this.dates.endDate) {
          await this.$store.dispatch('getReservationsAvailables', this.dates);
          this.getUnavailableReservations();
        }
      },
      getUnavailableReservations() {
        if (this.quote) {
          this.$store.dispatch('validReservations', this.quote.id);
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .base-layout {
    display: grid;
    height: 100%;
    grid-template-rows: min-content 1fr max-content;
    position: relative;
    &.no-footer {
      grid-template-areas: "banner" "content";
      grid-template-rows: min-content 1fr;
      .base-layout__content {
        grid-area: content;
      }
      .banner {
        grid-area: banner;
      }
    }
    .banner {
      z-index: 1100;
    }
    // This makes space for the floating mobile drawer
    @include respond-to('medium and down') {
      .no-banner {
        z-index: 0;
      }
    }
    @include respond-to('small and down') {
      footer {
        &.padding {
          padding-top: rem($mobile-drawer-min-height + $base-size);
        }
      }
    }

  }
</style>
