<template>
  <div class="drop-down" :class="{'no-click': !options}">
    <li ref="language" class="select">
        <a class="option-selected" :class="{open: showList}" @click="openOptions()">
            <img v-if="currentSelect.nationality" :src="require('@/assets/bars/'+currentSelect.nationality+'.svg')" alt="">
            <span class="text">{{ currentSelect ? `${currentSelect.label} | $ MXN` : "Select one" }}</span>
            <img class="arrow" src="@/assets/dropdown-arrow.svg">
        </a>
        <ul v-if="showList" class="option-list">
            <a class="gtm-languages option" v-for="option in options" :value="option.id" :key="option.id" :id="option.elementId"
                @click="selectOption(option)" :class="{ hide: currentSelect.id == option.id, 'gtm-english': 'en' === option.locale,
                'gtm-spanish': 'es' === option.locale}" >
                <img :src="require('@/assets/bars/'+option.nationality+'.svg')" alt="">
                <span class="text">{{ `${option.label} | $ MXN` }}</span>
            </a>
        </ul>
    </li>
  </div>
</template>
<script>

export default {
    name: "dropDown",
    data() {
        return {
            showList: false,
            currentSelect: {},
            options: [
                { id: 1, label: 'English', nationality: 'usa', locale: 'en', elementId: 'lang_en', gtmClass: 'gtm-english'},
                { id: 2, label: 'Español', nationality: 'mx',  locale: 'es', elementId: 'lang_es', gtmClass: 'gtm-spanish'}
            ]
        }
    },
    methods: {
        openOptions() {
            setTimeout(() => {
                if(this.showList) {
                    document.removeEventListener('click', this.onClick, false);
                    document.removeEventListener("keyup", this.closeDropDown, false);
                } else {
                    document.addEventListener('click', this.onClick);
                    document.addEventListener("keyup", this.closeDropDown);
                }
                this.showList = !this.showList;
            } , 100);
        },
        selectOption (optionChanged) {
            if(this.$refs.language) {
                this.currentSelect = optionChanged;
                this.$emit("input", optionChanged);
                this.$emit("change");
                this.$store.dispatch('updateLocale', this.currentSelect.locale);
                this.$i18n.locale = this.currentSelect.locale;
                if(this.currentSelect) {
                    this.$refs.language.classList.remove("not-option-selected");
                    this.$refs.language.classList.add("option-selected");
                } else {
                    this.$refs.language.classList.add("not-option-selected");
                }
                this.showList = false;
            }
        },
        onClick: function (evt) {
            if(this.$el && !this.$el.contains(evt.target)) {
                this.close();
            }
        },
        closeDropDown: function (evt) {
            if (evt.keyCode === 27) {
                this.close();
            }
        },
        close() {
            document.removeEventListener('click', this.onClick, false);
            document.removeEventListener("keyup", this.closeDropDown, false);
            this.$emit("change");
            this.showList = false;
        },
        init() {
            this.currentSelect = {};
            this.close();
            this.selectOption();
        }
    },
    mounted() {
        let currentLocale = this.$store.getters.locale;
        if(!currentLocale) {
            currentLocale = 'es';
        }
        let op = this.options.filter(option => {
            return option.locale === currentLocale;
        });

        this.selectOption(op[0]);
    }
};
</script>
<style scoped lang="scss">
    .no-click {
        pointer-events: none;
    }
    .drop-down {
        height: rem(48px);
        .select {
            background-color: $forest-green-color;
            width: 100%;
            height: 100%;
            list-style: none;
            letter-spacing: 0.05em;
            position: relative;
            z-index: 5;
            a {
                cursor: pointer;
                width: 100%;
                height: 100%;
                display: grid;
                text-decoration:none;
                font-family: $font-primary;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 16px;
                grid-template-columns: rem(24px) auto rem(8px);
                grid-column-gap: rem(8px);
                padding: 0 rem(24px);
                span, img {
                    align-self: center;
                    color: white;
                }
                &.option { span { grid-column: 2/4 } }
                &:not(.open) {
                    .arrow {
                        -moz-transform: rotate(-180deg);
                        -o-transform: rotate(-180deg);
                        -webkit-transform: rotate(-180deg);
                        transform: rotate(-180deg);
                    }
                }
                &.hide {
                    display: none;
                }
                &.open , &.option:hover{
                    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #049569;
                }
            }
            .option-list {
                background-color: $forest-green-color;
                width: 100%;
                max-height: rem(225px);
                overflow-y: auto;
                font-weight: normal;
                position: absolute;
                .option {
                    height: rem(48px);
                }
            }
        }
    }
</style>