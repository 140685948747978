<template>
    <div class="button-container">
        <button :class="{ selected: isSelected(option) }" v-for="(option, index) in options" :key="index"
            @click.prevent="changeValue(option)">{{ option.label }}</button>
        <input type="text" v-if="otherOption" name="otherOption" maxlength="80" placeholder="Otro (Especifíque)"
            :value="other" @input="other = $event.target.value" @change="inputValue" @keydown.delete="onDelete">
    </div>
</template>

<script>
export default {
    name: "RadioButtonMultipleSelection",
    props: {
        options: { type: Array, required: true, },
        otherOption: { type: Boolean, required: false },
        value: { type: Array, required: true, },
    },
    data() {
        return {
            other: ""
        }
    },
    methods: {
        changeValue(option) {
            if (this.isSelected(option)) {
                const index = this.value.indexOf(option.id);
                this.value.splice(index, 1);
            } else {
                this.value.push(option.id);
            }

            this.$emit('input', this.value);
        },
        isSelected(option) {
            return this.value.includes(option.id);
        },
        inputValue() {
            const foundOption = this.options.find(option => option.label.toLowerCase() === this.other.toLowerCase());
            if (foundOption) {
                this.other = "";
                this.changeValue(foundOption);
            } else {
                this.value.push(this.other);
                this.$emit('input', this.value);
            }
        },
        onDelete() {
            const index = this.value.indexOf(this.other);
            if (index > -1) this.value.splice(index, 1);
        }
    }
}
</script>

<style lang="scss" scoped>
.button-container {
    display: grid;
    grid-column-gap: 16px;
    grid-auto-flow: column;
    justify-content: start;

    button {
        display: flex;
        width: max-content;
        border: 1px solid $dark-brown-color;
        background-color: transparent;
        border-radius: 8px;
        color: $dark-brown-color;
        font-family: $font-primary;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 19px;
        height: 32px;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &.selected {
            background: linear-gradient(0deg, rgba(255, 153, 0, 0.2), rgba(255, 153, 0, 0.2)), #FFFFFF;
            border: 1px solid $orange-color;
        }
    }

    input {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px 16px;
        width: 192px;
        height: 32px;
        background: #FFFFFF;
        border: 1px solid #A9A9A9;
        border-radius: 8px;
    }
}
</style>
