<template>
  <div class="column"><slot></slot></div>
</template>

<script>
  export default {
    name: 'ColumnBlock',
  };
</script>

<style lang="scss" scoped>
  .column {
    place-self: start center;
    display: grid;

    @include respond-to("small and down") {
      padding: 0 1rem;
    }

    @include respond-to("medium and up") {
      padding: 0 1.5rem;
    }


    @include respond-to("medium and down") {
      width: 100%;
    }

    @include respond-to("medium") {
      width: 100%;
      max-width: 100%;
    }

    @include respond-to("large") {
      width: 100%;
      max-width: 992px;
    }

    @include respond-to("xlarge") {
      width: 100%;
      max-width: 1440px;
    }

    @include respond-to("xxlarge") {
      width: 100%;
      max-width: 1440px;
    }

    @include respond-to("xxxlarge") {
      width: 100%;
      max-width: 1600px;
    }

  }
</style>
