<template>
    <modal ref="modal" @close="closeModal()" v-show="isOpen">
        <div class="modal__content see-conditions">
            <div class="see-conditions__header">
                <h1>{{$t('promotion3x2')}}</h1>
                <a href="" @click.prevent="closeModal()"><img src="@/assets/deletesign.svg"></a>
            </div>
            <div class="see-conditions__content">
                <span class="see-conditions__content__sub-title">{{$t('conditionsOfUse.text')}}</span>
                <span class="see-conditions__content__condition" :class="{ 'highlight': (index + 1) == 6 }" v-for="(condition, index) in conditions" :key="index">{{(index + 1)}}. {{condition}}</span>
            </div>
            <button class="see-conditions__button" @click="closeModal()">{{$t('conditionsOfUse.3x2.ok')}}</button>
        </div>
    </modal>
</template>

<script>
import Modal from '@/components/modal/Modal'

    export default {
    name: 'SeeConditionsModal',
        components: { Modal },
        props: [],
        data() {
            return {
                isOpen: false,
            }
        },
        computed: {
            conditions() {
                const conditions = [];
                conditions.push(this.$t('conditionsOfUse.3x2.text1'));
                conditions.push(this.$t('conditionsOfUse.3x2.text2'));
                conditions.push(this.$t('conditionsOfUse.3x2.text3'));
                conditions.push(this.$t('conditionsOfUse.3x2.text4'));
                conditions.push(this.$t('conditionsOfUse.3x2.text5'));
                conditions.push(this.$t('conditionsOfUse.3x2.text6'));
                return conditions;
            }
        },
        mounted() {},
        methods: {
            openModal() {
                this.$refs.modal && this.$refs.modal.open();
                this.isOpen = true;
            },
            closeModal() {
                this.$refs.modal && this.$refs.modal.close();
                this.isOpen = false;
                this.$store.dispatch('hideConditions3x2');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .see-conditions {

        background-color: white;
        overflow: auto;
        color: $dark-brown-color;
        border-radius: 1rem;
        max-width: rem(411px);
        padding: 1.5rem;
        display: grid;
        grid-row-gap: 1.5rem;
        letter-spacing: 0.05em;
        &__header {
            display: grid;
            grid-template-columns: 1fr auto;
            align-items: center;
            h1 {
                font-family: $font-secondary;
                font-weight: normal;
                font-size: 1.5rem;
                line-height: rem(33px);
            }
        }
        &__content {
            display: grid;
            &__sub-title {
                text-transform: uppercase;
                font-family: $font-secondary;
                font-weight: normal;
                font-size: 1rem;
                line-height: rem(22px);
                color: $black-opacity-color;
                padding-bottom: .5rem;
            }
            &__condition {
                font-family: $font-primary;
                font-weight: 500;
                font-size: 1rem;
                line-height: 1.5rem;
            }
            .highlight {
                color: #ff3030;
            }
        }
        &__button {
            width: 100%;
            cursor: pointer;
            margin-bottom: 1rem;
        }
        @include respond-to("medium and down"){
            max-width: rem(343px);
            height: auto;
            grid-row-gap: 1rem;
            &__button {
                margin-bottom: 2rem;
            }
        }
    }
    .see-conditions::-webkit-scrollbar {
        display: none;
    }
</style>
