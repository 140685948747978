<template>
  <article class="activity" :class="{disabled: !activityAvailable || !activity.price}">
    <div v-if="activity.price" class="activity__prices">
        <span class="activity__prices--product-type">{{ $t(activity.productPer) }}</span>
        <span class="activity__prices--product-price">{{ activity.price | currency }}</span>
    </div>
    <img :src="require('@/assets/activities/' + $t(activity.imageName) + '')" alt="">
    <div v-if="activityAvailable" class="activity__number-of" :class="{disabled: activityDisabled}">
        <h2>{{ $t(activity.counterType) }}</h2>
        <div class="activity__number-of--buttons">
          <counter :ref="activity.name + '-quantity'" :on-change="setQuantity" :size="24" :maxAllowed="maxAllowed"></counter>
        </div>
    </div>
    <div v-else class="activity__comming-soon">
      <h2>{{ $t(activity.comingSoonTitle) }}</h2>
    </div>
    <div v-if="activityAvailable" class="activity__selectors" :class="{disabled: activityDisabled}">
        <div v-for="(property, index) in activity.rentalProperties" :key="index" class="selector_wrapper"
             :class="{disabled: property === 'schedule' && errors.date === 'dateNotAvailable'}"
        >
          <span>{{ $t('selector.'+ property) }}</span>
          <selector @click.native="selectProperty(property, index)"
                    :options="options[property]" :activity="activity.name" :selector="property"
                    :show-options="openProperty === activity.name + '-' + property + '-' + index" @onSelect="fillProperty"
                    v-if="property !== 'hours' " :ref="activity.name + '-' + property" :change-date="changeDate"
                    @dateChanged="changeDate = $event"></selector>
          <div v-else style="max-width: 111px">
            <counter :ref="activity.name + '-hours'"  fontColor="#786F66" :on-change="setHours" :size="16"></counter>
          </div>
          <small class="selector_wrapper__error" v-if="errors[property] && form.quantity > 0">{{ $t('selector.errors.' + errors[property]) }}</small>
        </div>
    </div>
    <div v-if="activityAvailable" class="activity__info">
        <p class="activity__info--description">{{ $t(activity.productDesc) }}</p>
        <p class="activity__info--more" @click="showModal">{{ $t('seeImpInfo') }}</p>
    </div>
    <div v-else class="activity__info not-available">
      <p class="activity__info--description">{{ $t(activity.comingSoonDescription) }}</p>
    </div>
  </article>
</template>

<script>
import Selector from "@/components/visit-drawer/Selector";
import Counter from '@/components/visit-drawer/Counter';
import moment from "moment";
import 'moment-timezone';

export default {
  name: 'ActivityCard',
  components: {Selector, Counter},
  props: {
    activity: { type: Object, required: true },
  },
  computed: {
    currentTime() {
      return moment.tz(this.timeZone);
    },
    quote() {
      return this.$store.getters.quote ? this.$store.getters.quote : {};
    },
    activityDisabled() {
      if (this.isCanopyTourNight) {
        return this.isCanopyNightNotAvailable(this.quote.visit.arrivalDate)
          && this.quote.visit.numberOfDays < 2;
      }
      return false;
    },
    quoteActivities() {
      return this.quote && this.quote.visitActivityReservations ? this.quote.visitActivityReservations : [];
    },
    activityAvailable() {
      return !this.activity.hasOwnProperty('comingSoonDescription');
    },
    isCanopyTourNight() {
      return this.activity.name == 'canopy_tour_night';
    },
    isHorsebackRiding() {
      return this.activity.name === 'horseback_riding';
    },
    maxAllowed() {
      return  this.isHorsebackRiding ? this.maxAllowedHorses
              : this.isCanopyTourNight ? this.maxAllowedCanopyNight
              : 99;
    },
    currentSchedule() {
      if (this.form.scheduleId && this.options) {
          let schedules = this.options && this.options.schedule ? this.options.schedule : [];
          let schedulesAvailable = schedules.filter(o => o.available);
        return schedulesAvailable.find(o => o.id == this.form.scheduleId);
      }
      return null;
    },
  },
  watch: {
    'quote.visit.arrivalDate'() {
      this.changeDate = true;
    }
  },
  data() {
        return {
            openProperty: null,
            options: {
              date: [],
              schedule: []
            },
            form: {
              type: null,
              quoteId: null,
              date: null,
              quantity: null,
              hours: null,
              scheduleId: null
            },
            errors: {
              date: null,
              schedule: null,
              hours: null
            },
            timeZone: 'America/Tijuana',
            canopyTourNightLimitHour: process.env.VUE_APP_16:00,
            maxAllowedHorses: 12,
            maxAllowedCanopyNight: 50,
            changeDate: false,
        }
    },
    mounted() {
      this.form.type = this.activity.name.toUpperCase();
      this.init();
      setTimeout(() => {
        this.selectProperty('date', null);
        let reservation = this.quoteActivities && this.quoteActivities.length > 0
            ? this.quoteActivities.filter(act => act.visitActivitySchedule.visitActivity.type === this.activity.name.toUpperCase())[0]
            : null;
        if ((this.quoteActivities.length <= 0 || (reservation && reservation.quantity <= 0)) && this.activityAvailable) {
          let counter = this.activity.name + '-quantity';
          this.$refs[counter].setQuantity(0);
          this.setQuantity(0);
        }
      }, 500);
    },
  methods: {
    init() {
      let reservations = this.getQuoteActivityReservations(this.activity.name);
      if(reservations && reservations.length > 0) {
        let activity = reservations[0];

        if(activity.quantity) {
          let quantityCounter = this.activity.name + '-quantity';
          if(this.$refs[quantityCounter])
            this.$refs[quantityCounter].setQuantity(activity.quantity);
          this.setQuantity(activity.quantity);
        }

        if(activity.hours) {
          let hoursCounter = this.activity.name + '-hours';
          let hours = reservations.reduce((t, r) => t + r.hours, 0);
          if(this.$refs[hoursCounter] && this.$refs[hoursCounter][0])
            this.$refs[hoursCounter][0].setQuantity(hours);
          this.setHours(hours);
        }

        if(activity.visitDate) {
          let dateSelector = this.activity.name + '-date';
          let dateSelection = {
            option: activity.visitDate,
            property: "date"
          }
          let date = moment(activity.visitDate);
          let dateOption = {
            id: date.format('YYYY-MM-DD'),
            label: date.format('ddd DD MMM YYYY').replaceAll('.', '')
          }
          if(this.$refs[dateSelector] && this.$refs[dateSelector][0])
            this.$refs[dateSelector][0].setLabel(dateOption);
          this.fillProperty(dateSelection);
        }

        if(activity.visitActivitySchedule) {
          let scheduleSelector = this.activity.name + '-schedule';
          let scheduleSelection = {
            option: activity.visitActivitySchedule.id,
            property: "schedule"
          }

          let scheduleOption = {
            id: activity.visitActivitySchedule.id,
            label: moment(activity.visitActivitySchedule.startHour, 'HH:mm').format('h:mm A')
          }

          if(this.$refs[scheduleSelector] && this.$refs[scheduleSelector][0])
            this.$refs[scheduleSelector][0].setLabel(scheduleOption);
          this.fillProperty(scheduleSelection);
        }
      }

    },
    showModal() {
      this.$store.dispatch('updateActivityModalType', this.activity.name);
      this.$store.dispatch('updateActivitiesModalVisible', true);
    },
    async selectProperty(property, index) {

      if(property == 'date') {
        this.form.quoteId = this.quote.id;
        let arrivalDate = moment(this.quote.visit.arrivalDate);
        let departureDate = moment(this.quote.visit.departureDate);
        let dates = [];

        let date = arrivalDate;
        if (!this.isCanopyTourNight && this.quote.visit.arrivalTime == 'NIGHT' ) {
          date = date.add(1, 'days');
        }

        while(date.isSameOrBefore(departureDate)) {

          dates.push(date.format());
          date = date.add(1, 'days');

        }

        this.options.date = await this.getActivityDates(dates);

        if(this.form.date) {
          await this.getSchedules();
          if(this.options.schedule.length < 1) {
            this.form.scheduleId = null;
            this.errors.date = 'dateNotAvailable';
          }
        }


      }

      if(property == 'schedule') {
        await this.getSchedules();
        if(this.options.schedule.length < 1) {
          this.form.scheduleId = null;
          this.errors.date = 'dateNotAvailable';
        }

      }
      this.openProperty = this.activity.name + '-' + property + "-" + index;
    },
    async setQuantity(qty) {
        this.form.quantity = qty;
        await this.updateVisitActivity();
    },
    async setHours(hours) {
      this.form.hours = hours;
      await this.updateVisitActivity();
    },
    async fillProperty(selection) {
      if(selection.property == 'date'){
        this.form.date = selection.option;
      }
      if(selection.property == 'schedule') {
        this.form.scheduleId = selection.option;
      }
      await this.updateVisitActivity();
    },
    async updateVisitActivity() {
        if (this.form.date)
          await this.getSchedules();

        if (this.activity.name === 'canopy_tour' && this.form.quantity > 0 && this.form.date) { //Send schedule by default
          let schedules = await this.$store.dispatch('getVisitActivitySchedule', this.form);
          this.form.scheduleId = schedules[0].id;
        }

        if(!this.validateForm())
          return;

        if(!this.activity.price)
          return;

        let command = {
          visitActivityScheduleId: this.form.scheduleId,
          quoteId: this.quote.id,
          quantity: this.form.quantity,
          hours: this.form.hours,
          visitDate: this.form.date,
          price: this.activity.price
        }

        if ((this.isHorsebackRiding || this.isCanopyTourNight) && !this.currentSchedule) {
          this.resetCart(command);
          return;
        }

      try {
          const updQuote = await this.$store.dispatch('updateVisitActivity', command);
          await this.$store.dispatch('updateProducts', updQuote.products);
          this.onAddToCartEvent();
        } catch (error) {

          this.resetCart(command);

          if(error.response.data.message == 'Hour out of range')
            this.errors.hours = 'hoursOutOfRange';
          if(error.response.data.message == 'Not enough inventory'){
            if(this.activity.name === 'canopy_tour') {
              this.errors.date = 'dateNotAvailable';
            } else {
              this.errors.schedule = 'scheduleNotAvailable';
            }
          }
        }
    },
    async resetCart(command) {
      let currentActivity = this.quoteActivities.find(a => a.type == this.activity.name.toUpperCase());
      if (currentActivity) {
        command.visitDate = currentActivity.visitDate
        command.quantity = 0;
        const updQuote = await this.$store.dispatch('updateVisitActivity', command);
        this.form.hours = null;
        this.form.scheduleId = null;
        await this.$store.dispatch('updateProducts', updQuote.products);
        this.onAddToCartEvent();
      }
    },
    validateForm() {
      let valid = true;
      let properties = {
        date: 'date',
        hours: 'hours',
        scheduleId: 'schedule'
      };
      this.errors = {
        date: null,
        schedule: null,
        hours: null
      };

      Object.keys(this.form).forEach(key => {
        let property = properties[key];
        if (this.form[key] == null && (key !== 'hours' && this.activity.name !== 'ATV')) {
          if (property)
            this.errors[property] = `${property}Missing`;
          valid = false;
        }
      });
      return valid;
    },
    getQuoteActivityReservations(activity) {
        if(!this.quote || !this.quote.visitActivityReservations || this.quote.visitActivityReservations.length < 0)
          return [];

        return this.quote.visitActivityReservations.filter(reservation =>
            reservation.visitActivitySchedule.visitActivity.type === activity.toUpperCase());
    },
    async getActivityDates(dates) {

      if (this.isCanopyTourNight) {
        return await this.getCanopyNightAvailabilityByDate();
      }

      return dates.map(date => {
        return {
          id: moment(date).format('YYYY-MM-DD'),
          value: moment(date).format('ddd DD MMM YYYY').replaceAll('.', ''),
          available: true
        }
      });

    },
    isCanopyNightNotAvailable(date) {

      if (date === this.quote.visit.departureDate) {
        return true;
      }

      const now = this.currentTime.format('HH:mm');
      const day = moment.tz(date, this.timeZone);

      const isSameDay =  day.isSame(this.currentTime, 'day');
      const canopyTourNightLimitTime = moment.tz(this.canopyTourNightLimitHour, "HH:mm", this.timeZone).format('HH:mm');

      const isAfterValidHour = now > canopyTourNightLimitTime;
      const isSameDayAfter4pm = isSameDay && isAfterValidHour;

      return isSameDayAfter4pm;
    },
    async getCanopyNightAvailabilityByDate() {
      const command = {
        arrivalDate: moment(this.quote.visit.arrivalDate).format('YYYY-MM-DD'),
        departureDate: moment(this.quote.visit.departureDate).format('YYYY-MM-DD')
      };

      const response = await this.$store.dispatch("getCanopyNightAvailabilityByDate", command);

      return response.map(value => {
        return {
          id: moment(value.date).format('YYYY-MM-DD'),
          value: moment(value.date).format('ddd DD MMM YYYY').replaceAll('.', ''),
          available: value.available
        }
      });
    },
    isHorsebackScheduleNotAvailable(date, schedule) {

      if (!schedule.available) {
        return true;
      }

      const now = this.currentTime.format('HH:mm');
      const day = moment.tz(date, this.timeZone);

      const isSameDay =  day.isSame(this.currentTime, 'day');
      const scheduleLimitHour = moment.tz(schedule.startHour, "HH:mm", this.timeZone).format('HH:mm');

      const isAfterValidHour = now > scheduleLimitHour;
      const isSameDayAfterValidHour = isSameDay && isAfterValidHour;

      return isSameDayAfterValidHour;
    },
    async getSchedules() {
      const schedules = await this.$store.dispatch('getVisitActivitySchedule', this.form);
      this.options.schedule = schedules.map( schedule => {
        return {
          id : schedule.id,
          value: moment(schedule.startHour, 'HH:mm').format('h:mm A'),
          available: this.isHorsebackRiding ? !this.isHorsebackScheduleNotAvailable(this.form.date, schedule) : schedule.available,
          availableUnits: schedule.availableUnits
        }
      });
    },
    onAddToCartEvent() {
      this.$root.$emit('onAddToCartEvent', this.quote.productItems);
    }
  },
  filters: {
    currency(value) {
      return '$' + value + 'MXN';
    }
  }
}
</script>

<style lang="scss" scoped>
.activity {
  display: grid;
  position: relative;
  align-content: start;
  grid-gap: 1rem;
  .selector_wrapper {
    display: grid;
    grid-template-columns: 1fr minmax(10px, auto);
    color: $dark-brown-color;
    font-family: $font-secondary;
    font-size: 16px;
    font-weight: normal;
    text-transform: uppercase;
    line-height: 21px;
    &__error {
      grid-column: 1 / 3;
      font-family: $font-primary;
      font-size: rem(12px);
      line-height: 1rem;
      color: $red-color2;
      text-transform: none;
      letter-spacing: 0.05em;
    }
  }
  &__prices {
      display: grid;
        justify-items: end;
      font-style: normal;
      font-weight: 500;
      position: absolute;
      top: 1rem;
      right: 1rem;
      z-index: 1;
      &--product-type {
          color: white;
          font-family: $font-primary;
          font-size: 0.75rem;
          line-height: 1rem;
      }
      &--product-price {
          color: white;
          font-family: $font-secondary;
          font-size: 1.5rem;
          line-height: 2rem;
      }
  }
  img {
    width: 100%;
  }
  &__number-of {
      display: grid;
      grid-template-columns: 1fr 111px;
      font-size: 24px;
      font-weight: normal;
      max-height: 56.4px;
      h2 {
        font-size: 24px;
        vertical-align: center;
        padding-top: 9px;
      }
      &--buttons {
          display: flex;
          align-items: center;
          font-family: $font-secondary;
          button {
              background-color: transparent;
              color: $dark-brown-color;
              font-size: 1.5rem;
          }
      }
  }
  &__comming-soon {
    font-size: 24px;
    font-weight: normal;
    h2 {
      font-size: 24px;
      vertical-align: center;
    }
  }
  &__selectors {
    display: grid;
    grid-row-gap: 9px;
    span {
      color: $light-gray;
    }
  }
  &__info {
    display: grid;
    font-weight: normal;
    font-size: 1rem;
    &--description {
      opacity: 0.6;
    }
    &--more {
      cursor: pointer;
      align-self: flex-end;
      justify-self: center;
      color: $blue-great-color;
    }
  }
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  .disable-property {
    background-color: $blue-color;
  }
  .disabled {
      opacity: 0.5;
      pointer-events: none;
  }

  @include respond-to("medium and up") {
    &__selectors {
      min-height: 53px;
    }
    &__info:not(.not-available) {
      .activity__info--description {
        min-height: 168px;
      }
    }
  }
}
</style>