<template>
    <div class="add-more">
        <a href="" class="add-more__sum" @click.prevent="updateNumber(-1)" :class="{ 'disabled': number == numberMin }">
            <img src="@/assets/components/sub.svg" alt="">
        </a>
        <span class="add-more__label">{{ number }}</span>
        <a href="" class="add-more__sub" @click.prevent="updateNumber(1)" :class="{ 'disabled': numberMax && numberMax == number + 1  }">
            <img src="@/assets/components/sum.svg" alt="">
        </a>
    </div>
</template>

<script>
    const addMore = {
        name: 'AddMore',
        props: ['number', 'numberMin', 'numberMax', 'onChangeNumber'],
        data() {
            return {}
        },
        mounted() {},
        methods: {
            updateNumber(sum) {
                this.onChangeNumber(sum);
            },
        }
    };
    export default addMore;
</script>


<style scoped lang="scss">
    .disabled {
        pointer-events: none;
        opacity: .6;
    }
    .add-more {
        display: grid;
        grid-template-columns: rem(14px) min-content rem(14px);
        @include respond-to("large and up") { grid-column-gap: rem(14px) }
        @include respond-to("medium and down") { grid-column-gap: rem(24px) }
        a {
            display: grid;
            align-items: center;
        }
    }
</style>