<template>
    <div></div>
</template>
<script>
    let hjid = process.env.VUE_APP_1894787;
    const displayMaintenanceView = process.env.VUE_APP_false;

    if (hjid && hjid != "" && displayMaintenanceView != 'true') {
        (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid: hjid,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    }
</script>
<style lang="scss">
</style>
