<template>
  <header :class="{'low-z': $route.name === 'contact-payment' || $store.getters.activeModal}">
    <column-block class="column">
      <div class="header__back">
        <a id="checkout_home_btn" href="" @click.prevent="$router.push({name: 'homepage'})">
          <img src="@/assets/arrow-left.svg" alt="">
          <span>{{ $t('home') }}</span>
        </a>
      </div>
      <div class="header__logo">
        <a href="" @click.prevent="$router.push({name: 'homepage'})">
          <img src="../../assets/las-canadas__logo.svg" alt="Las Cañadas">
        </a>
      </div>
      <a key="closer" class="header__close" v-if="visitDrawerVisible" href="" @click.prevent="$store.dispatch('hideVisitDrawer')"><img src="@/assets/chevron-up.svg" alt=""></a>
    </column-block>
  </header>
</template>

<script>
  import ColumnBlock from '@/components/columns/ColumnBlock';

  export default {
    name: 'CheckoutHeaderBar',
    components: {ColumnBlock},
    methods: {},
    computed: {
      visitDrawerVisible() {
        return this.$store.getters.visitDrawerVisible;
      }
    }
  };

</script>

<style lang="scss" scoped>
  header {
    display: grid;
    background-color: white;
    border-bottom: $header-bar__border;
    height: rem(80px);
    position: relative;
    z-index: 9000000012;
    &.low-z {
      z-index: 1000;
    }
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);

    .column {
      display: grid;
      position: relative;
      height: rem(80px);
      grid-template-columns: 1fr;
      @include respond-to("large and up") {
        grid-template-columns: rem(112px) 1fr rem(112px);
      }
    }

    .header {
      &__logo {
        align-self: center;
        justify-self: center;
        a {
          width: rem(144px);
          display: block;
        }
      }
      &__back {
        height: 80px;
        text-align: center;
        display: grid;
        align-items: center;
        padding-left: rem(24px);
        a {
          position: relative;
          display: grid;
          grid-template-columns: rem(20px) auto;
          grid-column-gap: rem(20px);
          text-decoration: none;
          color: $dark-brown-color;
          font-weight: 500;
        }
      }
      &__close {
        align-self: center;
      }
    }
    @include respond-to("medium and down") {
      height: rem(80px);
      .column {
        height: rem(80px);
      }
      .header {
        &__logo {
          place-self: unset;
          justify-self: center;
          margin-top: 16px;
        }
        &__back {
          position: absolute;
          height: rem(80px);
          align-items: unset;
          img { display: none }
          a {
            position: unset;
            display: unset;
            grid-template-columns: unset;
            grid-column-gap: unset;
            text-decoration: none;
            margin-top: 33.6px;
            font-weight: 500;
            letter-spacing: 0.05em;
            line-height: rem(16px);
          }
          width: auto;
          .back { grid-template-columns: auto }
        }
      }
    }
  }
</style>
