<template>
  <div class="visit-arrival-time" :class="{disabled: step < 4 || noDepartureDate}">
    <div class="notice" v-if="!needMoreCabins && arrivalTimeNotice">{{ arrivalTimeNotice }}</div>
    <div class="visit-arrival-time__time" v-show="isCampingOrDayPass || needMoreCabins">
      <a href="" @click.prevent="checkUpdate('DAY')" :class="{selected: numberOfDays == 0 || (arrivalTime === 'DAY')}">
        <img class="icon" src="@/assets/day--gray.svg" alt="">
        <span>{{ $t('between7pm6pm') }}</span>
      </a>
      <a href="" @click.prevent="checkUpdate('NIGHT')" v-if="stayType !== 'DAY_PASS'" :class="{selected: arrivalTime === 'NIGHT'}">
        <img class="icon" src="@/assets/night--gray.svg" alt="">
        <span>{{ $t('between6pm12am') }}</span>
      </a>
    </div>
    <div class="max-capacity" v-show="needMoreCabins">{{ $t('maxCapacity') }}</div>
</div>
</template>

<script>
  import {mapState} from 'vuex';

  export default {
    name: 'VisitArrival',
    props: ['step'],
    data() {
        return {
          showHourArrival: false,
          maxCapacity: 6
        }
    },
    computed: {
      ... mapState(['quote', 'datesForApply']),
      numberOfDays() {
        return this.quote && this.quote.visit && this.quote.visit.numberOfDays;
      },
      arrivalTime() {
        return this.quote && this.quote.visit && this.quote.visit.arrivalTime;
      },
      arrivalDate() {
        return this.quote && this.quote.visit && this.quote.visit.arrivalDate;
      },
      departureDate() {
        return this.quote && this.quote.visit && this.quote.visit.departureDate;
      },
      stayType() {
        return this.quote && this.quote.visit && this.quote.visit.stayType;
      },
      existsPromotion() {
        return this.quote && !!this.quote.promotion;
      },
      noDepartureDate() {
        return this.quote && this.quote.visit && !this.quote.visit.departureDate
      },
      isCampingOrDayPass() {
        if (!this.quote) {
          return false;
        }

        return this.stayType === 'DAY_PASS' || this.stayType === 'CAMPING_PASS';
      },
      needMoreCabins() {
        if (this.quote.visit.stayType == 'CABIN'){
          return this.needMoreRentals('cabins');
        }
        if (this.quote.visit.stayType == 'LAKE_VILLAGE') {
          return this.needMoreRentals('lakeVillages');
        }
        if (this.quote.visit.stayType == 'TIPI') {
          return this.needMoreRentals('tipis');
        }

        return null;
      },
      isCabin() {
        if (!this.quote) {
          return false;
        }

        return this.quote ? this.quote.products.cabins : null;
      },
      isVillages() {
        if (!this.quote) {
          return false;
        }

        return this.quote ? this.quote.products.lakeVillages : null;
      },
      isTipi() {
        if (!this.quote) {
          return false;
        }

        return this.quote ? this.quote.products.tipis : null;
      },
      arrivalTimeNotice() {
        if (this.isCabin) {
          return `${this.$t('cabinHourArrival')}`;
        } else if (this.isVillages) {
          return `${this.$t('lakeVillageHourArrival')}`;
        } else if (this.isTipi) {
          return `${this.$t('tipiHourArrival')}`;
        }

        return '';
      },
    },
    methods: {
      checkUpdate(arrivalTime) {
        if (arrivalTime != this.arrivalTime && this.existsPromotion) {
          const data = {
            arrivalTime: arrivalTime,
            arrivalDate: this.arrivalDate,
            departureDate: this.departureDate
          }
          this.$emit('openModal', data);
        } else {
          this.setArrivalTime(arrivalTime);
        }
      },

      async setArrivalTime(newArrivalTime) {
        const gtmVisit = JSON.parse(JSON.stringify(this.quote.visit));

        if (this.quote.visit.arrivalTime != newArrivalTime) {
          const stayType = this.quote && this.quote.visit ? this.quote.visit.stayType : null;
          const numberOfPersons = this.quote && this.quote.visit ? this.quote.visit.numberOfPersons : null;
          await this.$store.dispatch('updateVisit', {arrivalTime: newArrivalTime, numberOfPersons, stayType});
        }

        if (gtmVisit.arrivalTime) {
          this.onAddToCartEvent();
        }
      },
      needMoreRentals(stayType) {
        let rentals = this.quote.products[stayType];
        if (rentals && rentals > 0) {
          let numberOfPersons = this.quote.visit.numberOfPersons;
          let personsPerRentals = rentals * this.maxCapacity;
          return numberOfPersons > personsPerRentals;
        }
        return false;
      },
      onAddToCartEvent() {
        setTimeout(() => {
          this.$root.$emit('onAddToCartEvent', this.quote.productItems);
        }, 5000);
      }
    },
  };
</script>

<style lang="scss" scoped>
  .visit-arrival-time {
    .modal {
      position: fixed;
      height: 100vh;
      width: 100vw;
      top: 0;
      right: 0;
      z-index: 1000000;
    }
    display: grid;
    grid-row-gap: .5rem;
    &__title {
      color: $brown-opacity-color;
      font-weight: 500;
    }
    &__time {
      display: grid;
      grid-row-gap: .5rem;
      a {
        padding: rem(10px) 1rem;
        border: 1px solid $brown-opacity-2-color;
        border-radius: .5rem;
        display: grid;
        grid-template-columns: 1.5rem 1fr;
        grid-column-gap: .5rem;
        align-items: center;
        text-decoration: none;
        color: $dark-brown-color;
        span {
          font-weight: normal;
          font-family: $font-secondary;
          letter-spacing: 0.05em;
          text-transform: uppercase;
        }
        &.selected {
          background-color: $orange-opacity-color;
          border: 1px solid $orange-color;
        }
      }
    }
    @include respond-to("small and down") {
      grid-row-gap: 4px;
    }
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
  .notice {
    margin-top: 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: $light-gray;
    text-align: justify;
  }

  .max-capacity {
    margin-top: 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: $red-color2;
    text-align: justify;
  }
</style>
