<template>
  <default-layout>
    <section class="information">
      <h1 class="information__title">{{ title }}</h1>
      <div class="information__wrapper">
        <slot></slot>
      </div>
    </section>
  </default-layout>
</template>

<script>
import DefaultLayout from "@/layouts/DefaultLayout";

export default {
  components: {DefaultLayout},
  props: {
    title: String
  }
}
</script>

<style lang="scss" scoped>
  .information {
    --content-width: calc(75% + #{rem(4px)});
    width: var(--content-width);
    max-width: var(--content-width);
    padding: rem(57.6px) 0 rem(93px);
    margin: 0 auto;
    @include respond-to("medium and down") {
      --content-width: 100%;
      padding: rem(16px) 0 rem(120px);
    }
    &__title {
      font-family: $font-secondary;
      font-weight: normal;
      font-size: 32px;
      line-height: 24px;
      text-transform: uppercase;
      margin-bottom: rem(24px);
      @include respond-to("medium and down") {
        font-size: 24px;
        line-height: 33px;
      }
    }
    &__wrapper {
      background: rgba($color: white, $alpha: 0.8);
      border: 1px solid rgba(30, 15, 0, 0.2);
      border-radius: 16px;
      padding: rem(24px);
      @include respond-to("medium and down") {
        padding: rem(16px);
      }
    }
  }
</style>