<template>
  <footer class="footer">
    <column-block class="footer__content">
      <div class="footer__content__first-row">
        <div class="social-media desktop">
            <a class="gtm-social-networks gtm-facebook" href="https://es-la.facebook.com/LasCanadasCampamento/" target="_blank" rel="noopener" @click="goToEvent('facebook')">
              <img src="@/assets/facebook.svg" alt="">
            </a>
            <a class="gtm-social-networks gtm-instagram" href="https://www.instagram.com/lascanadascampamento" target="_blank" rel="noopener" @click="goToEvent('instagram')">
              <img src="@/assets/instagram.svg" alt="">
            </a>
            <a class="gtm-social-networks gtm-vimeo" href="https://vimeo.com/lascanadascampamento" target="_blank" rel="noopener" @click="goToEvent('vimeo')">
              <img class="vimeo" src="@/assets/vimeo.svg" alt="">
            </a>
            <a class="gtm-social-networks gtm-youtube" href="https://www.youtube.com/c/LasCa%C3%B1adasCampamento" target="_blank" rel="noopener" @click="goToEvent('youtube')">
              <img class="youtube" src="@/assets/youtube.svg" alt="">
            </a>
        </div>
        <div class="footer__content__first-row__column" v-for="(column, index) in columns" :key="index">
          <h2 class="footer__content__first-row__column__title">{{$t(column.title)}}</h2>
          <div class="footer__content__first-row__column__links">
            <a href="" v-for="(link, index) in column.links" :key="index" :id="`footer__${link.event}`"
            @click.prevent="goTo(link)">{{$t(link.name) | title}}</a>
          </div>
        </div>
      </div>
      <div class="footer__content__second-row">
        <div class="social-media mobile">
            <a class="gtm-social-networks gtm-facebook" href="https://es-la.facebook.com/LasCanadasCampamento/" target="_blank" rel="noopener" @click="goToEvent('facebook')">
              <img src="@/assets/facebook.svg" alt="">
            </a>
            <a class="gtm-social-networks gtm-instagram" href="https://www.instagram.com/lascanadascampamento" target="_blank" rel="noopener" @click="goToEvent('instagram')">
              <img src="@/assets/instagram.svg" alt="">
            </a>
            <a class="gtm-social-networks gtm-vimeo" href="https://vimeo.com/lascanadascampamento" target="_blank" rel="noopener" @click="goToEvent('vimeo')">
              <img class="vimeo" src="@/assets/vimeo.svg" alt="">
            </a>
            <a class="gtm-social-networks gtm-youtube" href="https://www.youtube.com/c/LasCa%C3%B1adasCampamento" target="_blank" rel="noopener" @click="goToEvent('youtube')">
              <img class="youtube" src="@/assets/youtube.svg" alt="">
            </a>
        </div>
        <div class="conditions">
          <span>Las Cañadas Campamento © {{ yearfull }}</span>
          <router-link id="footer_terms-of-service" :to="{name: 'terms'}">{{ $t('termsPage.title') }}</router-link>
          <router-link id="footer_privacy-notice" :to="{name: 'privacy'}">{{ $t('privacyNoticePage.title') }}</router-link>
        </div>
      </div>
    </column-block>
  </footer>
</template>

<script>
  import ColumnBlock from '@/components/columns/ColumnBlock';
  export default {
    name: 'FooterBar',
    components: {ColumnBlock},
    methods: {
      goToEvent(site) {
        this.onGenericEvent(`ir a ${site}`, {event_category: 'footer', event_label: `icono ${site}`});
      },
      goTo(link) {
        this.$router.push({name: link.routeName}).catch(() => {});
        this.goToEventFooter(link.event);
      },
      goToEventFooter(site) {
        this.onGenericEvent(`ir a ${this.goToKeys[site]}`, {event_category: 'footer', event_label: `footer_${site}`});
      },
      onGenericEvent(event, command) {
        this.$root.$emit('onGenericEvent', event, command);
      },
    },
    filters: {
      title: function (text) {
        let finalText = text;
        return finalText;
      }
    },
    data() {
      return {
        yearfull: '',
        columns: [
          {
            title: "info.liveTheExperience",
            links: [ { name:"fun.earth", routeName: "product.earth", event:"ground" }, { name:"fun.air", routeName: "product.air", event: "air" }, { name:"fun.water", routeName: "product.water", event: "water"} ]
          },
          { title: "info.whenToVisit", links: [ { name: "prices", routeName: "prices", event: "prices" }, { name: "events", routeName: "events", event: "events" } ]},
          { title: "info.resolveYourDoubts", links: [ { name: "faqPages.title", routeName: "faq", event: "faq" }, { name: "directions", routeName: "directions", event: "directions" }, { name: "contact", routeName: "contact", event: "contact" } ]},
          {
            title: "info.careers",
            links: [
              { name: "info.jobs", routeName: "careers", event: "careers" }
            ]
          }
        ],
        goToKeys: {
          ground: "diversión en tierra",
          air: "diversión en aire",
          water: "diversión en agua",
          prices: "calendario de precios",
          faq: "preguntas frecuentes",
          directions: "cómo llegar",
          contact: "contacto"
        }
      }
    },
    mounted() {
      this.yearfull = (new Date().getFullYear())
    }
  };
</script>

<style lang="scss" scoped>
  .footer {
    background-color: $footer-bar__bg;
    color: white;
    letter-spacing: 0.05em;
    display: grid;
    grid-template-rows: 1fr auto;
    .social-media {
      display: grid;
      grid-template-columns: repeat(4, 2rem);
    }
    &__content {
      height: 100%;
      padding: 0 3rem;
      &__first-row {
        display: grid;
        &__column {
          display: grid;
          grid-template-rows: min-content 1fr;
          h2, span, &__links > a {
            color: white;
          }
          &__title {
            font-family: $font-secondary;
            opacity: 0.6;
            font-weight: normal;
            font-size: 1rem;
            line-height: rem(22px);
          }
          &__links {
            display: grid;
            grid-row-gap: 1rem;
            grid-auto-rows: min-content;
            a {
              font-family: $font-primary;
              text-decoration: none;
              font-weight: 500;
              font-size: 1rem;
              line-height: 1rem;
              text-transform: none;
            }
          }
        }
      }
      &__second-row {
        border-top: 1px solid $white-opacity-color;
        .conditions {
          display: grid;
          grid-gap: 1.5rem;
          a, span {
            font-family: $font-primary;
            text-decoration: none;
            color: white;
            font-weight: 500;
            font-size: 1rem;
            line-height: 1rem;
          }
          span { opacity: 0.6 }
        }
      }
      &__first-row, &__second-row {
        padding: 3rem 0;
      }
    }
    @include respond-to("medium and down") {
      .desktop { display: none }
      .social-media { grid-column-gap: 1.5rem }
      padding-top: 0 !important;
      &__content {
        &__first-row {
          grid-row-gap: 3rem;
          &__column { grid-row-gap: 1rem }
        }
        &__second-row {
          display: grid;
          grid-row-gap: 1.5rem;
          .conditions {
            grid-template-areas: "row1"
                                 "row2"
                                 "row3";
            span { grid-area: row3; }
            a { grid-area: unset; }
          }

        }
      }
    }
    @include respond-to("large and up") {
      height: $footer-bar__min-height--desktop;
      .mobile { display: none }
      .social-media { grid-column-gap: 1rem }
      &__content {
        &__first-row {
          grid-template-columns: repeat(5, 1fr);
          grid-column-gap: 3rem;
          &__column { grid-row-gap: 1.5rem }
        }
        &__second-row {
          padding-bottom: 39px;
          .conditions {
            grid-template-areas: "column1 column2";
            grid-template-columns: auto auto 1fr;
          }
        }
      }
    }
  }
</style>
