<template>
<div>
    <img v-if="pixelId" height="1" width="1" style="display:none" :src="`https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1`" alt="">
</div>
</template>

<script>
    const pixelId = process.env.VUE_APP_987501427976652;

    export default {
        components: {},
        computed: {
            pixelId() {
                return pixelId;
            }
        },
        mounted() {
            this.pixcel();
        },
        methods: {
            pixcel() {
                if (this.pixelId && this.pixelId !== '') {
                    !function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    window.fbq('init', this.pixelId);
                }
            }
        }
    };
</script>

<style lang="scss">
</style>
