<template>
  <checkout-cart-layout ref="layout">
    <div class="contact-payment">
      <h1 class="contact-payment__title">{{ $t('bestExperience') }}</h1>
      <terms-conditions></terms-conditions>
      <validation-observer ref="validationObserver" v-slot="{ handleSubmit }" slim>
        <form class="contact-payment__form" @submit.prevent="handleSubmit(!capacityFull && savePayment)">
          <fieldset class="contact-payment__form__contact">
            <h2 class="contact-payment__form__contact__title">{{ $t('contactInformation') }}</h2>
            <div class="contact-payment__form__contact__information">
              <validation-provider class="field-block" rules="required" v-slot="{ errors }" mode="lazy">
                <label class="field-block__label" for="name">{{ $t('name')}}(s)<span class="required">*</span></label>
                <input v-model.trim="payment.contact.name"
                  class="field-block__input"
                  :class="{ 'input__error': errors && errors.length > 0 }"
                  v-capitalize
                  maxlength="45"
                  ref="name"
                  id="name"
                  type="text" 
                />
                <span class="field-block__error">{{ $t(errors[0]) }}</span>
              </validation-provider>

              <validation-provider class="field-block" rules="required" v-slot="{ errors }" mode="lazy">
                <label class="field-block__label" for="lastName">{{ $t('lastname') }}(s)<span class="required">*</span></label>
                <input v-model.trim="payment.contact.lastname"
                  class="field-block__input"
                  :class="{'input__error': errors && errors.length > 0}"
                  v-capitalize
                  maxlength="45"
                  ref="lastName"
                  id="lastName" type="text"
                />
                <span class="field-block__error">{{ $t(errors[0]) }}</span>
              </validation-provider>

              <validation-provider class="field-block" rules="required|email" v-slot="{ errors }" mode="lazy">
                <label class="field-block__label" for="email">{{ $t('email') }}<span class="required">*</span></label>
                <input class="field-block__input" maxlength="255" ref="email" id="email" v-model.trim="payment.contact.email" type="email" :class="{'input__error': errors && errors.length > 0}"/>
                <span class="field-block__error">{{ $t(errors[0]) }}</span>
              </validation-provider>

              <div class="contact-payment__form__contact__information__two-fields country">
                <validation-provider class="field-block" rules="required" v-slot="{ errors }" mode="lazy">
                  <label class="field-block__label" for="country">{{ $t('country') }}<span class="required">*</span></label>
                  <drop-down-refactor class="field-block__input" ref="countryRef" :class="{'drop-down-error': errors[0]}" v-model.trim="country" :select="state" :options="countries"></drop-down-refactor>
                  <span class="field-block__error">{{ $t(errors[0]) }}</span>
                </validation-provider>

                <validation-provider v-if="countryIsOther" class="field-block no-label" rules="required" v-slot="{ errors }" mode="lazy">
                  <input class="field-block__input" ref="country" maxlength="40" id="country" :placeholder="$t('placeholder.otherCountry')" v-model.trim="payment.contact.country" type="text" :class="{'input__error': errors && errors.length > 0}"/>
                  <span class="field-block__error">{{ $t(errors[0]) }}</span>
                </validation-provider>
              </div>

              <div class="contact-payment__form__contact__information__two-fields">
                <validation-provider v-if="!countryIsOther" class="field-block" rules="required" v-slot="{ errors }" mode="lazy">
                  <label class="field-block__label" for="state">{{ $t('state') }}<span class="required">*</span></label>
                  <drop-down-refactor class="field-block__input" ref="stateRef" :class="{'drop-down-error': errors[0]}" v-model.trim="state" :options="country ? country.states : []"></drop-down-refactor>
                  <span class="field-block__error">{{ $t(errors[0]) }}</span>
                </validation-provider>

                <validation-provider v-if="state && state.code === 'other'" class="field-block" :class="{'no-label': !countryIsOther}" rules="required" v-slot="{ errors }" mode="lazy">
                  <label class="field-block__label" v-if="countryIsOther" for="state">{{ $t('state') }}<span class="required">*</span></label>
                  <input class="field-block__input" ref="state" maxlength="40" :placeholder="countryIsOther ? '' : $t('placeholder.otherState')" id="state" v-model.trim="payment.contact.state" type="text" :class="{'input__error': errors && errors.length > 0}"/>
                  <span class="field-block__error">{{ $t(errors[0]) }}</span>
                </validation-provider>
              </div>


              <validation-provider class="field-block" rules="required" v-slot="{ errors }" mode="lazy">
                <label class="field-block__label">{{ $t('city') }}<span class="required">*</span></label>
                <predictive-search v-if="country && country.code === 'USA' && state && state.code !== 'other'" class="field-block__input" v-model.trim="payment.contact.city"
                                   :country="country" :state="state" @onSelectOrigin="setOriginFromSearch"></predictive-search>
                <input v-else class="field-block__input" ref="city" maxlength="40" id="city" v-model.trim="payment.contact.city" type="text" :class="{'input__error': errors && errors.length > 0}"/>
                <span class="field-block__error">{{ $t(errors[0]) }}</span>
              </validation-provider>

              <validation-provider v-if="hasStay" class="field-block"
                :rules="{min:14 }" v-slot="{ errors }" mode="lazy">
                <label class="field-block__label" for="telephone">{{ $t('telephone') }}</label>
                <input class="field-block__input" ref="telephone" v-mask="'(###) ###-####'" maxlength="14"
                  id="telephone" v-model.trim="payment.contact.telephone" placeholder="(000) 000 - 0000" type="text"
                  :class="{'input__error': errors && errors.length > 0}"/>
                <span class="field-block__error">{{ $t(errors[0]) }}</span>
              </validation-provider>

              <div class="checkbox-row">
                <div class="checkbox-row__flex">
                  <checkbox class="checkbox-row__flex__input" v-model="visitorWantsInfo"></checkbox>
                  <span class="checkbox-row__flex__text">{{ $t('additionalInfo') }}</span>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset class="contact-payment__form__payment">
            <h2 class="contact-payment__form__payment__title">{{ $t('paymentMethod') }}<img class="contact-payment__accepted-cards" src="@/assets/cards/accepted-cards.svg" alt=""></h2>
            <div class="contact-payment__form__payment__information">

              <div class="row">
                <validation-provider class="field-block card-country" rules="required" v-slot="{ errors }" mode="lazy">
                  <label class="field-block__label" for="country">{{ $t('cardCountry') }}<span class="required">*</span> <img src="@/assets/info-black.svg" alt="" v-tooltip.top="{ content: tooltips.cardCountry }"></label>
                  <drop-down-refactor class="field-block__input"
                    ref="cardCountryRef" :class="{'drop-down-error': errors[0]}"
                    v-model.trim="cardCountry" :options="countries" @input="cardCountryChanged"/>
                  <span class="field-block__error">{{ $t(errors[0]) }}</span>
                </validation-provider>

                <div ref="zipCode" class="field-block card-postal-code" :class="{ 'field-block--disabled': !cardCountry }">
                  <label class="field-block__label" for="card-postal-code">{{ $t('zipCode') }}<span class="required">*</span> <img src="@/assets/info-black.svg" alt="" v-tooltip.top="{ content: tooltips.zipCode }"></label>
                  <div id="card-postal-code" class="field-block__input input empty"></div>
                  <div class="field-block__error card" id="card-postal-code-errors"></div>
                </div>

                <validation-provider class="field-block card-name" :class="{ 'field-block--disabled': !cardCountry }" rules="required" v-slot="{ errors }" mode="lazy">
                  <label class="field-block__label" for="card-name">{{$t('holderName')}}<span class="required">*</span></label>
                  <input class="field-block__input" :disabled="!cardCountry" ref="cardName" maxlength="40" id="card-name" v-model.trim="cardInfo.cardName" type="text" :class="{'input__error': errors && errors.length > 0}"/>
                  <span class="field-block__error card">{{ $t(errors[0]) }}</span>
                </validation-provider>
              </div>

              <div class="row">
                <div ref="cardNumber" class="field-block card-number" :class="{ 'field-block--disabled': !cardCountry }">
                  <label class="field-block__label" for="card-number">{{ $t('cardNumber') }}<span class="required">*</span></label>
                  <div id="card-number" class="field-block__input input empty"></div>
                  <img class="field-block__card" v-if="cards.indexOf(cardBrand) != -1" :src="require('@/assets/cards/' + cardBrand + '.svg')" alt="">
                  <div class="field-block__error card" id="card-number-errors"></div>
                </div>

                <div ref="cardExpiry" class="field-block card-expiry" :class="{ 'field-block--disabled': !cardCountry }">
                  <label class="field-block__label" for="card-expiry">{{ $t('expiration') }}<span class="required">*</span></label>
                  <div id="card-expiry" class="field-block__input input empty"></div>
                  <div class="field-block__error card" id="card-expiry-errors"></div>
                </div>

                <div ref="cardCvc" class="field-block card-cvc" :class="{ 'field-block--disabled': !cardCountry }">
                  <label class="field-block__label" for="card-cvc">{{ $t('code') }}<span class="required">*</span></label>
                  <div id="card-cvc" class="field-block__input input empty"></div>
                  <div class="field-block__error card" id="card-cvc-errors"></div>
                </div>
              </div>

            </div>
          </fieldset>
        </form>
      </validation-observer>
      <processing-payment-modal ref="processingPaymentModal"></processing-payment-modal>
      <change-currency-modal ref="changeCurrencyModal"></change-currency-modal>
    </div>
    <see-conditions-modal ref="conditionsModal"></see-conditions-modal>
  </checkout-cart-layout>
</template>

<script>
import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import {email, min, required} from "vee-validate/dist/rules";
import {fbq} from '@/main.js';
import {mapState} from 'vuex';
import CheckoutCartLayout from "@/layouts/CheckoutCartLayout";
import DropDownRefactor from "@/components/dropdowns/DropDownRefactor";
import PredictiveSearch from "@/components/dropdowns/PredictiveCitySearch";
import ProcessingPaymentModal from "@/components/modal/ProcessingPaymentModal";
import TermsConditions from "@/components/TermsConditions";
import SeeConditionsModal from '@/components/visit-drawer/SeeConditionsModal';
import ChangeCurrencyModal from "@/components/modal/ChangeCurrencyModal";
import Checkbox from '@/components/inputs/Checkbox';

extend("required", {
  ...required,
  message: 'validations.requiredField'
});

extend("min", {
  ...min,
  message: 'validations.invalidPhoneNumber'
});

extend("email", {
  ...email,
  message: 'validations.invalidRmailAddress'
});

export default {
  components: {CheckoutCartLayout, ValidationProvider, ValidationObserver, ProcessingPaymentModal, TermsConditions,
    DropDownRefactor, SeeConditionsModal, ChangeCurrencyModal, Checkbox, PredictiveSearch},
    data: function() {
    return {
      cardBrand: null,
      stripe: null,
      elements: null,
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      zipCode: null,
      cardCountry: null,
      intent: null,
      countries: [],
      usaStates: ["AZ", "CA", "NV", "other"],
      country: null,
      state: null,
      visitorWantsInfo: false,
      payment: {
        contact: {
          name: "",
          lastname: "",
          email: "",
          city: "",
          state: "",
          country: "",
          telephone: ""
        },
        shoppingCartId: ""
      },
      cardInfo: {
        cardNumber: "",
        cardName: "",
        cardExpiry: "",
        cardCvc: "",
        zipCode: "",
      },
      cards: ['discover','visa', 'mastercard', 'amex']
    };
  },
  name: 'ContactPaymentForm',
  metaInfo() {
    return {title: this.$t("tabs.paymentAndContact")}
  },
  mounted() {
    fbq('track', 'InitiateCheckout');
    if(!this.quote){
      this.$store.dispatch("message", {
            type: "error",
            message: "Error al cargar carrito"
      });
      this.$router.push("/");
    }
    this.payment.shoppingCartId = localStorage.getItem('CANADAS_SHOPPING_CART');
    this.getUnavailableReservations(this.quote.id);
    this.getCountries();
    this.stripeSetup();
    this.$store.dispatch('showMobileSumatyVisible');

    this.onBeginCheckoutEvent();
  },
  filters: {
    StripeI18n: function(locale, status) {
      const errors = {
        'es': {
          'card_declined': 'La tarjeta fué rechazada.',
          'processing_error': 'Ocurrió un error procesando la tarjeta.',
          'expired_card': 'Tarjeta expirada.',
          'incorrect_cvc': 'Código de verificación incorrecto.'
        },
        'en': {
          'card_declined': 'The card was rejected.',
          'processing_error': 'An error occurred while processing the card.',
          'expired_card': 'Card expired.',
          'incorrect_cvc': 'Incorrect verification code.'
        }
      }
      return errors[locale][status] || status
    }
  },
  computed: {
    inRemoteActivity: {
      get() {
        return this.$store.getters.inRemoteActivity;
      },
    },
    ... mapState(['clickToFinishPayment', 'conditions2x3Visible']),
    cardNumberIsEmpty() {
      return this.cardNumber && this.cardNumber._empty;
    },
    countryIsOther() {
      return this.country && this.country.code === 'other';
    },
    stateIsOther() {
      return this.state && this.state.code === 'other';
    },
    notContainOther() {
      return !(this.country && this.country.states && this.country.states.find(state => state.code === 'other'));
    },
    capacityFull() {
      if (!this.quote) {
        return false;
      }

      if (this.hasStay) {
        return false;
      }

      const haveDates = this.quote && this.quote.visit && this.quote.visit.arrivalDate && this.quote.visit.departureDate && this.quote.visit.arrivalTime;
      return haveDates ? (this.quote.visit.arrivalTime === 'DAY' ? this.$store.getters.capacityFullDay : this.$store.getters.capacityFullNight) : false;
    },
    quote() {
      return this.$store.getters.quote ? this.$store.getters.quote : {};
    },
    hasStay() {
      return (this.quote.cabinNumbers && this.quote.cabinNumbers.length > 0)
        || (this.quote.tipiNumbers && this.quote.tipiNumbers.length > 0)
        || (this.quote.lakeVillageNumbers && this.quote.lakeVillageNumbers.length > 0);
    },
    currency() {
      return this.$store.getters.currency;
    },
    tooltips() {
      return {
        cardCountry: this.$t('cardCountryTooltip'),
        zipCode: this.$t('zipCodeTooltip'),
      }
    }
  },
  watch: {
    clickToFinishPayment() {
      this.validateStripeFields();
      this.$refs.validationObserver.handleSubmit(this.savePayment);
    },
    'country'() {
      let other = {id: 'other', code: 'other', text: 'other'};
      let states = (this.country && this.country.states && this.country.states.length > 0) ? (this.notContainOther ? this.country.states.concat([other]) : this.country.states) : [];
      if(this.country.code === 'USA') {
        states = states.filter(state => this.usaStates.includes(state.code));
      } else {
        this.payment.contact.city = null;
      }
      this.$set(this.country, 'states', states);
      if (this.country && this.country.code === 'other') {
        this.$set(this, 'state', other);
      } else if (this.state) {
        this.$set(this, 'state', {});
      }
    },
    'conditions2x3Visible'() {
      if(this.conditions2x3Visible) {
        document.removeEventListener('click', this.onClick, false);
        document.removeEventListener("keyup", this.closeVisitDrawer, false);
        this.$refs.conditionsModal.openModal();
      } else {
        this.$refs.conditionsModal.closeModal();
        setTimeout(() => {
          document.addEventListener('click', this.onClick);
          document.addEventListener("keyup", this.closeVisitDrawer);
        }, 100);
      }
    }
  },
  methods: {
    async getCountries() {
      try {
        this.countries = await this.$store.dispatch('loadCountries');
        this.countries = this.countries.map(country => {
          country.label = this.$t(`countries.${country.code}`);
          return country;
        });
        this.countries.push({id: 'other', code: 'other', label: this.$t('countries.other')});
      } catch (e) {
        return;
      }
    },
    async savePayment() {
      this.$refs.processingPaymentModal.openModal();
      this.$store.dispatch('hideMobileSumatyVisible');
      this.onGenericEvent(`finalizar compra`, {event_category: 'carrito', event_label: `cta - finalizar compra`});

      const paymentMethod = {
        type: "card",
        card: this.cardNumber,
        billing_details: {
          email: this.payment.contact.email,
          name: this.cardInfo.cardName
        },
        metadata:{
          shoppingCartId: this.payment.shoppingCartId,
          cardOwner: this.cardInfo.cardName,
          zipCode: this.cardInfo.zipCode,
          name: this.payment.contact.name,
          lastname: this.payment.contact.lastname,
          email: this.payment.contact.email,
          country: this.countryIsOther ? this.payment.contact.country : this.country.label,
          state: this.stateIsOther ? this.payment.contact.state : this.state.label,
          city: this.payment.contact.city,
        }
      };
      const paymentData = this.getPaymentData();

      this.$store.dispatch("beginRemoteActivity");

      await this.stripe.createPaymentMethod(paymentMethod).then(paymentMethodResult => {
        if(paymentMethodResult.error) {
          this.$refs.processingPaymentModal.closeModal();
          this.$store.dispatch('showMobileSumatyVisible');
          this.$store.dispatch("message", {
            type: "error",
            message: paymentMethodResult.error.message
          });
        } else {
          paymentData.paymentMethodId = paymentMethodResult.paymentMethod.id;
          this.makePayment(paymentData);
        }
        this.$store.dispatch("endRemoteActivity");
      });
    },
    async makePayment(paymentData) {
      try {
        let result = await this.$store.dispatch("makePayment", paymentData);
        this.handlePaymentResult(result);
      } catch (error) {
        this.$refs.processingPaymentModal.closeModal();
        this.$store.dispatch('showMobileSumatyVisible');
        switch (error.response.data.error) {
          case 'can_not_save_reservation':
              this.getUnavailableReservations(paymentData.shoppingCartId);
            break;
            case 'can_not_make_any_more_online_payments':
              this.$store.dispatch("message", {
                type: "error",
                message: this.$t('errors.canNotMakeAnyMorePayment')
              });
            break;
            case 'not_acceptable':
              this.$store.dispatch("message", {
                type: "error",
                message: error.response.data.message
              });
            break;
          default:
            break;
        }
      }
    },
    getUnavailableReservations(quoteId) {
      this.$store.dispatch('validReservations', quoteId);
    },
    async handlePaymentResult(result, paymentData) {
      if (result.paymentInfo.status === "error") {
        let message = result.paymentInfo.message.split(";").filter(value => value.includes("code")).toString().split(":")[1];
        let locale = this.$store.getters.locale;

        this.$refs.processingPaymentModal.closeModal();
        this.$store.dispatch('showMobileSumatyVisible');
        this.$store.dispatch("endRemoteActivity");
        this.$store.dispatch("message", {
          type: "error",
          message: this.$options.filters.StripeI18n(locale, message.trim())
        });
      } else if (result.paymentInfo.status === "requires_action") {
        this.stripe.handleCardAction(result.paymentInfo.clientSecret)
                .then(result => this.handleStripeResult(result, paymentData))
      } else {
        const quote = await this.$store.dispatch('getQuote', this.quote.id);
        this.goToThankYouPage(quote);
      }
    },
    handleStripeResult(result) {
      const paymentData = this.getPaymentData();
      if (result.error) {
        this.$refs.processingPaymentModal.closeModal();
        this.$store.dispatch("message", {
          type: "error",
          message: result.error.message
        });
      } else {
        paymentData.paymentIntentId = result.paymentIntent.id;
        this.$store.dispatch("makePayment", paymentData).then(result => {
          this.goToThankYouPage(result.quote);
        })
      }
    },
    getPaymentData() {
      return {
        shoppingCartId: this.payment.shoppingCartId,
        cardOwner: this.cardInfo.cardName,
        zipCode: this.cardInfo.zipCode,
        contact : {
          name: this.payment.contact.name,
          lastname: this.payment.contact.lastname,
          email: this.payment.contact.email,
          country: this.countryIsOther ? this.payment.contact.country : this.country.label,
          state: this.stateIsOther ? this.payment.contact.state : this.state.label,
          city: this.payment.contact.city,
          phoneNumber: this.payment.contact.telephone ? this.payment.contact.telephone.replace(/\D/g, '') : null
        },
        currency: this.currency.currency,
        exchangeRate: this.currency.exchangeRate,
        visitorWantsInfo: this.visitorWantsInfo
      }
    },
    async validatesPaymentMade(sCartId) {
      this.$store.dispatch("beginRemoteActivity");
      try {
        let sCart = await this.$store.dispatch("loadQuoteById", sCartId);
        if(!sCart.error) {
          this.goToThankYouPage(sCart.quote);
        }
      } catch (e) {
        setTimeout(() => { this.validatesPaymentMade(sCartId) }, 1500);
      }
    },
    stripeSetup() {
      let locale = this.$store.getters.locale;
      let stripeKey;
      if(this.currency.currency !== 'USD') {
        stripeKey = process.env.VUE_APP_pk_live_iGDq5h80cLYP05BE5cIDZR0G00YW1egtvd;
      } else {
        stripeKey = process.env.VUE_APP_pk_live_51I8zSUG6y4o2YUdMuSlWnFQwiv8nnCx7JsSuC5W8IrzszUlbRdxE3R6uaKISquOsRoZagYniQodf0U2XtybFbYNV00LsMFqoBp;
      }
      /* global Stripe */
      this.stripe = Stripe(stripeKey, { locale: locale });
      this.elements = this.stripe.elements({
        locale: locale
      });
      let elementStyles = {
        base: {
          "::placeholder": {},
          ":-webkit-autofill": {
            color: "#e39f48"
          }
        },
        invalid: {
          color: "#E25950",
          "::placeholder": {
            color: "#FFCCA5"
          }
        }
      };

      let elementClasses = {
        focus: "focused",
        empty: "empty",
        invalid: "invalid"
      };

      this.cardNumber = this.elements.create("cardNumber", {
        style: elementStyles,
        classes: elementClasses,
        placeholder: ''
      });
      this.cardNumber.mount("#card-number");

      this.cardExpiry = this.elements.create("cardExpiry", {
        style: elementStyles,
        classes: elementClasses
      });
      this.cardExpiry.mount("#card-expiry");

      this.cardCvc = this.elements.create("cardCvc", {
        style: elementStyles,
        classes: elementClasses
      });
      this.cardCvc.mount("#card-cvc");

      this.zipCode = this.elements.create("postalCode", {
        style: elementStyles,
        classes: elementClasses,
        placeholder: '',
      });
      this.zipCode.mount("#card-postal-code");

      this.cardNumber.addEventListener("change", card => {

        const displayError = document.getElementById("card-number-errors");
        this.cardBrand = card && card.brand;
        this.validateStripeFields();
        if (card.error) {
          displayError.textContent = card.error.message;
        } else {
          displayError.textContent = "";
        }
      });

      this.cardExpiry.addEventListener("change", ({ error }) => {
        const displayError = document.getElementById("card-expiry-errors");
        this.validateStripeFields();
        if (error) {
          displayError.textContent = error.message;
        } else {
          displayError.textContent = "";
        }
      });

      this.cardCvc.addEventListener("change", ({ error }) => {
        const displayError = document.getElementById("card-cvc-errors");
        this.validateStripeFields();
        if (error) {
          displayError.textContent = error.message;
        } else {
          displayError.textContent = "";
        }
      });

      this.zipCode.addEventListener("change", ({ error }) => {
        const displayError = document.getElementById("card-postal-code-errors");
        this.validateStripeFields();
        if (error) {
          displayError.textContent = error.message;
        } else {
          displayError.textContent = "";
        }
      });

      if (!this.cardCountry) {
        this.cardNumber.update({ disabled: true });
        this.cardExpiry.update({ disabled: true });
        this.cardCvc.update({ disabled: true });
        this.zipCode.update({ disabled: true });
      }
    },
    validateStripeFields() {
      this.validate('cardNumber', 'number');
      this.validate('cardExpiry', 'expiry');
      this.validate('cardCvc', 'cvc');
      this.validate('zipCode', 'postal-code');
    },
    validate(pName, pErrorName) {
        if(this[pName]) {
          if(this[pName]._empty) {
            this.$refs[pName].classList.add("empty");
            document.getElementById(`card-${pErrorName}-errors`).textContent = this.$t('requiredField')
          } else {
            this.$refs[pName].classList.remove("empty");
          }
        }
    },
    async cardCountryChanged(country) {
      if(country.code == 'USA'){
        let exchangeRate = await this.$store.dispatch('getExchangeRate');
        this.$refs.changeCurrencyModal.exchangeRate = exchangeRate.value;
        this.$refs.changeCurrencyModal.openModal();
        this.$store.commit('updateCurrency', {
              currency: 'USD',
              exchangeRate: exchangeRate.value
            });
      } else {
        this.$store.commit('updateCurrency', {
          currency: 'MXN',
          exchangeRate: 1
        });
      }
      this.stripeSetup();
    },
    setOriginFromSearch(origin) {
      if(origin) {
        this.payment.contact.city = origin.county ? origin.county : origin;
      } else {
        this.payment.contact.city = null;
      }
    },
    onBeginCheckoutEvent() {
      this.$root.$emit('onBeginCheckoutEvent', this.quote.productItems, this.quote.totalAmount);
    },
    async goToThankYouPage(quote) {
      await this.$store.dispatch("deleteQuote");
      this.onPurchaseEvent(quote);
      this.$router.push({ name: 'order.details', params: { code: quote.visit.code }});
      this.$store.dispatch("endRemoteActivity");
    },
    onPurchaseEvent(quote) {
      this.$root.$emit('onPurchaseEvent', quote.productItems, quote.totalAmount);
    },
    onGenericEvent(event, command) {
      this.$root.$emit('onGenericEvent', event, command);
    },
  }
};
</script>

<style lang="scss" scoped>
.contact-payment {
  letter-spacing: 0.05em;
  &__title {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: normal;
    font-size: 1.5rem;
    line-height: rem(33px);
    text-transform: uppercase;
  }
  &__accepted-cards {
    position: relative;
    padding-left: rem(24px);
    top: 4px;
  }
  &__form {
    padding-top: 1.5rem;
    display: grid;
    grid-gap: 1.5rem;
    &__contact, &__payment {
      display: grid;
      border-radius: 1rem;
      border: 1px solid $brown-opacity-2-color;
      background-color: hsla(0, 0%, 100%, .8);
      color: $brown-opacity-color;
      padding: 1.5rem;
      padding-bottom: .5rem;
      &__title {
        font-family: $font-secondary;
        font-size: 1.5rem;
        line-height: rem(33px);
        text-transform: uppercase;
        padding-bottom: rem(29px);
      }
      &__information {
        display: grid;
        &__two-fields {
          display: grid;
          grid-row-gap: .5rem;
          &.country {
            z-index: 3;
          }
        }
        .field-block {
          display: grid;
          height: min-content;
          grid-template-areas: "label" "input" "error";
          grid-template-rows: rem(19px) 3rem min-content;
          &.no-label {
            grid-template-areas: "input" "error";
            grid-template-rows: 3rem min-content;
          }
          grid-row-gap: rem(4px);
          position: relative;
          font-style: normal;
          font-weight: 500;
          &__label {
            grid-area: label;
            font-family: $font-primary;
            font-style: normal;
            font-weight: 500;
            font-size: 1rem;
            color: $black-opacity-color;
            line-height: 1rem;
            margin: 0;
            .required { color: $red-color2;}
          }
          &__input {
            grid-area: input;
            height: 3rem;
            margin: 0;
            color: $dark-brown-color;
            &.input__error {
              border: 1px solid $red-color2;
            }

          }
          &__error {
            grid-area: error;
            font-size: rem(12px);
            font-weight: 500;
            color: $alert-color;
            &.card {
              padding-bottom: .6rem;
            }
          }
          &.card-number, &.card-expiry, &.card-cvc, &.card-postal-code {
            &.empty > .field-block__input { border: 1px solid $red-color2; }
          }

          &--disabled {
            label {
              color: $dark-brown-color;
              opacity: 0.2;
            }
            input:disabled {
              opacity: 1;
              background-color: white;
            }
          }
        }
        .StripeElement {
          display: grid;
          align-items: center;
          background-color: white;
          font-size: 1rem;
          font-family: "Open Sans", sans-serif;
          border: $light-border;
          width: 100%;
          outline: none;
          padding: 0.5rem;
          border-radius: rem($small-radius);
          width: 100%;
          font-family: $font-primary;
          font-style: normal;
          font-weight: 500;
          font-size: 1rem;
          line-height: 1rem;
          &.invalid { border-color: $red-color2;}
        }
        .checkbox-row {
          grid-column-start: span 3;
          margin-top: -8px;
          &__flex {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px;
            flex: none;
            order: 2;
            align-self: stretch;
            flex-grow: 0;
            &__input {
              -webkit-appearance:checkbox;
              display: inline-block;
              flex-direction: column;
              align-self: flex-start;
              min-width: 24px;
              width: 24px;
              height: 24px;
            }
            &__text {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: flex-start;
              padding: 0px;
              order: 1;
              flex-grow: 1;
              margin: 0px 8px;
              font-family: Montserrat;
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
              letter-spacing: 0.05em;
              text-align: left;
            }
          }
        }
      }
    }
    &__contact {
      padding-bottom: rem(18px);
      &__information {
        grid-gap: rem(34px) 1.5rem;
        @include respond-to("small and down") {
          grid-template-columns: 1fr;
          .checkbox-row {
            grid-column-start: unset;
            &__flex {
              position: relative;
              &__input {
                flex-direction: column;
                align-self: flex-start;
              }
            }
          }
        }
        @include respond-to("large and up") {
          grid-template-columns: rem(271px) rem(271px) 1fr;
        }
      }
    }
    &__payment {
      &__information {
        display: grid;
        grid-row-gap: 1rem;

        .row {
          display: grid;
        }

        .field-block.card-country {
            .field-block__input {
              width: 100%;
            }
          }

        @include respond-to("large and up") {

          .row {
            grid-gap: 1.5rem;
            &:first-child {
              grid-template-columns: 271px 194px 1fr;
            }
            &:last-child {
              grid-template-columns: 1fr 250px 250px;
            }
          }
          .field-block {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr;
            justify-items: start;
            &__input {
              position: relative;
            }
            &__error { grid-area: 3/1;}
            &__card {
              display: grid;
              grid-area: 2/1;
              place-self: center end;
              padding-right: 1rem;
              z-index: 2;
            }
            &__label {
              width: 110%;
            }
            &.card-country {
              .field-block__input {
                width: 100%;
              }
            }
          }
        }
        @include respond-to("medium and down") {
          grid-row-gap: .5rem;
          &__first-row, &__second-row {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 1.5rem;
            grid-row-gap: .5rem;
          }
          .row {
            grid-gap: 1rem;
            &:last-child {
              grid-template-columns: repeat(2, 1fr);
              .card-number {
                grid-area: auto / span 2
              }
            }
          }
          .field-block {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr;
            grid-auto-rows: min-content;

            justify-items: start;
            &__error { grid-area: 3/1;}
            &__card {
              display: grid;
              align-self: center;
              position: absolute;
              right: 1rem;
              height: 1.7rem;
            }
          }
        }
      }
    }
  }
  @include respond-to("small and down") {
    padding-bottom: 1.5rem;
    &__title { display: none }
    &__accepted-cards {
      padding-left: 17px;
    }
    &__form {
      padding: 0;
      grid-row-gap: 1.5rem;
      &__contact, &__payment {
        padding: 1rem;
        &__title {
          font-weight: normal;
          font-size: 1rem;
          line-height: rem(22px);
          padding-bottom: 1.25rem;
        }
      }
    }
  }
}
</style>
