<template>
    <div class="vehicle">
      <h1 class="vehicle__title">{{ $t('expediteYourEntry')}}</h1>
      <span class="vehicle__instructions">{{ $t('fillTheFields') }}</span>
      <validation-observer ref="validationObserver" v-slot="{ invalid, handleSubmit }" slim>
        <form class="vehicle__form" @submit.prevent="handleSubmit(saveEntry)">
          <fieldset v-if="vehicle">
            <div class="vehicle__form__field">
              <div class="select">
                <validation-provider class="field vehicle__form__field__type" rules="required" v-slot="{ errors }" mode="aggressive">
                  <label for="type">{{ $t('vehicle.type')}}<span class="required">*</span></label>
                  <drop-down-refactor ref="type" v-model.trim="vehicle.type" :options="properties.vehicleTypes"></drop-down-refactor>
                  <span class="error">{{ $t(errors[0]) }}</span>
                </validation-provider>
                <validation-provider :key="keyForm + 1" v-if="vehicle.type && vehicle.type.id && vehicle.type.id == 'OTHER'" class="field field-block other" :rules="{required: !vehicleTypeIsPedestrian}" v-slot="{ errors }" mode="aggressive">
                  <input ref="otherType" maxlength="100" id="otherType" :placeholder="$t('placeholder.otherVehicle')" v-model.trim="vehicle.otherType" type="text"/>
                  <span class="error">{{ $t(errors[0]) }}</span>
                </validation-provider>
              </div>
              <div class="select" :key="keyForm + 2" :class="{disabled: vehicleTypeIsPedestrian}">
                <validation-provider class="field vehicle__form__field__brand" :rules="{required: !vehicleTypeIsPedestrian}" v-slot="{ errors }" mode="aggressive">
                  <label for="brand">{{ $t('vehicle.brand')}}<span class="required">*</span></label>
                  <drop-down :options="properties.brands"  placeholder="placeholder.selectBrand" ref="brand" v-model.trim="vehicle.brand" class="drop-down"></drop-down>
                  <span class="error">{{ $t(errors[0]) }}</span>
                </validation-provider>
                <validation-provider v-if="vehicle.brand && vehicle.brand == 'other'" class="field field-block other" :rules="{required: !vehicleTypeIsPedestrian}" v-slot="{ errors }" mode="aggressive">
                  <input ref="otherBrand" maxlength="100" id="otherBrand" :placeholder="$t('placeholder.otherBrand')" v-model.trim="vehicle.otherBrand" type="text"/>
                  <span class="error">{{ $t(errors[0]) }}</span>
                </validation-provider>
              </div>
              <div class="select" :key="keyForm + 3" :class="{disabled: vehicleTypeIsPedestrian}">
                <validation-provider class="field vehicle__form__field__color" :rules="{required: !vehicleTypeIsPedestrian}" v-slot="{ errors }" mode="aggressive">
                  <label for="color">Color<span class="required">*</span></label>
                  <drop-down :options="properties.colors" placeholder="placeholder.selectColor" ref="color" v-model.trim="vehicle.color" class="drop-down"></drop-down>
                  <span class="error">{{ $t(errors[0]) }}</span>
                </validation-provider>
                <validation-provider v-if="vehicle.color && vehicle.color == 'OTHER'" class="field field-block other" :rules="{required: !vehicleTypeIsPedestrian}" v-slot="{ errors }" mode="aggressive">
                  <input ref="otherColor" maxlength="100" id="otherColor" :placeholder="$t('placeholder.otherColor')" v-model.trim="vehicle.otherColor" type="text"/>
                  <span class="error">{{ $t(errors[0]) }}</span>
                </validation-provider>
              </div>
              <validation-provider :key="keyForm + 4" :class="{disabled: vehicleTypeIsPedestrian}" class="field field-block" :rules="{required: !vehicleTypeIsPedestrian}" v-slot="{ errors }" mode="aggressive">
                <label for="plate">{{ $t('vehicle.licensePlate') }}<span class="required">*</span></label>
                <input ref="plate" maxlength="100" id="plate" v-model.trim="vehicle.plate" type="text"/>
                <span class="error">{{ $t(errors[0]) }}</span>
              </validation-provider>
              <div class="select" :key="keyForm + 5" :class="{disabled: vehicleTypeIsPedestrian}">
                <validation-provider class="field vehicle__form__field__brand" :rules="{required: !vehicleTypeIsPedestrian}" v-slot="{ errors }" mode="aggressive">
                  <label for="plateType">{{ $t('vehicle.licensePlates.type')}}<span class="required">*</span></label>
                  <drop-down :options="properties.licenses" placeholder="placeholder.selectPlate" ref="brand" v-model.trim="vehicle.plateType" class="drop-down"></drop-down>
                  <span class="error">{{ $t(errors[0]) }}</span>
                </validation-provider>
                <validation-provider v-if="vehicle.plateType && vehicle.plateType == 'OTHER'" class="field field-block other" :rules="{required: !vehicleTypeIsPedestrian}" v-slot="{ errors }" mode="aggressive">
                  <input ref="otherPlateType" maxlength="100" id="otherPlateType" :placeholder="$t('placeholder.otherPlate')" v-model.trim="vehicle.otherPlateType" type="text"/>
                  <span class="error">{{ $t(errors[0]) }}</span>
                </validation-provider>
              </div>
            </div>
          </fieldset>
          <validation-provider class="vehicle__form__first-time" rules="required" v-slot="{ errors }" mode="aggressive">
            <input type="hidden" name="firstTime" id="firstTime" v-model="vehicle.firstTime">
            <span class="text_first_time">{{ $t('itsYourFirstVisit') }}
              <span class="required">*</span>
            </span>
            <div class="text-wrapper">
              <span class="sp option" @click="vehicle.firstTime = true" :class="{'option--selected': vehicle.firstTime === true}">{{ $t('affirmative') }}</span>
              <span class="separator"></span>
              <span class="option" @click="vehicle.firstTime = false" :class="{'option--selected': vehicle.firstTime === false }">{{ $t('negative') }}</span>
            </div>
            <span class="error">{{ $t(errors[0]) }}</span>
          </validation-provider>
          <button class="btn btn--primary vehicle__form__action" :class="{ disabled: invalid }">{{ $t('sendInfo') }}</button>
        </form>
      </validation-observer>
    </div>
</template>

<script>
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import DropDownRefactor from "@/components/dropdowns/DropDownRefactor";
import DropDown from "@/components/forms/DropDown";

extend("required", { ...required, message: 'validations.requiredField'});

export default {
  components: { ValidationProvider, ValidationObserver, DropDownRefactor, DropDown},
  metaInfo: {
    title: "Las Cañadas Campamento",
    titleTemplate: null
  },
  props: ['visitCode'],
  data: function() {
    return {
      vehicle: {
        type: null,
        otherType: null,
        brand: null,
        otherBrand: null,
        color: null,
        otherColor: null,
        plate: null,
        plateType: null,
        otherPlateType: null,
        firstTime: null,
      },
      properties: {
        colors: null,
        licenses: null,
        vehicleTypes: null,
        brands: null
      },
      keyForm: 0,
      propertyHandlers: {
        "VEHICLE_TYPE": (property) => {
          return this.findInProperties(property, this.properties.vehicleTypes);
        },
        "VEHICLE_BRAND": (property) => {
          return this.findInProperties(property, this.properties.brands);
        },
        "VEHICLE_COLOR": (property) => {
          return this.findInProperties(property, this.properties.colors);
        },
        "VEHICLE_PLAQUE_TYPE": (property) => {
          return this.findInProperties(property, this.properties.licenses);
        },
      }
    };
  },
  computed: {
    vehicleTypeIsPedestrian() {
      return this.vehicle && this.vehicle.type && this.vehicle.type.id === 'PEDESTRIAN'
    }
  },
  mounted() {
    this.getProperties();
  },
  watch: {
    'vehicleTypeIsPedestrian'() {
      this.cleanFiles && this.cleanFiles();
    }
  },
  methods: {
    async saveEntry() {
      try {
        let vehicle = this.getModel();
        this.setOtherValuesAndTranslations(vehicle);
        await this.$store.dispatch('createVisitPlanned', vehicle);
        this.$emit('onSave', vehicle);
      } catch (e) {
        this.$store.dispatch("message", {
          type: "error",
          message: "Error al guardar la entrada"
        });
      }
    },
    async getProperties() {
      let properties = null;
      try {
        properties = (await this.$store.dispatch("loadVehicleProperties"));
        this.properties.colors = this.createOption(properties.colors, 'color');
        this.properties.licenses = this.createOption(properties.licenses, 'vehicle.licensePlates');
        this.properties.vehicleTypes = this.createOption(properties.vehicleTypes, 'vehicle.types');
        this.properties.brands = this.createOption(properties.brands.map(brand => brand.label), 'vehicle.brands');
      } catch (e) {
        this.$store.dispatch("message", {
          type: "error",
          message: "Error al cargar los datos"
        });
      }
    },
    createOption(array, property) {
      let options = [];
      array.forEach(o => {
        if (o.toLowerCase() === "other") {
          return;
        }

        let text = o.toLowerCase();
        text = text.replace(' ','');
        text = property.concat('.'+text.replace('_',''));
        let option = {
          id: o, text: text
        }
        options.push(option)
      });
      return options;
    },
    getModel() {
      let entryPlanned = {
        visit: { code: this.visitCode },
        arrivalDate: new Date(),
        properties: [
          {
            id: { propertyType: "VEHICLE_TYPE" },
            value: this.vehicle.type.id,
            otherValue: this.vehicle.otherType
          },
          {
            id: { propertyType: "VEHICLE_BRAND" },
            value: this.vehicle.brand,
            otherValue: this.vehicle.otherBrand
          },
          {
            id: { propertyType: "VEHICLE_COLOR" },
            value: this.vehicle.color,
            otherValue: this.vehicle.otherColor
          },
          {
            id: { propertyType: "VEHICLE_PLAQUE" },
            value: this.vehicle.plate,
            otherValue: null
          },
          {
            id: { propertyType: "VEHICLE_PLAQUE_TYPE" },
            value: this.vehicle.plateType,
            otherValue: this.vehicle.otherPlateType
          },
          {
            id: { propertyType: "FIRST_TIME" },
            value: this.vehicle.firstTime
          }
        ]
      }
      return entryPlanned;
    },
    cleanFiles() {
      let vehicle = {
          type: this.vehicle.type,
          otherType: null,
          brand: null,
          otherBrand: null,
          color: null,
          otherColor: null,
          plate: null,
          plateType: null,
          otherPlateType: null,
          firstTime: null
        }
        this.$set(this, 'vehicle', vehicle);
        this.keyForm = this.keyForm + 10;
    },
    setOtherValuesAndTranslations(vehicle) {
      vehicle.properties.forEach(property => {

        let propertyHandler = this.propertyHandlers[property.id.propertyType];

        if (!propertyHandler) {
          return;
        }

        let tmp = propertyHandler(property);
        if (tmp) {
          property.text = tmp.text;
          return;
        }
        property.otherValue = property.value;
        property.value = "";
      });
    },
    findInProperties(property, properties) {
      return properties.find(type => type.id === property.value);
    }
  }
};
</script>


<style lang="scss" scoped>
::placeholder { color: $brown-opacity-color }
.disabled {
  pointer-events: none;
  opacity: .6;
}
.vehicle {
  letter-spacing: 0.05em;
  background-color: rgba(#fff, 0.8);
  width: 100%;
  border: 1px solid rgba($dark-brown-color, 0.2);
  border-radius: 1rem;
  &__title {
    font-weight: normal;
    font-size: 1.5rem;
    line-height: rem(33px);
    padding-bottom: 1rem;
  }
  &__instructions {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1rem;
    font-family: $font-primary;
    color: $dark-brown-color;
    text-decoration: none;
  }

  &__form {
    .required { color: red; }
    &__field {
      display: grid;
      grid-gap: 1.5rem;
      label {
        font-weight: 500;
        font-size: 1rem;
        line-height: 1rem;
        margin-bottom: rem(6px);
        color: $dark-brown-color;
        opacity: 0.6;
      }
      input {
        font-weight: 500;
        height: rem(48px);
        margin: 0;
        padding: rem(16px);
        border-radius: 8px;
        font-family: $font-primary;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0.05em;
        color: $dark-brown-color;
      }
      .error {
        top: 4px;
      }
      .select {
        display: grid;
        grid-row-gap: .5rem;
      }
    }
    &__first-time {

      display: grid;
      grid-template-columns: 2fr 1fr;
      font-weight: 500;
      width: 100%;
      max-width: rem(500px);
      align-items: center;
      text-decoration: none;
      grid-column-gap: .5rem;
      color: $dark-brown-color;
      .sp {
        margin-left: rem(16px);
      }
      .option {
        font-family: $font-primary;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0.05em;
        color: $blue-great-color;
        cursor: pointer;
          @include respond-to("small and down"){
              margin-right: rem(-9px);
              padding-right: 3px;
          }
        &--selected {
          background-color: rgba(0, 117, 255, 0.2);;
          border-radius: 4px;
          padding: rem(2px) rem(8px);
        }
      }
      .separator {
        border: 0.5px solid $dark-brown-color;
        margin: 0 rem(12px);
          @include respond-to("small and down"){
            margin-right: rem(15px);
            margin-left: rem(20px);
          }
      }
      .error {
        top: 4px;
      }
    }
  }
  @include respond-to("small and down") {
    &__form {
      &__first-time {
        margin: 1rem 0;
      }
    }
  }
  @include respond-to("medium and down") {
    padding: 1rem;
    padding-bottom: 0;
    &__title {
      font-weight: normal;
      font-size: 1rem;
      line-height: rem(22px);
    }
    &__form {
        margin-top: 1.5rem;
      &__field {
        .field { height: rem(72px) }
        .other {
          height: auto;
          padding: 0 !important;
        }
        label {
          font-size: 1rem;
          line-height: 1rem;
        }
      }
      &__first-time {
        margin: 1.5rem 0;
        align-items: start;
        padding-right: 1rem;
        display: grid;
        grid-template-columns: 2fr 1fr;
        @include respond-to("small and down"){
            padding-right: 1.5rem;
          }
        .text-wrapper {
          align-self: center;
          @include respond-to("small and down"){
            margin-right: rem(-8px);
            text-align: right;
          }
        }
      }
    }
  }
  @include respond-to("medium and up") {
    &__form {
        margin-top: 1.5rem;
      &__field {
        grid-template-areas: "type brand" "color plates" "platesType none";
        grid-template-columns: repeat(2, 1fr);
      }
      &__first-time {
        margin-top: 3.2rem;
        margin-bottom: -1rem;
      }
      &__action {
        width: rem(271px);
        float: right;
      }
    }
  }
  @include respond-to("large and up") {
    padding: 1.5rem;
    &__form {
      margin-top: 3rem;
      &__field {
        grid-template-areas: "type brand color" "plates platesType none";
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}
</style>
