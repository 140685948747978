<template>
<modal ref="modal" class="modal" v-show="isOpen">
    <div class="modal__content confirmation-modal">
      <div class="confirmation-modal__header">
        <h1>Formulario incompleto</h1>
        <a @click.prevent="closeModal" >
          <img src="@/assets/deletesign.svg">
        </a>
      </div>
      <div class="confirmation-modal__content">
        <p><b>¿Deseas salir del formulario de aplicación a esta vacante?</b></p>
        <p>Si sales de este formulario todos los datos llenados se perderán.</p>
      </div>
      <div class="confirmation-modal__actions">
        <button class="btn" @click="submit()">SALIR Y PERDER CAMBIOS</button>
      </div>
    </div>
</modal>
</template>

<script>
import Modal from '@/components/modal/Modal';

export default {
  name: 'LeaveJobApplicationConfirmationModal',
  components: { Modal, },
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
      openModal(callback) {
        this.$refs.modal && this.$refs.modal.open();
        this.isOpen = true;
        this.callback = callback;
      },
      closeModal() {
        this.$refs.modal && this.$refs.modal.close();
        this.isOpen = false;
      },
      submit() {
        this.closeModal();
        if(this.callback)
          this.callback()
      },
  }
}
</script>

<style lang="scss" scoped>
.confirmation-modal {
  max-width: rem(565px);
  background-color: white;
  padding: 1.5rem;
  border-radius: 1rem;
  display: grid;
  grid-gap: 1.5rem;

  .btn {
    cursor: pointer;
  }

  &__header {
    display: grid;
    grid-template-columns: 1fr auto;
    h1 {
      font-size: 24px;
      line-height: 33px;
    }
  }
  &__content {
    p {
      font-weight: 500;
      letter-spacing: 0.05em;
      color: $light-gray;
      b {
        color: $dark-brown-color;
      }
    }
  }
  &__actions {
    .btn {
      background-color: $red-color2
    }
  }

}
</style>