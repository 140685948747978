<template>
    <div class="choose-lake-village">
        <div class="choose-lake-village__content desktop">
            <img :src="require(`@/assets/reservations/lake-village-map--${locale}.png`)" alt="">
            <div class="svg">
                <div class="village-container" :style="coordinates(village, 'desktop')" v-for="(village, index) in villageList" :key="index"
                    :class="{disabled: !village.available, selected: pendingVillagesSelected(village.staying.id)}">
                    <div class="village-container__circle" @click.prevent="village.available && !pendingVillagesSelected(village.staying.id) && updateVillages(village.staying.id, 'Village')">
                        <span class="village-container__circle__number">{{ villages[village.staying.name].number }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="choose-lake-village__content mobile">
            <img :src="require(`@/assets/reservations/lake-village-map-mobile--${locale}.png`)" alt="">
            <div class="svg">
                <div class="village-container" :style="coordinates(village, 'mobile')" v-for="(village, index) in villageList" :key="index"
                    :class="{disabled: !village.available, selected: pendingVillagesSelected(village.staying.id)}">
                    <div class="village-container__circle" 
                        @click.prevent="village.available && !pendingVillagesSelected(village.staying.id) && updateVillages(village.staying.id, 'Village')"
                    >
                        <span class="village-container__circle__number">{{ villages[village.staying.name].number }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    const VILLAGES = {
        V1: {
            circleCx: 476,
            circleCy: 92,
            mobileCircleCx: 37.5,
            mobileCircleCy: 19,
            number: 1
        },
        V2: {
            circleCx: 394,
            circleCy: 112,
            mobileCircleCx: 45.6,
            mobileCircleCy: 32.4,
            number: 2
        },
        V3: {
            circleCx: 306,
            circleCy: 125,
            mobileCircleCx: 51.5,
            mobileCircleCy: 46.3,
            number: 3
        },
        V4: {
            circleCx: 221,
            circleCy: 135,
            mobileCircleCx: 56,
            mobileCircleCy: 60,
            number: 4
        },
        V5: {
            circleCx: 135,
            circleCy: 145,
            mobileCircleCx: 60.5,
            mobileCircleCy: 73.8,
            number: 5
        }
    }

    export default {
    name: 'ChooseLakeVillage',
        props: ['villageList'],
        data() {
            return {
                currentPendingTipis: [],
                currentPendingCabins: [],
                currentPendingVillages: [],
                auxCurrentPendingVillages: [],
                villages: VILLAGES
            }
        },
        watch: {
            '$store.getters.chooseLakeVillageVisible'() {
                this.openModal();
            }
        },
        computed: {
            locale() {
                return this.$i18n.locale;
            },
            quote() {
                return this.$store.getters.quote;
            },
            
        },
        mounted() {
            this.getCurrentPendingVillages();
        },
        methods: {
            getCurrentPendingVillages() {
                this.currentPendingVillages = this.quote ? this.quote.lakeVillageNumbers.map(c => c.id) : [];
                this.currentPendingCabins = this.quote ? this.quote.cabinNumbers.map(c => c.id) : [];
                this.currentPendingTipis = this.quote ? this.quote.tipiNumbers.map(c => c.id) : [];
                this.auxCurrentPendingVillages = [].concat(this.currentPendingVillages);
            },
            pendingVillagesSelected(id) {
                return this.currentPendingVillages.find(stayingId => stayingId === id);
            },
            updateVillages(id) {
                this.currentPendingVillages.shift();
                this.currentPendingVillages = this.currentPendingVillages.concat([id]);
                this.$emit('valid', !this.checkIfhaveNewVillages());
            },
            getArray(list) {
                let array = [];
                list.forEach(element => {
                    if(array.indexOf(element) == -1) {
                        array.push(element);
                    }
                });
                return array;
            },
            async save() {
                let currentPendingLodging = this.currentPendingTipis.concat(this.currentPendingCabins)
                let request = {
                    quoteId: this.quote.id,
                    stayIds: this.currentPendingVillages.concat(currentPendingLodging)
                }
                await this.$store.dispatch('saveReservations', request);
                this.validReservation();
            },
            async validReservation() {
                let unavailable = await this.$store.dispatch('validReservations', this.quote.id);
                if (unavailable.cabins.length == 0 && unavailable.tipis.length == 0 && unavailable.lakeVillages.length == 0) {
                    this.$store.dispatch('updateChooseLakeVillageVisible', false);
                } else {
                    this.getCurrentPendingVillages();
                    this.$emit('valid', false);
                }
            },
            checkIfhaveNewVillages() {
                let list = [].concat(this.currentPendingVillages);
                let auxList = [].concat(this.auxCurrentPendingVillages);
                list.sort();
                auxList.sort();
                return list.length == auxList.length && list.every(function(v,i) { return v === auxList[i] } );
            },
            coordinates(village, deviceType) {
                if(deviceType == 'desktop') {
                    return {
                        'top': this.villages[village.staying.name].circleCy + 'px',
                        'left': this.villages[village.staying.name].circleCx + 'px',
                    }
                } else {
                    return {
                        'top': this.villages[village.staying.name].mobileCircleCy + '%',
                        'left': this.villages[village.staying.name].mobileCircleCx + '%',
                    }
                }
            }
        }
    }
</script>


<style lang="scss" scoped>
.choose-lake-village {
    background-color: white;
    display: grid;
    grid-row-gap: 1.2rem;
    &__content {
        position: relative;
        .svg {
            position: absolute;
            .village-container {
                position: absolute;
                &__circle {
                    background: white;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid #1E0F00;
                    cursor: pointer;
                }
                
            }
            .selected {
                .village-container__circle {
                    background: $red-color2;
                }
                span {
                    color: white;
                }
            }
            .disabled {
                .village-container__circle {
                    border: 1px solid $lighter-gray;
                    cursor: not-allowed;
                }
                span {
                    color: $lighter-gray;
                }
            }
        }
        
        @include respond-to("medium and up") {
            &.mobile { display: none }
            img {
                width: 650px;
            }
            .svg {
                width: 95.5%;
                height: 70.5%;
                bottom: 2%;
                right: 0;
                left: 50%;
                transform: translate(-50%, 0);
            }
        }
        @include respond-to("small and down") {
            &.desktop { display: none }
            .svg {
                width: 70.5%;
                height: 95.5%;
                top: 1.5%;
                right: .5%;
                .village-container__circle {
                    width: 1.8em;
                    height: 1.8em;
                }
            }
        }
        @media (max-width: 280px) {
            .svg {
                .village-container__circle {
                    width: 25px;
                    height: 25px;
                }
            }
        }
    }
}
</style>
