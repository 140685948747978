<template>
    <div class="counter" :class="{'counter--disabled': disabled}">
        <div class="counter__section counter__minus" :class="{ disabled: minusDisabled }">
            <a href="" @click.prevent="subtract"><img src="@/assets/minus-square.svg"></a>
        </div>
        <div class="counter__section counter__quantity">
            <input type="number" :disabled="disabled" @change="updateQuantity" @input="parseQuantityAllowed"
                v-model="quantity" :style="'font-size:' + size + 'px; color:' + fontColor" readonly>
        </div>
        <div class="counter__section counter__plus" :class="{ disabled: plusDisabled }">
            <a href="" @click.prevent="limitReached && add()"><img src="@/assets/plus-square.svg"></a>
        </div>
    </div>
</template>

<script>
    export default {
        name: "counter",
        props: {
            entity: String,
            onChange: Function,
            min: Number,
            disabled: Boolean,
            maxAllowed: {
                type: Number,
                default: 99
            },
            size: {
                type: Number,
                default: 16
            },
            fontColor: {
                type: String,
                default: 'black'
            }
        },
        data() {
            return {
                quantity: 0,
                timeoutObj: null
            }
        },
        mounted() {

        },
        computed: {
            minAllowed() {
                if(!this.min) {
                    return 0;
                }

                return this.min;
            },
            limitReached() {
                return this.quantity < this.maxAllowed;
            },
            minusDisabled() {
                return this.minAllowed >= this.quantity;
            },
            plusDisabled() {
                return this.quantity >= this.maxAllowed;
            }
        },
        methods: {
            parseQuantityAllowed(event) {
                const quantity = event.target.value;
                if(quantity < this.minAllowed) this.quantity = this.minAllowed;
                else if(quantity) this.quantity = Math.round(this.quantity);
                else this.quantity = this.minAllowed;

                if(quantity > this.maxAllowed) this.quantity = this.maxAllowed;
                this.$forceUpdate()
            },
            initQuantity(quantity) {
                this.$set(this, 'quantity', quantity);
            },
            updateQuantity() {
                this.$emit('onQuantityUpdated', 0);
                this.callChange('manual');
            },
            subtract() {
                if(this.disabled || this.minusDisabled)
                    return;

                this.$emit('onQuantityUpdated', -1);
                if(this.quantity == this.minAllowed) return;
                this.quantity--;
                this.callChange('sub');
            },
            add() {
                if(this.disabled || this.plusDisabled)
                    return;

                this.$emit('onQuantityUpdated', 1);
                this.quantity++;
                this.callChange('add');
            },
            setQuantity(quantity) {
                if(this.quantity < this.minAllowed) this.quantity = this.minAllowed;
                else if(this.quantity > this.maxAllowed) this.quantity = this.maxAllowed;
                else this.quantity = quantity;
            },
            callChange(origin) {
                if(this.timeoutObj){
                    clearTimeout(this.timeoutObj);
                }
                this.timeoutObj = setTimeout(() => {
                    try {
                        this.onChange && this.onChange(this.quantity, { action: origin });
                    } catch(e) {
                        return e;
                    }
                }, 300);
            }
        }
    };
</script>

<style scoped lang="scss">
    .counter {
        display: grid;
        grid-template-columns: rem(14px) auto rem(14px);
        grid-column-gap: rem(8px);
        align-items: center;
        &__section {
            img, a, input {
                display: block;
                width: 100%;
            }
            input {
                text-align: center;
                padding: 0;
                border: unset;
                font-family: $font-secondary;
                font-size: rem(16px);
                line-height: rem(22px);
                letter-spacing: 0.05em;
                outline: none;
            }
            input[type=number]::-webkit-inner-spin-button,
            input[type=number]::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
            &.disabled {
                opacity: 0.5;
                a {
                    cursor: not-allowed;
                }
            }
        }

        &--disabled{
            cursor: default;
            .counter__section a{
                    cursor: default;
            }
        }
    }
</style>