<template>
    <div class="video-player" v-if="isOpen">
      <button v-tooltip.top="$t('close')"  @click="close"><img src="@/assets/close.svg"></button>
      <iframe :src="videoSource" frameborder="0" allow="autoplay; " allowfullscreen></iframe>
    </div>
</template>
<script>

export default {
  name: "dropDown",
  data () {
      return {
        isOpen: false,
        videoUrl: process.env.VUE_APP_https://player.vimeo.com/video/971295242?h=903e1567ba&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479,
        videoSettings: "&muted=0&autoplay=1&dnt=1&controls=1"
      }
  },
  computed: {
    videoSource() {
      return this.videoUrl.concat(this.videoSettings);
    }
  },
  methods: {
    open() {
      this.isOpen = true;
      this.disableBodyScroll();

    },
    close() {
      this.enableBodyScroll();
      this.isOpen = false;
    },
    isVisible() {
      return this.isOpen;
    },
    disableBodyScroll() {
      this.bodyOverflow = window.getComputedStyle(document.body).overflow;
      document.body.style.overflow = "hidden";
    },
    enableBodyScroll() {
      document.body.style.overflow = this.bodyOverflow;
    },

  }

};
</script>
<style lang="scss" scoped>
.video-player {
  background-color: black;
  width: 100%;
  height:100vh;
  position: fixed;
  z-index: 9100000000;
  top:0;
  right:0;
  bottom:0;
  left:0;

  iframe {
      width: 100vw;
      height: 100vh;
  }

  button {
    cursor: pointer;
    right: 0;
    background: none;
    position: absolute;
    z-index: 9200000000;
  }

}
.vue-tooltip {
  z-index: 9400000000 !important;
}

</style>