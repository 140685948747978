<template>
    <products-water selected-product="interactiveFountain">
      <div class="product-summary__details" v-if="currentProduct && information">
        <gallery>
          <template slot="small-image">
              <div class="interactive-fountain__content">
                <img class="interactive-fountain__fallback" src="@/assets/interactive-fountain-fallback.png" alt="">
                <iframe class="interactive-fountain__video" :class="{timesleep:'timesleep'}" src="https://player.vimeo.com/video/843728185?muted=true&loop=true&autoplay=1&title=0&byline=0&portrait=0&controls=0&sidedock=0"
                  rameborder="0" allow="autoplay; fullscreen" allowfullscreen>
                </iframe>
              </div>
          </template>
          <template slot="gallery-images">
            <div class="interactive-fountain__content">
              <img class="interactive-fountain__fallback" src="@/assets/interactive-fountain-fallback.png" alt="">
              <iframe class="interactive-fountain__video" :class="{timesleep:'timesleep'}" src="https://player.vimeo.com/video/843728185?muted=true&loop=true&autoplay=1&title=0&byline=0&portrait=0&controls=0&sidedock=0"
                rameborder="0" allow="autoplay; fullscreen" allowfullscreen>
              </iframe>
            </div>
            <img class="slide" v-for="(picture, i) in gallery" :key="i" :src="require('@/assets/products/interactive-fountain/interactive-fountain_' + picture + '.jpg')" alt="">
          </template>
        </gallery>
        <div class="product-summary__details__title">
          <h1>{{ $t(currentProduct.title) }}</h1>
        </div>
        <div class="product-summary__details__content">
          <div class="product-summary__details__content__information">
            <div class="product-summary__details__content__information__description">
              <p v-for="(text, index) in information[currentProduct.id].description" :key="index">{{ $t(text) }}</p>
            </div>
          </div>
        </div>
      </div>
    </products-water>
  </template>
  
  <script>
    import Gallery from '@/components/Gallery';
    import ProductsWater from '@/views/products/ProductsWater';
  
    const information = {
      interactiveFountain: {
        description: {
          text1: 'productPages.interactiveFountain.text1',
          text2: 'productPages.interactiveFountain.text2',
          text3: 'productPages.interactiveFountain.text3',
          text4: 'productPages.interactiveFountain.text4',
        }
      }
    }
  
    export default {
      name: 'IndianVillage',
      components: {ProductsWater, Gallery},
      metaInfo() {
        return {title: this.$t("tabs.interactiveFountain")}
      },
      data() {
        return {
          currentProduct: null,
          gallery: [1,2,3,4,5,6,7,8,9,10,11,12]
        }
      },
      computed: {
          information() {
              return information;
          }
      },
      mounted() {
        this.currentProduct = { id: 'interactiveFountain', title:'interactiveFountain', imageName: 'wave-adventure' };
      }
    };
  </script>
  
  <style lang="scss" scoped>
    .product-summary {
      &__details {
        &__content {
          &__services {
            height: rem(250px);
          }
          &__information {
            &__description {
              a {
                color: $blue-color2;
                font-weight: 400;
                text-decoration: underline;
                cursor: pointer;
              }
            }
          }
        }
      }
      .interactive-fountain {
        
        position: relative;
        &__content {
          position: relative;
          height: 550px;
          overflow: hidden;
          @include respond-to("medium and up") {
            width: 100%;
            left: 0;
          }
        }
        &__video {
          position: absolute;
          z-index: 2;
          bottom: 0;
          width: 100%;
          height: 57vw;
          top: 50%;
          transform: translate(0,-50%);
          @include respond-to("large ") {
            height: 120%;
            width: 120%;
            transform: translate(-10%, -50%);
          }
        }
        
        &__fallback {
          position: absolute;
          width: 100%;
          height: 100%;
        }
      }
    }
  </style>