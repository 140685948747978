<template>
  <div id="app" :class="{ios:isIos()}" @click="clickAnywhere()">
    <hotjar id="hotjar"></hotjar>
    <tawk-to id="tawk-to" v-if="displayMaintenanceView != 'true' && !visible"></tawk-to>
    <google-tag-manager id="tag-manager"></google-tag-manager>
    <remote-activity></remote-activity>
    <message-center class="message-center"></message-center>
    <whats-app-button v-if="displayMaintenanceView != 'true' && !visible"></whats-app-button>
    <facebook-pixel></facebook-pixel>
    <maintenance v-if="displayMaintenanceView == 'true'"/>
    <router-view v-else></router-view>
  </div>
</template>

<script>
  import MessageCenter from '@/components/MessageCenter';
  import RemoteActivity from '@/components/ActivityIndicator';
  import WhatsAppButton from '@/components/WhatsAppButton';
  import Hotjar from '@/service/Hotjar.vue';
  import GoogleTagManager from '@/service/GoogleTagManager.vue';
  import TawkTo from '@/service/TawkTo.vue';
  import moment from 'moment';
  import FacebookPixel from '@/service/FacebookPixel.vue';
  import Maintenance from '@/components/maintenance/Maintenance.vue';

  export default {
    components: { RemoteActivity, MessageCenter, WhatsAppButton, Hotjar, TawkTo, GoogleTagManager, FacebookPixel, Maintenance },
    metaInfo: {
      titleTemplate: '%s | Las Cañadas Campamento'
    },
    beforeCreate() {
      this.$store.dispatch('cleanQuoteState');
    },
    data() {
      return {
        displayMaintenanceView: process.env.VUE_APP_false,
        allowTawkToEvent: true,
      }
    },
    mounted() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      this.getBanner();
      setTimeout(() => {
        this.validateDates();
      }, 2000);
    },
    computed: {
      visit() {
        return this.$store.getters.quote && this.$store.getters.quote.visit;
      },
      visible() {
        const notVisible = this.$route.name == 'careers' || this.$route.name == 'job-application' || this.$route.name == 'job-details';

        if(document.getElementsByClassName('widget-visible')[0]) {
          const childElementId = document.getElementsByClassName('widget-visible')[0].firstChild.id;
          const fatherElementId = document.getElementsByClassName('widget-visible')[0].id;

          if(notVisible) {
            document.getElementById(childElementId).setAttribute('style', 'display: none !important');
          } else if(!notVisible && this.$route.name != 'homepage') {
            document.getElementById(fatherElementId).setAttribute('style', 'right: 20px !important; bottom: 200px !important;');
          } else {
            window.innerWidth < 1330 ?
              document.getElementById(fatherElementId).setAttribute('style', 'right: 20px !important; bottom: 120px !important;') :
              document.getElementById(fatherElementId).setAttribute('style', 'right: 20px !important; bottom: 20px !important;');
          }
          document.getElementById(childElementId).setAttribute('style', 'display: inline !important');
        }
        this.checkTawkTo();

        return notVisible;
      }
    },
    methods: {
      checkTawkTo() {
        setInterval(this.checkTawkToClick, 1000);
      },
      checkTawkToClick() {
        const elem = document.activeElement;
        if (this.allowTawkToEvent && elem && elem.tagName == 'IFRAME' && elem.classList.contains('custom-chat-widget')) {
          this.allowTawkToEvent = false;
          this.onTawkToEvent('tawk to clicked!');
        }
      },
      clickAnywhere() {
        this.allowTawkToEvent = true;
      },
      onTawkToEvent(event) {
        this.$root.$emit('onTawkToEvent', event);
      },
      getBanner() {
        this.$store.dispatch('getBanner');
      },
      isIos() {
        var iOS =
            !!navigator.platform &&
            /iPad|iPhone|iPod/.test(navigator.platform);
        return iOS;
      },
      validateDates() {
        if (this.visit && this.visit.arrivalDate && this.visit.departureDate) {
          let dates = { arrivalDate: moment(this.visit.arrivalDate), departureDate: moment(this.visit.departureDate) };
          if (dates.arrivalDate.diff(moment(), 'days') < 0 || dates.departureDate.diff(moment(), 'days') < 0) {
            let visit = this.visit;
            visit.arrivalDate = moment().format('YYYY-MM-DD')
            visit.departureDate = moment().format('YYYY-MM-DD')
            this.$store.dispatch('updateVisit', visit);
          }
        }
      }
    }
  };
</script>

<style lang="scss">

  #app {
    height: 100%;
  }

  .vue-tooltip {
    z-index: 9400000000 !important;
  }

  .vue-tooltip.tooltip-delete {
    background: rgba(0, 0, 0, 0.6);
    border-radius: 4px;
    max-width: 198px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: rem(12px);
    line-height: rem(16px);
    letter-spacing: 0.05em;
    color: #FFFFFF;
    .tooltip-arrow {
      border-top-color: rgba(0, 0, 0, 0.6) !important;
    }

  }
</style>
