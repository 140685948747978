<template>
    <div class="whatsapp">
        <a class="gtm-chats gtm-whatsapp whatsapp__btn" 
            target="__blank" rel="noopener" 
            :href="whatsAppUrl" 
            :class="{ 
                'medium-bottom': $route.name === 'order.details', 
                'max-bottom': ['shopping-cart','contact-payment'].indexOf($route.name) > -1
            }">
            <img src="@/assets/whatsapp.svg" alt="">
        </a>
    </div>
</template>

<script>
    export default {
        name: 'WhatsAppButton',
        data() {
            return {
                whatsAppUrl:`https://api.whatsapp.com/send?phone=${process.env.VUE_APP_WHATSAPP_NUMBER}&text=Hola%20buen%20dia%20!`
            };
        }
    };
</script>

<style lang="scss" scoped>
    .whatsapp {
        &__btn{
            z-index: 200000;
            position: fixed;
            bottom: rem(84px);
            right: rem(18px);
            cursor: pointer;
            img{ width:rem(60px); }
            @include respond-to("medium and down") {
                bottom: rem(184px);
            }
            &.max-bottom, &.medium-bottom {
                bottom: rem(274px);
            }
        }
    }

    @media screen and (max-width: 765px) {
        .whatsapp {
            .max-bottom, .medium-bottom {
                display: none;
            }
        }
    }
</style>