<template>
    <modal ref="modal" class="modal" v-show="isOpen" @close="updateChooseTipiVisible(false)">
        <div class="modal__content choose-tipis-modal">
            <div class="choose-tipis-modal__header">
                <h1 class="choose-tipis-modal__header__title">{{$t('modal.chooseTepee.pickYourTipi')}}</h1>
                <a href="" class="icon" @click.prevent="updateChooseTipiVisible(false)" ><img src="@/assets/deletesign.svg"></a>
            </div>
            <ul class="choose-tipis-modal__info-marks">
                <li><img src="@/assets/reservations/selected.svg" alt=""><span>{{$t('modal.chooseTepee.selected')}}</span></li>
                <li><img src="@/assets/reservations/available.svg" alt=""><span>{{$t('modal.chooseTepee.available')}}</span></li>
                <li><img src="@/assets/reservations/occupied.svg" alt=""><span>{{$t('modal.chooseTepee.occupied')}}</span></li>
            </ul>
            <div class="choose-tipis-modal__alert" v-show="showSpecialTipiAlert">
                <h2 class="reservation-warning">{{$t('modal.chooseTepee.warning')}}</h2>
            </div>
            <div class="choose-tipis-modal__choose-tipi">
                <choose-tipi ref="chooseTipi" :tipi-list="tipis" @valid="validateForm" @hasSpecialTipi="hasSpecialTipi"></choose-tipi>
            </div>
            <div class="choose-tipis-modal__button">
                <unavailable-reservation></unavailable-reservation>
                <button :class="{disabled: !validForm}" @click.prevent="$refs.chooseTipi.save()">{{$t('modal.chooseTepee.save')}}</button>
            </div>
        </div>
    </modal>
</template>

<script>
    import Modal from '@/components/modal/Modal';
    import ChooseTipi from "@/components/reservations/ChooseTipi";
    import UnavailableReservation from '@/components/UnavailableReservation';

    export default {
    name: 'ChooseTipiModal',
        components: { Modal, ChooseTipi, UnavailableReservation },
        props: ['tipiList'],
        data() {
            return {
                isOpen: false,
                validForm: false,
                showSpecialTipiAlert: false
            }
        },
        watch: {
            '$store.getters.chooseTipiVisible'() {
                this.$store.getters.chooseTipiVisible ? this.openModal() : this.closeModal();
            }
        },
        computed: {
            quote() {
                return this.$store.getters.quote;
            },
            tipis() {
                return this.$store.getters.reservationsAvailable && this.$store.getters.reservationsAvailable.tipis;
            },
            unavailableCabins() {
                return this.$store.getters.unavailableCabins;
            },
            unavailableTipis() {
                return this.$store.getters.unavailableTipis;
            },
            unavailableReservations() {
                return this.unavailableCabins && this.unavailableCabins.length > 0 || this.unavailableTipis && this.unavailableTipis.length > 0;
            }
        },
        methods: {
            openModal() {
                this.$store.dispatch('updateReservationAvailableFlag');
                this.$refs.modal && this.$refs.modal.open();
                this.isOpen = true;
                this.validateForm();
            },
            closeModal() {
                this.$refs.modal && this.$refs.modal.close();
                this.isOpen = false;
            },
            updateChooseTipiVisible(val) {
                setTimeout(() => {
                    this.$store.dispatch('updateChooseTipiVisible', val);
                }, 500);
            },
            validateForm(value) {
                this.validForm = value;
            },
            hasSpecialTipi(hasSpecialTipi) {
                this.showSpecialTipiAlert = hasSpecialTipi;
            }
        }
    }
</script>


<style lang="scss" scoped>
.modal {
    margin-top: 1rem;
    margin-bottom: 1rem;
    max-height: calc(100% - 2rem);
}
.choose-tipis-modal {
    max-width: rem(698px);
    border-radius: 1rem;
    background-color: white;
    padding: 1.5rem;
    display: grid;
    grid-row-gap: 1rem;
    &__header {
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;
        h1 {
            font-size: 1.5rem;
            line-height: 33px;
            padding-bottom: 5px;
        }
    }
    &__choose-tipi {
        padding-top: 2px;
    }
    &__alert {
        padding: 16px;
        background-color: #FFEBCC;

    }
    ul {
        display: grid;
        grid-template-columns: repeat(3, min-content);
        grid-column-gap: 1.5rem;
        align-items: center;
        li {
            display: grid;
            grid-template-columns: 1rem 1fr;
            align-items: center;
            grid-column-gap: .5rem;
        }
    }
    &__button {
        justify-self: end;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr rem(192px);
        grid-column-gap: 1.5rem;
        justify-items: end;
        button{
            width: 100%;
            max-width: rem(192px);
            font-size: rem(20px);
            letter-spacing: 0.05em;
            cursor: pointer;
        }
    }
    @include respond-to("medium and up") {
        &__button {
            grid-template-columns: 1fr auto;;
            padding-top: rem(4px);
        }
    }
    @include respond-to("small and down") {
        max-width: rem(343px);
        height: 100%;
        max-height: rem(851px);
        padding: 1rem;
        grid-row-gap: .5rem;
        overflow: hidden;
        ul {
            grid-template-columns: 1fr;
            grid-template-rows: repeat(3, min-content);
            grid-row-gap: .5rem;
        }
        &__choose-tipi {
            height: 100%;
            overflow-y: scroll;
        }
        &__button {
            grid-template-columns: 1fr;
            grid-row-gap: 1rem;
            button {
                max-width: 100%;
            }
        }
    }
}
.ios {
    .choose-tipis-modal {
        @include respond-to("small and down") {
            &__choose-tipi {
                height: auto;
            }
        }
    }
}
</style>
