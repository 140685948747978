<template>
  <checkout-layout>
    <div class="checkout-layout__content">
      <div v-show="$store.getters.visitDrawerVisible" class="overlay" @click="$store.dispatch('hideVisitDrawer')"></div>
      <visit-drawer ref="visitDrawer" class="visit-drawer" :class="{open: $store.getters.visitDrawerVisible}"></visit-drawer>
      <current-step></current-step>
      <mobile-drawer v-show="$store.getters.mobileDrawerVisible" class="mobile-drawer" :no-button="false" :in-cart="true" ref="mobileDrawer" :class="{'position-fixed': $store.getters.mobilDrawerVisible}"></mobile-drawer>
      <column-block class="container" :class="{'container--empty': !this.quote || this.quote.totalAmount === 0 || (!quote.visit.departureDate)}">
        <div class="content">
          <slot></slot>
        </div>
        <div class="promotions" v-if="this.quote && this.quote.totalAmount > 0 && quote.visit.departureDate">
          <promotions class=""></promotions>
        </div>
        <div class="summary" v-if="this.quote && quote.totalAmount > 0 && quote.visit.departureDate">
          <cart-summary v-if="items && items.length > 0" :items="items" :summaryItems="summaryItems" class="cart__summary"></cart-summary>
          <mobile-summary :items="items" :summaryItems="summaryItems" v-if="!$store.getters.mobileDrawerVisible && items && items.length > 0" class="cart__mobile-summary"></mobile-summary>
        </div>
      </column-block>
    </div>
  </checkout-layout>
</template>

<script>
  import CheckoutLayout from '@/layouts/CheckoutLayout';
  import CartSummary from '@/components/CartSummary';
  import MobileSummary from '@/components/MobileSummary';
  import ColumnBlock from '@/components/columns/ColumnBlock';
  import {mapState} from 'vuex';
  import CurrentStep from '@/views/checkout/CurrentStep';
  import Promotions from "@/components/Promotions";
  import VisitDrawer from '@/components/VisitDrawer';
  import MobileDrawer from '@/components/MobileDrawer';

  export default {
    components: {ColumnBlock, CartSummary, MobileSummary, CheckoutLayout, CurrentStep, Promotions, VisitDrawer, MobileDrawer},
    computed: {
      subTotal() {
        return this.quote.discountAmount + this.quote.totalAmount;
      },
      ... mapState(['quote', 'isUnavailableCabins', 'inRemoteActivity']),
      numberOfDays() {
        return this.quote.visit.numberOfDays == 0 ? 1 : this.quote.visit.arrivalTime == 'DAY' ? 1 : 0;
      },
      dates() {
        let dates = {
          start: this.quote && this.quote.visit && this.quote.visit.arrivalDate,
          end: this.quote && this.quote.visit && this.quote.visit.departureDate
        }
        return dates;
      }
    },
    watch: {
      'quote.items'() {
        this.getPrices();
      },
      'quote.visit.arrivalDate'() {
        this.getPrices();
      },
      'quote.visit.departureDate'() {
        this.getPrices();
      },
      'quote.promotion'() {
        this.getPrices();
      },
      'quote.visitActivityReservations'() {
        this.getPrices();
      },
      'dates'() {
        this.checkIfCapacityIsFull(this.dates);
      }
    },
    data() {
      return {
          items: [],
          prices: {},
          resumeItems: [],
          summaryItems: {
            day: {
              name: null,
              value: null
            },
            camping: {
              name: null,
              value: null
            },
            items: []
          }
      };
    },
    mounted() {
      if(!this.quote || !this.quote.items || this.quote.items.length <= 0) {
        this.$router.push({name: 'homepage'});
      }
      this.getPrices();
    },
    methods: {
      async checkIfCapacityIsFull(dates) {
        let arrivalTime = this.quote && this.quote.visit && this.quote.visit.arrivalTime;
        if(dates && dates.start && dates.end && arrivalTime) {
          Object.assign(dates, {arrivalTime: arrivalTime});
          const capacityIsFull = await this.$store.dispatch('checkIfCapacityWasExceeded', dates);
          this.$store.dispatch('updateCapacityFull', capacityIsFull);
        }
      },
      async getPrices() {
        try {
            let dates = {
              start: this.quote.visit.arrivalDate,
              end: this.quote.visit.departureDate,
            }
            if (dates && dates.start && dates.end) {

              let prices = await this.$store.dispatch('loadPricesByRange', dates);

            if(!prices.error) {
              prices.forEach(date => {
                if(this.prices.cabin == null || date.datePrices.cabinPrice > this.prices.cabin) {
                  this.$set(this.prices, 'cabin', date.datePrices.cabinPrice);
                }
                if(this.prices.tipi == null || date.datePrices.tipisPrice > this.prices.tipi) {
                  this.$set(this.prices, 'tipi', date.datePrices.tipiPrice);
                }
              });
              this.$store.dispatch('updateReservationPrices', this.prices);
              this.getItems();
            }
          }
        } catch (e) {
          return e;
        }
      },
      continueToPayment() {
        this.onGenericEvent(`continuar al pago`, {event_category: 'carrito', event_label: `cta - continuar al pago`});
        this.$store.dispatch('clickToFinishPayment');
      },
      getPricePerItem(item) {
        return (item && item.quantity && item.totalAmount) ? item.totalAmount / item.quantity : 0;
      },
      getItemByKey(key) {
        let item = this.quote && this.quote.productItems.find(i => i.key === key);
        return item ? {price: item.price, quantity: item.quantity, totalAmount: item.totalAmount } : null;
      },
      getVisitActivityByType(type) {
        let activity = null;
        if(this.quote && this.quote.visitActivityReservations && this.quote.visitActivityReservations.length > 0) {
          let reservationsByType = this.quote.visitActivityReservations
              .filter(r => r.visitActivitySchedule.visitActivity.type == type);
          if(reservationsByType.length > 0) {
            activity = {
              quantity: 0,
              hours: 0,
              price: 0,
              type: type
            }
            reservationsByType.forEach(r => {
              activity.quantity = r.quantity + r.borrowedUnits;
              activity.hours += r.hours;
              activity.price = r.activityPrice;
            })
          }
        }
        return activity;
      },
      getItems() {
        let items = [];
        const dayPass = this.getItemByKey('day_pass');
        const campingPass = this.getItemByKey('camping_pass');
        const cabin = this.getItemByKey('cabin');
        const tipi = this.getItemByKey('tipi');
        const lakeVillage = this.getItemByKey('lake_village');
        const atv = this.getVisitActivityByType('ATV');
        const canopyNightPasses = this.getVisitActivityByType('CANOPY_TOUR_NIGHT');
        const canopyPasses = this.getVisitActivityByType('CANOPY_TOUR');
        const horsebackRides = this.getVisitActivityByType('HORSEBACK_RIDING');

        if (dayPass && dayPass.quantity > 0) {
            items.push({
                order: 0,
                name: 'oneDayPass',
                quantity: dayPass.quantity,
                price: this.getPricePerItem(dayPass)
            });
        }

        if (campingPass && campingPass.quantity > 0) {
          items.push({
              order: 1,
              name: 'campingPass',
              quantity: campingPass.quantity,
              price: this.getPricePerItem(campingPass)
          })
        }

        if(cabin) {
          items.push({
            order: 2,
            name: 'ecotouristCabin',
            quantity: cabin.quantity,
            price: cabin.price
          })
        }

        if(tipi) {
          items.push({
            order: 3,
            name: 'teepee',
            quantity: tipi.quantity,
            price: tipi.price
          })
        }

        if (lakeVillage) {
          items.push({
            order: 4,
            name: 'lakeVillage',
            quantity: lakeVillage.quantity,
            price: lakeVillage.price,
          });
        }

        if(canopyPasses) {
          items.push({
            order: 5,
            name: 'canopy',
            quantity: canopyPasses.quantity,
            price: canopyPasses.price
          })
        }

        if(canopyNightPasses){
          items.push({
            order: 6,
            name: 'canopyNight',
            quantity: canopyNightPasses.quantity,
            price: canopyNightPasses.price
          })
        }

        if(horsebackRides) {
          items.push({
            order: 7,
            name: 'horseRide',
            quantity: horsebackRides.quantity,
            price: horsebackRides.price
          })
        }

        if(atv) {
          items.push({
            order: 8,
            name: "atvs",
            quantity: atv.quantity,
            price: atv.price,
            hours: atv.hours
          })
        }

        this.items = this.sortItems(items);
        this.getResume();
      },
      getResume() {
        const atv = this.getVisitActivityByType('ATV');

        const items = {
          day: this.quote.visit.numberOfDays + this.numberOfDays,
          camping: this.quote.visit.numberOfDays,
          persons: this.quote.visit.numberOfPersons,
          cabins: this.quote.products.cabins,
          tipis: this.quote.products.tipis,
          lakeVillages: this.quote.products.lakeVillages,
          canopy: this.quote.products.canopyPasses,
          canopyNight: this.quote.products.canopyNightPasses,
          horsebackRiding: this.quote.products.horsebackRidingPasses,
          atv: atv ? atv.quantity : 0
        }
        this.summaryItems.items = [];
        this.summaryItems.day.value = items.day;
        this.summaryItems.day.name = this.summaryItems.day.value > 1 ? 'days' : 'day';
        this.summaryItems.camping.value = items.camping;
        this.summaryItems.camping.name = this.summaryItems.camping.value > 1 ? 'nights' : 'night';
        this.summaryItems.items.push({name: items.persons > 1 ? 'visitors' : 'visitor', value: items.persons});
        this.summaryItems.items.push({name: items.cabins > 1 ? 'ecotouristCabins' : 'ecotouristCabin', value: items.cabins});
        this.summaryItems.items.push({name: items.tipis > 1 ? 'teepees' : 'teepee', value: items.tipis});
        this.summaryItems.items.push({name: items.lakeVillages > 1 ? 'lakeVillages' : 'lakeVillage', value: items.lakeVillages});
        this.summaryItems.items.push({name: 'canopy', value: items.canopy});
        this.summaryItems.items.push({name: 'canopyNight', value: items.canopyNight});
        this.summaryItems.items.push({name: 'horseRide', value: items.horsebackRiding});
        this.summaryItems.items.push({name: 'atvs', value: items.atv});
      },
      sortItems(items) {
        return items.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0))
      },
      onGenericEvent(event, command) {
        this.$root.$emit('onGenericEvent', event, command);
      },
    }
  };
</script>

<style lang="scss" scoped>
  $dark-color-table: $dark-brown-color;
  .overlay {
    background-color: rgba(black, 0.7);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10000;
  }
  .checkout-layout__content {
    position: relative;
    display: grid;
    grid-template-rows: min-content 1fr;
    grid-row-gap: 1.5rem;
    .visit-drawer {
      top: 56px;
      &.open {
        z-index: 100001;
      }
    }
    .mobile-drawer {
      z-index: 10000;
      &.position-fixed {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
      }
    }

    @include respond-to("small and down") {
      grid-row-gap: 0px;
    }
  }
  .container {
    display: grid;
    @include respond-to("small and down") {
      grid-template-columns: 1fr;
      .cart__summary {
        display: none;
      }
    }
    @include respond-to("large and up") {
      grid-template-rows: auto 1fr;
      grid-template-columns: 1fr $cart-column__width;
      grid-column-gap: 1.5rem;
      grid-template-areas: "content summary" "promotions summary";
      .content { grid-area: content; }
      .summary { grid-area: summary; }
      .promotions { grid-area: promotions; }

      .mobile-summary {
        display: none;
      }

      &--empty {
        grid-template-columns: 1fr;
        grid-column-gap: unset;
        grid-template-areas: "content";
      }
    }
    .content { display: grid }
  }
</style>
