<template>
  <products-earth selected-product="horseBackRiding">
    <div class="product-summary__details" v-if="currentProduct">
      <gallery>
        <template slot="small-image">
          <img :src="require('@/assets/products/horseback-riding/horseback-riding-' + gallery[0] + '.jpg')" alt="">
        </template>
        <template slot="gallery-images">
          <img class="slide" v-for="(picture, i) in gallery" :key="i" :src="require('@/assets/products/horseback-riding/horseback-riding-' + picture + '.jpg')" alt="">
        </template>
      </gallery>
      <div class="product-summary__details__title">
        <h1>{{ $t(currentProduct.title) }}</h1>
      </div>
      <add-to-cart-horse class="product-summary__details__add"></add-to-cart-horse>
      <div class="product-summary__details__content">
        <div class="product-summary__details__content__information">
          <div class="product-summary__details__content__information__description" :class="{ hidden: informationHidden }">
            <ul>
              <li v-for="(text, index) in horseBackRiding.description" :key="index">{{ $t(text) }}</li>
            </ul>
          </div>
          <span class="hide-description" @click="informationHidden = !informationHidden">{{ informationHidden ? $t('readMore') : $t('hide') }}</span>
        </div>
      </div>
    </div>
  </products-earth>
</template>

<script>
  import Gallery from '@/components/Gallery';
  import ProductsEarth from '@/views/products/ProductsEarth';

  export default {
    name: 'hosebackRiding',
    components: {ProductsEarth, Gallery},
    metaInfo() {
      return {title: this.$t("tabs.horsebackRiding")}
    },
    data() {
      return {
        currentProduct: null,
        informationHidden: true,
        gallery: [1, 2, 3],
        horseBackRiding: {
            description: {
                text1: 'productPages.horseBackRiding.text1',
                text2: 'productPages.horseBackRiding.text2',
                text3: 'productPages.horseBackRiding.text3',
                text4: 'productPages.horseBackRiding.text4'
            }
        }
      }
    },
    mounted() {
      this.currentProduct = { id: 'horseBackRiding', title:'horseBackRiding', imageName: 'live-music' };
    }
  };
</script>

<style lang="scss" scoped>
  .product-summary {
    &__details {
      &__title {
            @include respond-to("medium and up"){
                margin-top: 0px;
            }
      }
      &__content {
          &__information {
              &__description {
                  @include respond-to("small and down"){
                      margin-top: -5px;
                  }
                  li {
                    margin-bottom: 1.5rem;
                  }
              }
          }
        &__services {
          height: rem(250px);
        }
      }
    }
  }

</style>