<template>
  <div class="checkbox" @click="clickBox()">
        <img :src="require('@/assets/'+ (check ? 'checked-green.svg' : 'unchecked.svg'))" alt/>
  </div>
</template>

<script>
export default {
    name: 'Checkbox',
    watch: {
        value() {
            this.check = this.value;
        }
    },
    beforeMount() {
        this.check = this.value;
    },
    data(){
        return {
            check: false
        }
    },
    methods: {
        clickBox(){
            if(this.disabled)
                return;
            this.check = !this.check;
            this.$emit('input', this.check);
        },
    },
}
</script>
<style lang="scss" scoped>
.checkbox {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    grid-gap: 0.5rem;
    cursor: pointer;
    &__title {
        cursor: pointer;
        font-family: $font-primary;
        text-transform: uppercase;
        line-height: 20px;
        font-weight: bold;
        margin: 0;
        color: $darker-gray;
        font-size: 1rem;
    }
    &__normal {
      font-size: 0.875rem;
      font-weight: 500;
      margin: 0;
    }
    span {
      color: $red-color2;
    }
    &--disabled{
        cursor: not-allowed;
        .checkbox__title {
            cursor: not-allowed;
        }
    }
}
</style>
