<template>
    <modal ref="modal" @close="updateModal()" v-show="isOpen">
        <div class="modal__content cancelPromo" v-if="promotion">
            <div class="cancelPromo__title">
                <span>{{$t('modal.cancelPromo.title')}}</span>
                <a href="" class="icon" @click.prevent="updateModal()" ><img src="@/assets/deletesign.svg" alt=""></a>
            </div>
            <span class="cancelPromo__description">{{$t('modal.cancelPromo.description1')}} {{promotion.code}} {{$t('modal.cancelPromo.description2')}}</span>
            <button class="cancelPromo__button" @click="cancelPromotion()">{{$t('modal.cancelPromo.cancelPromo')}}</button>
        </div>
    </modal>
</template>

<script>
import Modal from '@/components/modal/Modal'

    export default {
    name: 'ChangeDateCancelPromo',
        components: { Modal },
        props: ['promotion'],
        data() {
            return {
                isOpen: false,
            }
        },
        methods: {
            openModal() {
                this.$refs.modal && this.$refs.modal.open();
                this.isOpen = true;
                this.$store.dispatch('activeModal', true);
            },
            closeModal() {
                this.$refs.modal && this.$refs.modal.close();
                this.isOpen = false;
                this.$store.dispatch('activeModal', false);
            },
            updateModal() {
                this.$emit('refuse');
                this.closeModal();
            },
            cancelPromotion() {
                this.$emit('confirm');
                this.closeModal();
            }
        }
    }
</script>

<style lang="scss" scoped>
    .cancelPromo {
        background-color: $visit-drawer__bg;
        border-radius: 1rem;
        display: grid;
        cursor: default; 
        span, button { letter-spacing: 0.05em; }
        &__title, &__description{
            display: grid;
            align-items: center;
            grid-gap: .5rem;
        }
        &__title {
            grid-template-columns: 1fr 1.5rem;
            text-transform: uppercase;
            font-family: $font-secondary;
            font-size: 1.5rem;
            line-height: 33px;
            a {
                display: grid;
            }
        }
        &__description {
            font-weight: 700;
            line-height: 1.5rem;
            font-family: $font-primary;
            font-size: 1rem;
            letter-spacing: 0.06em !important;
        }
        &__button { 
            cursor: pointer; 
            background: $red-color2;
        }
        @include respond-to("medium and up") {
            grid-row-gap: 1.5rem;
            padding: 1.5rem;
            max-width: rem(533px);
        }
        @include respond-to("small and down") {
            grid-row-gap: 1rem;
            padding: 1rem;
            max-width: rem(343px);
        }
    }
</style>
