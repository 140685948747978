<template>
  <default-layout>
    <div class="product-summary">
      <div class="product-summary__list">
        <h1 class="product-summary__list__title">{{$t(sectionTitle)}}</h1>
        <div class="product-summary__list__products">
          <div class="product-summary__list__products__content">
            <a href="" class="product" v-for="(product, index) in products" :key="index" :id="product.id" :class="{
                  select: currentProduct && currentProduct.id == product.id,
                  subproduct: product.subproduct,
                  'next-subproduct': products && (products.length - 1) > index && products[index + 1].subproduct && !product.subproduct
                }" @click.prevent="changeProduct(product)">
              <img class="picture" :src="require('@/assets/products/'+product.imageName+'--icon--gray.svg')" alt="">
              <h1>{{ $t(product.title) }}</h1>
            </a>
          </div>
        </div>
        <div class="product-summary__list__sections" v-if="sections && sections.length > 0">
          <div class="product-summary__list__sections__content">
            <a href="" class="section" v-for="section in sections" :key="section.id" :id="getIdSection(section.state)" @click.prevent="goSection(section.state)">
              <img class="picture" src="@/assets/products/arrow-right--orange.svg" alt="">
              <h2>{{ $t(section.title) }}</h2>
            </a>
          </div>
        </div>
      </div>
      <slot></slot>
      <div class="product-summary__sections" v-if="sections && sections.length > 0">
        <div class="product-summary__sections__content">
          <a href="" class="section" v-for="section in sections" :key="section.id" :id="getIdSection(section.state)" @click.prevent="goSection(section.state)">
            <img class="picture" src="@/assets/products/arrow-right--orange.svg" alt="">
            <h2>{{ $t(section.title) }}</h2>
          </a>
        </div>
      </div>
    </div>
  </default-layout>
</template>

<script>
  import DefaultLayout from '@/layouts/DefaultLayout';

  export default {
    components: {DefaultLayout},
    name: 'ProductsSummary',
    props: ['products', 'selectedProduct', 'sectionTitle', 'showSectionLinks'],
    data() {
      return {
        currentProduct: null,
        sections: [
          { id: 'funInEarth', title: 'fun.earth', state: 'product.earth' },
          { id: 'funInWater', title: 'fun.water', state: 'product.water' },
          { id: 'funInAir', title: 'fun.air', state: 'product.air' },
          { id: 'lodging', title: 'lodging', state: 'lodging' },
          { id: 'otherServices', title: 'other-services', state: 'services' },
        ]
      }
    },
    mounted() {
      this.currentProduct = this.products.find(product => product.title == this.selectedProduct);
      this.$set(this, 'sections', this.sections.filter(
        section => this.showSectionLinks && section.title != this.sectionTitle));
    },
    methods: {
      changeProduct(product) {
        this.$router.push({ name: product.state }).catch(() => {});
        product.callback && product.callback(product.eventName);
      },
      goSection(name) {
        let currentProduct = this.$route.name.split('.')[1];
        switch (name) {
          case 'product.air':
            this.analyticsProductAir(currentProduct);
            break;
        case 'product.earth':
            this.analyticsProductEarth(currentProduct);
            break;
        case 'product.water':
            this.analyticsProductWater(currentProduct);
            break;
          default:
            break;
        }
        this.$router.push({ name: name }).catch(() => {});
      },
      getIdSection(name) {
        let currentProduct = this.$route.name.split('.')[1];
        switch (name) {
          case 'product.air':
            return `${currentProduct}-menu__air`
        case 'product.earth':
            return `${currentProduct}-menu__earth`
        case 'product.water':
            return `${currentProduct}-menu__water`
          default:
            break;
        }
      },
      analyticsProductAir(currentProduct) {
          this.onGenericEvent(`Diversión en aire`, { event_category: 'Productos', event_label: `${currentProduct}-menu__air` });
      },
      analyticsProductEarth(currentProduct) {
          this.onGenericEvent(`Diversión en tierra`, { event_category: 'Productos', event_label: `${currentProduct}-menu__earth` });
      },
      analyticsProductWater(currentProduct) {
          this.onGenericEvent(`Diversión en agua`, { event_category: 'Productos', event_label: `${currentProduct}-menu__water` });
      },
      onGenericEvent(event, command) {
        this.$root.$emit('onGenericEvent', event, command);
      },
    }
  };
</script>

<style lang="scss">
.product-summary {
  display: grid;
  letter-spacing: 0.065em;
  grid-row-gap: rem(16px);
  &__list {
    &__title {
      font-weight: normal;
      color: $brown-opacity-color;
    }
    .product {
      cursor: pointer;
      text-decoration: none;
      display: grid;
      grid-column-gap: .5rem;
      img, h1 {
        align-self: center;
        font-weight: bold;
        font-weight: 400;
      }
      img { justify-self: center }
    }
    &__sections {
      display: none;
    }
  }
  &__sections {
    display: none;
  }
  &__details {
    display: grid;
    .gallery {
      border-radius: 16px;
    }
    &__title {
      display: grid;
    }
    &__content {
      display: grid;
      &__description {
        display: grid;
        grid-row-gap: 1.5rem;
      }
      &__information {
        font-weight: 500;
        font-size: 1rem;
        line-height: rem(28px);
        display: grid;
        &__description {
          display: grid;
          grid-template-rows: auto 1fr;
          grid-row-gap: rem(30px);
          margin-top: -4px;
          p {
            font-weight: 500;
            font-size: 1rem;
            font-family: $font-primary;
            color: $dark-brown-color;
          }
          li {
            font-weight: 500;
          }
        }
        &__subtitles {
          display: grid;
          grid-row-gap: 1.5rem;
          color: $dark-brown-color;
          li {
            display: grid;
            grid-row-gap: .5rem;
            list-style: none;
            padding-bottom: rem(4px);
            .subtitle {
              line-height: 1.5rem;
              font-family: $font-secondary;
            }
            .text {
              line-height: 1.5rem;
              font-weight: 500;
              font-family: $font-primary;
            }
          }
        }
      }
      &__services {
        display: grid;
        &__title {
          font-weight: normal;
          color: $brown-opacity-color;
          margin-bottom: 8px;
        }
        &__item {
          display: grid;
          grid-template-columns: rem(30px) auto;
          grid-column-gap: .5rem;
          line-height: rem(30px);
          height: 48px;
          img {
            justify-self: center;
          }
          span{
            font-weight: 500;
            color: $dark-brown-color;
          }
          span, img {
            align-self: center;
          }
        }
      }
    }
  }
  @include respond-to("medium and down") {
    padding: 1.5rem 0;
    height: auto;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
    grid-row-gap: 1.5rem;
    &__details, &__list {
      height: auto;
    }
    &__list {
      border-bottom: 1px solid $brown-opacity-color;
      display: grid;
      grid-row-gap: 1.5rem;
      &__title {
        justify-self: center;
        font-size: 1.5rem;
        line-height: rem(33px);
      }
      &__products {
        overflow-x: scroll;
        overflow-y: hidden;
        &__content {
          display: grid;
          grid-auto-flow: column;
          grid-column-gap: 1.5rem;
          align-items: start;
          padding-bottom: 1rem;
          margin-bottom: -1px;
          overflow: hidden;
          width: max-content;
          .product {
            grid-template-columns: 1.5rem auto;
            &.select {
              padding-bottom: rem(9px);
              border-bottom: 3px solid $orange-color;
            }
            img, h1 {
              line-height: rem(22px);
            }
            img { width: rem(18px) }
          }
        }
      }
    }
    &__sections {
      display: block;
      margin-top: rem(46px);
      border-top: 1px solid rgba($dark-brown-color, 0.6);
      padding-top: rem(24px);
      &__content {
        a {
          display: block;
          img, h2 {
            display: inline-block;
            vertical-align: middle;
          }
          img {
            margin-right: rem(11px);
          }
          h2 {
            font-weight: 500;
            color: rgba($dark-brown-color, 0.6);
            font-size: rem(24px);
          }
        }
      }
    }
    &__details {
      grid-row-gap: 1rem;
      &__title {
        padding-top: .5rem;
        h1 {
          font-weight: normal;
          line-height: 2rem;
        }
      }
      &__title {
        grid-row-gap: rem(12px);
      }
      &__content {
        grid-row-gap: 1.5rem;
        &__information {
          padding-top: .5rem;
          &__description {
            margin-top: 3px;
            &.hidden {
              max-height: rem(108px);
              overflow: hidden;
            }
            p {
              height: 100%;
              line-height: rem(27px);
            }
          }
          .hide-description {
            color: #0075FF;
            font-style: normal;
            font-weight: 500;
            font-size: 1rem;
            line-height: 1rem;
          }
          &__subtitles {
            margin-top: -8px;
            li {
              .subtitle {
                line-height: 1.5rem;
              }
              .text {
                line-height: 26px;
              }
            }
          }
        }
        &__services {
          &__title {
            font-weight: normal;
            font-size: 1.5rem;
            line-height: 1.5rem;
            letter-spacing: .02em;
          }
        }
      }
    }
  }
  @include respond-to("large and up") {
    padding: 1.5rem 0 rem(120px) 0;
    width: 100%;
    grid-template-columns: rem(330px) auto;
    &__list {
      border-right: 1px solid $brown-opacity-color;
      &__title {
        font-weight: normal;
        font-size: 1.5rem;
        line-height: rem(33px);
        padding-bottom: 1.5rem;
      }
      &__products {
        &__content {
          display: grid;
          grid-row-gap: .5rem;
        }
      }
      .product {
        position: relative;
        display: grid;
        width: max-content;
        grid-template-columns: 2rem 1fr;
        margin-bottom: 25px;
        img, h1 {
          display: inline-block;
          vertical-align: middle;
          font-size: 1.5rem;
          line-height: rem(33px);
        }
        &.select::after {
          position: absolute;
          left: 0;
          top: 100%;
          height: rem(3px);
          background: $orange-color;
          content: "";
          width: 100%;
          display: block;
        }
        &:last-child { margin-bottom: 3rem }
      }
      &__sections {
        display: block;
        &__content {
            display: grid;
            row-gap: 32px;
          a {
            display: block;
            img, h2 {
              display: inline-block;
              vertical-align: middle;
            }
            img {
              margin-right: rem(11px);
            }
            h2 {
              font-weight: 500;
              color: rgba($dark-brown-color, 0.6);
              font-size: rem(24px);
            }
          }
        }
      }
    }
    &__details {
      grid-template-columns: 1fr rem(330px);
      grid-row-gap: 1.5rem;
      grid-column-gap: rem(24px);
      padding-left: 1.5rem;
      .gallery {
        grid-column: 1/3;
        width: 100%;
        height: 100%;
      }
      &__title {
        position: relative;
        padding-top: 1.5rem;
        .title {
          padding: 2rem 0 rem(5px) 0;
          grid-column: 1/span 2;
          position: absolute;
        }
        h1 {
          line-height: rem(40px);
          align-self: start;
          font-size: rem(40px);
        }
      }

      &__add {
        grid-column: 2/3;
        grid-row: 2/4;
      }

      &__content {
        grid-column: 1/3;
        grid-row: 3;
        grid-template-columns: 1fr rem(330px);
        grid-column-gap: 1.5rem;
        &__information {
          .hide-description { display: none }
          &__description {
            grid-row-gap: 1.5rem;
            p { line-height: 1.5rem }
          }
          &__subtitles {
            padding-top: 1rem;
          }
        }
        &__services {
          &__title {
            font-size: 1.5rem;
            line-height: 1.5rem;
          }
          // grid-row-gap: 1.5rem;
          row-gap: 8px ;
        }
      }
    }
  }
}

.ios {
  .product-summary {
    @include respond-to("medium and down") {
      &__list {
        &__products {
          overflow-y: hidden;
        }
      }
    }
  }
}
</style>