<template>
  <default-layout>
    <section class="header">
      <h1 class="header__title">{{ $t('prices') }}</h1> 
    </section>

    <section class="cards-container">
      <h2 class="cards-container__title">{{ $t('admissionPrices') }}</h2>
      <p class="cards-container__description">{{ $t('admissionPricesDesc') }}</p>
      <div v-if="tariffs.length > 0" class="cards">
        <tariffs-card v-for="(tariff, index) in tariffs" :key="index" :tariff="tariff"></tariffs-card>
      </div>
    </section>

    <section class="cards-container">
      <h2 class="cards-container__title">{{ $t('aditionalExperiences') }}</h2>
      <div class="cards medium-width-cards">
        <activity-info-card v-for="(activity, index) in activities" :key="index" :activity="activity"></activity-info-card>
      </div>
    </section>

    <section class="calendar">
      <h2 class="calendar__title">{{ $t('priceCalendar') }}</h2>
      <p class="calendar__description">{{ $t('priceCalendarDesc') }}</p>
      <calendars :rows="3" :columns="4" :attributes="attributes" :tariffs="tariffs"></calendars>
    </section>
    <section class="calendar calendar--mobile">
      <h2 class="calendar__title">{{ $t('admissionPrices') }}</h2>
      <p class="calendar__description">{{ $t('priceCalendarDesc') }}</p>
      <calendars :rows="12" :columns="1" :attributes="attributes" :tariffs="tariffs"></calendars>
    </section>
   

  </default-layout>
</template>

<script>
  import DefaultLayout from '@/layouts/DefaultLayout';
  import TariffsCard from './TariffsCard';
  import ActivityInfoCard from './ActivityInfoCard';
  import Calendars from './Calendars';
  import moment from "moment";

  const COLORS = ['BLACK', 'RED', 'YELLOW', 'BLUE'];

  export default {
    name: 'Calendar',
    components: {DefaultLayout, TariffsCard, ActivityInfoCard, Calendars},
    metaInfo() {
      return {title: this.$t("tabs.prices")}
    },
    data() {
      return {
        tariffs: [],
        attributes: [],
        activities: [
          {
            name: 'canopyTour',
            image: 'canopy-icon.svg',
            normalPrice: '950 MXN',
            specialPrice: '600 MXN',
            nightPrice: '750 MXN',
          },
          {
            name: 'horseRide',
            image: 'horseride-icon.svg',
            normalPrice: '700 MXN',
            specialPrice: '500 MXN',
          },
          {
            name: 'quadbikeRental',
            image: 'quadbike-icon.svg',
            normalPrice: '1000 MXN',
            specialPrice: '800 MXN',
          },
        ]
      }
    },
    mounted() {
      this.loadPrices();
    },
    methods: {
      async loadPrices() {
        let currentYear = new Date().getFullYear();
        let firstDayOfYear = `${currentYear}-01-01`;
        let lastDayOfYear = `${currentYear}-12-31`;
        let prices = await this.$store.dispatch('loadPrices', {start: firstDayOfYear, end: lastDayOfYear});
        this.generateTariffs(prices);
        this.generateAttributes(prices);
      },
      generateTariffs(prices) {
        COLORS.forEach(color => {
          let tariff = this.getPricesByColor(color, prices);
          if (!tariff) {
            return;
          }
          this.tariffs.push({
            ...tariff.datePrices
          });
          this.tariffs.sort((a,b) => (a.color > b.color) ? 1 : ((b.color > a.color) ? -1 : 0));
        });
      },
      getPricesByColor(color, prices) {
        prices = prices.filter(price => price.datePrices.color == color);
        let tariff = prices.find(price => moment(price.date).isSameOrAfter(moment())); // checks if prices after today exist
        if(!tariff) {                                                                  // else it takes the last instance of that color
          let tariffs = prices.filter(price => price.datePrices.color == color)
              .sort((a,b) => moment(b.date).valueOf() - moment(a.date).valueOf());
          tariff = tariffs && tariffs.length > 0 ? tariffs[0] : null;
        }
        return tariff;
      },
      generateAttributes(prices) {
        COLORS.forEach(color => {
          let dates = this.getDatesByColor(color, prices);
          if (!dates) {
            return;
          }
          let colorTariff = this.tariffs.find(tariff => tariff.color == color);
          this.attributes.push(this.addAttribute(color.toString().toLowerCase() , dates, colorTariff));
        });
      },
      getDatesByColor(color, prices) {
        return prices.filter(prices => prices.datePrices.color == color)
          .map(d => d.date);
      },
      addAttribute(color, dates, tariff) {
        return {
          key: color,
          highlight: {
            color,
          },
          content: `${color}`,
          dates: dates,
          popover: {
            visibility: 'click',
            placement: 'auto',
            customData: {
              ...tariff
            }
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  ::-webkit-scrollbar {
    width: 8px;
    height: 10px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: #c2c9d2;
  }
  .header {
    &__title {
      font-size: 2rem;
      letter-spacing: 0.05em;
      padding-top: 2.5rem;
    }
  }
  .cards-container {
    display: grid;
    justify-self: center;
    grid-template-columns: 1fr;
    max-width: 1392px;
    width: 100%;
    &__title {
      font-size: 1.5rem;
      letter-spacing: 0.05em;
      margin-bottom: 0.5rem;
      padding-top: 2.5rem;
    }
    .cards {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: 1fr;
      grid-column-gap: 1rem;
      overflow: scroll;
      background-color: white;
      border-radius: .5rem;
      margin-top: 2rem;
      padding: 1rem;
    }
  }
  .calendar {
    display: grid;
    padding: 1.5rem 0 rem(195px);
    &__title {
      font-size: 1.5rem;
      letter-spacing: 0.05em;
      margin-bottom: 0.5rem;
      padding-top: 2.5rem;
    }
    &__description {
      margin-bottom: 2rem;
    }
    &--mobile {
      display: none;
    }
  }

@include respond-to("large and down") {
  .cards-container {
    &__title {
      font-size: 1.5rem;
      padding: 1.5rem 1rem 0;
    }
    .cards {
      margin-top: 1rem;
      &::after {
        content: '';
        width: rem(1px);
      }
    }
    .medium-width-cards {
      grid-auto-columns: 1fr;
    }
  }
  .calendar {
    display: none;
    &--mobile {
      display: block;
    }
  }
}

@include respond-to("medium and up") {
  .cards-container {
    .cards {
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
    }
  }
}

@include respond-to("medium and down") {
  .cards-container {
    &__title {
      padding-left: 0;
    }
    .cards {
      border: 1px solid $brown-opacity-2-color;
      border-color: #cbd5e0;
      grid-auto-columns: 1fr;
      margin-right: 16px;
      margin-left: 0;
      padding: 1rem;
    }
  }
}
</style>