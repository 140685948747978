<template>
  <base-information :title="title">
    <iframe height="417" frameborder="0" style="border:0; width:100%" :src="url" allowfullscreen></iframe>
  </base-information>
</template>

<script>
import BaseInformation from "./BaseInformation";

export default {
  components: {BaseInformation},
  metaInfo() {
    return {title: this.$t("tabs.howToGetThere")}
  },
  computed: {
    title() {
      return this.$t('directions');
    },
    url() {
      return `https://www.google.com/maps/embed/v1/place?q=place_id:ChIJA6OeWueD2IARCeiAwQEy0MQ&key=${process.env.VUE_APP_MAP_KEY}&zoom=10`
    }
  }
}
</script>

<style lang="scss" scoped>

</style>