<template>
    <div v-if="display" class="unavailable">
        <label for="" v-if="fullCabins === false">
            {{ cabins }}
        </label>
        <label for="" v-if="fullTipis === false">
            {{ tipis }}
        </label>
        <label for="" v-if="fullLakeVillages === false">
            {{ lakeVillages }}
        </label>
        {{$t('unavaiilableInYourVisit')}}
        <a v-if="$route.name === 'contact-payment'" href="" @click.prevent="$router.push({name: 'shopping-cart'})">
            {{$t('goToCart')}}
        </a>
    </div>
</template>
<script>
import {mapState} from 'vuex';

export default {
    computed: {
        ...mapState(['fullCabins', 'fullTipis', 'fullLakeVillages', 'unavailableCabins', 'unavailableTipis', 'unavailableLakeVillages']),
        display() {
            return (this.fullCabins === false && this.unavailableCabins && this.unavailableCabins.length > 0)
                || (this.fullTipis === false && this.unavailableTipis && this.unavailableTipis.length > 0)
                || (this.fullLakeVillages === false && this.unavailableLakeVillages && this.unavailableLakeVillages.length > 0)
        },
        cabins() {
            return this.unavailableCabins && this.unavailableCabins.length > 1
                ? `${this.unavailableCabins.length} ${this.$t('cabins').toLowerCase()}`
                : this.unavailableCabins.length == 1
                    ? `${this.unavailableCabins.length} ${this.$t('cabin').toLowerCase()}` : '';
        },
        tipis() {
            return this.unavailableTipis && this.unavailableTipis.length > 1
                ? `${this.unavailableTipis.length} ${this.$t('teepees').toLowerCase()}`
                : this.unavailableTipis.length == 1
                    ? `${this.unavailableTipis.length} ${this.$t('teepe').toLowerCase()}` : '';
        },
        lakeVillages() {
            return this.unavailableLakeVillages && this.unavailableLakeVillages.length > 1
                ? `${this.unavailableLakeVillages.length} ${this.$t('villages').toLowerCase()}`
                : this.unavailableLakeVillages.length == 1
                    ? `${this.unavailableLakeVillages.length} ${this.$t('village').toLowerCase()}` : '';
        },
    }
};
</script>
<style lang="scss" scoped>
.unavailable {
    line-height: 1.5rem;
    font-weight: 500;
    color: $red-color2;
    a {
        text-decoration: none;
        font-weight: 500;
        color: $blue-great-color;
    }
}
</style>