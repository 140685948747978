<template>
    <div class="visit-stays">
        <div class="visit-stays__header">
            <p>{{ $t('chooseYourStay') }}</p>
        </div>
        <div class="visit-stays__content" v-if="quote && quote.visit">
            <div class="visit-stays__content__cards"
                v-for="(stay, index) in stayType" :key="index"
                :class="{selected: stay.id == currentVisitStay}"
                @click.prevent="setStayType(stay.id)">
                <div class="visit-stays__content__cards__image"
                    :class="!stay.imgSrc.includes('camping') ? 'align-images' : 'align-camping'"
                    :style="'background-image: url(' + stay.imgSrc +')'"></div>
                <div class="visit-stays__content__cards__info">
                    <div class="card-header">
                        <img :src="stay.iconSrc" alt="">
                        <span class="stay-name">{{ $t(stay.name) }}</span>
                    </div>
                    <div class="card-description">
                        <span>{{ stay.hour }}</span>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>

const CABIN = 'CABIN';
const TIPI = 'TIPI';
const LAKE_VILLAGE = 'LAKE_VILLAGE';

import moment from 'moment';
import {mapState} from 'vuex';
export default {
    name: 'StayOptions',
    watch: {
        'analyticsCabin'() {
            if(this.analyticsCabin == 'Subtract') {
                this.analyticsCabinSubtract();
            } else if(this.analyticsCabin == 'Add') {
                this.analyticsCabinAdd();
            } else if(this.analyticsCabin == 'Manual') {
                this.analyticsCabinManual();
            }
        }
    },
    props: [ 'prices', 'stayType', 'analyticsCabin' ],
    data() {
        return {
            isCampingPass: true,
            currentVisitStay: null
        }
    },
    computed: {
        ... mapState(['quote', 'isUnavailableCabins', 'isUnavailableTipis', 'isUnavailableLakeVillages']),
    },
    mounted() {

        if(this.quote) {
            this.currentVisitStay = this.quote.visitType;

            if(this.quote.visitType && (this.quote.visitType == CABIN
                || this.quote.visitType == TIPI || this.quote.visitType == LAKE_VILLAGE)) {

                if (this.quote.visitType == CABIN) {
                    this.toggleCabins();
                } else if (this.quote.visitType == TIPI) {
                    this.toggleTipis();
                } else if (this.quote.visitType == LAKE_VILLAGE) {
                    this.toggleLakeVillages();
                }

            }
        }
    },
    methods: {
        openChooseTipiModal() {
            this.$store.dispatch('updateChooseTipiVisible', true);
        },
        openChooseCabinModal() {
            this.$store.dispatch('updateChooseCabinVisible', true);
        },
        openChooseLakeVillageModal() {
            this.$store.dispatch('updateChooseLakeVillageVisible', true);
        },
        async setStayType(newStayType) {
            await this.$store.dispatch('createEmptyQuote');

            if(newStayType == 'DAY_PASS') {
                this.analyticsDayPass();
            } else if(newStayType == 'CAMPING_PASS') {
                this.analyticsCampingPass();
            }

            const arrivalDate = this.quote && this.quote.visit && this.quote.visit.arrivalDate;
            const arrivalTime = this.quote && this.quote.visit && this.quote.visit.arrivalTime;
            const departureDate = this.calculateDepartureDate(arrivalDate, newStayType);

            this.currentVisitStay = newStayType;

            await this.updateVisit(newStayType, arrivalDate, departureDate, arrivalTime);
            switch (newStayType) {
                case TIPI:
                    this.toggleTipis();
                    break;
                case CABIN:
                    this.toggleCabins();
                    break;
                case LAKE_VILLAGE:
                    this.toggleLakeVillages();
                    break;
                default:
                    break;
            }

            this.$emit("staySelected", this.currentVisitStay);
        },
        async updateVisit(stayType, arrivalDate, departureDate, arrivalTime) {
            await this.$store.dispatch('updateVisit', {
                stayType,
                arrivalDate,
                departureDate,
                arrivalTime,
                numberOfPersons: 0
            });
        },
        toggleCabins() {
            const cabinsQuantity = this.quote.products.cabins == 0
                ? 1
                : this.quote.cabinNumbers.length != 0
                    ? this.quote.cabinNumbers.length
                    : 0;

            this.$emit('setCabins', cabinsQuantity);
            if(!this.quote.offer3x2) {
                this.onGenericEvent(`selección cabañas`, { event_category: 'cotizador', event_label: `tipo de estancia` });
            }
        },
        toggleTipis() {
            const tipisQuantity = this.quote.products.tipis == 0
                ? 1
                : this.quote.tipiNumbers.length != 0
                    ? this.quote.tipiNumbers.length
                    : 0;

            this.$emit('setTipis', tipisQuantity);
        },
        toggleLakeVillages() {
            const villagesQuantity = this.quote.products.lakeVillages == 0
                ? 1
                : this.quote.lakeVillageNumbers.length != 0
                    ? this.quote.lakeVillageNumbers.length
                    : 0;

            this.$emit('setLakeVillages', villagesQuantity);
        },
        analyticsDayPass() {
            this.onGenericEvent(`selección pase por día`, { event_category: 'cotizador', event_label: `tipo de estancia` });
        },
        analyticsCampingPass() {
            this.onGenericEvent(`selección acampado`, { event_category: 'cotizador', event_label: `tipo de estancia` });
        },
        analyticsCabinSubtract() {
            this.onGenericEvent(`control disminuir cantidad cabañas`, { event_category: 'cotizador', event_label: `control` });
        },
        analyticsCabinAdd() {
            this.onGenericEvent(`control aumentar cantidad cabañas`, { event_category: 'cotizador', event_label: `control` });
        },
        analyticsCabinManual() {
            this.onGenericEvent(`input cantidad cabañas`, { event_category: 'cotizador', event_label: `input` });
        },
        calculateDepartureDate(arrivalDate, newStayType) {

            if (newStayType === 'DAY_PASS') {
                return arrivalDate ? moment(arrivalDate).format('YYYY-MM-DD') : null;
            }

            if (this.quote && this.quote.visit && this.quote.visit.stayType === 'DAY_PASS') {
                return arrivalDate ? moment(arrivalDate).add(1, 'days').format('YYYY-MM-DD') : moment().add(1, 'days').format('YYYY-MM-DD');
            }

            if (this.quote && this.quote.visit && this.quote.visit.departureDate && newStayType !== 'DAY_PASS') {
                return this.quote.visit.departureDate;
            }

            return null;
        },
        onGenericEvent(event, command) {
            this.$root.$emit('onGenericEvent', event, command);
        },
    }
};
</script>
<style lang="scss" scoped>
.visit-stays {
    &__header {
        width: 100%;
        height: 41px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-bottom: 32px;
        gap: 4px;
        p {
            font-family: 'Chau Philomene One';
            font-style: normal;
            font-weight: 400;
            font-size: 32px;
            text-align: center;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: $light-gray;
            height: 41px;
            width: 100%;
        }
    }
    &__content {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 32px;
        width: auto;
        height: 262px;
        &__cards {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0px 0px 16px;
            gap: 16px;
            width: 218px;
            height: 262px;
            border-radius: 0px 0px 16px 16px;
            background: #FAFAF9;
            position: relative;
            cursor: pointer;
            &__image {
                width: 100%;
                height: 186px;
                border-radius: 16px 16px 0px 0px;
                position: absolute;
                top: 0;
                background-size: cover;
            }
            &__info {
                display: grid;
                grid-template-rows: 24px 24px;
                align-items: center;
                z-index: 1;
                width: 100%;
                height: 44px;
                position: absolute;
                bottom: 0;
                margin: 16px 0;
                font-style: normal;
                letter-spacing: 0.05em;
                .card-header {
                    display: grid;
                    grid-auto-flow: column;
                    grid-gap: 4px;
                    align-items: center;
                    justify-self: center;
                    img {
                        height: 24px;
                        width: 24px;
                    }
                    span {
                        text-transform: uppercase;
                        font-family: 'Chau Philomene One';
                        font-weight: 400;
                        font-size: 16px;
                        color: $dark-brown-color;
                    }
                }
                .card-description {
                    font-family: 'Montserrat';
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 16px;
                    text-align: center;
                    color: #272727;
                }
            }
            &.disabled {
                opacity: 0.5;
                pointer-events: none;
            }
            &.selected {
                outline: 4px solid #FF9900;
                border-radius: 16px;
            }
        }
    }
}
.align-images {
    background-position: center;
}
.align-camping {
    background-position: 8%;
    @include respond-to("medium") {
        background-position: 20%;
    }
    @include respond-to("xsmall and down") {
        background-position: 25%;
    }
    filter: brightness(140%);
}
</style>